import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../../../components/SideBar";
import DashboardNavBar from "../../../../../components/DashboardNavbar";
import { getPromotionSubscriber } from "../../../../../reduxStore/action/action.creator";
import { Loader } from "../../../../../components/Accessories";
import {
  PromotionSubscriberBody,
  PromotionSubscriberHead,
} from "../../../../../components/Rows/PromotionTable";

class PromotionSubscriber extends React.Component {
  state = {};

  componentDidMount() {
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("n/") + 2, index.indexOf("/su"));

    this.props.getPromotionSubscriber(index, this.props.token);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <PromotionSubscriberHead />
          <tbody>
            {this.props.promotion_subscribers &&
              this.props.promotion_subscribers.map((content) => (
                <PromotionSubscriberBody {...content} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            padding: "0 .5em 0 0",
                            textAlign: "center",
                          }}>
                          <i
                            class="fas fa-chevron-left"
                            style={{
                              color: "#fff",
                              padding: "0.3em .5em",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}></i>
                        </span>
                        <span style={{ color: "#fff" }}>Back</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Subscribers</h3>
                    </div>
                    <div className="table-responsive py-4 table-div">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.promotions.getSubscribers.loading,
  promotion_subscribers: state.promotions.getSubscribers.promotion_subscribers,
  token: state.login.token,
});

export default connect(mapStateToProps, { getPromotionSubscriber })(
  PromotionSubscriber
);
