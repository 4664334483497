import React from "react";
import { currencyFormatter, dateFormatter, timeFormatter } from "../Row";

export const CompanyHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Company Name</th>
      <th>Company Address</th>
      <th>Enabled</th>
      <th>Date Joined</th>
      <th>Date Approved</th>
      <th>Created By</th>
      <th>Updated By</th>
      <th>Phone Number</th>
      <th>Email Address</th>
      <th>CAC Number</th>
      <th>Staff Strength</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

const getAdmin = (admin_data, id) => {
  const data = admin_data && admin_data.find((content) => content.id == id);
  return data && data.username;
};

const getCompany = (company_data, id) => {
  const data = company_data && company_data.find((content) => content.id == id);
  return data && data.company_name;
};

const getRole = (role_data, id) => {
  const data = role_data && role_data.find((content) => content.id == id);
  return data && data.name;
};

const getMerchant = (merchant, id) => {
  const data = merchant && merchant.find((content) => content.id == id);
  return data && data.name;
};

const getCycle = (cycle, id) => {
  const data = cycle && cycle.find((content) => content.id == id);
  return data && data.cycle_name;
};

const getCategory = (category, id) => {
  const data = category && category.find((content) => content.id == id);
  return data && data.name;
};

const getWallet = (wallets, id, item) => {
  const data = wallets && wallets.find((content) => content.id == id);
  return data && data.wallet_number;
};

export const CompanyBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.company_name}</td>
    <td>{props.company_address}</td>
    <td>{props.enabled ? "Yes" : "No"}</td>
    <td>{dateFormatter(props.date_joined)}</td>
    <td>{dateFormatter(props.date_approved)}</td>
    <td>{getAdmin(props.admin_data, props.created_by)}</td>
    <td>{getAdmin(props.admin_data, props.updated_by)}</td>
    <td>{props.phone_number}</td>
    <td>{props.email_address}</td>
    <td>{props.cac_number}</td>
    <td>{props.staff_strength}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-eye" onClick={() => props.viewCompany(props)}></i>
      <i className="fa fa-pencil" onClick={() => props.editCompany(props)}></i>
    </td>
  </tr>
);

export const InvestRoleHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const InvestRoleBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editRole(props)}></i>
    </td>
  </tr>
);
export const MerchantTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Phone Number</th>
      <th>Created By</th>
      <th>Approval Date</th>
      <th>Created Date</th>
      <th>Enabled</th>
      <th>Company</th>
      <th>Email</th>
      <th>Role</th>
      <th>Action</th>
      <th>Lock</th>
    </tr>
  </thead>
);

export const MerchantTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.phone_number}</td>
    <td>{getAdmin(props.admin_data, props.created_by)}</td>
    <td>{dateFormatter(props.approval_date)}</td>
    <td>{dateFormatter(props.created_date)}</td>
    <td>{props.enabled ? "Yes" : "No"}</td>
    <td>{getCompany(props.company_data, props.company_id)}</td>
    <td>{props.email}</td>
    <td>{getRole(props.role_data, props.role_id)}</td>
    <td>
      {/* <i className="fa fa-eye" onClick={() => props.viewMerchant(props)}></i> */}
      <i
        className="fa fa-pencil"
        style={{ padding: "0 0.5em" }}
        onClick={() => props.editMerchant(props)}></i>
    </td>
    <td>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          onChange={() => props.toggleHandler(props)}
          checked={!props.enabled}
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
  </tr>
);

export const InvestmentSubHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Investment Cycle</th>
      <th>User</th>
      <th>Slots</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Acquired Interest</th>
      <th>Status</th>
      <th>User Wallet</th>
      <th>Recurrent Subscription</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const InvestmentSubBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{getCycle(props.cycle, props.product_investment_cycle_id)}</td>
    <td>{props.user.username}</td>
    <td>{props.slots}</td>
    <td>{dateFormatter(props.start_date)}</td>
    <td>{dateFormatter(props.end_date)}</td>
    <td>{props.accrued_interest}</td>
    <td>{props.status}</td>
    <td>{getWallet(props.savings_wallet, props.user_wallet_id)}</td>
    <td>{props.recurrent_subscription ? "Yes" : "No"}</td>
    <td>
      <i
        className="fa fa-eye"
        onClick={() => props.viewSubscription(props)}></i>
    </td>
  </tr>
);

export const InvestmentProdHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Re-Occuring</th>
      <th>Status</th>
      <th>Created By</th>
      <th>Approved By</th>
      <th>Merchant</th>
      <th>Return type</th>
      <th>Created Date</th>
      <th>Approval Date</th>
      <th>Investment Account Number</th>
      <th>Product Reference</th>
      <th>Category</th>
      <th>Total RIO Percentage</th>
      <th>Total Investment Sum</th>
      <th>Total Slot</th>
      <th>Total Slot Taken</th>
      <th>Image</th>
      <th>Approve/Disapprove</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const InvestmentProdBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td style={{ width: "500px", lineHeight: "1.3", wordBreak: "break-all" }}>
      {props.description}
    </td>
    <td>{props.recurring ? "Yes" : "No"}</td>
    <td>{props.status}</td>
    <td>{getAdmin(props.admin_data, props.approved_by)}</td>
    <td>{getAdmin(props.admin_data, props.approval_by)}</td>
    <td>{getMerchant(props.merchant, props.merchant_id)}</td>
    <td>{props.return_type}</td>
    <td>{dateFormatter(props.created_date)}</td>
    <td>{dateFormatter(props.approval_date)}</td>
    <td>
      {props.investment_account_number == null
        ? "Not given"
        : props.investment_account_number}
    </td>
    <td>{props.product_ref}</td>
    <td>{getCategory(props.category, props.category_id)}</td>
    <td>{props.investment_cycle?.ROI_percentage}</td>
    <td>{props.investment_cycle?.total_investment_sum}</td>
    <td>{props.investment_cycle?.slot}</td>
    <td>{props.investment_cycle?.slots_taken}</td>
    <td>
      <img src={props.image_url} alt="logo" width="20" height="20" />
    </td>
    <td>
      {props.status == "in-active" || props.status == "pending" ? (
        <button
          className="btn btn-primary"
          onClick={() => props.approveDoc(props)}>
          <span>approve</span>
        </button>
      ) : (
        <button
          className="btn btn-warning"
          onClick={() => props.approveDoc(props)}>
          <span>disapprove</span>
        </button>
      )}
    </td>
    <td>
      <i className="fa fa-eye" onClick={() => props.viewInvestProd(props)}></i>
      <i
        className="fa fa-pencil"
        style={{ padding: "0 .5em" }}
        onClick={() => props.editProd(props)}></i>
      {/* <i className="fa fa-trash-o" onClick={() => props.deleteProd(props)}></i> */}
    </td>
  </tr>
);

export const InvestCategoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Industry</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const InvestCategoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>{props.industry}</td>
    <td></td>
  </tr>
);
export const InvestReturnHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Product Investment Cycle</th>
      <th>Amount</th>
      <th>Verified By</th>
      <th>Verified Date</th>
      <th>Evidence</th>
      <th>Account Number</th>
      <th>Account Name</th>
      <th>Source Account Number</th>
      <th>Source Account Name</th>
      <th>Source Bank Code</th>
      <th>Transaction Reference</th>
      <th>Narration</th>
      <th>Transaction Date</th>
    </tr>
  </thead>
);
export const InvestReturnBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.product_investment_cycle_id}</td>
    <td>{props.amount}</td>
    <td>{props.verified_by}</td>
    <td>{props.verified_date}</td>
    <td>{props.evidence}</td>
    <td>{props.account_number}</td>
    <td>{props.account_name}</td>
    <td>{props.src_account_number}</td>
    <td>{props.src_account_name}</td>
    <td>{props.src_bank_code}</td>
    <td>{props.transaction_ref}</td>
    <td>{props.narration}</td>
    <td>{props.transaction_date}</td>
  </tr>
);

export const InvestmentDocHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Document Name</th>
      <th>Company</th>
      <th>Path To File</th>
      <th>Document Type</th>
      <th>Approved By</th>
      <th>Approval Status</th>
      <th>Approval Date</th>
      <th>Comment</th>
      <th>Created At</th>
    </tr>
  </thead>
);

export const InvestmentDocBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.document_name}</td>
    <td>{getCompany(props.company, props.company_id)}</td>
    <td>
      <a href={props.path_to_file}>view document</a>
    </td>
    <td>{props.document_type}</td>
    <td>{getAdmin(props.admin_data, props.approved_by)}</td>
    <td>{props.approval_status != "" ? props.approval_status : "not given"}</td>
    <td>{dateFormatter(props.approval_date)}</td>
    <td>{props.comment}</td>
    <td>{dateFormatter(props.created_at)}</td>
  </tr>
);

//investment product details
export const InvestProductDetailBody = (props) => (
  <>
    <div class="mt-4 ml-3">
      <div style={{ display: "inline-block" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Name
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.name}
        </p>
      </div>
    </div>
    <table class="table table-hover mt-1">
      <tbody>
        <tr>
          <td>Description</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.description}
          </td>
        </tr>
        <tr>
          <td>Recurring</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.recurring ? "Yes" : "No"}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.status}
          </td>
        </tr>
        <tr>
          <td>Created By</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getAdmin(props.admin_data, props.created_by)}
          </td>
        </tr>
        <tr>
          <td>Merchant</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getMerchant(props.merchant, props.merchant_id)}
          </td>
        </tr>
        <tr>
          <td>Return Type</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.return_type}
          </td>
        </tr>
        <tr>
          <td>Created Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.created_date)}
          </td>
        </tr>
        <tr>
          <td>Approval Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.approval_date)}
          </td>
        </tr>
        <tr>
          <td>Investment Account Number</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_account_number}
          </td>
        </tr>
        <tr>
          <td>Product Reference</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.product_ref}
          </td>
        </tr>
        <tr>
          <td>Category</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getCategory(props.category, props.category_id)}
          </td>
        </tr>
        <tr>
          <td>Image</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            <img
              src={props.image_url != "no image" ? props.image_url : ""}
              alt="logo"
            />
          </td>
        </tr>
        <tr>
          <td>Cycle</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.cycle_name}
          </td>
        </tr>
        <tr>
          <td>Start Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.investment_cycle.start_date)}
          </td>
        </tr>
        <tr>
          <td>End Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.investment_cycle.end_date)}
          </td>
        </tr>
        <tr>
          <td>ROI Percentage</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.ROI_percentage} %
          </td>
        </tr>
        <tr>
          <td>Total Investment Sum</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(props.investment_cycle.total_investment_sum)}
          </td>
        </tr>
        <tr>
          <td>Slot</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.slot}
          </td>
        </tr>
        <tr>
          <td>Slot Taken</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.slots_taken}
          </td>
        </tr>
        <tr>
          <td>Cost Per Slot</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {currencyFormatter(props.investment_cycle.cost_per_slot)}
          </td>
        </tr>
        <tr>
          <td>Minimum Slot</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.minimum_slot}
          </td>
        </tr>
        <tr>
          <td>Cycle Status</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_cycle.status}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

//merchant details
export const MerchantDetailBody = (props) => (
  <>
    <div class="mt-4 ml-3">
      <div style={{ display: "inline-block" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Name
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.name}
        </p>
      </div>
    </div>
    <table class="table table-hover mt-1">
      <tbody>
        <tr>
          <td>Phone Number</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.phone_number}
          </td>
        </tr>
        <tr>
          <td>Created By</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getAdmin(props.admin_data, props.created_by)}
          </td>
        </tr>
        <tr>
          <td>Approval Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.approval_date)}
          </td>
        </tr>
        <tr>
          <td>Approval Time</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {timeFormatter(props.approval_date)}
          </td>
        </tr>
        <tr>
          <td>Created Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.created_date)}
          </td>
        </tr>
        <tr>
          <td>Created Time</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {timeFormatter(props.created_date)}
          </td>
        </tr>
        <tr>
          <td>Merchant Enabled</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.enabled ? "Yes" : "No"}
          </td>
        </tr>
        <tr>
          <td>Company</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getCompany(props.company, props.company_id)}
          </td>
        </tr>
        <tr>
          <td>Email</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.email}
          </td>
        </tr>
        <tr>
          <td>Merchant Role</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getRole(props.role, props.role_id)}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

//user investment details
export const UserInvestSub = (props) => (
  <>
    <div class="mt-4 ml-3">
      <div style={{ display: "inline-block" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Name
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {/* {props.user.username} */}
        </p>
      </div>
      <div style={{ display: "inline-block", float: "right" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Amount
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.amount}
        </p>
      </div>
    </div>
    <table class="table table-hover mt-1">
      <tbody>
        <tr>
          <td>Product Investment Cycle</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getCycle(props.cycle, props.product_investment_cycle_id)}
          </td>
        </tr>
        <tr>
          <td>Slot</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.slots}
          </td>
        </tr>
        <tr>
          <td>Start Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.start_date)}
          </td>
        </tr>
        <tr>
          <td>End Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.end_date)}
          </td>
        </tr>
        <tr>
          <td>Accrued Interest</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.accrued_interest}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.status}
          </td>
        </tr>
        <tr>
          <td>User Wallet Number</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {/* {getWallet(props.savings_wallet, props.user_wallet_id)} */}
          </td>
        </tr>
        <tr>
          <td>Account Number</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.account_number}
          </td>
        </tr>
        <tr>
          <td>Investment Name</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.name}
          </td>
        </tr>
        <tr>
          <td>Interest Rate</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.interest_rate}
          </td>
        </tr>
        <tr>
          <td>Created</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.created_at)}
          </td>
        </tr>
        <tr>
          <td>Investment Product</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.investment_product_id}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export const RelatedProductHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Re-Occuring</th>
      <th>Status</th>
      <th>Created By</th>
      <th>Approved By</th>
      <th>Merchant</th>
      <th>Return type</th>
      <th>Created Date</th>
      <th>Approval Date</th>
      <th>Investment Account Number</th>
      <th>Product Reference</th>
      <th>Category</th>
      <th>Image</th>
    </tr>
  </thead>
);
export const RelatedProductBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>{props.recurring ? "Yes" : "No"}</td>
    <td>{props.status}</td>
    <td>{getAdmin(props.admin_data, props.created_by)}</td>
    <td>{getAdmin(props.admin_data, props.approved_by)}</td>
    <td>{getMerchant(props.merchant, props.merchant_id)}</td>
    <td>{props.return_type}</td>
    <td>{dateFormatter(props.created_date)}</td>
    <td>{dateFormatter(props.approval_date)}</td>
  </tr>
);

export const InvestmentHistoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Progress Name</th>
      <th>Investment Product Id</th>
      <th>Description</th>
    </tr>
  </thead>
);

const getProduct = (invest_prod, id) => {
  const data =
    invest_prod &&
    invest_prod.map((content) => {
      if (content.id == id) {
        return content.name;
      }
    });
  return data;
};

export const InvestmentHistoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.progress_name}</td>
    <td>{getProduct(props.invest_prod, props.investment_product_id)}</td>
    <td>{props.description}</td>
  </tr>
);
