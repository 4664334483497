import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class GroupSavingsModal extends React.Component {
  state = {
    name: "",
    description: "",
    maximum_participants: "",
    creator_id: this.props.admin_data.id,
    maximum_amount: "",
    group_type: "",
    savings_product_id: "",
    slot: "",
    // group_pin: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.setSavingGroup(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Group Savings">
        <div className="body-grid">
          <div className="box box1">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Number of Participants</label>
            <input
              className="form-control"
              name="maximum_participants"
              value={this.state.maximum_participants}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Maximum Amount</label>
            <input
              className="form-control"
              name="maximum_amount"
              value={this.state.maximum_amount}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Group Type</label>
            <select
              className="form-control"
              name="group_type"
              value={this.state.group_type}
              onChange={this.handleChange}>
              <option></option>
              <option>Opened Group</option>
            </select>
          </div>
          <div className="box box2">
            <label>Savings Product</label>
            <select
              className="form-control"
              name="savings_product_id"
              value={this.state.savings_product_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.savings_product &&
                this.props.savings_product.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>Slot</label>
            <input
              className="form-control"
              name="slot"
              value={this.state.slot}
              onChange={this.handleChange}
            />
          </div>
          {/* <div className="box box2">
              <label>Group</label>
              <input className="form" />
          </div> */}
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set group savings
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
  admin_data: state.login.data,
  savings_product: state.saving.get_save_product.saving_data,
});
export default connect(mapStateToProps, {})(GroupSavingsModal);
