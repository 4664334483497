import {
  GET_COMMISSION_FAILED,
  GET_COMMISSION_SUCCESS,
  SET_COMMISSION_SUCCESS,
  SET_COMMISSION_FAILED,
  TOGGLE_SUCCESS,
} from "../action/action.types";
import { combineReducers } from "redux";

const initialState = {
  commissionProfile: [],
};

const allCommissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_COMMISSION_SUCCESS:
      return {
        ...state,
        commissionProfile: action.payload.commissionProfiles,
        loading: false,
      };
    case GET_COMMISSION_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    case TOGGLE_SUCCESS:
      return {
        ...state,
        toggle: action.payload,
      };
    default:
      return state;
  }
};

const setCommissionProfile = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case SET_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: true,
      };
    case GET_COMMISSION_SUCCESS:
      return {
        ...state,
        successMsg: false,
        loading: false,
      };
    case SET_COMMISSION_FAILED:
      return {
        ...state,
        failed: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const initialCommissionReducer = combineReducers({
  allcommissions: allCommissionReducer,
  setcommissions: setCommissionProfile,
});
