import { headers } from "../api.config";

export const setAdminPermission = async (data, token) => {
  //   alert(JSON.stringify(data));
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/permissions`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, response_message } = await response.json();
    return { success, response_message };
  }
};

export const getAllAdminPermission = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/permissions`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};
