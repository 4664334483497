import { headers } from "./api.config";

export const addInvestmentData = async (
  url_path,
  data,
  token,
  type = "POST"
) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url_path}`, {
    method: type,
    headers: headers(token),
    body: JSON.stringify(data),
  });
  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchInvestmentData = async (url_path, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url_path}`, {
    headers: headers(token),
  });
  if (response.ok) {
    let { data, success } = await response.json();
    return { success, data };
  }
};

export const uploadImage = async (url, id, form_data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/${url}/${id}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form_data,
    }
  );
  if (response.ok) {
    const data = await response.json();
    return { data };
  }
};

export const uploadDocumentRequest = async (form_data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/investment_company_documents`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form_data,
    }
  );
  if (response.ok) {
    const data = await response.json();
    return { data };
  }
};

export const deleteInvestmentItem = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
    method: "DELETE",
    headers: headers(token),
  });
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
