import { headers } from "./api.config";

export const setLimitConfig = async (data, token) => {
  const transactionCode = data.transCategory.split(",");
  const limitData = {
    limitName: data.limitName,
    description: data.limitDescription,
    transactionServiceCode: data.code,
    transactionCategoryCode: transactionCode[1],
    kycLevel: data.kycLevel,
    limitType: data.limitType,
    limit: data.limitValue,
    userCategory: data.userCategory,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/set_txn_limit_config`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(limitData),
    }
  );
  if (response.ok) {
  }
};
const processLimitConfigs = (result) => ({
  name: result.name,
  _id: result._id,
  description: result.description,
  kycLevel: result.kycLevel,
  limitType: result.limitType,
  limit: result.limit,
  transactionServiceId: result.transactionServiceId,
  transactionCategoryCode: result.transactionCategoryCode,
  transactionServiceCode: result.transactionServiceCode,
  transactionCategoryId: result.transactionCategoryId,

  // transCategory: result.transactionCategoryName,
  // transCode: result.transactionServiceCode,
});
export const fetchLimitConfigs = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_txn_limit_configs?page=${page}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    // console.log(response);
    const { data } = await response.json();
    const { txnLimitConfigs, allLimitConfigs } = await data;
    const mLimitConfigs = txnLimitConfigs.map(processLimitConfigs);
    return { mLimitConfigs, allLimitConfigs };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const getLimitType = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_txn_limit_types`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return result;
  }
};

export const editTransactionLimitConfig = async (id, data, token) => {
  const newEdit = {
    name: data.limitName,
    description: data.limitDescription,
    transactionServiceCode: data.code,
    transactionCategoryCode: data.transCategory,
    kycLevel: data.kycLevel,
    limitType: data.limitType,
    limit: data.limitValue,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/update_txn_limit_config/${id}`,
    {
      method: "PATCH",
      headers: headers(token),
      body: JSON.stringify(newEdit),
    }
  );

  if (response.ok) {
    const { response_description } = await response.json();

    return { response_description };
  }
};

export const deleteTransactionLimit = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/delete_txn_limit_config/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { response_description } = await response.json();
    return { response_description };
  }
};
