import React from "react";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import "../../../morestyles.css";
import { Loader, Icon, CardBodyHolder } from "../../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class PhysicalCardBar extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Card Settings"
            report={false}
            getInfo={this.props.handleRefresh}
            handleReport={this.getTotalTransaction}
            shouldRefresh="false"
          />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="Batches"
                title={
                  <Link to={`/physical-card/batches`}>
                    <Icon className="fa fa-cog" title="Card Batch Config" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="Card Request"
                title={
                  <Link to={`/physical-card/card-request`}>
                    <Icon className="fa fa-cog" title="Card Request" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="Card Request Batch"
                title={
                  <Link to={`/physical-card/card-request-batch`}>
                    <Icon className="fa fa-cog" title="Card Request Batch" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default PhysicalCardBar;
