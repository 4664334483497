import React from "react";
import { currencyFormatter, dateFormatter } from "../Row";

export const SavingProductHead = () => (
  <thead className="thead-light" style={{ textAlign: "left" }}>
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      {/* <th>Frequency</th>
      <th>Frequency Type</th> */}
      <th>Start Date</th>
      <th>Maturity Period</th>
      <th>Amount</th>
      <th>Interest Rate</th>
      <th>Created By</th>
      <th>Kyc Level</th>
      <th>Is Group</th>
      <th>Status</th>
      <th>Action</th>
      <th>Lock</th>
    </tr>
  </thead>
);
export const SavingProductBody = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.name}</td>
      <td>{props.description}</td>
      {/* <td>{props.frequency}</td>
      <td>{props.frequency_type}</td> */}
      <td>{dateFormatter(props.start_date)}</td>
      <td>{props.maturity_period}</td>
      <td style={{ textAlign: "right" }}>{currencyFormatter(props.amount)}</td>
      <td style={{ textAlign: "right" }}>{props.interest_rate}</td>
      <td></td>
      <td>{props.kyc_level}</td>
      <td>{props.group ? "group savings" : "not a group saving"}</td>
      <td>{props.lock ? "in-active" : "active"}</td>
      <td>
        {/* <i
          className="fa fa-trash-o"
          onClick={() => props.deleteSavingConfig(props)}></i> */}
        <i
          className="fa fa-eye"
          style={{ padding: "0 1em" }}
          onClick={() => props.viewDetails(props)}></i>
        <i
          className="fa fa-pencil"
          onClick={() => props.editProductConfig(props)}></i>
      </td>
      <td>
        <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            onChange={() => props.toggleHandler(props)}
            checked={props.lock}
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
      </td>
    </tr>
  </>
);

export const SavingWalletHead = () => (
  <thead className="thead-light" style={{ textAlign: "center" }}>
    <tr>
      <th>S/N</th>
      <th>Wallet Number</th>
      <th>Wallet Type</th>
      <th>Ledger Balance</th>
      <th>Avialable Balance</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const SavingWalletBody = (props) => (
  <>
    <tr style={{ textAlign: "center" }}>
      <td>{props.index}</td>
      <td>{props.wallet_number}</td>
      <td>{props.wallet_type}</td>
      <td>{props.ledger_balance}</td>
      <td>{props.available_balance}</td>
      <td>{dateFormatter(props.created_at)}</td>
      <td>
        <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            name="is_required"
            checked={props.lock}
            onChange={() => props.toggleHandler(props)}
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
      </td>
    </tr>
  </>
);

export const UserSavingHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Savings Product</th>
      <th>User</th>
      <th>User Wallet</th>
      <th>Status</th>
      <th>User Group</th>
      <th>Amount</th>
      <th>Total Interest</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

const getProductData = (data, product_id) => {
  const mData = data.map((content) => {
    if (content.id == product_id) {
      return content.name;
    } else {
      return "";
    }
  });
  return mData;
};

const getSavingNumber = (wallet, user_wallet_id) => {
  const user_wallet =
    wallet &&
    wallet.map((content) => {
      if (content.id == user_wallet_id) {
        return content.wallet_number;
      }
    });
  return user_wallet;
};

export const UserSavingBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{getProductData(props.product, props.savings_product_id)}</td>
    <td>{props.user_id}</td>
    <td>{props.wallet.wallet_number}</td>
    <td>{props.status}</td>
    <td>{props.user_group_id == 0 ? "No" : "Yes"}</td>
    <td>{props.amount}</td>
    <td>{props.total_interest ? props.total_interest : "not given"}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-eye" onClick={() => props.viewDetails(props)}></i>
    </td>
  </tr>
);

export const UserSaveDetails = (props) => (
  <>
    <div className="mt-4 ml-3">
      <div style={{ display: "inline-block" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Saving Name
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.name}
        </p>
      </div>
      <div style={{ display: "inline-block", float: "right" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Savings Amount
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.amount}
        </p>
      </div>
    </div>
    <table class="table table-hover mt-1">
      <tbody>
        <tr>
          <td>User Wallet</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {getSavingNumber(props.wallet, props.user_id)}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.status}
          </td>
        </tr>
        <tr>
          <td>Group</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.user_group_id == 0 ? "No" : "Yes"}
          </td>
        </tr>
        <tr>
          <td>Amount</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.amount}
          </td>
        </tr>
        <tr>
          <td>Total Interest</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.total_interest}
          </td>
        </tr>
        <tr>
          <td>Created At</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.created_at)}
          </td>
        </tr>
        <tr>
          <td>Auto Save</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.auto_save == 0 ? "No" : "Yes"}
          </td>
        </tr>
        <tr>
          <td>Frequency Type</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.frequency_type}
          </td>
        </tr>
        <tr>
          <td>Start Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.start_date)}
          </td>
        </tr>
        <tr>
          <td>Target Amount</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.target_amount}
          </td>
        </tr>
        <tr>
          <td>Maturity Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.maturity_date)}
          </td>
        </tr>
        <tr>
          <td>Interest Rate</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.interest_rate}
          </td>
        </tr>
        <tr>
          <td>Account Number</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.account_no ? props.account_no : "Not given"}
          </td>
        </tr>
        <tr>
          <td>Amount Saved</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.amount_save}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export const SavingProductDetails = (props) => (
  <>
    <div class="mt-4 ml-3">
      <div style={{ display: "inline-block" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Name
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.name}
        </p>
      </div>
      <div style={{ display: "inline-block", float: "right" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "lightgray",
            marginTop: "1em",
            marginBottom: "0em",
          }}>
          Amount
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "0em",
          }}>
          {props.amount}
        </p>
      </div>
    </div>
    <table class="table table-hover mt-1">
      <tbody>
        <tr>
          <td>Product Name</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.name}
          </td>
        </tr>
        <tr>
          <td>Group</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.group ? "yes" : "no"}
          </td>
        </tr>
        <tr>
          <td>Start Date</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {dateFormatter(props.start_date)}
          </td>
        </tr>
        <tr>
          <td>Maturity Period</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.maturity_period}
          </td>
        </tr>
        <tr>
          <td>Amount Type</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.amount_type}
          </td>
        </tr>
        <tr>
          <td>Min Amount</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.min_amount}
          </td>
        </tr>
        <tr>
          <td>Max Amount</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.max_amount}
          </td>
        </tr>
        <tr>
          <td>Amount Step</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.amount_step}
          </td>
        </tr>
        <tr>
          <td>Interest Rate</td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {props.interest_rate}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);
export const PenaltyHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Amount</th>
      <th>Amount Type</th>
      <th>Cap Amount</th>
      <th>Cap Base</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const PenaltyBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.amount}</td>
    <td>{props.amount_type}</td>
    <td>{props.cap_amount}</td>
    <td>{props.cap_base}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editPenalty(props)}></i>
    </td>
  </tr>
);

export const WalletTransactionHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Source Number</th>
      <th>Transaction Type</th>
      <th>Recipient Number</th>
      <th>Amount</th>
      <th>Description</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const WalletTransactionBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.source_no}</td>
    <td>{props.transaction_type}</td>
    <td>{props.recipient_no}</td>
    <td>{props.amount}</td>
    <td>{props.description}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td></td>
  </tr>
);

export const UserSavingDataHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User Wallet Number</th>
      <th>Status</th>
      <th>User Group</th>
      <th>Amount</th>
      <th>Total Interest</th>
      <th>Created At</th>
    </tr>
  </thead>
);
export const UserSavingDataBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{getSavingNumber(props.wallet, props.userId)}</td>
    <td>{props.status}</td>
    <td>{props.user_group_id}</td>
    <td>{props.amount}</td>
    <td>{props.total_interest}</td>
    <td>{dateFormatter(props.created_at)}</td>
  </tr>
);

export const WithDrawalHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User Type</th>
      <th>Created By</th>
      <th>Withdrawal Date</th>
      <th>Intervals</th>
      <th>Interval Type</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const WithDrawalBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.user_type}</td>
    <td>{props.user_id}</td>
    <td>{dateFormatter(props.withdrawal_date)}</td>
    <td>{props.interval}</td>
    <td>{props.interval_type}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-trash-o" onClick={() => props.deleteItem(props)}></i>
    </td>
  </tr>
);

export const SavingHistoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Source Number</th>
      <th>Transaction Type</th>
      <th>Recipient Number</th>
      <th>Amount</th>
      <th>Description</th>
      <th>User Wallet</th>
      <th>User Saving Id</th>
      <th>Transaction Reference</th>
      <th>Status</th>
      <th>Created At</th>
    </tr>
  </thead>
);

export const SavingHistoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.source_no}</td>
    <td>{props.transaction_type}</td>
    <td>{props.recipient_no}</td>
    <td>{props.amount}</td>
    <td>{props.description}</td>
    <td>{props.user_wallet_id}</td>
    <td>{props.user_savings_id}</td>
    <td>{props.trans_ref}</td>
    <td>{props.status}</td>
    <td>{dateFormatter(props.created_at)}</td>
  </tr>
);

export const SavingGroupHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Maximum Participants</th>
      <th>Created By</th>
      <th>Maximum Amount</th>
      <th>Group Type</th>
      <th>Savings Product</th>
      <th>Slug</th>
      <th>Created At</th>
    </tr>
  </thead>
);

export const SavingGroupBody = (props) => (
  <tr>
    <td>{props.id}</td>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>{props.maximum_participants}</td>
    <td>{props.creator_id}</td>
    <td>{props.maximum_amount}</td>
    <td>{props.group_type}</td>
    <td>{props.savings_product_id}</td>
    <td>{props.slug}</td>
  </tr>
);
