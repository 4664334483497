import React from "react";
import { dateFormatter, statusBadge, timeFormatter } from "../Row";

export const NewAccountHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Account Name</th>
      <th>Account Type</th>
      <th>Date Requested</th>
      <th>Time</th>
      <th>Approval Status</th>
      <th>Purpose</th>
      <th>Approval Date</th>
      <th>Approval Time</th>
      <th>Approved by</th>
      <th>Comment</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const NewAccountBody = (props) => {
  return (
    <>
      <tr key={props.index}>
        <td
          style={{
            color: "#0038ba",
          }}
        >
          {props.index}
        </td>
        <td>{props.account_name}</td>
        <td>{props.account_type}</td>
        <td>{dateFormatter(props.created_at)}</td>
        <td>{timeFormatter(props.created_at)}</td>
        <td>{props.approved ? "Approved" : "Pending approval"}</td>
        <td>{props.purpose}</td>
        <td>{props.approve_date ? dateFormatter(props.approve_date) : ""}</td>
        <td>{props.approve_date ? timeFormatter(props.approve_date) : ""}</td>
        <td>{props.admin_id}</td>

        <td>{props.comment}</td>
        <td
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            className="fa fa-pencil"
            style={{ padding: "0 .25em", cursor: "pointer" }}
            onClick={() => props.approveRequest(props)}
          ></i>
        </td>
      </tr>
    </>
  );
};
