import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class InvestDocumentModal extends React.Component {
  state = {
    document_name: "",
    company_id: "",
    document: null,
    // path_to_file: "",
    document_type: "",
    approved_by: "",
    approval_status: "",
    approval_date: "",
    comment: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleFile = (e) => {
    this.setState({
      document: e.target.files[0],
    });
  };

  handleSubmit = () => {
    const formData = new FormData();
    formData.append("document_name", this.state.document_name);
    formData.append("company_id", this.state.company_id);
    formData.append("document", this.state.document);
    formData.append("document_type", this.state.document_type);
    formData.append("approved_by", this.state.approved_by);
    formData.append("approval_status", this.state.approval_status);
    formData.append("approval_date", this.state.approval_date);
    formData.append("comment", this.state.comment);
    // alert(JSON.stringify(typeof formData));
    this.props.addDoc(formData);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Upload Company Document">
        <div className="body-grid">
          <div className="box box1">
            <label>Document Name</label>
            <input
              className="form-control"
              name="document_name"
              value={this.state.document_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Company Name</label>
            <select
              className="form-control"
              name="company_id"
              value={this.state.company_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.company_data &&
                this.props.company_data.map((content) => (
                  <option value={content.id}>{content.company_name}</option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>Document Type</label>
            <select
              className="form-control"
              name="document_type"
              value={this.state.document_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="cac">CAC</option>
              <option value="permit">Permit</option>
            </select>
          </div>
          <div className="box box2">
            <label>Approve By</label>
            <select
              className="form-control"
              name="approved_by"
              value={this.state.approved_by}
              onChange={this.handleChange}>
              <option></option>
              {this.props.admins.map((content) => (
                <option value={content.id}>{content.username}</option>
              ))}
            </select>
          </div>
          <div className="box box1">
            <label>Approval Status</label>
            <select
              className="form-control"
              name="approval_status"
              value={this.state.approval_status}
              onChange={this.handleChange}>
              <option></option>
              <option value="1">Active</option>
              <option value="0">In-Active</option>
            </select>
          </div>
          <div className="box box2">
            <label>Approval Date</label>
            <input
              type="date"
              className="form-control"
              name="approval_date"
              value={this.state.approval_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Document</label>
            <input
              type="file"
              className="form-control"
              name="document"
              accept="image/png, image/jpeg"
              onChange={this.handleFile}
            />
          </div>
          <div className="box box9">
            <label>Comment</label>
            <textarea
              className="form-control"
              name="comment"
              value={this.state.comment}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  company_data: state.invest.getData.comp_data,
  admins: state.users.alladmins.adminData,
});

export default connect(mapStateToProps, {})(InvestDocumentModal);
