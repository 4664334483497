import { headers } from "./api.config";

const processTransaction = (result) => ({
  _id: result.id,
  id: result.user_id,
  account_name: result.account_name,
  account_type: result.account_type,
  admin_id: result.admin_id,
  approve_date: result.approve_date,
  approved: result.approved,
  comment: result.comment,
  created_at: result.created_at,
  purpose: result.purpose,
  updated_at: result.updated_at,
});

export const fetchAccountRequest = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "GET",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    const { total } = await data;
    const result = data.data;

    const transaction = result.map(processTransaction);
    console.log(transaction);
    return { transaction, success, total };
  } else {
    const { response_error } = await response.json();
    throw new Error(response_error);
  }
};

export const filterTransactionStatus = async (status, page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_all_txns?page=${page}&transactionStatus=${status}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data, success } = await response.json();
    const { transactions, allTransactions } = await data;
    const transaction = transactions.map(processTransaction);
    return { transaction, success, allTransactions };
  }
};

export const filterTransaction = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_all_txns?per_page=15&startDate=${data.startDate}&endDate=${data.endDate}&per_page=1000&transactionRef=${data.reference}&debitedAccount=${data.debited_ac}&channelId=${data.channel}&transactionType=${data.transType}&narration=${data.narration}&transactionAmount=${data.transAmount}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data, success } = await response.json();
    const { transactions, allTransactions } = await data;
    const transaction = transactions.map(processTransaction);
    return { transaction, success, allTransactions };
  }
};

export const approveNewAccount = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/approve/request_additional_accounts`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
