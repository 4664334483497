import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { usersReducer } from "./alluserReducer";
import { transactionReducer } from "./transactionReducer";
import { initialFeeReducer } from "./feesReducer";
import { initialCommissionReducer } from "./commissionReducer";
import { detailReducer } from "./detailsReducer";
import { loanReducer } from "./loanProductsReducer";
import { initialLimitReducer } from "./limitReducer";
import { promotionReducer } from "./promotionReducer";
import { initialRoleReducer } from "./AuthorizationReducer";
import { initialBillReducer } from "./BillReducer";
import { initialNotification } from "./notificationReducer";
import { reportReducer } from "./ReportReducer";
import { cardReducer } from "./cardReducer";
import { kycReducer } from "./KycReducer";
import { auditLogs } from "./auditLogsReducer";
import { virtualCardReducer } from "./virtualCardReducer";
import { developerAccounts } from "./developerAccountsReducer";
import { savingReducer } from "./savingReducer";
import { investReducer } from "./investmentReducer";
import { incentiveReducer } from "./IncentiveReducer";
import { inventoryReducer } from "./InventoryReducer";
import { bulkTransactionReducer } from "./bulktransactionReducer";
import { interestReducer } from "./interestReducer";
import { newAccountReducer } from "./newAccountReducer";
// import { deve} from "./developerAccountsReducer"

const reducer = combineReducers({
	login: loginReducer,
	users: usersReducer,
	alltransactions: transactionReducer,
	fees: initialFeeReducer,
	details: detailReducer,
	commissions: initialCommissionReducer,
	loans: loanReducer,
	limits: initialLimitReducer,
	promotions: promotionReducer,
	roles: initialRoleReducer,
	bills: initialBillReducer,
	notifications: initialNotification,
	report: reportReducer,
	card: cardReducer,
	kyc: kycReducer,
	logs: auditLogs,
	virtualCards: virtualCardReducer,
	devAccounts: developerAccounts,
	saving: savingReducer,
	invest: investReducer,
	incentive: incentiveReducer,
	inventory: inventoryReducer,
	bulk: bulkTransactionReducer,
	interest: interestReducer,
  newAccount:newAccountReducer
});

export default reducer;
