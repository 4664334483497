import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import CardBatchRoute from "./CardBatchSettings/Card.Batch";
import CardRequestRoute from "./CardRequestSettings/CardRequest";
import CardRequestBatchRoute from "./CardRequestBatch/CardRequestBatch";
// import { Error } from "../../../Error";

const Show = () => <div>wassa</div>;

const CardRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/batches`} />
      </Route>
      <Route path={`${match.path}/batches`} component={CardBatchRoute} />
      <Route path={`${match.path}/card-request`} component={CardRequestRoute} />
      <Route
        path={`${match.path}/card-request-batch`}
        component={CardRequestBatchRoute}
      />
    </Switch>
  </>
);
export default CardRoute;
