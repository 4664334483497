import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  getDeveloperDoc,
  toggleDeveloperDoc,
  getUserCategory,
} from "../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../components/Accessories";
import {
  DeveloperDocHead,
  DeveloperDocBody,
} from "../../components/Rows/DeveloperAccountsTable";
import SetCategoryTypeModal from "./CategoryTypeModal";

class DeveloperDocument extends React.Component {
  state = {
    display: false,
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.toggle_result) {
    //   alert(JSON.stringify(nextProps));
    // }
  }

  componentDidMount() {
    this.props.getDeveloperDoc(this.props.token);
    this.props.getUserCategory(this.props.token);
  }

  toggleDevDoc = (props) => {
    this.setState({
      display: true,
      supportEmail: props.businessSupportEmail,
    });
    // alert(props._id);
    // alert(JSON.stringify(this.props.user_cat));
    // this.props.toggleDeveloperDoc(props._id, this.props.token);
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  submitCategory = (categoryType) => {
    // alert(categoryType);
    callBackMethod(
      this.props.toggleDeveloperDoc(
        categoryType,
        this.state.supportEmail,
        this.props.token
      )
    )
      .then(() => alert(this.props.toggle_result.response_description))
      .then(() => this.setState({ display: false }))
      .then(() => this.props.getDeveloperDoc(this.props.token));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <DeveloperDocHead />
          {this.props.all_dev_doc.developerDocuments &&
            this.props.all_dev_doc.developerDocuments.map((content) => (
              <DeveloperDocBody {...content} toggleDevDoc={this.toggleDevDoc} />
            ))}
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Developer Documents"
              getInfo={this.props.getDeveloperDoc}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Developers Documents</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SetCategoryTypeModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitCategory={this.submitCategory}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  all_dev_doc: state.devAccounts.developerDoc.all_doc,
  toggle_result: state.devAccounts.developerDoc.toggle_result,
  loading: state.devAccounts.developerDoc.loading,
  user_cat: state.users.getUserCat.user_cat,
});

export default connect(mapStateToProps, {
  getDeveloperDoc,
  toggleDeveloperDoc,
  getUserCategory,
})(DeveloperDocument);
