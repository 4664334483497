import React from "react";
import { Modal } from "../../../../../components/Accessories";

class KycSettingModal extends React.Component {
  state = {
    document_type: "",
    name: "",
    description: "",
    system_can_approved: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    this.props.setSetting(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Kyc Level setup">
        <div className="body-grid">
          <div className="box box9">
            <label>Document Type</label>
            <input
              className="form-control"
              name="document_type"
              value={this.state.document_type}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          {/* <div className="box box9">
            <label>System Can Approve</label>
            <input
              className="form-control"
              name="system_can_approved"
              value={this.state.system_can_approved}
              onChange={this.handleChange}
            />
          </div> */}
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                Set Kyc Level Settings
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default KycSettingModal;
