import React from "react";
import { connect } from "react-redux";
import {
	getNotification,
	filterNotification,
} from "../../../reduxStore/action/action.creator";
import {
	NotificationHead,
	NotificationBody,
} from "../../../components/Rows/NotificationRow";
import FilterModal from "./FilterModal";
import NotificationBar from "../NotificationBar";
import { Loader, Pagination } from "../../../components/Accessories";

class Notification extends React.Component {
	state = {
		currentPage: 1,
		display: false,
	};

	componentDidMount() {
		this.props.getNotification(1, this.props.token);
	}

	changePage = (id) => () => {
		this.props.getNotification(id, this.props.token);
		this.setState({
			currentPage: id,
		});
	};

	goPrevious = () => {
		if (this.state.currentPage - 1 != 0) {
			const id = this.state.currentPage - 1;
			this.props.getNotification(id, this.props.token);
			this.setState({
				currentPage: id,
			});
		}
	};

	goNext = () => {
		const total = parseInt(Math.ceil(this.props.total / 15));
		if (this.state.currentPage != total) {
			const id = this.state.currentPage + 1;
			this.props.getNotification(id, this.props.token);
			this.setState({
				currentPage: id,
			});
		}
	};

	getIndex = (index) => {
		const newIndex = 15 * (this.state.currentPage - 1) + index;
		return newIndex;
	};

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};
	closeModal = () => {
		this.setState({
			display: false,
		});
	};

	handleRefresh = () => {
		this.props.getNotification(this.props.token);
	};

	submitFilter = (data) => {
		let id;
		if (this.state.currentPage > 1) {
			id = 1;
		} else {
			id = this.state.currentPage;
		}
		this.props.filterNotification(data, this.props.token, id);
		// alert(JSON.stringify(data));
	};

	render() {
		let itemInTable;
		let renderPaginate;
		const total =
			this.props.total && parseInt(Math.ceil(this.props.total / 15));
		const paginateNumber = Array(total).fill(0);
		this.props.total > 15 &&
			!this.props.loading &&
			(renderPaginate = (
				<Pagination
					paginateNumber={paginateNumber}
					currentPage={this.state.currentPage}
					handlePagination={this.changePage}
					handlePrevious={this.goPrevious}
					handleNext={this.goNext}
					total={total}
					totalData={this.props.total}
				/>
			));

		if (this.props.loading) {
			itemInTable = <Loader loading={this.props.loading} />;
		} else {
			itemInTable = (
				<>
					<NotificationHead />
					<tbody>
						{this.props.all_notification &&
							this.props.all_notification.map(
								(content, index) => (
									<NotificationBody
										{...content}
										index={this.getIndex(index + 1)}
									/>
								)
							)}
					</tbody>
				</>
			);
		}
		return (
			<>
				<NotificationBar
					{...this.props}
					active="notification"
					showModal={this.showModal}
					handleRefresh={this.handleRefresh}
				>
					<div className="table-responsive py-4">
						<table
							className="table table-flush"
							id="datatable-basic"
						>
							{itemInTable}
						</table>
						{renderPaginate}
					</div>
				</NotificationBar>
				<FilterModal
					display={this.state.display}
					closeModal={this.closeModal}
					submit={this.submitFilter}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.notifications.allnotification.loading,
	all_notification: state.notifications.allnotification.notification,
	total: state.notifications.allnotification.total,
	token: state.login.token,
});

export default connect(mapStateToProps, {
	getNotification,
	filterNotification,
})(Notification);
