import React from "react";
import { connect } from "react-redux";
import { callBackMethod, Modal } from "../../../../components/Accessories";
import {
  getAllCardBatch,
  getAllCardRequest,
} from "../../../../reduxStore/action/action.creator";

class AddCardReqBatch extends React.Component {
  state = {
    card_requests_id: "",
    batches_id: "",
  };

  componentDidMount() {
    this.props.getAllCardRequest("card_requests", this.props.token);
    this.props.getAllCardBatch("batches", this.props.token);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    // alert(JSON.stringify(this.state));
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add Card Request Batch">
        <div className="body-grid">
          <div className="box box9">
            <label>Card Request Name</label>
            <select
              className="form-control"
              name="card_requests_id"
              value={this.state.card_requests_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.all_request &&
                this.props.all_request.map((content) => (
                  <option value={content.id}>{content["req-ref"]}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Card Batch Name</label>
            <select
              className="form-control"
              name="batches_id"
              value={this.state.batches_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.all_batches &&
                this.props.all_batches.map((content) => (
                  <option value={content.id}>{content.batch_ref}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-4"
                onClick={this.submit}>
                Add Card Batch Request
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  all_request: state.card.all_batches.request,
  all_batches: state.card.all_batches.batches,
  token: state.login.token,
});

export default connect(mapStateToProps, { getAllCardBatch, getAllCardRequest })(
  AddCardReqBatch
);
