import React from "react";
import { connect } from "react-redux";
import CardLayout from "../VirtualCardBar";
import RateModal from "./RateModal";
import {
  addCardRate,
  getCardRate,
  getCurrencies,
} from "../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../components/Accessories";
import {
  RateTableBody,
  RateTableHead,
} from "../../../../components/Rows/CardTable";
import RateViewModal from "./RateViewModal";

class RateSettings extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getCardRate(this.props.token);
    this.props.getCurrencies(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      view: false,
    });
  };

  setCardRate = (data) => {
    callBackMethod(this.props.addCardRate(data, this.props.token))
      .then(() =>
        this.setState({
          display: false,
        })
      )
      .then(() => alert(this.props.rate_response))
      .then(() => this.props.getCardRate(this.props.token));
  };

  handleRefresh = () => {
    this.props.getCardRate(this.props.token);
  };

  viewRate = (id) => {
    this.setState({
      ...this.state,
      view: true,
      idToView: id,
    });
  };

  render() {
    let itemInTable;

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <RateTableHead />
          <tbody>
            {this.props.all_rate &&
              this.props.all_rate.map((content, index) => (
                <RateTableBody
                  {...content}
                  index={index + 1}
                  viewRate={this.viewRate}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardLayout active="ratesetting">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </CardLayout>
        <RateModal
          display={this.state.display}
          closeModal={this.closeModal}
          setRate={this.setCardRate}
        />
        <RateViewModal
          display={this.state.view}
          closeModal={this.closeModal}
          rateId={this.state.idToView}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  rate_response: state.virtualCards.set_rate.rate_response,
  all_rate: state.virtualCards.get_rate.virtual_rate,
  loading: state.virtualCards.get_rate.loading,
});

export default connect(mapStateToProps, {
  addCardRate,
  getCardRate,
  getCurrencies,
})(RateSettings);
