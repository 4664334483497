import { headers } from "./api.config";

const processUser = (result, index) => ({
  id: result?.id,
  name: `${result?.first_name} ${result?.last_name}`,
  email: result?.email,
  phone: result?.phone_number,
  bvn: result?.bvn,
  dob: result?.date_of_birth,
  username: result?.username,
  gender: result?.gender,
  verified: result?.email_verified_at,
  locked_status: result?.locked_status,
  date_join: result?.date_join,
  account_num:
    result?.bank_account && result?.bank_account.length > 0
      ? result?.bank_account[0]?.account_number
      : "",
});

export const fetchUsers = async (token, url) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: "POST",
    headers: headers(token),
  });
  if (response.ok) {
    const { success, data } = await response.json();
    const { total } = await data;
    const result = data.data;

    const users = result.map(processUser);
    return { users, success, total };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const fetchAdmin = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin/users`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { success, data } = await response.json();
    const { total } = await data;
    const admin_info = await data.data;
    console.log(admin_info);
    return { admin_info, success, total };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const setAdmin = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/create/admin_users`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const result = await response.json();

    console.log(result);
  }
};

export const filterBy = async (data, page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${data}?page=${page}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { success, data } = await response.json();
    const { total } = await data;
    const result = await data.data;
    const users = result.map(processUser);
    return { users, success, total };
  }
};

export const deleteAdminUser = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_users/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    // const result = await response.json();
  }
};

export const resetGoogleAuth = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_users/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    // const result = await response.json();
  }
};

export const toggleAdmin = async (id, action, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${action}/admin_users/${id}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    return result;
  }
};

export const lockUser = async (url, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, Response_message } = await response.json();
    return { success, Response_message };
  }
};

export const editAdmin = async (id, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_users/${id}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, response_message } = await response.json();
    return { success, response_message };
  }
};
export const filterUsers = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/search/users`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify({
        words:
          data.email ||
          data.name ||
          data.phone ||
          data.username ||
          data.account_num ||
          data.referral,
      }),
    }
  );
  if (response.ok) {
    const { success, data } = await response.json();
    const { total } = await data;
    const result = data.data;

    const users = result.map(processUser);
    return { users, success, total };
  } else {
  }
};
export const filterUsersByRange = async (startDate, endDate, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/users-all/${startDate}/${endDate}`,
    {
      headers: headers(token),
    }
  );
};

export const getUserLoginHistory = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/login_histories/user/${id}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const user_data = await data.data[0];

    const result = await user_data.login_history;

    // alert(JSON.stringify(result));

    return { result };
  }
};

export const setUserBvn = async (bvnData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/validate-bvn`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(bvnData),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const fetchUserCategory = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_categories`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const addUserCategory = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_categories`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
