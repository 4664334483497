import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import "../../../../morestyles.css";
import { Modal, Loader, Icon } from "../../../../components/Accessories";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { Link } from "react-router-dom";

class IncentivesBar extends React.Component {
  state = {
    key: "transaction-limit",
  };

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  render() {
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Incentives" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.props.active}
                        onSelect={this.getActiveState}>
                        <Tab
                          eventKey="earned-actions"
                          title={
                            <Link to={`/settings/incentives/earned-incentives`}>
                              <Icon title="Earned Actions" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="charge-backs"
                          title={
                            <Link to={`/settings/incentives/charge-backs`}>
                              <Icon title="Charge Backs" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IncentivesBar;
