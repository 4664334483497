import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { logOut } from "../reduxStore/action/action.creator";

const SideBar = ({ match, ...props }) => {
	// alert(match.url);

	const logoutHandler = (e) => {
		e.preventDefault();
		props.logOut();
		//document.location.href = "/";
	};

	return (
		<nav
			className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
			id="sidenav-main"
		>
			<div className="scrollbar-inner">
				<div className="sidenav-header  d-flex  align-items-center">
					<a
						className="navbar-brand"
						href="../../pages/dashboards/dashboard.html"
					>
						<img
							src="../../assets/img/brand/Logo.png"
							className="navbar-brand-img"
							alt="..."
						/>
					</a>
					<div className=" ml-auto ">
						<div
							className="sidenav-toggler d-none d-xl-block"
							data-action="sidenav-unpin"
							data-target="#sidenav-main"
						>
							<div className="sidenav-toggler-inner">
								<i className="sidenav-toggler-line"></i>
								<i className="sidenav-toggler-line"></i>
								<i className="sidenav-toggler-line"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="navbar-inner">
					<div
						className="collapse navbar-collapse"
						id="sidenav-collapse-main"
					>
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link
									to={`/home`}
									className="nav-link active"
									aria-controls="navbar-dashboards"
								>
									<i className="ni ni-shop text-primary"></i>
									<span className="nav-link-text">
										Dashboard
									</span>
								</Link>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-examples"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-examples"
								>
									<i className="ni ni-ungroup text-orange"></i>
									<span className="nav-link-text">
										Customers
									</span>
								</a>
								<div className="collapse" id="navbar-examples">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/allusers`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													A{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													All Customers{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/customer-category`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													C{" "}
												</span>
												<span className="sidenav-normal">
													Customer Category
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={`/transactions`}>
									<i className="ni ni-archive-2 text-green"></i>
									<span className="nav-link-text">
										Transaction
									</span>
								</Link>
							</li>

							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-service"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-service"
								>
									<i class="fa fa-balance-scale text-yellow"></i>
									<span className="nav-link-text">
										Transaction Service
									</span>
								</a>
								<div className="collapse" id="navbar-service">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/bank-transaction`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													B{" "}
												</span>
												<span className="sidenav-normal">
													Bank Transfer Transactions
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/bills`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													B{" "}
												</span>
												<span className="sidenav-normal">
													Bills-list
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/bills/biller-transaction`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													B{" "}
												</span>
												<span className="sidenav-normal">
													Bills-transaction
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/flutterwave-transact`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													F{" "}
												</span>
												<span className="sidenav-normal">
													Flutterwave Transactions
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={`/loan-request`}>
									<i className="fas fa-hand-holding-usd text-pink"></i>
									<span className="nav-link-text">
										Loan Request
									</span>
								</Link>
							</li>

							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-cards"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-examples"
								>
									<i className="fa fa-credit-card"></i>
									<span className="nav-link-text">Cards</span>
								</a>
								<div className="collapse" id="navbar-cards">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/physical-card`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													P{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Physical Card{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/virtual-card`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													V{" "}
												</span>
												<span className="sidenav-normal">
													Virtual Cards
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-report"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-examples"
								>
									<i className="ni ni-chart-pie-35 text-info"></i>
									<span className="nav-link-text">
										Reports
									</span>
								</a>
								<div className="collapse" id="navbar-report">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/report`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													R{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Report Summary{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/report/user-report`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													C{" "}
												</span>
												<span className="sidenav-normal">
													Customer Report
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/report/transaction-report/transaction-month`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													T{" "}
												</span>
												<span className="sidenav-normal">
													Transaction Report
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/report/subscription-report`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													S{" "}
												</span>
												<span className="sidenav-normal">
													Subscription Report
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/report/savings-report/savings-month`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													S{" "}
												</span>
												<span className="sidenav-normal">
													Savings and Investment
													Report
												</span>
											</Link>
										</li>
										{/* <li className="nav-item">
                      <Link
                        to={`/report/investment-report/all-investment-report`}
                        className="nav-link"
                        aria-controls="navbar-dashboards">
                        <span className="sidenav-mini-icon"> I </span>
                        <span className="sidenav-normal">
                          Investment Report
                        </span>
                      </Link>
                    </li> */}
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/notification`}>
									<i className="fas fa-bell text-brown"></i>
									<span className="nav-link-text">
										Notification
									</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/savings`}>
									<i class="fas fa-piggy-bank text-orange"></i>{" "}
									{/* <i className="fas fa-hand-holding-usd text-pink"></i> */}
									<span className="nav-link-text">
										Savings
									</span>
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/investments`}>
									<i class="fas fa-coins text-green"></i>
									<span className="nav-link-text">
										Investment
									</span>
								</Link>
							</li>
							{/* <li className="nav-item">
                <Link className="nav-link" to={`/bulk-payment`}>
                  <i class="fas fa-piggy-bank text-purple"></i>
                  <span className="nav-link-text">Bulk Payment</span>
                </Link>
              </li> */}
							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-inventory"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-examples"
								>
									<i class="fas fa-pallet"></i>
									<span className="nav-link-text">
										shopfront
									</span>
								</a>
								<div className="collapse" id="navbar-inventory">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/e-commerce`}
												className="nav-link"
												aria-controls="navbar-dashboards"
											>
												<span className="sidenav-mini-icon">
													{" "}
													E{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													E-Commerce
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/interests`}>
									<i className="fas fa-percent text-green"></i>
									<span className="nav-link-text">
										Interests
									</span>
								</Link>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={`/new-account`}>
									<i class="fas fa-coins text-green"></i>
									<span className="nav-link-text">
										New Accounts
									</span>
								</Link>
							</li>
						</ul>
						<hr className="my-3" />
						<h6 className="navbar-heading p-0 text-muted">
							<span className="docs-normal">More Options</span>
							<span className="docs-mini">M</span>
						</h6>
						<ul className="navbar-nav mb-md-3">
							<li className="nav-item">
								<Link className="nav-link" to="/settings">
									<i className="ni ni-settings"></i>
									<span className="nav-link-text">
										Settings
									</span>
								</Link>
							</li>

							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-components"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-components"
								>
									<i className="ni ni-ui-04 text-info"></i>
									<span className="nav-link-text">Admin</span>
								</a>
								<div
									className="collapse"
									id="navbar-components"
								>
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/alladmin`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													A{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													All Admins{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/admin-category`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													A{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Admin Category{" "}
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-agents"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-components"
								>
									<i className="ni ni-ui-04 text-info"></i>
									<span className="nav-link-text">Agent</span>
								</a>
								<div className="collapse" id="navbar-agents">
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												className="nav-link"
												to={`/agents`}
											>
												<span className="sidenav-mini-icon">
													{" "}
													A{" "}
												</span>
												<span className="nav-link-text">
													Agents List
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/agent-claim`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													A{" "}
												</span>
												<span className="nav-link-text">
													{" "}
													Agent Claims{" "}
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>

							<li className="nav-item">
								<Link className="nav-link" to={`/auditlogs`}>
									<i className="ni ni-tag text-info"></i>
									<span className="nav-link-text">
										Audit Logs
									</span>
								</Link>
							</li>

							<li className="nav-item">
								<a
									className="nav-link"
									href="#navbar-developers"
									data-toggle="collapse"
									role="button"
									aria-expanded="false"
									aria-controls="navbar-components"
								>
									<i className="fa fa-code text-info"></i>
									<span className="nav-link-text">
										Developers Accounts
									</span>
								</a>
								<div
									className="collapse"
									id="navbar-developers"
								>
									<ul className="nav nav-sm flex-column">
										<li className="nav-item">
											<Link
												to={`/developer-accounts`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													G{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Getripay Developers{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/developer-accounts/call-stats`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													D{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Developer Call Stats{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/developer-accounts/api-calls`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													D{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Developer Api Calls{" "}
												</span>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to={`/developer-accounts/documents`}
												className="nav-link"
											>
												<span className="sidenav-mini-icon">
													{" "}
													D{" "}
												</span>
												<span className="sidenav-normal">
													{" "}
													Developer Documents
												</span>
											</Link>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="#"
									onClick={logoutHandler}
								>
									<i className="fa fa-sign-out"></i>
									<span className="nav-link-text">
										Log Out
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};

const mapStateToProps = (state) => ({
	permission: state.login.permission,
});

export default connect(mapStateToProps, { logOut })(SideBar);
