import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import BillerList from "./Bill_List/BillerList";
import BillerTransaction from "./Bill_Transaction/BillerTransaction";

const BillerRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/biller-list`} />
      </Route>
      <Route exact path={`${match.path}/biller-list`} component={BillerList} />
      <Route
        exact
        path={`${match.path}/biller-transaction`}
        component={BillerTransaction}
      />
    </Switch>
  </>
);
export default BillerRoute;
