import { Modal } from "../../components/Accessories";
import React, { useEffect, useState } from "react";

export const ApproveModal = (props) => {
  const [state, setState] = useState({
    request_id: "",
    comment: "",
    admin_id: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  }

  useEffect(() => {
    setState({
      ...state,
      admin_id: props?.data?.admin,
      request_id: props?.data?.product_data?._id,
    });
  }, [props]);

  const submit = () => {
    if(state.comment){
      props.action(state);
    }
   
  };

  return (
    <Modal
      display={props.display ? "block" : "none"}
      closeModal={props.closeModal}
      modalTitle={props.title}
    >
      <div className="body-grid">
        <div className="box box9 text-center my-4">
          <span className="">Are you sure you want to approve this account?</span>
        </div>

        <div className="box box9 my-4">
          <label>Please enter your comments</label>
          <input
            name="comment"
            value={state.comment}
            onChange={handleChange}
            className="form-control"
            placeholder={`Comment`}
            style={{ width: "auto" }}
          />
        </div>

        <div className="box box1">
          <div className="text-center">
            <button type="button" class="btn btn-primary" onClick={submit}>
              Approve
            </button>
          </div>
        </div>
        <div className="box box2">
          <div className="text-center">
            <button
              type="button"
              class="btn btn-danger"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
