import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import {
  getTransCategory,
  getServiceCode,
  getServiceApplicable,
} from "../../../../../reduxStore/action/action.creator";

class PromotionModal extends React.Component {
  state = {
    name: "",
    description: "",
    categoryCode: "",
    serviceCode: "",
    category_applicable: "",
    serviceCode_applicable: "",
    customerMaxTxnCount: "",
    maxSubscriptionPermit: "",
    startDate: "",
    endDate: "",
  };

  componentDidMount() {
    this.props.getTransCategory(this.props.token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.transcat.length > 0 &&
      this.state.categoryCode != prevState.categoryCode
    ) {
      this.props.getServiceCode(
        this.state.categoryCode.split(",")[1],
        this.props.token
      );
    }

    if (this.state.category_applicable != prevState.category_applicable) {
      this.props.getServiceApplicable(
        this.state.category_applicable.split(",")[1],
        this.props.token
      );
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.setPromotion(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Promotion Configuration">
        <div className="body-grid">
          <div className="box box1">
            <label for="fee-name">Promotion Name</label>

            <div className="input-div">
              <input
                type="text"
                name="name"
                value={this.state.name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label for="limitDescription">Promotion Description</label>

            <div className="input-div">
              <input
                type="text"
                name="description"
                value={this.state.description}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Promotion Category Code</label>

            <div className="input-div">
              <select
                name="categoryCode"
                value={this.state.categoryCode}
                onChange={this.handleChange}>
                <option></option>
                {this.props.transcat.map((content) => (
                  <option value={`${content.categoryCode},${content._id}`}>
                    {content.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box2">
            <label>Promotion Service Code</label>

            <div className="input-div">
              <select
                name="serviceCode"
                value={this.state.serviceCode}
                onChange={this.handleChange}>
                <option></option>
                {this.props.servicecode.map((content) => (
                  <option value={content.serviceCode}>
                    {content.serviceCode}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box1">
            <label>Transaction Category Applicable</label>

            <div className="input-div">
              <select
                name="category_applicable"
                value={this.state.category_applicable}
                onChange={this.handleChange}>
                <option></option>
                {this.props.transcat.map((content) => (
                  <option value={`${content.categoryCode},${content._id}`}>
                    {content.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box2">
            <label>Service Applicable</label>

            <div className="input-div">
              <select
                name="serviceCode_applicable"
                value={this.state.serviceCode_applicable}
                onChange={this.handleChange}>
                <option></option>
                {this.props.serviceCodeApplicable.map((content) => (
                  <option value={content.serviceCode}>
                    {content.serviceCode}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box1">
            <label>Customer Max Transaction Count</label>

            <div className="input-div">
              <input
                name="customerMaxTxnCount"
                value={this.state.customerMaxTxnCount}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Maximum Subscription Permit </label>

            <div className="input-div">
              <input
                type="text"
                name="maxSubscriptionPermit"
                value={this.state.maxSubscriptionPermit}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Start Date</label>

            <div className="input-div">
              <input
                type="date"
                name="startDate"
                value={this.state.startDate}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>End Date</label>

            <div className="input-div">
              <input
                type="date"
                name="endDate"
                value={this.state.endDate}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <br />

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set promotion config
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  transcat: state.alltransactions.transcategory.transCat,
  servicecode: state.alltransactions.transcategory.serviceCode,
  serviceCodeApplicable:
    state.alltransactions.transcategory.serviceCodeApplicable,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  getTransCategory,
  getServiceCode,
  getServiceApplicable,
})(PromotionModal);
