import React from "react";
import { currencyFormatter, dateFormatter, timeFormatter } from "../Row";

export const StoreHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Store Name</th>
      <th>Description</th>
      <th>Industry</th>
      <th>Industry Created At</th>
      <th>Store Image</th>
      <th>Store URL</th>
      <th>Store Available</th>
      <th>Store Created At</th>
      <th>DisApprove/Approve</th>
      <th>Approve Store</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const StoreBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.storeName}</td>
    <td>{props.storeDescription}</td>
    <td>{props.Industry.industryName}</td>
    <td>
      {props.Industry.createdAt == null
        ? "not given"
        : props.Industry.createdAt}
    </td>
    <td style={{ justifyContent: "center", alignItems: "center" }}>
      <img src={props.storeImageUrl} alt="logo" width="20" height="20" />
    </td>
    <td>
      <a href={`http://${props.preferredUrl}.test.getrishop.com`}>
        {props.preferredUrl}
      </a>
    </td>
    <td>{!props.isOffline ? "Available" : "Not Available"}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          onChange={() => props.toggleHandler(props.id)}
          checked={!props.isApproved ? true : false}
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
    <td>{!props.isApproved ? "Already Approved" : "Not Approved"}</td>
    <td>
      <i className="fa fa-eye" onClick={() => props.storeDetails(props.id)}></i>
    </td>
  </tr>
);

export const StoreDetailsList = (props) => (
  <>
    <tr>
      <td>Store Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.storeName}
      </td>
    </tr>
    <tr>
      <td>Industry Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.Industry.industryName}
      </td>
    </tr>
    <tr>
      <td>Online Status</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {!props.isOffline ? "online" : "offline"}
      </td>
    </tr>
    <tr>
      <td>Store URL</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        <a
          href={`http://${props.preferredUrl}.test.getrishop.com`}
          target="_blank">
          {props.preferredUrl}
        </a>
      </td>
    </tr>
    <tr>
      <td>Store Description</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.storeDescription}
      </td>
    </tr>
    <tr>
      <td>Store Image</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        <img src={props.storeImageUrl} alt="logo" width="30" height="30" />
      </td>
    </tr>
    <tr>
      <td>Date Created</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {dateFormatter(props.createdAt)}
      </td>
    </tr>
    <tr>
      <td>Time Created</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {timeFormatter(props.createdAt)}
      </td>
    </tr>
  </>
);

export const StoreOrderHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Order Number</th>
      <th>Order Status</th>
      <th>Order Date</th>
      <th>Order Time</th>
      <th>Order Sum</th>
      <th>Order Type</th>
      <th>Created At</th>
      <th>{!props.store ? "Action" : "View More"}</th>
    </tr>
  </thead>
);

export const StoreOrderBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.orderNumber}</td>
    <td>{props.orderStatus.status}</td>
    <td>{dateFormatter(props.orderDate)}</td>
    <td>{timeFormatter(props.orderDate)}</td>
    <td>{props.orderSum}</td>
    <td>{props.orderType}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>
      <a href="#" onClick={(e) => props.viewOrderDetails(props.id, e)}>
        View Order Details
      </a>
    </td>
  </tr>
);

export const InventoryProductHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Product Name</th>
      <th>Product Description</th>
      <th>Product Price</th>
      <th>Product Image</th>
      <th>Availability</th>
      <th>Product Slug</th>
      <th>Product Category</th>
      <th>Product Sub Category</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const InventoryProductBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.productName}</td>
    <td>{props.productDescription}</td>
    <td>{props.productPrice}</td>
    <td>
      <img src={props.productImagesUrl} alt="logo" width="20" height="20" />
    </td>
    <td>{props.isOffline == 0 ? "online" : "offline"}</td>
    <td>{props.productSlug}</td>
    <td>{props.categoryName}</td>
    <td>{props.productSubCategory}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td style={{ textAlign: "center" }}>
      <i
        className="fa fa-eye"
        onClick={() => props.viewStoreProductDetail(props.id)}></i>
    </td>
  </tr>
);

export const InventoryProductDetail = (props) => (
  <>
    <tr>
      <td>Total In Stock</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.TotalInStock}
      </td>
    </tr>
    <tr>
      <td>Total Sold</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.TotalSold}
      </td>
    </tr>
    <tr>
      <td>Total Supplies</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.TotalSupplies}
      </td>
    </tr>
    <tr>
      <td>Category Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.categoryName}
      </td>
    </tr>
    <tr>
      <td>Product Status</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {!props.isOffline ? "Online" : "Offline"}
      </td>
    </tr>
    <tr>
      <td>Created At</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {dateFormatter(props.createdAt)}
      </td>
    </tr>
    <tr>
      <td>Product Description</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productDescription}
      </td>
    </tr>
    <tr>
      <td>Product Image</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        <img src={props.productImagesUrl} alt="logo" width="30" height="30" />
      </td>
    </tr>
    <tr>
      <td>Product Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productName}
      </td>
    </tr>
    <tr>
      <td>Product Price</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productPrice}
      </td>
    </tr>
    <tr>
      <td>Product Slug</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productSlug}
      </td>
    </tr>
    <tr>
      <td>Product Sub Category</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productSubCategory}
      </td>
    </tr>
    <tr>
      <td>Product Tags</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productTags}
      </td>
    </tr>
    <tr>
      <td>Product Type Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.productTypeName}
      </td>
    </tr>
    <tr>
      <td>Store Selling this product</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.storeName}
      </td>
    </tr>
  </>
);
export const StoreInventoryOrderBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.orderNumber}</td>
    <td>{props.Order.OrderStatusId}</td>
    <td>{dateFormatter(props.Order.orderDate)}</td>
    <td>{timeFormatter(props.Order.orderDate)}</td>
    <td></td>
    <td>{props.Order.orderTypeId}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td style={{ textAlign: "center" }}>
      <i
        className="fa fa-eye"
        onClick={() => props.viewOrderDetails(props.id)}></i>
    </td>
  </tr>
);

export const OrderDetailBody = (props) => (
  <>
    <tr>
      <td>Order Number</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.orderNumber}
      </td>
    </tr>
    <tr>
      <td>Order Date</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {dateFormatter(props.orderDate)}
      </td>
    </tr>
    <tr>
      <td>Order Time</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {timeFormatter(props.orderDate)}
      </td>
    </tr>
    <tr>
      <td>Order Status</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.orderStatus.status}
      </td>
    </tr>
    <tr>
      <td>Order Type</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.orderType}
      </td>
    </tr>
    <tr>
      <td>Store</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>{props.store}</td>
    </tr>
    <tr>
      <td>User Email</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.userObject.email}
      </td>
    </tr>
    <tr>
      <td>User Full Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.userObject.fullName}
      </td>
    </tr>
    <tr>
      <td>User Phone Number</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.userObject.phoneNumber}
      </td>
    </tr>
  </>
);

export const MoreOrderDetailHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Item Number</th>
      <th>Date Created</th>
      <th>Time Created</th>
      <th>Order Number</th>
      <th>PaymentTypeId</th>
      <th>Quantity</th>
      <th>ShippingDetailId</th>
      <th>Unit Price</th>
    </tr>
  </thead>
);

export const MoreOrderDetailBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.itemNumber}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>{timeFormatter(props.createdAt)}</td>
    <td>{props.orderNumber}</td>
    <td>{props.paymentTypeId}</td>
    <td>{props.quantity}</td>
    <td>{props.shippingDetailId}</td>
    <td>{props.unitPrice}</td>
  </tr>
);

export const UserSiteHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Website Domain</th>
      <th>Website Published</th>
      <th>Website Template</th>
      <th>Date Created</th>
      <th>Time Created</th>
      <th>Store Id</th>
    </tr>
  </thead>
);

export const UserSiteBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>
      <a href={`http://${props.website_domain}`} target="_blank">
        {props.website_domain}
      </a>
    </td>
    <td>
      {props.website_published ? "Site is Published" : "Site not Published"}
    </td>
    <td>{props.website_template}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td>{props.store_id}</td>
  </tr>
);

export const OrderProductHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Product Name</th>
      <th>Product Price</th>
      <th>Product Description</th>
      <th>Product Image</th>
      <th>Product Slug</th>
      <th>Availability</th>
      <th>Product Sub Category</th>
      <th>Product Tags</th>
    </tr>
  </thead>
);

export const OrderProductBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.Product.productName}</td>
    <td>{props.Product.productPrice}</td>
    <td>{props.Product.productDescription}</td>
    <td>
      <img src={props.Product.productImagesUrl} width="35" height="35" />
    </td>
    <td>{props.Product.productSlug}</td>
    <td>{!props.Product.isOffline ? "Available" : "Not Available"}</td>
    <td>{props.Product.productSubCategory}</td>
    <td>{props.Product.productTags}</td>
  </tr>
);

export const ProductViewHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>ProductId</th>
      <th>Number of Views</th>
    </tr>
  </thead>
);

export const ProductViewBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.productId}</td>
    <td>{props.viewsCount}</td>
  </tr>
);

export const CheckoutViewHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Product</th>
      <th>Checked out Number</th>
    </tr>
  </thead>
);

export const CheckoutViewBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.productId}</td>
    <td>{props.count}</td>
  </tr>
);
