import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class InvestmentHistoryModal extends React.Component {
  state = {
    progress_name: "",
    investment_product_id: "",
    description: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.addHistory(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Setup Merchant">
        <div className="body-grid">
          <div className="box box9">
            <label>Progress Name</label>
            <input
              className="form-control"
              name="progress_name"
              value={this.state.progress_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Investment Product</label>
            <select
              className="form-control"
              name="investment_product_id"
              value={this.state.investment_product_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.invest_prod &&
                this.props.invest_prod.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Upload History Data
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  admin_data: state.login.data,
  company_data: state.invest.getData.comp_data,
  role_data: state.invest.getData.role_data,
  invest_prod: state.invest.getData.invest_prod?.data,
});

export default connect(mapStateToProps, {})(InvestmentHistoryModal);
