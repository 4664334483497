import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import { KycTab } from "../../components/SettingsComponent";
import Fees from "./SettingsPage/FeeSettings/Fees";
// import Commission from "./SettingsPage/CommissionSettings/Commission";

import { Icon } from "../../components/Accessories";
import "../../morestyles.css";

class Settings extends React.Component {
	state = {
		key: "kyc",
	};

	getActiveState = (k) => {
		this.setState({ key: k });
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		// alert(this.props.permission);
		return (
			<div className="tab-div">
				{this.props.history.location.pathname ==
					`/settings/${this.props.active.toLowerCase()}/${this.props.history.location.pathname.substring(
						this.props.history.location.pathname.lastIndexOf("/") +
							1
					)}` &&
					this.props.history.location.pathname !=
						`/settings/extra/${this.props.history.location.pathname.substring(
							this.props.history.location.pathname.lastIndexOf(
								"/"
							) + 1
						)}` &&
					this.props.history.location.pathname !=
						`/settings/authorization/${this.props.history.location.pathname.substring(
							this.props.history.location.pathname.lastIndexOf(
								"/"
							) + 1
						)}` && (
						<div style={{ paddingBottom: "1em" }}>
							<span
								onClick={this.goBack}
								style={{ cursor: "pointer" }}
							>
								<span
									style={{
										padding: "0 .5em 0 0",
										textAlign: "center",
									}}
								>
									<i
										class="fas fa-chevron-left"
										style={{
											color: "#fff",
											padding: "0.3em .5em",
											border: "2px solid #fff",
											borderRadius: "50%",
											fontSize: "12px",
										}}
									></i>
								</span>
								<span style={{ color: "#fff" }}>Back</span>
							</span>
						</div>
					)}

				<div className="setting">
					<h3 class="mb-0">
						Settings -{" "}
						{this.props.active == "kyc"
							? this.props.active.toUpperCase()
							: this.props.active}
					</h3>
				</div>
				<Tabs
					id="controlled-tab-example"
					activeKey={this.props.active}
					onSelect={this.getActiveState}
				>
					<Tab
						eventKey="kyc"
						title={
							<Link
								to={`/settings/kyc`}
								className="settings-tabs"
								style={{
									color:
										this.props.active == "kyc"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-address-card"
									title="KYC"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Commissions"
						title={
							<Link
								to={`/settings/commissions`}
								style={{
									color:
										this.props.active == "Commissions"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-dropbox"
									title="Commissions"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Beneficiaries"
						title={
							<Link
								to={`/settings/beneficiaries`}
								style={{
									color:
										this.props.active == "Beneficiaries"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-"
									title="Beneficiaries"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Fees"
						title={
							<Link
								to={`/settings/fees`}
								style={{
									color:
										this.props.active == "Fees"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon className="fa fa-money" title="Fees" />
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="LoanProducts"
						title={
							<Link
								to={`/settings/loans`}
								style={{
									color:
										this.props.active == "LoanProducts"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fas fa-hand-holding-usd"
									title="LoanProducts"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Wallet"
						title={
							<Link
								to={`/settings/wallet`}
								style={{
									color:
										this.props.active == "Wallet"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fas fa-wallet"
									title="Wallet Settings"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Authorization"
						title={
							<Link
								to={`/settings/authorization`}
								style={{
									color:
										this.props.active == "Authorization"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-university"
									title="Authorization"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Extra"
						title={
							<Link
								to={`/settings/extra`}
								style={{
									color:
										this.props.active == "Extra"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon className="fas fa-cog" title="Extra" />
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Bills"
						title={
							<Link
								to={`/settings/bills`}
								style={{
									color:
										this.props.active == "Bills"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon className="fas fa-bill" title="Bills" />
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Incentives"
						title={
							<Link
								to={`/settings/incentives`}
								style={{
									color:
										this.props.active == "Incentives"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-incentive"
									title="Incentives"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
					<Tab
						eventKey="Interests"
						title={
							<Link
								to={`/settings/interests`}
								style={{
									color:
										this.props.active == "Interests"
											? "#0038ba"
											: "#fff",
								}}
							>
								<Icon
									className="fa fa-percent"
									title="Interests"
								/>
							</Link>
						}
						tabClassName="mTab"
					></Tab>
				</Tabs>
			</div>
		);
	}
}

export default Settings;
