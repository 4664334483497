import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";

class VirtualCardLimitModal extends React.Component {
  state = {
    kyc_level: "",
    daily_limit: "",
    weekly_limit: "",
    monthly_limit: "",
    yearly_limit: "",
    total_limit: "",
    currency: "",
    created_by: this.props.admin_data.id,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkNumber = (e) => {
    if (
      +this.state.daily_limit >= 0 &&
      +this.state.weekly_limit >= 0 &&
      +this.state.monthly_limit >= 0 &&
      +this.state.yearly_limit >= 0
    ) {
      return;
    } else {
      this.setState({
        [e.target.name]: "",
      });
    }
  };

  handleSubmit = () => {
    this.props.submitLimit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Virtual Transaction Limit">
        <div className="body-grid">
          <div className="box box9">
            <label>Kyc Level</label>
            <select
              className="form-control"
              name="kyc_level"
              value={this.state.kyc_level}
              onChange={this.handleChange}>
              <option></option>
              {this.props.levels &&
                this.props.levels.map((content) => (
                  <option value={content.level}>{content.level_name}</option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>Daily Limit</label>
            <input
              className="form-control"
              name="daily_limit"
              value={this.state.daily_limit}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Weekly Limit</label>
            <input
              className="form-control"
              name="weekly_limit"
              value={this.state.weekly_limit}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box1">
            <label>Monthly Limit</label>
            <input
              className="form-control"
              name="monthly_limit"
              value={this.state.monthly_limit}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Yearly Limit</label>
            <input
              className="form-control"
              name="yearly_limit"
              value={this.state.yearly_limit}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box1">
            <label>Total Limit</label>
            <input
              className="form-control"
              name="total_limit"
              value={this.state.total_limit}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Currency</label>
            <select
              className="form-control"
              name="currency"
              value={this.state.currency}
              onChange={this.handleChange}>
              <option></option>
              {this.props.currency &&
                this.props.currency.map((content) => (
                  <option value={content.currency_code}>
                    {content.currency_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Set Transaction Limit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  levels: state.kyc.get_kyc_level.levels,
  token: state.login.token,
  admin_data: state.login.data,
  currency: state.virtualCards.get_rate.currency,
});

export default connect(mapStateToProps, {})(VirtualCardLimitModal);
