import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NewAccount from "./NewAccount";

const NewAccountRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={NewAccount} />
    </Switch>
  </>
);
export default NewAccountRoute;
