import React from "react";
import { connect } from "react-redux";
import CardLayout from "../VirtualCardBar";
import {
  getVirtualTransaction,
  getUsers,
  getCurrencies,
  filterVirtualCardTrans,
} from "../../../../reduxStore/action/action.creator";
import {
  callBackMethod,
  Loader,
  Pagination,
} from "../../../../components/Accessories";
import {
  VirtualTransactionBody,
  VirtualTransactionHead,
} from "../../../../components/Rows/CardTable";
import ModalFilter from "./VirtualCardTransactionModal";

class VirtualCardTransaction extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentDidMount() {
    this.props.getVirtualTransaction(
      `virtual_cards_transactions`,
      false,
      this.props.token
    );
    this.props.getCurrencies(this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getVirtualTransaction(
        `virtual_cards_transactions?page=${id}`,
        false,
        this.props.token
      );
    } else {
      this.props.filterVirtualCardTrans(
        this.state.filterData,
        id,
        this.props.token
      );
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getVirtualTransaction(
          `virtual_cards_transactions?page=${id}`,
          false,
          this.props.token
        );
      } else {
        this.props.filterVirtualCardTrans(
          this.state.filterData,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getVirtualTransaction(
          `virtual_cards_transactions?page=${id}`,
          false,
          this.props.token
        );
      } else {
        this.props.filterVirtualCardTrans(
          this.state.filterData,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getVirtualTransaction(
      `virtual_cards_transactions?page=${this.state.currentPage}`,
      false,
      this.props.token
    );
  };

  openFilter = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  filter = (data) => {
    callBackMethod(
      this.props.filterVirtualCardTrans(
        data,
        this.state.currentPage,
        this.props.token
      )
    ).then(() => this.setState({ display: false }));
    this.setState({
      ...this.state,
      filterData: data,
    });
  };

  render() {
    let renderPaginate;

    let itemInTable;
    const total =
      this.props.total && parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <VirtualTransactionHead />
          <tbody>
            {this.props.virtual_trans &&
              this.props.virtual_trans.map((content, index) => (
                <VirtualTransactionBody
                  {...content}
                  users={this.props.users}
                  index={this.getIndex(index + 1)}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardLayout active="virtualcard-trasaction">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
            <a
              href="#"
              className="btn btn-sm btn-neutral"
              onClick={this.openFilter}>
              <i className="fa fa-filter"></i> Filter
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
            {renderPaginate}
          </div>
        </CardLayout>
        <ModalFilter
          display={this.state.display}
          closeModal={this.closeModal}
          filter={this.filter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.virtualCards.get_virtual_config.loading,
  token: state.login.token,
  virtual_trans: state.virtualCards.get_virtual_config.data,
  total: state.virtualCards.get_virtual_config.total,
  users: state.users.allusers.usersData,
  filtering: state.virtualCards.get_virtual_config.filtering,
});

export default connect(mapStateToProps, {
  getVirtualTransaction,
  getUsers,
  getCurrencies,
  filterVirtualCardTrans,
})(VirtualCardTransaction);
