import React from "react";
import { currencyFormatter, dateFormatter, timeFormatter } from "../Row";
import { Link } from "react-router-dom";

export const BillerListHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Biller Category</th>
      <th>Date</th>
      <th>Biller Name</th>
      <th>Biller Code</th>
      <th>Label Name</th>
      <th>Biller Narration</th>
      <th>Currency</th>
      <th>Biller Reference</th>
      <th>Biller Category Ref</th>
      <th>Switch</th>
    </tr>
  </thead>
);

const getSwitchers = () => {
  const switchers = ["flutterwave", "interswitch"];

  return switchers;
};

export const BillerListBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.biller_category_name}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{props.biller_name}</td>
    <td>{props.biller_code}</td>
    <td>{props.label_name}</td>
    <td>{props.biller_naration}</td>
    <td>{props.currency}</td>
    <td>{props.biller_ref}</td>
    <td>{props.biller_category_ref}</td>
    <td>
      <ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
        <li class="nav-item dropdown">
          <a
            class="nav-link pr-0"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <div class="media align-items-left">
              <div class="media-body  ml-2  d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">Switch Bill</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu  dropdown-menu-center ">
            {getSwitchers().map((content) => (
              <div
                class="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => props.switchBills(content)}>
                <span>{content}</span>
              </div>
            ))}
          </div>
        </li>
      </ul>
    </td>
  </tr>
);

export const BillerTransHead = () => (
  <thead className="thead-light">
    <th>S/N</th>
    <th>User Id</th>
    <th>Customer Id</th>
    <th>Amount</th>
    <th>Institution Name</th>
    <th>Biller Request Reference</th>
    <th>Date</th>
    <th>Biller Transfer Reference</th>
    <th>Biller Response Code</th>
    <th>Biller Response Message</th>
    <th>Biller Returned Balance</th>
    <th>Biller Returned Pin</th>
    <th>Biller Response Group</th>
    <th>Re-query/Reverse</th>
  </thead>
);

const checker = (status) => {
  let txt, class_status;
  if (status == "PENDING") {
    txt = "re-query";
    class_status = "bg bg-orange";
  }
  return { txt, class_status };
};

export const BillerTransBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.userId}</td>
    <td>{props.customerId}</td>
    <td>{currencyFormatter(props.amount)}</td>
    <td>{props.institutionName}</td>
    <td>{props.biller_requestReference}</td>
    <td>{dateFormatter(props.timeStamp)}</td>
    <td>{props.Biller_trans_ref ? props.Biller_trans_ref : "not provided"}</td>
    <td>{props.Biller_resp_code}</td>
    <td>{props.Biller_resp_msg}</td>
    <td>{props.Biller_return_bal ? props.Biller_return_bal : "not given"}</td>
    <td>{props.Biller_return_pin ? props.Biller_pin : "not given"}</td>
    <td>
      <span
        className={`badge ${
          props.Biller_resp_group == "SUCCESSFUL"
            ? "badge-success"
            : "badge-warning"
        }`}>
        {props.Biller_resp_group}
      </span>
    </td>
    <td>
      {props.Biller_resp_group && (
        <button
          type="button"
          className={checker(props.Biller_resp_group).class_status}
          style={{ padding: ".2em .5em", color: "#fff", borderRadius: "5px" }}>
          <span>{checker(props.Biller_resp_group).txt}</span>
        </button>
      )}
    </td>
  </tr>
);

export const BillCategorySetupHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>

      <th>Name</th>
      <th>Description</th>
      {props.shouldshow == true && (
        <>
          <th>Service Code</th>
          <th>Provider Code</th>
        </>
      )}

      <th>Date Created</th>
      <th>Time Created</th>
      <th>Actions</th>
    </tr>
  </thead>
);

export const BillCategorySetupBody = (props) => (
  <tr>
    <td>{props.index}</td>

    <td>{props.name}</td>
    <td>{props.description}</td>
    {props.shouldshow == true && (
      <>
        <td>{props.servicescode.name}</td>
        <td>{props.providercode.name}</td>
      </>
    )}
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editCode(props)}></i>
    </td>
  </tr>
);

export const ServiceSwitchHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>

      <th>Service Code Id</th>
      <th>Provider Code Id</th>

      <th>Date Created</th>
      <th>Time Created</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const ServiceSwitchBody = (props) => (
  <tr>
    <td>{props.index}</td>

    <td>{props.servicescode?.name}</td>
    <td>{props.providercode.name}</td>

    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editCode(props)}></i>
    </td>
  </tr>
);

export const SwitchCodeHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Service Code Id</th>
      <th>Biller Category Code Id</th>
      <th>Items Code Id</th>
      <th>Product Code Id</th>
      <th>General Code Id</th>
      <th>Provider Code Id</th>
      <th>Date Created</th>
      <th>Time Created</th>
    </tr>
  </thead>
);

export const SwitchCodeBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.servicescode.name}</td>
    <td>{props.biller_category_code_id}</td>
    <td>{props.itemscode?.name}</td>
    <td>{props.product_code_id}</td>
    <td>{props.generalcode?.name}</td>
    <td>{props.providercode?.name}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
  </tr>
);
