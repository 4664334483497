import React from "react";
import { Modal } from "../../../../components/Accessories";

class InterestModal extends React.Component {
	state = {
		name: "",
		description: "",
		minimum_amount: "",
		maximum_amount: "",
		annual_interest: "",
	};

	handleChange = (e, n) => {
		let value = n ? Number(e.target.value) : e.target.value;
		this.setState({
			[e.target.name]: value,
		});
	};

	submit = () => {
		this.props.setup(this.state);
	};

	render() {
		return (
			<Modal
				display={this.props.display ? "block" : "none"}
				closeModal={this.props.closeModal}
				modalTitle="Interest Setup"
			>
				<div className="body-grid">
					<div className="box box9">
						<label>Name</label>
						<input
							className="form-control"
							name="name"
							value={this.state.name}
							onChange={this.handleChange}
						/>
					</div>
					<div className="box box9">
						<label>Description</label>
						<textarea
							className="form-control"
							name="description"
							value={this.state.description}
							onChange={this.handleChange}
						></textarea>
					</div>
					<div className="box box9">
						<label>Minimum Amount</label>
						<input
							className="form-control"
							placeholder="minimum_amount"
							name="minimum_amount"
							value={this.state.minimum_amount}
							onChange={(e) => this.handleChange(e, "n")}
							type="number"
						/>
					</div>
					<div className="box box9">
						<label>Maximum Amount</label>
						<input
							className="form-control"
							placeholder="maximum_amount"
							name="maximum_amount"
							value={this.state.maximum_amount}
							onChange={(e) => this.handleChange(e, "n")}
							type="number"
						/>
					</div>
					<div className="box box9">
						<label>Annual Interest</label>
						<input
							className="form-control"
							placeholder="annual_interest"
							name="annual_interest"
							value={this.state.annual_interest}
							onChange={(e) => this.handleChange(e, "n")}
							type="number"
						/>
					</div>
					<div className="box box9">
						<div className="text-center">
							<button
								type="button"
								class="btn btn-primary my-2"
								onClick={this.submit}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default InterestModal;
