import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { TableHead, Row } from "../../components/Rows";
import "../../morestyles.css";
import {
  getAdmins,
  detailsInfo,
  deleteAdminAction,
  editAdminInfo,
  resetGoogleAuthAction,
} from "../../reduxStore/action/action.creator";
import { Loader } from "../../components/Accessories";
import AdminModal from "./AdminModal";
import { setAdmin, toggleAdmin } from "../../API/UserRequest";
import AdminEditModal from "./AdminEditModal";

class AllAdmin extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      this.setState({
        display2: false,
      });
      this.props.getAdmins(this.props.token, 1);
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.success != this.props.success) {
  //     alert("good");
  //   }
  // }

  componentDidMount() {
    this.props.getAdmins(this.props.token, 1);
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleNextPage = (props) => {
    const { index } = props;
    this.props.detailsInfo(props);
    this.props.history.push(`${this.props.match.url}/${index}/details`);
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  handleSubmit = async (data) => {
    try {
      const res = await setAdmin(data, this.props.token);
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
    });
  };

  deleteAdmin = (id) => {
    this.props.deleteAdminAction(this.props.token, id);
    this.props.getAdmins(this.props.token, this.state.currentPage);
  };
  toggleHandler = async (id, lock) => {
    try {
      let action = "";
      if (lock == "0") {
        action = "disable";
      } else {
        action = "enable";
      }
      const res = await toggleAdmin(id, action, this.props.token);
      this.props.getAdmins(this.props.token, this.state.currentPage);
    } catch (error) {}
  };

  updateAdmin = (props) => {
    this.setState({
      ...this.state,
      display2: true,
      adminToEdit: props.id,
      adminData: props,
    });
  };

  handleEdit = (data) => {
    this.props.editAdminInfo(this.state.adminToEdit, data, this.props.token);
  };

  resetAuth = (id) => {
    console.log(id);
    if (window.confirm("Are you sure?")) {
      console.log("Yes");
    }
  };

  isAccessible = (key) => {
    const check =
      this.props.roles &&
      this.props.roles?.data.filter((res) => {
        return res?.permissions.find((pap) => pap.name === key);
      });
    return check.length === 0 ? false : true;
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TableHead
            admin="true"
            canLockAdmin={this.isAccessible("Lock Admin")}
            canViewAdmin={this.isAccessible("View an Admin Details")}
            canEditAdmin={this.isAccessible("Edit Admin")}
            canDeleteAdmin={this.isAccessible("Delete Admin")}
          />
          <tbody>
            {this.props.admins.map((info, index) => (
              <Row
                {...info}
                admin="true"
                index={this.getIndex(index + 1)}
                goToDetails={this.handleNextPage}
                deleteAdmin={this.deleteAdmin}
                toggleHandler={this.toggleHandler}
                updateAdmin={this.updateAdmin}
                resetAuth={this.resetAuth}
                canLockAdmin={this.isAccessible("Lock Admin")}
                canViewAdmin={this.isAccessible("View an Admin Details")}
                canEditAdmin={this.isAccessible("Edit Admin")}
                canDeleteAdmin={this.isAccessible("Delete Admin")}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          {this.isAccessible("View Admin") ? (
            <div>
              <div class="main-content" id="panel">
                <DashboardNavBar
                  submenu="All Admins"
                  getInfo={this.props.getAdmins}
                  addNew={this.addNew}
                />

                <div class="container-fluid mt--6">
                  <div class="row">
                    <div class="col">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="mb-0">All Admins</h3>
                        </div>
                        <div className="table-responsive py-4">
                          <table
                            className="table table-flush"
                            id="datatable-basic"
                          >
                            {itemInTable}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <AdminModal
                display={this.state.display}
                closeModal={this.closeModal}
                handleSubmit={this.handleSubmit}
              />
              <AdminEditModal
                display={this.state.display2}
                closeModal={this.closeModal}
                adminData={this.state.adminData}
                handleEdit={this.handleEdit}
              />
            </div>
          ) : (
            <h2 className="text-center text-bolder">
              You do not have access to this page
            </h2>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.users.alladmins.loading,
  admins: state.users.alladmins.adminData,
  success: state.users.alladmins.update_message,
  total: state.users.alladmins.total,
  err: state.users.alladmins.failed,
  token: state.login.token,
  roles: state.roles.getrole,
});
export default connect(mapStateToProps, {
  getAdmins,
  detailsInfo,
  deleteAdminAction,
  editAdminInfo,
  resetGoogleAuthAction,
})(AllAdmin);
