import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import {
  getKycLevel,
  getKycLevelSettings,
} from "../../../../../reduxStore/action/action.creator";

class KycRequirementModal extends React.Component {
  state = {
    kyc_setting_id: "",
    kyc_level_id: "",
    is_required: false,
    type: "",
  };

  componentDidMount() {
    this.props.getKycLevelSettings(this.props.token);
    this.props.getKycLevel(this.props.token);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = () => {
    this.setState((prevState) => ({
      is_required: !prevState.is_required,
    }));
  };

  submit = () => {
    // alert(JSON.stringify(this.state));
    this.props.setRequirement(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Kyc Requirement setup">
        <div className="body-grid">
          <div className="box box9">
            <label>Setting Identification</label>
            <select
              className="form-control"
              name="kyc_setting_id"
              value={this.state.kyc_setting_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.level_settings &&
                this.props.level_settings.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Kyc Level Id</label>
            <select
              value={this.state.kyc_level_id}
              name="kyc_level_id"
              className="form-control"
              onChange={this.handleChange}>
              <option></option>
              {!this.props.loading &&
                this.props.levels &&
                this.props.levels.map((content) => (
                  <option value={content.id}>{content.level_name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Kyc Type</label>
            <select
              className="form-control"
              name="type"
              value={this.state.type}
              onChange={this.handleChange}>
              <option value="text">text</option>
              <option value="document">document</option>
              <option value="image">image</option>
              <option value="file">file</option>
            </select>
          </div>
          <div className="box box9">
            <div>
              <label>Is Required</label>
            </div>
            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="is_required"
                checked={this.state.is_required}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                Set Kyc Level Settings
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_kyc_level.loading,
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
  level_settings: state.kyc.get_level_settings.level_settings,
});

export default connect(mapStateToProps, { getKycLevel, getKycLevelSettings })(
  KycRequirementModal
);
