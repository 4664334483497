import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Notifications from "./NotificationList/Notifications";
import NotificationChannel from "./NotificationChannel/NotificationChannel";
import { Error } from "../Error";

export const NotificationRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Notifications} />
      <Route
        exact
        path={`${match.path}/channel`}
        component={NotificationChannel}
      />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
