import { headers } from "./api.config";

const processLoanProducst = (result) => ({
  // name: result.name,
  // _id: result._id,
  // description: result.description,
  // transCategory: result.transactionCategoryName,
  // transCode: result.transactionServiceCode,
  // feeValue: result.feeValue,
  // enabled: result.enabled,
});

export const getLoanProducts = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loan_products`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data, success } = await response.json();
    const allLoanProducts = await data;
    return { success, allLoanProducts };
  } else {
  }
};

export const getLoanRequests = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loans?page=${page}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data, success } = await response.json();
    const allLoanRequests = await data.data;
    const { total } = await data;
    return { success, allLoanRequests, total };
  } else {
    // const { r } = await response.json();
    // throw new Error(response_description);
  }
};
export const setLoan = async (loanInfo, token) => {
  const data = {
    name: loanInfo.loanName,
    range: "some range",
    min_amount: loanInfo.minAmount,
    max_amount: loanInfo.maxAmount,
    penalty: loanInfo.penalty,
    tenure_type: loanInfo.tenureType,
    min_credit_score: loanInfo.minScore,
    min_kyc_level: loanInfo.minKyc,
    process_fees: loanInfo.chargeFee,
    system_can_approved: loanInfo.approve,
    max_tenure: loanInfo.maxTenure,
    interest_rate: loanInfo.intRate,
    interest_rate_type: loanInfo.rateType,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loan_products`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return result;
  }
};

export const changeStatus = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loans/approved/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const rejectLoan = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loans/reject/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const disburseLoan = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loans/disburse/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const userLoanDetails = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/${url}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const updateLoanProduct = async (data, token) => {
  const { id, datatoedit } = data;

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loan_products/${id}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify({
        name: datatoedit.e_loanName,
        min_amount: datatoedit.e_minAmount,
        max_amount: datatoedit.e_maxAmount,
        penalty: datatoedit.e_penalty,
        tenure_type: datatoedit.e_tenureType,
        min_credit_score: datatoedit.e_minscore,
        min_kyc_level: datatoedit.e_minKyc,
        process_fees: datatoedit.e_chargefee,
        system_can_approved: datatoedit.e_approve,
        max_tenure: datatoedit.e_max_tenure,
        interest_rate: datatoedit.e_rate,
        interest_rate_type: datatoedit.e_tenureType,
      }),
    }
  );
  if (response.ok) {
    return true;
  }
};

export const getLoanStatus = async (token) => {
  //
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/statuses`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const filterLoan = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/loan/loans?from=${data.startDate}&to=${data.endDate}&status=${data.status}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data, success } = await response.json();
    const allLoanRequests = await data.data;
    const { total } = await data;
    return { success, allLoanRequests, total };
    // const result = await response.json();
    // return { result };
  }
};
