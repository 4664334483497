import React from "react";
import { connect } from "react-redux";
import InvestmentBar, { ConfirmationModal } from "../InvestmentBar";
import InvestmentProductModal, { UploadModal } from "./InvestmentProductModal";
import InvestmentEditModal from "./InvestmentEditModal";
import {
  getCompany,
  getAdmins,
  getAllMerchant,
  addInvestProduct,
  getInvestmentProduct,
  getInvestmentCategory,
  approveInvestDoc,
  uploadProductImage,
  editInvestmentProduct,
  deleteInvestmentProduct,
  filterInvestment,
} from "../../../reduxStore/action/action.creator";
import {
  callBackMethod,
  Loader,
  DateRange,
} from "../../../components/Accessories";
import {
  InvestmentProdHead,
  InvestmentProdBody,
} from "../../../components/Rows/InvestmentTable";

class InvestmentConfig extends React.Component {
  state = {
    display: false,
    product: "",
    name: "",
    image: null,
    filterby: "",
    showBtnDate: false,
    showDropStatus: false,
    startDate: null,
    endDate: null,
    status: "",
  };

  componentDidMount() {
    this.props.getAdmins(this.props.token, 1);
    this.props.getAllMerchant("/savinvest/merchant_users", this.props.token);
    this.props.getInvestmentProduct(
      "/savinvest/all/investment_products",
      this.props.token
    );
    this.props.getInvestmentCategory(
      "/savinvest/investment_categories",
      this.props.token
    );
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getInvestmentProduct(
      "/savinvest/all/investment_products",
      this.props.token
    );
  };

  handleChange = (e) => {
    this.setState({
      product: e.target.value,
      name: "item name",
    });
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      upload: false,
      edit_modal: false,
      delete_modal: false,
    });
  };

  appoveModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      upload: true,
    });
  };

  addProduct = (data) => {
    callBackMethod(
      this.props.addInvestProduct(
        "/savinvest/investment_products",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => {
        alert(this.props.invest_product_resp);
      })
      .then(() =>
        this.props.getInvestmentProduct(
          "/savinvest/all/investment_products",
          this.props.token
        )
      );
  };

  handleFile = (e) => {
    this.setState({
      image: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("image", this.state.image);

    callBackMethod(
      this.props.uploadProductImage(
        "savinvest/investment_product_image",
        this.state.product,
        formData,
        this.props.token
      )
    )
      .then(() => this.setState({ ...this.state, upload: false }))
      .then(() =>
        alert(JSON.stringify(this.props.upload_resp.response_message))
      );
  };

  changeStatus = (status) => {
    if (status == "active") return "reject";
    if (status == "in-active" || status == "pending") return "approve";
  };

  approveDoc = (props) => {
    callBackMethod(
      this.props.approveInvestDoc(
        "/savinvest/approve_reject/investment_products",
        {
          action: this.changeStatus(props.status),
          investment_product_id: props.id,
          admin_id: this.props.admin_data.id,
        },
        this.props.token
      )
    )
      .then(() => alert(JSON.stringify(this.props.approve_resp)))
      .then(() =>
        this.props.getInvestmentProduct(
          "/savinvest/all/investment_products",
          this.props.token
        )
      );
  };

  editProd = (props) => {
    this.setState({
      ...this.state,
      edit_modal: true,
      editData: props,
    });
  };

  editProduct = (data) => {
    callBackMethod(
      this.props.editInvestmentProduct(
        `/savinvest/investment_products/${this.state.editData.id}`,
        data,
        this.props.token,
        "PUT"
      )
    )
      .then(() => this.setState({ edit_modal: false }))
      .then(() => {
        alert(this.props.edit_prod_resp);
        // if (this.props.edit_prod_resp == true)
        //   alert("successfully updated Investment Product");
        // if (this.props.edit_prod_resp == "undefined")
        //   alert("sorry, could not update Investment Product");
      })
      .then(() =>
        this.props.getInvestmentProduct(
          "/savinvest/all/investment_products",
          this.props.token
        )
      );
  };

  deleteProd = (props) => {
    this.setState({
      delete_modal: true,
      itemToDelete: props.id,
    });
  };

  deleteInvestmentProd = () => {
    callBackMethod(
      this.props.deleteInvestmentProduct(
        `/savinvest/investment_products/${this.state.itemToDelete}`,
        this.props.token
      )
    )
      .then(() => this.setState({ delete_modal: false }))
      .then(() => alert(this.props.invest_del_resp));
  };

  viewInvestProd = (data) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${data.id}/details`,
      state: data.id,
    });
  };

  filterByType = (e) => {
    // alert(e.target.value);
    callBackMethod(
      this.setState({
        filterby: e.target.value,
      })
    ).then(() => this.runByType(this.state.filterby));
  };

  runByType = (type) => {
    if (type == "status") {
      this.setState({
        showDropStatus: true,
        showBtnDate: false,
      });
    } else {
      this.setState({
        showBtnDate: true,
        showDropStatus: false,
      });
    }
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));

    callBackMethod(
      this.setState({
        startDate: start,
        endDate: end,
      })
    ).then(() =>
      this.props.filterInvestment(
        "date",
        `/savinvest/all/investment_products?from=${this.state.startDate}&to=${this.state.endDate}`,
        this.props.token
      )
    );
  };

  handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
      })
    ).then(() =>
      this.props.filterInvestment(
        "status",
        `/savinvest/all/investment_products?status=${this.state.status}`,
        this.props.token
      )
    );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestmentProdHead />
          <tbody>
            {this.props.invest_prod &&
              this.props.invest_prod.map((content, index) => (
                <InvestmentProdBody
                  {...content}
                  index={index + 1}
                  approveDoc={this.approveDoc}
                  admin_data={this.props.admins}
                  merchant={this.props.merchant_data}
                  category={this.props.invest_category}
                  editProd={this.editProd}
                  deleteProd={this.deleteProd}
                  viewInvestProd={this.viewInvestProd}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="investment-config">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <div className="row">
              <div className="col-md-6 text-left">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    value={this.state.filterby}
                    className="form-control"
                    style={{ width: "20em" }}
                    onChange={this.filterByType}>
                    <option>filter by...</option>
                    <option value="status">filter by status</option>
                    <option value="date">filter by date</option>
                  </select>

                  <div style={{ paddingLeft: "1em" }}>
                    {this.state.showBtnDate && (
                      <DateRange handleRange={this.handleRange} />
                    )}

                    {this.state.showDropStatus && (
                      <select
                        style={{ width: "15em" }}
                        onChange={this.handleStatusFilter}
                        className="form-control">
                        <option>Filter by...</option>
                        <option value="active">Active</option>
                        <option value="in-active">In-Active</option>
                        <option value="pending">Pending</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.handleRefresh}>
                  <i class="fas fa-sync-alt"></i> Refresh
                </a>

                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.showModal}>
                  <i class="fas fa-plus"></i> New
                </a>
                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.appoveModal}>
                  <i class="fas fa-plus"></i> Upload Image
                </a>
              </div>
            </div>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <InvestmentProductModal
          display={this.state.display}
          closeModal={this.closeModal}
          addProduct={this.addProduct}
        />
        <UploadModal
          display={this.state.upload}
          closeModal={this.closeModal}
          invest_prod={this.props.invest_prod}
          product_id={this.state.product}
          handleChange={this.handleChange}
          handleFile={this.handleFile}
          handleSubmit={this.handleSubmit}
        />
        <InvestmentEditModal
          display={this.state.edit_modal}
          editData={this.state.editData}
          closeModal={this.closeModal}
          editProduct={this.editProduct}
        />
        <ConfirmationModal
          display={this.state.delete_modal}
          closeModal={this.closeModal}
          action={this.deleteInvestmentProd}
          title="Delete Investment Product"
          actionText="Delete"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  invest_product_resp: state.invest.setData.invest_product_resp,
  invest_prod: state.invest.getData.invest_prod?.data,
  loading: state.invest.getData.loading,
  upload_resp: state.invest.setData.upload_resp,
  admin_data: state.login.data,
  admins: state.users.alladmins.adminData,
  approve_resp: state.invest.setData.approve_resp,
  merchant_data: state.invest.getData.merchant_data,
  invest_category: state.invest.getData.invest_category,
  edit_prod_resp: state.invest.setData.edit_prod_resp,
  invest_del_resp: state.invest.setData.invest_del_resp,
});
export default connect(mapStateToProps, {
  getCompany,
  getAdmins,
  getAllMerchant,
  addInvestProduct,
  getInvestmentProduct,
  getInvestmentCategory,
  approveInvestDoc,
  uploadProductImage,
  editInvestmentProduct,
  deleteInvestmentProduct,
  filterInvestment,
})(InvestmentConfig);
