import React from "react";
import { connect } from "react-redux";

class AllAdminsDetails extends React.Component {
  state = {};
  render() {
    return (
      <div>
        <h3>This is details page</h3>
        <p>{this.props.details.username}</p>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  details: state.users.details.details,
});
export default connect(mapStateToProps)(AllAdminsDetails);
