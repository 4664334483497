import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import TransactionReportMonth from "./TransactionByMonth";
import TransactionByInstitution from "./TransactionByInstitution";
import InstitutionReport from "./InstituteReport";
import ServiceReport from "./ServiceReport";

const View = () => <div>Wassa</div>;

const TransactionReportRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/transaction-month`} />
      </Route>
      <Route
        path={`${match.path}/transaction-month`}
        component={TransactionReportMonth}
      />
      <Route
        path={`${match.path}/transaction-institute`}
        component={TransactionByInstitution}
      />
      <Route
        path={`${match.path}/institute-report`}
        component={InstitutionReport}
      />
      <Route path={`${match.path}/service-report`} component={ServiceReport} />
    </Switch>
  </>
);
export default TransactionReportRoute;
