import { headers } from "./api.config";

export const addIncentives = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/points/config`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_description } = await response.json();
    return { response_description };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const getIncentives = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/points/summary`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, response_description, data } = await response.json();
    return { success, response_description, data };
  }
};

export const redeemedTransfer = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/redeemed/transfers`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const conversionPoint = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/conversions/config`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_description, success } = await response.json();
    return { response_description, success };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const getConversionPoint = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/conversions/config`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const updateIncentiveConversion = async (url, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/${url}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_description } = await response.json();
    return { response_description };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const getMoreIncentiveConfig = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/incentives/${url}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const getUserHistoryConfig = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/interests/${url}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
