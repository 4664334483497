import React from "react";
import { connect } from "react-redux";
import BillsBar from "../BillsBar";
import {
  getSwitchCode,
  getGeneralCode,
  getCodeSetup,
  getProviderCode,
  addCodeConfig,
} from "../../../../../reduxStore/action/action.creator";
import { Loader, callBackMethod } from "../../../../../components/Accessories";
import {
  SwitchCodeHead,
  SwitchCodeBody,
} from "../../../../../components/Rows/BillerRow";
import AddCode from "./SwitchCodeModal";

class SwitchCode extends React.Component {
  state = {
    display: false,
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleSubmit = (data) => {
    callBackMethod(
      this.props.addCodeConfig(`switch_codes`, data, this.props.token)
    )
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.code_response))
      .then(() => this.props.getSwitchCode(`switch_codes`, this.props.token));
  };

  componentDidMount() {
    this.props.getSwitchCode(`switch_codes`, this.props.token);
    this.props.getGeneralCode(`general_codes`, this.props.token);
    this.props.getCodeSetup(`biller_category_codes`, this.props.token);
    this.props.getProviderCode(`provider_codes`, this.props.token);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <SwitchCodeHead />
          <tbody>
            {this.props.switch_code &&
              this.props.switch_code.data.map((content, index) => (
                <SwitchCodeBody
                  is_service={true}
                  {...content}
                  index={index + 1}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <BillsBar {...this.props} active="Switch-Code">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </BillsBar>
        <AddCode
          display={this.state.display}
          closeModal={this.closeModal}
          submit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.bills.billconfig.loading,
  token: state.login.token,
  switch_code: state.bills.billconfig.switch_code,
  code_response: state.bills.addbillconfig.code_response,
});

export default connect(mapStateToProps, {
  getSwitchCode,
  getGeneralCode,
  getCodeSetup,
  getProviderCode,
  addCodeConfig,
})(SwitchCode);
