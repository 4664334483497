import React, { useState } from "react";
import UserDetails from "../../pages/Interests/InterestHistory/UserDetails";
import { getUsers } from "../../reduxStore/action/action.creator";
import { dateFormatter } from "../Row";

export const DocumentTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User Name / Id</th>
      <th>Created At</th>
      <th>Approve / Reject</th>
      <th>Name</th>
      <th>Document ID Number</th>
      <th>Type</th>
      <th>Path</th>

      <th>Kyc Level Name</th>
      {/* <th>Kyc Level Id</th> */}

      <th>Action</th>
      <th>Status</th>
    </tr>
  </thead>
);

const showDoc = (data) => {
  let docName;
  if (data.indexOf("_") > 0) {
    docName = data.substring(data.indexOf("s/") + 2, data.indexOf("_"));
  } else {
    docName = data.substring(data.indexOf("s/") + 2, data.length);
  }

  return docName;
};

export const DocumentTableBody = (props) => {
  // const user_name = getUser(props);

  return (
    <>
      <tr>
        <td>{props.index}</td>
        {/* <td>
          <a href="#" onClick={(e) => props.displayUser(e, props.user_id)}>
            {props.user ? props.user.username : props.user_id}
          </a>
        </td> */}
        {/* <td>{dateFormatter(props.created_at)}</td> */}
        {/* <td>
          {props.kyc_verification.length > 0 ? (
            <>
              {(props.kyc_verification[0].status == "" ||
                props.kyc_verification[0].status == "rejected") && (
                <button
                  className="btn btn-warning"
                  onClick={() => props.approveDoc(props)}>
                  <span>Approve</span>
                </button>
              )}
              {(props.kyc_verification[0].status == "" ||
                props.kyc_verification[0].status == "approved") && (
                <>
                  <button
                    className="btn btn-danger"
                    onClick={() => props.rejectDoc(props)}
                    style={{ backgroundColor: "red" }}>
                    <span>Reject</span>
                  </button>
                  {props.kyc_verification[0].status != "" && (
                    <button
                      className="btn btn-primary"
                      onClick={() => props.finalApprove(props)}>
                      <span>Final Approval</span>
                    </button>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <button
                className="btn btn-warning"
                onClick={() => props.approveDoc(props)}>
                <span>approve</span>
              </button>
              <button
                className="btn btn-danger"
                onClick={() => props.rejectDoc(props)}
                style={{ backgroundColor: "red" }}>
                <span>reject</span>
              </button>
            </>
          )}
        </td>
        <td>{props.name}</td> */}
        {/* <td>{props.identification_number}</td>
        <td>{props.type}</td> */}
        {/* <td>
          <button
            onClick={() => props.viewDoc(props.path)}
            style={{ color: "blue" }}>
            {props.path
              ? `view ${showDoc(
                  process.env.REACT_APP_DOCUMENT_BASE_URL + props.path
                )}`
              : "not given"}
          </button>
        </td> */}

        {/* <td>{props.kyc_level_name}</td>
        <td>
          <i className="fa fa-eye" onClick={() => props.viewDetails(props)}></i>
        </td>
        <td>
          {props.kyc_verification[0]
            ? props.kyc_verification[0].status
            : "pending"}
        </td> */}

        {/* <UserDetails
          display={props.showUser}
          close={props.closePopup}
          user_details={props.user_details}
        /> */}
      </tr>
    </>
  );
};

export const LevelSettingHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Document Type</th>
      <th>Name</th>
      <th>Description</th>
      <th>System Can Approve</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const LevelSettingBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.document_type}</td>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>{props.system_can_approved ? "Yes" : "No"}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td></td>
  </tr>
);

export const CustomerDocumentHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th style={{ width: "16.66%" }}>Customer Name</th>
      <th style={{ width: "16.66%" }}>Document Name</th>
      <th style={{ width: "16.66%" }}>Document URL</th>
      <th style={{ width: "16.66%" }}>Approved By</th>
      <th style={{ width: "16.66%" }}>Document Status</th>
      <th style={{ width: "16.66%" }}>Comment</th>
      <th style={{ width: "16.66%" }}>Created At</th>
      <th style={{ width: "16.66%" }}>Action</th>
    </tr>
  </thead>
);

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const CustomerDocumentBody = (props) => (
  <tr>
    <td style={{ width: "16.66%" }}>{props?.index}</td>
    <td style={{ width: "16.66%" }}>
      {props.user.first_name.toUpperCase()}{" "}
      {props?.user?.last_name.toUpperCase()}
    </td>
    <td style={{ width: "16.66%" }}>
      {props?.document_url && props?.document?.name}
    </td>
    <td>
      {props?.document_url && props?.document_url.substr(0, 4) === "http" ? (
        <button
          onClick={() => openInNewTab(props?.document_url)}
          className="btn btn-primary">
          View
        </button>
      ) : (
        <p>{props?.document_url && props?.document_url}</p>
      )}
    </td>
    <td style={{ width: "16.66%" }}>
      {props?.approval_details?.username}
      {/* {props?.approval_details?.first_name} {props?.approval_details?.last_name} */}
    </td>
    <td style={{ width: "16.66%" }}>{props?.approval_status.toUpperCase()}</td>
    <td style={{ width: "16.66%" }}>{props?.comment}</td>
    <td style={{ width: "16.66%" }}>{dateFormatter(props?.created_at)}</td>
    <td style={{ width: "16.66%" }}>
      {props?.approval_status === "Pending" ? (
        <>
          <button
            onClick={() =>
              props.pickAgent(props?.user?.id, props?.id, "Accept")
            }
            type="button"
            class="btn btn-primary">
            Accept
          </button>
          <button
            onClick={() => props.pickAgent(props?.user.id, props?.id, "Reject")}
            type="button"
            class="btn btn-danger">
            Reject
          </button>
        </>
      ) : (
        ""
      )}
    </td>
  </tr>
);

export const KycUpgradeHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Customer Name</th>
      {/* <th>Document Name</th> */}
      <th>Status</th>
      <th>Present Kyc</th>
      <th>Proposed Kyc</th>
      <th>Created At</th>
      {/* <th>Action</th> */}
    </tr>
  </thead>
);

export const KycUpgradeBody = (props) => (
  <tr key={props.index}>
    <td>{props?.index}</td>
    <td
      style={{ cursor: "pointer", color: "blue" }}
      onClick={(e) =>
        props.showModal(
          e,
          props?.user_id,
          props?.id,
          props?.user,
          props?.approval_status, 
          props
        )
      }>
      {props?.user?.first_name.toUpperCase()}{" "}
      {props?.user?.last_name.toUpperCase()}
    </td>
    {/* <td>{props?.document?.name}</td> */}
    <td>{props?.approval_status}</td>
    <td>{props?.current_level?.level_name}</td>
    <td>{props?.new_level?.level_name}</td>
    <td>{dateFormatter(props?.created_at)}</td>
    {/* <td>ehllo</td> */}
  </tr>
);

export const KycLevelHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Level Name</th>
      <th>Description</th>
      <th>Position</th>
      <th>Kyc Level</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const KycLevelBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props?.level_name}</td>
    <td>{props?.description}</td>
    <td>{props?.position}</td>
    <td>{props?.level}</td>
    <td>{dateFormatter(props?.created_at)}</td>
    <td>
      <i
        className="fa fa-eye"
        onClick={() => {
          props.goToNextPage(props);
        }}></i>
      <i
        className="fa fa-pencil"
        onClick={() => props.editLevel(props)}
        style={{ padding: "0 .5em" }}></i>
      <i className="fa fa-trash-o" onClick={() => props.deleteLevel(props)}></i>
    </td>
  </tr>
);

export const KycRequirementHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Kyc Setting Name</th>
      <th>Kyc Level Name</th>
      <th>Item</th>
      <th>Is Required</th>
      <th>Type</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

const getKycLevelName = (props) => {
  const level_name = props.kyc_level.map((content) => {
    if (content.id == props.kyc_level_id) {
      return content.level_name;
    }
  });
  return level_name;
};

const getKycLevelSettings = (props) => {
  const level_settings = props.kyc_level_setting.map((content) => {
    if (content.id == props.kyc_setting_id) {
      return content.name;
    }
  });
  return level_settings;
};

export const KycRequirementBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{getKycLevelSettings(props)}</td>
    <td>{getKycLevelName(props)}</td>
    <td>{props.item == null ? "not given" : props.item}</td>
    <td>{props.is_required ? "Yes" : "No"}</td>
    <td>{props.type ? props.type : "not given"}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i
        className="fa fa-trash-o"
        onClick={() => props.deleteRequirement(props.id)}></i>
    </td>
  </tr>
);

export const KycDetailsList = (props) => {
  return (
    <>
      <table class="table table-hover mt-1">
        <tbody>
          <tr>
            <td>Level Name</td>
            <td>{props.level_name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{props.description}</td>
          </tr>
          <tr>
            <td>Position</td>
            <td>{props.position}</td>
          </tr>
          <tr>
            <td>Date Created</td>
            <td>{props.created_at}</td>
          </tr>
          <tr>
            <td>Level</td>
            <td>{props.level}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
