import {
  GET_TXN_LIMIT_CONFIGS_SUCCESS,
  GET_TXN_LIMIT_CONFIGS_FAILED,
  SET_TXN_LIMIT_SUCCESS,
  SET_TXN_LIMIT_FAILED,
  EDIT_LIMIT,
  DELETE_TRANSACTION_LIMIT,
} from "../action/action.types";
import { combineReducers } from "redux";

const initialState = {
  limitConfigs: [],
  total: 0,
};

const limitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
      };
    case GET_TXN_LIMIT_CONFIGS_SUCCESS:
      return {
        ...state,
        limitConfigs: action.payload.mLimitConfigs,
        total: action.payload.allLimitConfigs,
        loading: false,
      };
    case GET_TXN_LIMIT_CONFIGS_FAILED:
      return {
        ...state,
        limitConfigs: [],
        failed: action.payload,
      };

    default:
      return state;
  }
};

const updateLimitConfig = (state = {}, action) => {
  switch (action.type) {
    case EDIT_LIMIT:
      return {
        ...state,
        response_msg: action.payload.response_description,
        edit_success: true,
      };
    case DELETE_TRANSACTION_LIMIT:
      return {
        ...state,
        response_msg: action.payload.response_description,
      };
    default:
      return state;
  }
};

const setLimitConfig = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case SET_TXN_LIMIT_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.response_description,
        loading: false,
      };
    case SET_TXN_LIMIT_FAILED:
      return {
        ...state,
        failed: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const initialLimitReducer = combineReducers({
  allLimits: limitsReducer,
  setLimits: setLimitConfig,
  editLimits: updateLimitConfig,
});
