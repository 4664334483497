import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { NewAccountBody, NewAccountHead } from "../../components/Rows";
import {
  getNewAccountRequest as getTransaction,
  approveAccountRequest,
  detailsInfo,
  filterStatus,
  filterAllTransaction,
  reverseTransaction,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import {
  Loader,
  Pagination,
  callBackMethod,
} from "../../components/Accessories";
import ModalFilter from "./ModalFilter";
import { ApproveModal } from "./ApproveModal";

class NewAccount extends React.Component {
  state = {
    currentPage: 1,
    total: 0,
    display: false,
  };

  componentDidMount() {
    if (this.props.search_filter) {
      this.props.filterAllTransaction(
        this.state.dataToFilter,
        this.props.token
      );
    } else {
      this.props.getTransaction(
        `request_additional_accounts`,
        this.props.token
      );
    }
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getTransaction(
        `request_additional_accounts?page=${id}`,
        this.props.token
      );
    } else if (this.props.search_filter) {
      this.props.filterAllTransaction(
        this.state.dataToFilter,
        this.props.token
      );
    } else {
      this.props.filterStatus(this.state.statusValue, id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getTransaction(
          `request_additional_accounts?page=${id}`,
          this.props.token
        );
      } else if (this.props.search_filter) {
        this.props.filterAllTransaction(
          this.state.dataToFilter,
          this.props.token
        );
      } else {
        this.props.filterStatus(this.state.statusValue, id, this.props.token);
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.totalPages / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering == true) {
        this.props.filterStatus(this.state.statusValue, id, this.props.token);
      } else if (this.props.search_filter) {
        this.props.filterAllTransaction(
          this.state.dataToFilter,
          this.props.token
        );
      } else {
        this.props.getTransaction(
          `request_additional_accounts?page=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleRefresh = () => {
    this.setState({
      filtering: false,
    });
    this.props.getTransaction(
      `request_additional_accounts?page=${this.state.currentPage}`,
      this.props.token
    );
  };

  getIndex = (index) => {
    let newIndex;
    newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleSubmit = (data) => {
    this.props.filterAllTransaction(data, this.props.token);
    this.setState({
      ...this.state,
      dataToFilter: data,
    });
  };

  approveAction = (data) => {
    this.props
      .approveAccountRequest(data, this.props.token)
      .then(() => {
        this.setState({ approve_modal: false });
      })
      .then(() => {
        alert(this.props.approve_resp);
      })
      .then(() => {
        this.props.getTransaction(
          `request_additional_accounts`,
          this.props.token
        );
      });
  };

  closeApproveModal = () => {
    this.setState({ approve_modal: false });
  };

  filterByStatus = (status) => {
    this.props.filterStatus(status, 1, this.props.token);
    this.setState({
      ...this.state,
      statusValue: status,
    });
  };

  approveRequest = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props._id,
      approve_modal: true,
      product_data: props,
      admin: this.props.admin_data.admin_id,
    });
  };

  render() {
    let renderPaginate;
    let itemInTable;
    // const total = parseInt(Math.ceil(this.props.totalPages / 15));
    // const paginateNumber = Array(total).fill(0);
    // this.props.total > 15 &&
    //   !this.props.loading &&
    //   (renderPaginate = (
    //     <Pagination
    //       paginateNumber={paginateNumber}
    //       currentPage={this.state.currentPage}
    //       handlePagination={this.changePage}
    //       handlePrevious={this.goPrevious}
    //       handleNext={this.goNext}
    //       total={total}
    //       totalData={this.props.totalPages}
    //     />
    //   ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <NewAccountHead />
          <tbody>
            {this.props.request.map((trans, index) => (
              <NewAccountBody
                {...trans}
                index={this.getIndex(index + 1)}
                goToNextPage={this.handleNextPage}
                approveRequest={this.approveRequest}
              />
            ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="New Accounts"
              getInfo={this.handleRefresh}
              showModal={this.showModal}
              filterByStatus={this.filterByStatus}
              shouldFilter={true}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Additional Account Requests</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                      {renderPaginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalFilter
          display={this.state.display}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
        <ApproveModal
          display={this.state.approve_modal}
          closeModal={this.closeApproveModal}
          action={this.approveAction}
          title="Approve Account"
          actionText={this.state.actionText ? "Lock" : "Unlock"}
          data={this.state}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.newAccount.newAccount.loading,
  request: state.newAccount.newAccount.all_requests,
  totalPages: state.newAccount.newAccount.totalPages,
  //err: state.newAccount.newAccount.failed,
  filtering: state.newAccount.newAccount.filtering,
  search_filter: state.newAccount.newAccount.search_filter,
  admin_data: state.login.data,
  token: state.login.token,
  approve_resp: state.newAccount.approveNewAccount.approve_resp,
});

export default connect(mapStateToProps, {
  getTransaction,
  detailsInfo,
  filterStatus,
  reverseTransaction,
  filterAllTransaction,
  approveAccountRequest,
})(NewAccount);
