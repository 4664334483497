import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import KycRequirementModal from "./RequirementModal";
import {
  setKycRequirement,
  getKycRequirement,
  deleteKycRequirement,
} from "../../../../../reduxStore/action/action.creator";
import { Loader, callBackMethod } from "../../../../../components/Accessories";
import {
  KycRequirementHead,
  KycRequirementBody,
} from "../../../../../components/Rows/KycTable";

class KycRequirement extends React.Component {
  state = {
    display: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.response_msg) {
      //
    }
  }

  componentDidMount() {
    this.props.getKycRequirement(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getKycRequirement(this.props.token);
  };

  setRequirement = (data) => {
    callBackMethod(this.props.setKycRequirement(data, this.props.token))
      .then(() => {
        this.setState({
          display: false,
        });
      })
      .then(() => alert(this.props.response_msg))
      .then(() => this.props.getKycRequirement(this.props.token));
  };

  deleteRequirement = (id) => {
    callBackMethod(this.props.deleteKycRequirement(id, this.props.token))
      .then(() => alert(this.props.response))
      .then(() => this.props.getKycRequirement(this.props.token));
  };

  render() {
    let itemToDisplay;
    if (this.props.loading) {
      itemToDisplay = <Loader loading={this.props.loading} />;
    } else {
      itemToDisplay = (
        <>
          <KycRequirementHead />
          <tbody>
            {this.props.requirement &&
              this.props.requirement.map((content) => (
                <KycRequirementBody
                  {...content}
                  kyc_level={this.props.levels}
                  kyc_level_setting={this.props.level_settings}
                  deleteRequirement={this.deleteRequirement}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <KycBar {...this.props} active="Requirements">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
          </div>
        </KycBar>
        <KycRequirementModal
          display={this.state.display}
          closeModal={this.closeModal}
          setRequirement={this.setRequirement}
        />
      </>
    );
  }
}

const mapStateToProp = (state) => ({
  response_msg: state.kyc.set_requirement.response,
  requirement: state.kyc.get_requirement.requirement,
  response: state.kyc.get_requirement.response_message,
  loading: state.kyc.get_requirement.loading,
  levels: state.kyc.get_kyc_level.levels,
  level_settings: state.kyc.get_level_settings.level_settings,
  token: state.login.token,
});

export default connect(mapStateToProp, {
  setKycRequirement,
  getKycRequirement,
  deleteKycRequirement,
})(KycRequirement);
