import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FeeSharing from "./FeeSharing";
import Fees from "./Fees";
import { Error } from "../../../Error";

const FeeRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Fees} />
      <Route
        exact
        path={`${match.path}/:id/feesharing`}
        component={FeeSharing}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);
export default FeeRoute;
