import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "../pages/DashBoard";
import { AdminProfile } from "../pages/ProfilePage";
import { Report } from "../pages/Report";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AllUserRoute } from "../pages/AllUsers";
import { AllAdminRoute } from "../pages/AllAdmin";
import { AuditLogsRoute } from "../pages/AuditLogs";
import { TransactionRoute } from "../pages/Transaction";
import { BankTransactRoute } from "../pages/BankTransaction";
import { FlutterwaveRoute } from "../pages/Flutterwave";
import { NotificationRoute } from "../pages/Notification/NotificationRoute";
import { BillerRoute } from "../pages/Bills";
import { LoanRequestRoute } from "../pages/LoanRequest";
import { SettingRoute } from "../pages/Settings";
import { VirtualCardRoute } from "../pages/Cards/VirtualCards";
import { PhysicalCardRoute } from "../pages/Cards/PhysicalCards";
import { Error } from "../pages/Error";
import { DeveloperAccountsRoute } from "../pages/DeveloperAccounts";
import CategoryRoute from "../pages/AllUsersCategory/CategoryRoute";
import { SavingsRoute } from "../pages/Savings";
import { InvestmentRoute } from "../pages/Investment";
import IdleTimer from "react-idle-timer";
import { InventoryRoute } from "../pages/Inventory";
import AgentRoute from "../pages/Agents/AgentRoute";
import AgentClaimRoute from "../pages/AgentClaims/AgentClaimRoute";
import {
  getPermissionMod,
  getUsers,
  logOut,
} from "../reduxStore/action/action.creator";
import { LogOutModal } from "../components/Accessories";
import { BulkPaymentRoute } from "../pages/BulkPayments";
import { NewAccountRoute } from "../pages/NewAccount";
import { InterestRoute } from "../pages/Interests/InterestRoute";

class Routes extends React.Component {
  state = {
    isAllowed: [],
    display: false,
    countDown: 1000 * 600 * 1,
    isTimedOut: false,
  };

  idleTimer = null;

  componentDidMount() {
    console.log(this.props.permission);
    this.props.permission &&
      this.props.permission.map((permit, index) => {
        this.state.isAllowed.push(permit.permissions);
        // this.setState((prevState) => ({
        //   isAllowed: [...prevState.isAllowed, permit.permissions],
        // }));
      });

    // setInterval(() => {
    //   if (this.state.countDown >= 1) {
    //     this.setState((prevState) => ({
    //       countDown: prevState.countDown - 1,
    //     }));
    //   } else {
    //     this.setState({
    //       countDown: 10,
    //     });
    //   }
    // }, 1000);

    // alert(JSON.stringify(this.state.isAllowed));

    // if (this.props.username == "Admin") {
    //   this.setState({
    //     isAllowed: true,
    //   });
    // }
    // this.props.getUsers(this.props.token);
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.err) {
    //   alert(nextProps.err);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.word != this.props.word) {
      this.props.history.push("/auditlogs");
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  _onAction = (e) => {
    this.setState({ isTimedOut: false });
  };

  _onActive = (e) => {
    this.setState({ isTimedOut: false });
  };

  _onIdle = (e) => {
    if (this.state.isTimedOut) {
      // do something
      this.props.logOut();
    } else {
      this.setState({ display: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };

  handleLogout = () => {
    this.setState({ display: false });
    this.props.logOut();
  };

  handleClose = () => {
    this.setState({ display: false });
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this._onActive}
          onIdle={this._onIdle}
          onAction={this._onAction}
          debounce={250}
          timeout={this.state.countDown}>
          <Switch>
            <Route exact path="/home" component={Dashboard} />
            <Route exact path="/profile" component={AdminProfile} />
            {/* <Route path="/allusers" component={AllUserRoute} /> */}
            {(this.state.isAllowed.includes("Customer") ||
              this.props.username == "Admin") && (
              <Route path="/allusers" component={AllUserRoute} />
            )}
            <Route exact path="/customer-category" component={CategoryRoute} />
            <Route path="/alladmin" component={AllAdminRoute} />
            <Route path="/auditlogs" component={AuditLogsRoute} />
            <Route path="/agents" component={AgentRoute} />
            <Route path="/agent-claim" component={AgentClaimRoute} />
            <Route
              path="/developer-accounts"
              component={DeveloperAccountsRoute}
            />
            {/* <Route
              path="/transactions"
              render={(props) => <TransactionRoute {...props} />}
            /> */}
            {(this.state.isAllowed.includes("Transaction") ||
              this.props.username == "Admin") && (
              <Route
                path="/transactions"
                render={(props) => <TransactionRoute {...props} />}
              />
            )}
            {/* {this.state.isAllowed.includes("Bank Transaction") && ( */}
            <Route path="/bank-transaction" component={BankTransactRoute} />
            {/* )} */}
            {this.state.isAllowed.includes("Flutterwave Transaction") && (
              <Route
                path="/flutterwave-transact"
                component={FlutterwaveRoute}
              />
            )}
            {this.state.isAllowed.includes("Bills") && (
              <Route path="/bills" component={BillerRoute} />
            )}
            {(this.state.isAllowed.includes("Notification") ||
              this.props.username == "Admin") && (
              <Route path="/notification" component={NotificationRoute} />
            )}
            {(this.state.isAllowed.includes("Loan Request") ||
              this.props.username == "Admin") && (
              <Route path="/loan-request" component={LoanRequestRoute} />
            )}
            {/* {this.state.isAllowed.includes("Report") && ( */}
            <Route path="/report" component={Report} />
            {/* )} */}
            <Route path="/settings" component={SettingRoute} />
            {/* {this.state.isAllowed.includes("VirtualCard") && ( */}
            <Route path="/virtual-card" component={VirtualCardRoute} />
            {/* )} */}
            {this.state.isAllowed.includes("PhysicalCard") && (
              <Route path="/physical-card" component={PhysicalCardRoute} />
            )}
            {/* {this.state.isAllowed.includes("Savings") && ( */}
            <Route path="/savings" component={SavingsRoute} />
            {/* )} */}
            {/* {this.state.isAllowed.includes("Investment") && ( */}
            <Route path="/investments" component={InvestmentRoute} />
            {/* )} */}

            <Route path="/e-commerce" component={InventoryRoute} />
            <Route path="/bulk-payment" component={BulkPaymentRoute} />
            <Route path="/new-account" component={NewAccountRoute} />
            <Route path="/interests" component={InterestRoute} />
            <Route exact component={Error} />
          </Switch>
          <LogOutModal
            display={this.state.display}
            closeModal={this.closeModal}
            logout={this.handleLogout}
            close={this.handleClose}
          />
        </IdleTimer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  permission: state.login.permission,
  username: state.login.data.username,
  data: state.roles.getpermissionmod.data,
  err: state.users.allusers.failed,
  token: state.login.token,
  word: state.logs.auditLogs.word,
});
export default connect(mapStateToProps, { getPermissionMod, getUsers, logOut })(
  Routes
);
