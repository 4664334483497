import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import {
  getStoreDetails,
  getStoreProduct,
  getStoreOrder,
} from "../../../reduxStore/action/action.creator";
import { Loader } from "../../../components/Accessories";
import {
  StoreDetailsList,
  StoreOrderBody,
  StoreOrderHead,
  InventoryProductBody,
  InventoryProductHead,
} from "../../../components/Rows/InventoryTable";

class StoreDetails extends React.Component {
  state = {
    isTabActive: [],
  };

  componentDidMount() {
    this.changeTabActive(0);

    const storeId = this.props.history.location.state;
    this.props.getStoreDetails(`/store/${storeId}`, this.props.token);
    this.props.getStoreProduct(`/store_products/${storeId}`, this.props.token);
    this.props.getStoreOrder(`/store_orders/${storeId}`, this.props.token);
  }

  changeTabActive = (id) => {
    let activeTab = [];
    activeTab[id] = true;
    this.setState((prevState) => ({
      isTabActive: (prevState.isTabActive = activeTab),
    }));
    if (id === 0) {
      // this.props.tabDetail(
      //   `user/users-profile/${this.props.loanRequestDetails.data.user_id}`
      // );
    } else {
      //   this.props.loanTabDetails(url, this.props.token);
    }
  };

  viewStoreProductDetail = (id) => {
    this.props.history.push({
      pathname: `/e-commerce/products/${id}/detail`,
      state: id,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  viewOrderDetails = (id, e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/e-commerce/orders/${id}/detail`,
      state: id,
    });
  };

  render() {
    let itemInCard;
    if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
    } else {
      itemInCard = this.props.store_details && (
        <StoreDetailsList {...this.props.store_details} />
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            padding: "0 .5em 0 0",
                            textAlign: "center",
                          }}>
                          <i
                            class="fas fa-chevron-left"
                            style={{
                              color: "#fff",
                              padding: "0.3em .5em",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}></i>
                        </span>
                        <span style={{ color: "#fff" }}>Back</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--5">
              <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
                <div class="col-5 userDetailsCol1">
                  <div style={{ padding: "1em 0.5em" }}>
                    <h3>
                      Full Details for Store{" "}
                      {this.props.store_details?.storeName}
                    </h3>
                  </div>
                  <table class="table table-hover">
                    <tbody>{itemInCard}</tbody>
                  </table>
                </div>
                <div class="col-7 userDetailsCol2">
                  <div class="tab" role="tabpanel">
                    <div
                      class="card-header"
                      style={{
                        margin: "0px",
                        backgroundColor: "lavenderblush",
                        borderBottom: "0px",
                      }}>
                      <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                        <li
                          role="presentation"
                          className={this.state.isTabActive[0] ? "active" : ""}
                          onClick={() => this.changeTabActive(0)}>
                          <a
                            href="#Section0"
                            aria-controls="user-details"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-user"></i> View All Products for
                            this Store
                          </a>
                        </li>
                        <li
                          role="presentation"
                          className={this.state.isTabActive[1] ? "active" : ""}
                          onClick={() => this.changeTabActive(1)}>
                          <a
                            href="#Section1"
                            aria-controls="transactions"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-credit-card"></i> View All Orders
                            for this Store
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="tab-content tabs">
                      <div
                        role="tabpanel"
                        class="tab-pane active"
                        id="Section0">
                        <table className="table table-responsive table-div">
                          {/** product for a store goes here */}
                          <InventoryProductHead store={true} />
                          <tbody>
                            {this.props.store_product &&
                              this.props.store_product.products.map(
                                (content, index) => (
                                  <InventoryProductBody
                                    {...content}
                                    index={index + 1}
                                    viewStoreProductDetail={
                                      this.viewStoreProductDetail
                                    }
                                    store={true}
                                  />
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                      <div role="tabpanel" class="tab-pane fade" id="Section1">
                        <table className="table table-responsive table-div">
                          {/** orders for a store goes here */}
                          <StoreOrderHead store={true} />
                          <tbody>
                            {this.props.store_order &&
                              this.props.store_order.orders.map(
                                (content, index) => (
                                  <StoreOrderBody
                                    {...content}
                                    index={index + 1}
                                    viewStoreProductDetail={
                                      this.viewStoreProductDetail
                                    }
                                    viewOrderDetails={this.viewOrderDetails}
                                  />
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store_details: state.inventory.get_store_inventory.store_details,
  store_product: state.inventory.get_store_inventory.store_product,
  store_order: state.inventory.get_store_inventory.store_order,
});

export default connect(mapStateToProps, {
  getStoreDetails,
  getStoreProduct,
  getStoreOrder,
})(StoreDetails);
