import React from "react";
import { connect } from "react-redux";
import {
  TxnLimitTableHead,
  TxnLimitRow,
} from "../../../../../components/Rows/TransactionLimitTable";
import "../../../../../morestyles.css";
import {
  Modal,
  Loader,
  callBackMethod,
} from "../../../../../components/Accessories";
import {
  getLimitConfigs,
  getTransCategory,
  getServiceCode,
  detailsInfo,
  updateTransactionLimit,
  deleteTransLimit,
  getUserCategory,
} from "../../../../../reduxStore/action/action.creator";
import ExtraBar from "../Extra";
import {
  setLimitConfig,
  getLimitType,
} from "../../../../../API/limitConfigRequest";
import EditTransactionLimitModal from "./EditTransactionLimitModal";

class TransactionLimit extends React.Component {
  state = {
    limitName: "",
    limitDescription: "",
    limitType: "",
    kycLevel: "",
    transCategory: "",
    code: "",
    limitValue: "",
    id: "",
    display: false,
    currentPage: 1,
    limitTypeArray: [],
    type: "",
    userCategory: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.transcat.length > 0 &&
      prevState.transCategory != this.state.transCategory
    ) {
      const trans_data = this.state.transCategory.split(",");
      this.props.getServiceCode(trans_data[0], this.props.token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.edit_success == true) {
    }
    // if (!nextProps.history) {
    //   if (nextProps.successmsg === "Successfully added") {
    //     this.setState({
    //       display: false,
    //     });
    //   }
    // }
    // if (nextProps.edit_res && !nextProps.history) {
    //   this.setState({
    //     display: false,
    //   });
    //   // this.props.getLimitConfigs();
    // }
    // alert(JSON.stringify(nextProps));
  }

  componentDidMount() {
    this.props.getLimitConfigs(1, this.props.token);
    this.getLimitType();
    this.props.getUserCategory(this.props.token);
  }

  changePage = (id) => () => {
    this.props.getLimitConfigs(id, this.props.token);

    this.setState({
      currentPage: id,
    });
  };

  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;

      this.props.getLimitConfigs(id, this.props.token);

      this.setState({
        currentPage: id,
      });
    }
  };

  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));

    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      this.props.getLimitConfigs(id, this.props.token);
      this.setState({
        currentPage: id,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    const trans_data = this.state.transCategory.split(",");
    const res = await setLimitConfig(this.state, this.props.token);
    this.setState({
      display: false,
    });
    this.props.getLimitConfigs(this.props.token);
  };

  getTransactionCategory = async () => {
    if (!this.props.transerr) {
      this.props.getTransCategory(this.props.token);
    }
  };

  getLimitType = async () => {
    try {
      const res = await getLimitType(this.props.token);
      const data = await res.data.transactionLimitType;
      console.log(data);
      this.setState((prevState) => ({
        limitTypeArray: [...prevState.limitTypeArray, ...data],
      }));
    } catch (error) {}
  };

  toggleRange = () => {
    this.setState((prevState) => ({
      range: !prevState.range,
    }));
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        display: true,
      },
      this.getTransactionCategory
    );
  };

  closeModal = () => {
    this.setState({
      display: false,
      editing: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getLimitConfigs(this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  toggleHandler = async (id, disabledValue) => {
    try {
      this.props.getLimitConfigs(this.props.token);
    } catch (error) {}
  };

  editTransactionLimit = (info) => {
    this.setState(
      {
        ...this.state,
        idToEdit: info._id,
        transData: info,
        editing: true,
      },
      this.getTransactionCategory
    );
  };

  handleTransactionLimitEdit = (data) => {
    this.props.updateTransactionLimit(
      this.state.idToEdit,
      data,
      this.props.token
    );
  };

  deleteLimit = (id) => {
    callBackMethod(this.props.deleteTransLimit(id, this.props.token)).then(() =>
      this.props.getLimitConfigs(this.props.token)
    );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TxnLimitTableHead />
          <tbody>
            {this.props.limitConfigs.map((content, index) => (
              <TxnLimitRow
                {...content}
                index={this.getIndex(index + 1)}
                goToDetails={this.goToDetails}
                toggleHandler={this.toggleHandler}
                editTransactionLimit={this.editTransactionLimit}
                deleteLimit={this.deleteLimit}
              />
            ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <ExtraBar {...this.props} active="transaction-limit">
          <div className="table-responsive py-4">
            <div
              class="col-lg-12 col-5 text-right"
              style={{ padding: "0 0.5em 1em" }}>
              <a
                href="#"
                class="btn btn-sm btn-neutral"
                onClick={this.handleRefresh}>
                <i class="fas fa-sync-alt"></i> Refresh
              </a>

              <a
                href="#"
                class="btn btn-sm btn-neutral"
                onClick={this.showModal}>
                <i class="fas fa-plus"></i> New
              </a>
            </div>

            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </ExtraBar>
        <Modal
          display={this.state.display ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Transaction Limit Configuration">
          <div className="body-grid">
            <div className="box box1">
              <label for="fee-name">Limit Configuration Name</label>

              <div className="input-div">
                <input
                  type="text"
                  name="limitName"
                  value={this.state.limitName}
                  id=""
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="box box2">
              <label for="limitDescription">Limit Description</label>

              <div className="input-div">
                <input
                  type="text"
                  name="limitDescription"
                  value={this.state.limitDescription}
                  id=""
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="box box1">
              <label>Transaction Category</label>

              <div className="input-div">
                <select
                  name="transCategory"
                  value={this.state.transCategory}
                  onChange={this.handleChange}>
                  <option></option>

                  {this.props.transcat.map((option) => (
                    <option value={`${option._id},${option.categoryCode}`}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="box box2">
              <label>Transaction Service Code</label>

              <div className="input-div">
                <select
                  name="code"
                  value={this.state.code}
                  onChange={this.handleChange}>
                  <option></option>

                  {this.props.servicecode.map((code) => (
                    <option value={code.serviceCode}>{code.serviceCode}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="box box1">
              <label>Limit Type</label>

              <div className="input-div">
                <select
                  name="limitType"
                  value={this.state.limitType}
                  onChange={this.handleChange}>
                  <option></option>

                  {this.state.limitTypeArray.map((option) => (
                    <option value={option.tnxLimitTypeCode}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="box box2">
              <label>Limit Value</label>

              <div className="input-div">
                <input
                  type="text"
                  name="limitValue"
                  value={this.state.limitValue}
                  id=""
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="box box1">
              <label>Type</label>
              <div className="input-div">
                <select
                  name="type"
                  value={this.state.type}
                  onChange={this.handleChange}>
                  <option></option>
                  <option value="kyc">Kyc</option>
                  <option value="user_category">User Category</option>
                </select>
              </div>
            </div>
            <div className="box box2">
              <label>
                {this.state.type == "kyc" ? "KYC Level" : "User Category"}
              </label>

              <div className="input-div">
                {this.state.type == "kyc" ? (
                  <input
                    type="text"
                    name="kycLevel"
                    value={this.state.kycLevel}
                    id=""
                    onChange={this.handleChange}
                  />
                ) : (
                  <select
                    name="userCategory"
                    value={this.state.userCategory}
                    onChange={this.handleChange}>
                    <option></option>
                    {this.props.user_cat &&
                      this.props.user_cat.map((content) => (
                        <option value={content.id}>{content.name}</option>
                      ))}
                  </select>
                )}
              </div>
            </div>

            <br />

            <div className="box box9">
              <div className="text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={this.handleSubmit}>
                  Add Limit Configuration
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <EditTransactionLimitModal
          display={this.state.editing}
          closeModal={this.closeModal}
          limitData={this.state.transData}
          editTransactionLimit={this.handleTransactionLimitEdit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  limitConfigs: state.limits.allLimits.limitConfigs,
  edit_res: state.limits.editLimits.response_msg,
  edit_success: state.limits.editLimits.edit_success,
  loading: state.limits.allLimits.loading,
  total: state.limits.allLimits.total,
  err: state.limits.allLimits.failed,
  transcat: state.alltransactions.transcategory.transCat,
  servicecode: state.alltransactions.transcategory.serviceCode,
  transerr: state.alltransactions.transcategory.failed,
  token: state.login.token,
  user_cat: state.users.getUserCat.user_cat,
});

export default connect(mapStateToProps, {
  getLimitConfigs,
  getTransCategory,
  getServiceCode,
  detailsInfo,
  updateTransactionLimit,
  deleteTransLimit,
  getUserCategory,
})(TransactionLimit);
