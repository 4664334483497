import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllAdmins from "./AllAdmins";
import AllAdminsDetails from "./AllAdminsDetails";

const AllAdminRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AllAdmins} />
      <Route
        exact
        path={`${match.path}/:name/details`}
        component={AllAdminsDetails}
      />
    </Switch>
  </>
);
export default AllAdminRoute;
