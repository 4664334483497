import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import { getBillTransactions } from "../../../reduxStore/action/action.creator";
import {
  BillerTransHead,
  BillerTransBody,
} from "../../../components/Rows/BillerRow";
import { Loader, Pagination } from "../../../components/Accessories";

class BillerTransaction extends React.Component {
  state = {
    currentPage: 1,
  };

  componentDidMount() {
    this.props.getBillTransactions(15, this.props.token);
  }

  showModal = () => {};

  changePage = (id) => () => {
    this.props.getBillTransactions(id, this.props.token);
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      this.props.getBillTransactions(id, this.props.token);
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      this.props.getBillTransactions(id, this.props.token);
      this.setState({
        currentPage: id,
      });
    }
  };

  handleRefresh = () => {
    this.props.getBillTransactions(this.state.currentPage, this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  render() {
    let itemInTable;
    let renderPaginate;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <BillerTransHead />
          {this.props.billtrans &&
            this.props.billtrans.map((content, index) => (
              <BillerTransBody {...content} index={this.getIndex(index + 1)} />
            ))}
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Biller Service"
              showModal={this.showModal}
              getInfo={this.handleRefresh}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Biller Transactions</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                      {renderPaginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.bills.allbilllist.loading,
  billtrans: state.bills.allbilllist.billertrans,
  total: state.bills.allbilllist.total,
  token: state.login.token,
});

export default connect(mapStateToProps, { getBillTransactions })(
  BillerTransaction
);
