import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Role from "./Role";
import { Error } from "../../../../Error";

const Show = () => <div>wassa, Hello</div>;

const RoleRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Role} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default RoleRoute;
