import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class ConversionModal extends React.Component {
  state = {
    loyaltyPoint: "",
    transferEquivalence: "",
    chargeBack: "",
    chargebackLoyalPoint: "",
    description: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data != this.props.data) {
      this.setState({
        loyaltyPoint: this.props.data.pointsToTransferRatio.loyaltyPoint,
        transferEquivalence: this.props.data.pointsToTransferRatio
          .transferEquivalence,
        chargeBack: this.props.data.chargeBacksToPointsRatio.chargeBack,
        chargebackLoyalPoint: this.props.data.chargeBacksToPointsRatio
          .loyaltyPoint,
        description: this.props.data.description,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    const data = {
      description: this.state.description,
      pointsToTransferRatio: {
        loyaltyPoint: this.state.loyaltyPoint,
        transferEquivalence: this.state.transferEquivalence,
      },
      chargeBacksToPointsRatio: {
        chargeBack: this.state.chargeBack,
        loyaltyPoint: this.state.chargebackLoyalPoint,
      },
    };
    this.props.onSubmit(data);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Edit Conversion Settings">
        <div className="body-grid">
          <div className="box box9">
            <label>Enter Transfer Loyalty Point</label>
            <input
              name="loyaltyPoint"
              className="form-control"
              value={this.state.loyaltyPoint}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter Transfer Equivalence</label>
            <input
              name="transferEquivalence"
              className="form-control"
              value={this.state.transferEquivalence}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter chargeBack</label>
            <input
              name="chargeBack"
              className="form-control"
              value={this.state.chargeBack}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter chargeback Loyalty Point</label>
            <input
              name="chargebackLoyalPoint"
              className="form-control"
              value={this.state.chargebackLoyalPoint}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter description here</label>
            <textarea
              placeholder="Enter Description"
              name="description"
              className="form-control"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Update Conversion
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(ConversionModal);
