import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class FilterEarnings extends React.Component {
	state = {
		account_number: "",
		payout_date: "",
		start_date: "",
		end_date: "",
	};

	componentDidMount() {}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = () => {
		this.props.submit(this.state);
	};

	render() {
		return (
			<Modal
				display={this.props.display ? "block" : "none"}
				closeModal={this.props.closeModal}
				modalTitle="Filter Earnings"
			>
				<div className="body-grid">
					<div className="box box9">
						<label for="account_number">Account Number</label>
						<input
							type="text"
							name="account_number"
							className="input-div"
							value={this.state.account_number}
							onChange={this.handleChange}
						/>
					</div>
					{/* <div className="box box2">
            <label for="payout_date">Last Payout Date</label>
            <input
              type="date"
              name="payout_date"
              className="input-div"
              value={this.state.payout_date}
              onChange={this.handleChange}
            />
          </div> */}

					<div className="box box1">
						<label for="account-number">Start Date</label>
						<input
							type="date"
							name="start_date"
							className="input-div"
							value={this.state.start_date}
							onChange={this.handleChange}
						/>
					</div>
					<div className="box box2">
						<label for="end_date">End Date</label>
						<input
							type="date"
							name="end_date"
							className="input-div"
							value={this.state.end_date}
							onChange={this.handleChange}
						/>
					</div>
					<div className="box box9">
						<div className="text-center">
							<button
								type="button"
								class="btn btn-primary"
								onClick={this.handleSubmit}
							>
								Filter Earnings
							</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FilterEarnings);
