import { combineReducers } from "redux";
import {
  CHANGE_STORE_STATUS,
  GET_ORDERS,
  GET_ORDER_DETAILS,
  GET_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_SITES,
  GET_STORES,
  GET_STORE_DETAILS,
  GET_STORE_ORDER,
  GET_STORE_PRODUCT,
  GET_PRODUCT_VIEW,
  GET_CHECKOUT_VIEW,
} from "../action/action.types";

const getStoreInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_STORES:
      return {
        ...state,
        loading: false,
        stores: action.payload,
      };
    case GET_STORE_DETAILS:
      return {
        ...state,
        loading: false,
        store_details: action.payload,
      };
    case GET_STORE_PRODUCT:
      return {
        ...state,
        loading: false,
        store_product: action.payload,
      };
    case GET_STORE_ORDER:
      return {
        ...state,
        loading: false,
        store_order: action.payload,
      };
    case CHANGE_STORE_STATUS:
      return {
        ...state,
        loading: false,
        store_status: action.payload,
      };
    default:
      return state;
  }
};

const getProductInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        inventory_products: action.payload,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        loading: false,
        inventory_products_detail: action.payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        loading: false,
        inventory_orders: action.payload,
      };
    case GET_ORDER_DETAILS:
      return {
        ...state,
        loading: false,
        inventory_order_detail: action.payload,
      };
    case GET_PRODUCT_VIEW:
      return {
        ...state,
        loading: false,
        product_view: action.payload,
      };
    case GET_CHECKOUT_VIEW:
      return {
        ...state,
        loading: false,
        checkout_view: action.payload,
      };
    default:
      return state;
  }
};

const getSiteReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_SITES:
      return {
        ...state,
        loading: false,
        all_site: action.payload,
      };
    default:
      return state;
  }
};

export const inventoryReducer = combineReducers({
  get_store_inventory: getStoreInventoryReducer,
  get_product_inventory: getProductInventoryReducer,
  get_site: getSiteReducer,
});
