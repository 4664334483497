import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import SavingConfigModal, {
  ConfirmationModal,
  UploadModal,
} from "./SavingConfigModal";
import {
  getKycLevel,
  setSavingProduct,
  getSavingProduct,
  deleteSavingsProduct,
  lockSavingProduct,
  editSavingProductConfig,
  getSavingPenalty,
  uploadSavingImage,
  filterSavings,
} from "../../../reduxStore/action/action.creator";
import {
  callBackMethod,
  Loader,
  DateRange,
} from "../../../components/Accessories";
import {
  SavingProductHead,
  SavingProductBody,
} from "../../../components/Rows/SavingTable";
import EditSavingsConfigModal from "./EditSavingsConfigModal";

class SavingsConfig extends React.Component {
  state = {
    display: false,
    image: null,
    product: "",
    name: "",
    filterby: "",
    showBtnDate: false,
    showDropStatus: false,
    startDate: null,
    endDate: null,
    status: "",
    actionText: false,
  };

  componentDidMount() {
    this.props.getSavingProduct(this.props.token);
    this.props.getKycLevel(this.props.token);
    this.props.getSavingPenalty(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  appoveModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      upload: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      del_modal: false,
      showLockModal: false,
      edit_modal_display: false,
      upload: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getSavingProduct(this.props.token);
  };

  setSaving = (data) => {
    callBackMethod(this.props.setSavingProduct(data, this.props.token))
      .then(() => {
        this.setState({ display: false });
      })
      .then(() => alert(this.props.saving_response))
      .then(() => {
        this.props.getSavingProduct(this.props.token);
      });
  };

  getIndex = (index) => {
    return index;
  };

  deleteSavingConfig = (data) => {
    this.setState({
      ...this.state,
      idToDelete: data.id,
      del_modal: true,
    });
  };
  deleteProduct = () => {
    // alert(this.state.idToDelete);
    callBackMethod(
      this.props.deleteSavingsProduct(this.state.idToDelete, this.props.token)
    )
      .then(() => this.setState({ del_modal: false }))
      .then(() => alert(this.props.delete_res));
  };

  viewDetails = (data) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${data.id}/details`,
      state: data.id,
    });
  };

  toggleHandler = (props) => {
    this.setState({
      idToLock: props.id,
      showLockModal: true,
      actionText: props.lock == true ? false : true,
    });
  };
  lockProduct = () => {
    callBackMethod(
      this.props.lockSavingProduct(this.state.idToLock, this.props.token)
    )
      .then(() => {
        this.setState({ showLockModal: false });
      })
      .then(() => {
        alert(this.props.lock_resp);
      })
      .then(() => {
        this.props.getSavingProduct(this.props.token);
      });
  };

  editProductConfig = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props.id,
      edit_modal_display: true,
      saving_type: props.amount_type,
      product_data: props,
    });
  };

  editSavingProduct = (data) => {
    callBackMethod(
      this.props.editSavingProductConfig(
        this.state.idToEdit,
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ edit_modal_display: false }))
      .then(() => {
        if (this.props.edit_response == true) {
          alert("successfully updated the product");
        }
      })
      .then(() => this.props.getSavingProduct(this.props.token));
  };

  handleFile = (e) => {
    this.setState({
      image: e.target.files[0],
      // file: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("image", this.state.image);

    callBackMethod(
      this.props.uploadSavingImage(
        `savinvest/savings_product_image`,
        this.state.product,
        formData,
        this.props.token
      )
    )
      .then(() => this.setState({ ...this.state, upload: false }))
      .then(() =>
        alert(
          JSON.stringify(this.props.upload_saving_img_resp.response_message)
        )
      )
      .then(() => this.props.getSavingProduct(this.props.token));
  };

  filterByType = (e) => {
    // alert(e.target.value);
    callBackMethod(
      this.setState({
        filterby: e.target.value,
      })
    ).then(() => this.runByType(this.state.filterby));
  };

  runByType = (type) => {
    if (type == "status") {
      this.setState({
        showDropStatus: true,
        showBtnDate: false,
      });
    } else {
      this.setState({
        showBtnDate: true,
        showDropStatus: false,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      product: e.target.value,
      name: "item name",
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));

    callBackMethod(
      this.setState({
        startDate: start,
        endDate: end,
      })
    ).then(() =>
      this.props.filterSavings(
        "date",
        `savinvest/savings_products?from=${this.state.startDate}&to=${this.state.endDate}`,
        this.props.token
      )
    );
  };

  handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
      })
    ).then(() =>
      this.props.filterSavings(
        "status",
        `savinvest/savings_products?lock=${this.state.status}`,
        this.props.token
      )
    );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <SavingProductHead />
          <tbody>
            {this.props.savings_product &&
              this.props.savings_product.map((content, index) => (
                <SavingProductBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  deleteSavingConfig={this.deleteSavingConfig}
                  viewDetails={this.viewDetails}
                  toggleHandler={this.toggleHandler}
                  editProductConfig={this.editProductConfig}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <SavingBar active="savings-config">
          <div class="col-lg-12 col-5" style={{ padding: "0 0.5em 1em" }}>
            <div className="row">
              <div className="col-md-6 text-left">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    value={this.state.filterby}
                    className="form-control"
                    style={{ width: "20em" }}
                    onChange={this.filterByType}>
                    <option>filter by...</option>
                    <option value="status">filter by status</option>
                    <option value="date">filter by date</option>
                  </select>

                  <div style={{ paddingLeft: "1em" }}>
                    {this.state.showBtnDate && (
                      <DateRange handleRange={this.handleRange} />
                    )}

                    {this.state.showDropStatus && (
                      <select
                        style={{ width: "15em" }}
                        onChange={this.handleStatusFilter}
                        className="form-control">
                        <option>Filter by...</option>
                        <option value={1}>Locked Savings</option>
                        <option value={0}>UnLocked Savings</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.handleRefresh}>
                  <i class="fas fa-sync-alt"></i> Refresh
                </a>

                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.showModal}>
                  <i class="fas fa-plus"></i> New
                </a>
                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.appoveModal}>
                  <i class="fas fa-plus"></i> Upload Saving Image
                </a>
              </div>
            </div>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </SavingBar>
        <SavingConfigModal
          display={this.state.display}
          closeModal={this.closeModal}
          setSaving={this.setSaving}
        />
        <ConfirmationModal
          display={this.state.showLockModal}
          closeModal={this.closeModal}
          action={this.lockProduct}
          title="Lock Saving Product"
          actionText={this.state.actionText ? "Lock" : "Unlock"}
        />
        <EditSavingsConfigModal
          display={this.state.edit_modal_display}
          closeModal={this.closeModal}
          type={this.state.saving_type}
          product_data={this.state.product_data}
          editSaving={this.editSavingProduct}
        />
        <UploadModal
          display={this.state.upload}
          closeModal={this.closeModal}
          savings_product={this.props.savings_product}
          product_id={this.state.product}
          handleChange={this.handleChange}
          handleFile={this.handleFile}
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  saving_response: state.saving.set_save_product.save_product_response,
  edit_response: state.saving.set_save_product.edit_product_response,
  savings_product: state.saving.get_save_product.saving_data,
  delete_res: state.saving.get_save_product.delete_res,
  lock_resp: state.saving.get_save_product.saving_prod_resp,
  loading: state.saving.get_save_product.loading,
  upload_saving_img_resp: state.saving.set_save_product.upload_saving_img_resp,
});

export default connect(mapStateToProps, {
  getKycLevel,
  setSavingProduct,
  getSavingProduct,
  deleteSavingsProduct,
  lockSavingProduct,
  editSavingProductConfig,
  getSavingPenalty,
  uploadSavingImage,
  filterSavings,
})(SavingsConfig);
