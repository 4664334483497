import { headers } from "./api.config";

export const createAgent = async (url, data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const readAgent = async (token, url) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const addAgentToUser = async (url, data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};
