import React from "react";
import { Modal } from "../../../../../components/Accessories";

class EditDocumentModal extends React.Component {
  state = {};

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Approve Document">
        <div className="body-grid">
            
        </div>
      </Modal>
    );
  }
}

export default EditDocumentModal;
