import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class EditModal extends React.Component {
  state = {
    name: "",
    description: "",
    // frequency: "0",
    // frequency_type: "daily",
    min_terms: "0",
    min_cashout_days: "0",
    group: false,
    start_date: "",
    maturity_period: "0",
    maturity_period_type: "day",
    amount: "0.00",
    amount_type: "",
    min_amount: "0",
    max_amount: "0",
    amount_step: "0",
    creator_id: this.props.admin_data.id,
    kyc_level: "",
    interest_rate: "",
    lock: false,
    error: false,
    penalty: false,
    penalty_id: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product_data != prevProps.product_data) {
      this.setState({
        name: this.props.product_data.name,
        description: this.props.product_data.description,
        amount: this.props.product_data.amount,
        amount_type: this.props.product_data.amount_type,
        min_terms: this.props.product_data.min_terms,
        min_cashout_days: this.props.product_data.min_cashout_days,
        group: this.props.product_data.group,
        start_date: this.props.product_data.start_date,
        maturity_period: this.props.product_data.maturity_period,
        maturity_period_type: this.props.product_data.maturity_period_type,
        min_amount: this.props.product_data.min_amount,
        max_amount: this.props.product_data.max_amount,
        kyc_level: this.props.product_data.kyc_level,
        interest_rate: this.props.product_data.interest_rate,
        lock: this.props.product_data.lock,
        amount_step: this.props.product_data.amount_step,
        penalty: this.props.product_data.penalty == true ? "Yes" : "No",
        penalty_id: this.props.product_data.penalty_id,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    if (
      this.state.amount_step < this.state.min_amount &&
      this.state.amount_type == "range"
    ) {
      this.setState({
        error: true,
      });
      alert("amount step cannot be lesser than minimum amount");
    } else {
      this.props.editSaving(this.state);
    }
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Edit Savings Product">
        <div className="body-grid">
          <div className="box box1">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>
          {this.state.amount_type != "variable" && (
            <div className="box box1">
              <label>Amount</label>
              <input
                className="form-control"
                name="amount"
                value={this.state.amount}
                onChange={this.handleChange}
                disabled={this.state.amount_type == "range" ? true : false}
              />
            </div>
          )}
          <div
            className={
              this.state.amount_type != "variable" ? "box box2" : "box box9"
            }>
            <label>Amount Type</label>
            <select
              className="form-control"
              name="amount_type"
              disabled
              value={this.state.amount_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="fixed">Fixed</option>
              <option value="variable">Variable</option>
              <option value="range">Range</option>
            </select>
          </div>
          <div className="box box1">
            <label>Interest Rate</label>
            <input
              className="form-control"
              name="interest_rate"
              value={this.state.interest_rate}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              name="start_date"
              value={this.state.start_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Min Terms</label>
            <input
              className="form-control"
              name="min_terms"
              value={this.state.min_terms}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Min Cashout Days</label>
            <input
              className="form-control"
              name="min_cashout_days"
              value={this.state.min_cashout_days}
              onChange={this.handleChange}
            />
          </div>
          {this.state.amount_type != "fixed" && (
            <>
              {/* {this.state.amount_type != "variable" && (
                <>
                  <div className="box box1">
                    <label>Frequency Type</label>
                    <select
                      className="form-control"
                      name="frequency_type"
                      value={this.state.frequency_type}
                      onChange={this.handleChange}>
                      <option></option>
                      <option value="daily">daily</option>
                      <option value="weekly">weekly</option>
                      <option value="monthly">monthly</option>
                    </select>
                  </div>
                  <div className="box box2">
                    <label>Frequency</label>
                    <input
                      className="form-control"
                      name="frequency"
                      value={this.state.frequency}
                      onChange={this.handleChange}
                    />
                  </div>
                </>
              )} */}

              <div className="box box1">
                <label>Minimum Amount</label>
                <input
                  className="form-control"
                  name="min_amount"
                  value={this.state.min_amount}
                  onChange={this.handleChange}
                />
              </div>
              <div className="box box2">
                <label>Maximum Amount</label>
                <input
                  className="form-control"
                  name="max_amount"
                  value={this.state.max_amount}
                  onChange={this.handleChange}
                />
              </div>
            </>
          )}
          {this.props.type != "variable" && (
            <>
              <div className="box box1">
                <label>Maturity Period</label>
                <input
                  className="form-control"
                  name="maturity_period"
                  value={this.state.maturity_period}
                  onChange={this.handleChange}
                />
              </div>
              <div className="box box2">
                <label>Maturity Period Type</label>
                <select
                  className="form-control"
                  name="maturity_period_type"
                  value={this.state.maturity_period_type}
                  onChange={this.handleChange}>
                  <option></option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
            </>
          )}

          {this.props.type != "fixed" && this.props.type != "variable" && (
            <div className="box box1">
              <label>Amount Steps</label>
              <input
                className="form-control"
                style={{ borderColor: this.state.error ? "red" : "" }}
                name="amount_step"
                value={this.state.amount_step}
                onChange={this.handleChange}
              />
            </div>
          )}

          <div
            className={`box ${
              this.props.type != "fixed" && this.props.type != "variable"
                ? "box2"
                : "box1"
            }`}>
            <label>KYC Level</label>
            <select
              className="form-control"
              name="kyc_level"
              value={this.state.kyc_level}
              onChange={this.handleChange}>
              <option></option>
              {this.props.levels &&
                this.props.levels.map((content) => (
                  <option value={content.level}>{content.level_name}</option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>Should Penalty Apply</label>
            <select
              className="form-control"
              name="penalty"
              value={this.state.penalty}
              onChange={this.handleChange}>
              <option></option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
          <div className="box box2">
            <label>Penalty</label>
            <select
              className="form-control"
              name="penalty_id"
              value={this.state.penalty_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.penalty_data &&
                this.props.penalty_data.map((content) => (
                  <option value={content.id}>{content.amount_type}</option>
                ))}
            </select>
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Is Group Savings </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="group"
                checked={this.state.group}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>

          {/* should lock */}
          <div className="box box2">
            <div className="row">
              <div className="col-md-12">
                <label>Lock Product</label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="lock"
                checked={this.state.lock}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                edit saving product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
  admin_data: state.login.data,
  penalty_data: state.saving.get_save_penalty.penalty_data,
});

export default connect(mapStateToProps, {})(EditModal);
