import React from "react";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import DashboardNavBar from "../../../components/DashboardNavbar";
import SideBar from "../../../components/SideBar";
import {
  InvestProductDetailBody,
  RelatedProductHead,
  RelatedProductBody,
} from "../../../components/Rows/InvestmentTable";
import {
  getInvestProductDetail,
  getAdmins,
} from "../../../reduxStore/action/action.creator";

class InvestmentProductDetails extends React.Component {
  state = {};

  componentDidMount() {
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("g/") + 2, index.indexOf("/d"));

    this.props.getInvestProductDetail(
      `/savinvest/investment_products/${index}`,
      this.props.token
    );
    this.props.getAdmins(this.props.token, 1);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let itemInCard;
    if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
    } else {
      itemInCard = this.props.invest_prod_detail && (
        <InvestProductDetailBody
          {...this.props.invest_prod_detail}
          role={this.props.role_data}
          company={this.props.company_data}
          admin_data={this.props.admins}
          merchant={this.props.merchant_data}
          category={this.props.invest_category}
        />
      );
    }

    return (
      <div>
        <SideBar />
        <div className="main-content" id="panel">
          <DashboardNavBar settings="false" />
          <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
            <div class="container-fluid">
              <div class="header-body">
                <div class="row align-items-center py-4">
                  <div className="col-lg-6 col-7">
                    <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                      <span
                        style={{
                          padding: "0 .5em 0 0",
                          textAlign: "center",
                        }}>
                        <i
                          class="fas fa-chevron-left"
                          style={{
                            color: "#fff",
                            padding: "0.3em .5em",
                            border: "2px solid #fff",
                            borderRadius: "50%",
                            fontSize: "12px",
                          }}></i>
                      </span>
                      <span style={{ color: "#fff" }}>Back</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mt--5">
            <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
              <div class="col-6 userDetailsCol1">{itemInCard}</div>
              <div class="col-6 userDetailsCol2">
                <div class="tab" role="tabpanel">
                  <div
                    class="card-header"
                    style={{
                      margin: "0px",
                      backgroundColor: "lavenderblush",
                      borderBottom: "0px",
                    }}>
                    <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                      <li
                        role="presentation"
                        // onClick={() => this.changeTabActive(1)}
                        className="active">
                        <a
                          href="#Section1"
                          aria-controls="wallets"
                          role="tab"
                          //   onClick={this.loadBalance}
                          data-toggle="tab">
                          <i class="fa fa-dollar"> &nbsp; </i>Related Product
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content tabs">
                    <div role="tabpanel" class="tab-pane active" id="Section1">
                      <div className="table-responsive py-4 table-div">
                        <table
                          className="table table-flush"
                          id="datatable-basic">
                          <RelatedProductHead />
                          <>
                            {this.props.invest_prod_detail &&
                              this.props.invest_prod_detail.related_product.map(
                                (content, index) => (
                                  <RelatedProductBody
                                    index={index + 1}
                                    admin_data={this.props.admins}
                                    merchant={this.props.merchant_data}
                                    {...content}
                                  />
                                )
                              )}
                          </>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  user_sub: state.invest.getData.user_sub,
  loading: state.invest.getData.loading,
  merchant_data: state.invest.getData.merchant_data,
  admins: state.users.alladmins.adminData,
  company_data: state.invest.getData.comp_data,
  role_data: state.invest.getData.role_data,
  invest_prod_detail: state.invest.getData.invest_prod_detail,
  invest_category: state.invest.getData.invest_category,
});
export default connect(mapStateToProps, { getInvestProductDetail, getAdmins })(
  InvestmentProductDetails
);
