import React, { Component } from "react";
import { connect } from "react-redux";
import IncentiveBar from "../IncentivesBar";
import {
  getChargeBack,
  getUnitChargeBack,
  updateChargeBack,
} from "../../../../../reduxStore/action/action.creator";
import {
  ChargeBackBody,
  ChargeBackHead,
} from "../../../../../components/Rows/IncentiveTable";
import EditChargeBack from "./EditChargeBack";
import { callBackMethod } from "../../../../../components/Accessories";

class ChargeBack extends Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getChargeBack(`chargebacks/config`, this.props.token);
    this.props.getUnitChargeBack(
      `chargebacks/config/${this.state.idToView}`,
      this.props.token
    );
  }

  editChargeBack = (data) => {
    this.setState({
      ...this.state,
      display: true,
      idToEdit: data._id,
      chargeback_data: data,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      display: false,
    });
  };

  handleSubmit = (data) => {
    callBackMethod(
      this.props.updateChargeBack(
        `chargeback/config/${this.state.idToEdit}`,
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.charge_back_resp))
      .then(() =>
        this.props.getChargeBack(`chargebacks/config`, this.props.token)
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading";
    } else {
      itemInTable = (
        <>
          <ChargeBackHead />
          <tbody>
            {this.props.charge_back &&
              this.props.charge_back.map((content, index) => (
                <ChargeBackBody
                  {...content}
                  index={index + 1}
                  edit={this.editChargeBack}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <IncentiveBar {...this.props} active="charge-backs">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </IncentiveBar>
        <EditChargeBack
          display={this.state.display}
          closeModal={this.closeModal}
          data={this.state.chargeback_data}
          submit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  charge_back: state.incentive.get_incentives.charge_back,
  unit_charge_back: state.incentive.get_incentives.a_charge_back,
  charge_back_resp: state.incentive.add_incentives.charge_back_resp,
});

export default connect(mapStateToProps, {
  getChargeBack,
  getUnitChargeBack,
  updateChargeBack,
})(ChargeBack);
