import React, { Component } from "react";
import { connect } from "react-redux";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import Settings from "../../SettingNavbar";
import InterestModal from "./InterestModal";

import {
	setupInterest,
	getInterestSetup,
	updateInterestDetail,
} from "../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../components/Accessories";
import InterestDetail from "./InterestDetail";
import EditInterest from "./EditInterest";
import { formatCurrency } from "../../../../components/Row";

class Interest extends Component {
	state = {
		display: false,
	};

	componentDidMount() {
		this.props.getInterestSetup(this.props.token);
	}

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};

	closeModal = () => {
		this.setState({
			display: false,
			detailsview: false,
			editing: false,
		});
	};

	handleRefresh = (e) => {
		e.preventDefault();
		this.props.getInterestSetup(this.props.token);
	};

	interestHandler = (data) => {
		callBackMethod(this.props.setupInterest(data, this.props.token))
			.then(() =>
				this.setState({
					display: false,
				})
			)
			.then(() => this.props.getInterestSetup(this.props.token));
	};

	viewDetails = (id) => {
		this.setState({
			...this.state,
			detailsview: true,
			idToView: id,
		});
	};

	deleteDetail = (props) => {};

	editDetail = (props) => {
		this.setState({
			...this.state,
			editing: true,
			interest_props: props,
			idToEdit: props.id,
		});
	};

	editInterestDetail = (data) => {
		callBackMethod(
			this.props.updateInterestDetail(
				`/${this.state.idToEdit}`,
				data,
				this.props.token
			)
		)
			.then(() =>
				this.setState({
					editing: false,
				})
			)
			.then(() => this.props.getInterestSetup(this.props.token));
	};

	render() {
		return (
			<>
				<div>
					<SideBar />
					<div class="main-content" id="panel">
						<DashboardNavBar settings="false" />
						<div
							class="header pb-6"
							style={{ backgroundColor: "#0038ba" }}
						>
							<div class="container-fluid">
								<div class="header-body">
									<div class="row align-items-center py-4">
										<Settings
											{...this.props}
											active="Interests"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="container-fluid mt--6">
							<div class="row align-items-center py-4">
								<div class="col">
									<div class="card">
										<div style={{ padding: "1em" }}>
											<div className="row">
												<div
													class="col-lg-12 col-5 text-right"
													style={{
														padding: "0 0.5em 1em",
													}}
												>
													<a
														href="#"
														class="btn btn-sm btn-neutral"
														onClick={
															this.handleRefresh
														}
													>
														<i class="fas fa-sync-alt"></i>{" "}
														Refresh
													</a>

													<a
														href="#"
														class="btn btn-sm btn-neutral"
														onClick={this.showModal}
													>
														<i class="fas fa-plus"></i>{" "}
														New
													</a>
												</div>
												<div className="table-responsive py-4">
													<table
														className="table table-flush"
														id="datatable-basic"
													>
														{this.props.loading ? (
															<Loader
																loading={
																	this.props
																		.loading
																}
															/>
														) : (
															this.props
																.interests && (
																<>
																	<thead className="thead-light">
																		<tr>
																			<th>
																				S/N
																			</th>
																			<th>
																				Name
																			</th>
																			<th>
																				Description
																			</th>
																			<th>
																				Annual
																				Interest
																			</th>
																			<th className="text-right">
																				Minimum
																				Amount
																				(NGN)
																			</th>
																			<th className="text-right">
																				Maximum
																				Amount
																				(NGN)
																			</th>

																			<th>
																				Action
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.props.interests.map(
																			(
																				int,
																				i
																			) => (
																				<tr
																					key={
																						int.id
																					}
																				>
																					<td>
																						{i +
																							1}
																					</td>
																					<td>
																						{
																							int.name
																						}
																					</td>
																					<td>
																						{
																							int.description
																						}
																					</td>
																					<td>
																						{
																							int.annual_interest
																						}
																					</td>
																					<td className="text-right">
																						{formatCurrency(
																							int.minimum_amount
																						)}
																					</td>
																					<td className="text-right">
																						{formatCurrency(
																							int.maximum_amount
																						)}
																					</td>

																					<td>
																						<i
																							className="fa fa-eye"
																							onClick={() =>
																								this.viewDetails(
																									int.id
																								)
																							}
																						></i>
																						<i
																							className="fa fa-pencil"
																							onClick={() =>
																								this.editDetail(
																									int
																								)
																							}
																							style={{
																								padding:
																									"0 .5em",
																							}}
																						></i>
																						<i
																							className="fa fa-trash-o"
																							onClick={() =>
																								this
																									.deleteDetail
																							}
																						></i>
																					</td>
																				</tr>
																			)
																		)}
																	</tbody>
																</>
															)
														)}
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<InterestModal
					display={this.state.display}
					closeModal={this.closeModal}
					setup={this.interestHandler}
				/>
				<InterestDetail
					display={this.state.detailsview}
					closeModal={this.closeModal}
					interest_id={this.state.idToView}
				/>
				<EditInterest
					display={this.state.editing}
					closeModal={this.closeModal}
					interestProps={this.state.interest_props}
					setup={this.editInterestDetail}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token,
	loading: state.interest.get_interest.loading,
	interests: state.interest.get_interest.interests,
});

export default connect(mapStateToProps, {
	setupInterest,
	getInterestSetup,
	updateInterestDetail,
})(Interest);
