import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { LoansRequests, LoanRequestsTableHead } from "../../components/Rows";
import "../../morestyles.css";
import {
  detailsInfo,
  getAllLoanRequests,
  loanDisburse,
  tabDetail,
  rejectUserLoan,
  filterUserLoan,
  getUserLoanStatus,
} from "../../reduxStore/action/action.creator";
import {
  Modal,
  Loader,
  Pagination,
  CardBodyHolder,
  callBackMethod,
} from "../../components/Accessories";
import { setLoan } from "../../API/loanRequest";
import Settings from "../Settings/SettingNavbar";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import LoanFilterModal from "./LoanFilterModal";

class LoanProducts extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentDidMount() {
    this.props.getAllLoanRequests(this.state.currentPage, this.props.token);
    this.props.getUserLoanStatus(this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering == true) {
      //
    } else {
      this.props.getAllLoanRequests(id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };

  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering == true) {
      } else {
        this.props.getAllLoanRequests(id, this.props.token);
      }

      this.setState({
        currentPage: id,
      });
    }
  };

  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering == true) {
        //
      } else {
        this.props.getAllLoanRequests(id, this.props.token);
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleRefresh = () => {
    this.props.getAllLoanRequests(this.state.currentPage, this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  goToRequestDetails = async (props) => {
    const { index, user_id, id } = props;
    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/detail`,
      state: user_id,
    });
  };
  changeStatus = async (id, status_id) => {
    if (status_id == "Pending") {
      // alert("pending");
      this.props.approveLoan(id, this.props.token);
    } else {
      this.props.permission.some((permit) => {
        if (permit.permissions_json.loan_disburse === true) {
          this.props.loanDisburse(id, this.props.token);
        } else {
          alert("you no get permission to disburse loan");
        }
      });
      // this.props.permission.some(permit => permit.permissions_json.loan_disburse == true)
      // checkBeneficiary = () => {
      //   return this.state.splitDetail.some(
      //     (content) => content.userType === this.state.beneficiaryData.split(",")[1]
      //   );
      // };
    }
    this.props.getAllLoanRequests(this.props.token);
  };

  rejectLoan = (id) => {
    callBackMethod(this.props.rejectUserLoan(id, this.props.token)).then(() =>
      this.props.getAllLoanRequests(this.state.currentPage, this.props.token)
    );
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  toggleHandler = async (id, disabledValue) => {};

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  filterLoan = async (data) => {
    callBackMethod(this.props.filterUserLoan(data, this.props.token)).then(
      () => {
        this.setState({
          ...this.state,
          dataToFilter: data,
          display: false,
        });
      }
    );

    // alert(JSON.stringify(data));
  };

  render() {
    let itemInTable;
    let renderPaginate;
    const total =
      this.props.total && parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <LoanRequestsTableHead />
          <tbody>
            {this.props.loanReqs &&
              this.props.loanReqs.map((content, index) => (
                <LoansRequests
                  {...content}
                  {...this.props}
                  index={this.getIndex(index + 1)}
                  goToDetails={this.goToRequestDetails}
                  toggleHandler={this.toggleHandler}
                  changeLoan={this.changeStatus}
                  rejectLoan={this.rejectLoan}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Loan Request"
              getInfo={this.handleRefresh}
              shouldFilter={true}
              showModal={this.showModal}
            />

            <CardBodyHolder>
              <Tabs id="controlled-tab-example">
                <Tab
                  eventKey="loanRequests"
                  title="Loan Requests"
                  tabClassName="mTab kycTab">
                  <div className="table-responsive py-4">
                    <table className="table table-flush" id="datatable-basic">
                      {itemInTable}
                    </table>
                    {renderPaginate}
                  </div>
                </Tab>
              </Tabs>
            </CardBodyHolder>
          </div>
        </div>
        <LoanFilterModal
          display={this.state.display}
          closeModal={this.closeModal}
          submit={this.filterLoan}
          loanStatus={this.props.loan_status}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.loans.allLoanRequests.loading,
  loanReqs: state.loans.allLoanRequests.loanRequests,
  filtering: state.loans.allLoanRequests.filtering,
  total: state.loans.allLoanRequests.total,
  permission: state.login.permission,
  token: state.login.token,
  loan_status: state.loans.allLoanRequests.loan_status,
});
export default connect(mapStateToProps, {
  detailsInfo,
  getAllLoanRequests,
  loanDisburse,
  tabDetail,
  rejectUserLoan,
  filterUserLoan,
  getUserLoanStatus,
})(LoanProducts);
