import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import "../../../../morestyles.css";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Accessories";

class BillsBar extends React.Component {
  state = {
    key: "Biller-Code",
  };

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  render() {
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Bills" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.props.active}
                        onSelect={this.getActiveState}>
                        <Tab
                          eventKey="Biller-Code"
                          title={
                            <Link to={`/settings/bills/biller-category-codes`}>
                              <Icon className="" title="Biller Category Code" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>

                        <Tab
                          eventKey="General-Code"
                          title={
                            <Link to={`/settings/bills/general-code`}>
                              <Icon className="" title="General Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Item-Code"
                          title={
                            <Link to={`/settings/bills/item-code`}>
                              <Icon className="" title="Item Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Product-Code"
                          title={
                            <Link to={`/settings/bills/product-code`}>
                              <Icon className="" title="Product Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Provider-Code"
                          title={
                            <Link to={`/settings/bills/provider-code`}>
                              <Icon className="" title="Provider Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Service-Code"
                          title={
                            <Link to={`/settings/bills/service-code`}>
                              <Icon className="" title="Service Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Service-Switch"
                          title={
                            <Link to={`/settings/bills/service-switch`}>
                              <Icon
                                className=""
                                title="Service Switch Config"
                              />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Switch-Code"
                          title={
                            <Link to={`/settings/bills/switch-code`}>
                              <Icon className="" title="Switch Code Setup" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BillsBar;
