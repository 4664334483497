import React from "react";
import { connect } from "react-redux";
import { DateRange, Modal } from "../../components/Accessories";

class AgentModal extends React.Component {
  state = {
    agent_id: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Agent Id</label>
            <select
              className="form-control"
              name="agent_id"
              value={this.state.agent_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.agnt_data &&
                this.props.agnt_data.map((content) => (
                  <option value={content.sales_agent_id}>
                    {content.first_name} {content.last_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Map User to Agent
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export const ConfirmationModal = (props) => (
  <Modal
    display={props.display ? "block" : "none"}
    closeModal={props.closeModal}
    modalTitle={props.title}>
    <div className="body-grid">
      <div className="box box9 text-center my-4">
        <span className="">Are you sure about this operation</span>
      </div>
      <div className="box box1">
        <div className="text-center">
          <button type="button" class="btn btn-primary" onClick={props.action}>
            {props.actionText}
          </button>
        </div>
      </div>
      <div className="box box2">
        <div className="text-center">
          <button
            type="button"
            class="btn btn-danger"
            onClick={props.closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

const mapStateToProps = (state) => ({
  agnt_data: state.users.alladmins.agent_data,
});

export default connect(mapStateToProps, {})(AgentModal);
