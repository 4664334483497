import React from "react";
import { connect } from "react-redux";
import { Modal, DateRange } from "../../components/Accessories";

class BankTransactModal extends React.Component {
  state = {
    trans_ref: "",
    amount: "",
    source_num: "",
    dest_acct_num: "",
    status: "",
    source_name: "",
    startDate: null,
    endDate: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitFilter = () => {
    this.props.filter(this.state);
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Filter Bank Transaction">
        <div className="body-grid">
          <div className="box box9">
            <label>Select Date Range</label>
            <DateRange handleRange={this.handleRange} />
          </div>
          <div className="box box1">
            <label>Transaction Ref</label>

            <div className="input-div">
              <input
                type="texg"
                name="trans_ref"
                value={this.state.trans_ref}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box2">
            <label for="fee-name">Amount</label>

            <div className="input-div">
              <input
                type="text"
                name="amount"
                value={this.state.amount}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label for="limitDescription">Source Account Num</label>

            <div className="input-div">
              <input
                type="text"
                name="source_num"
                value={this.state.source_num}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>destination account number</label>

            <div className="input-div">
              <input
                name="dest_acct_num"
                value={this.state.dest_acct_num}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1">
            <label>Status</label>
            <div className="input-div">
              <input
                name="status"
                value={this.state.status}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Source Account Name</label>
            <div className="input-div">
              <input
                name="source_name"
                value={this.state.source_name}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.submitFilter}>
                Filter
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(BankTransactModal);
