import React from "react";
import { connect } from "react-redux";
import "../../../../../morestyles.css";
import AuthorizationTab from "../AuthorizationTab";
import PermissionModuleForm from "./PermissionModForm";
import {
  setPermissionMod,
  getPermissionMod,
  deletePermission,
} from "../../../../../reduxStore/action/action.creator";

class PermissionModule extends React.Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.success) {
      this.props.getPermissionMod(this.props.token);
    }
  }

  componentDidMount() {
    this.props.getPermissionMod(this.props.token);
  }

  setPermission = (data) => {
    this.props.setPermissionMod(data, this.props.token);
    this.props.getPermissionMod(this.props.token);
  };

  deleteModule = (url) => () => {
    this.props.deletePermission(url, this.props.token);
    this.props.getPermissionMod(this.props.token);
  };

  render() {
    return (
      <AuthorizationTab {...this.props} active="permission-module">
        <div className="row" style={{ padding: "0 1em" }}>
          {/* <div
            className="col-md-12"
            style={{
              backgroundColor: "#eee",
              borderBottom: "1px solid #aaa",
              paddingLeft: ".5em",
            }}></div> */}
          <div className="col-md-5">
            {/* <div className="permission-mod">
              <ul>
                <li>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck1"
                    />
                    <label class="custom-control-label" for="customCheck1">
                      Permission Type
                    </label>
                  </div>
                </li>
                <li></li>
                <li></li>
              </ul>
            </div> */}
            <PermissionModuleForm submitPermissionModule={this.setPermission} />
          </div>
          <div className="col-md-7">
            <div className="table-responsive py-4 table-div">
              <table
                className="table table-striped table-bordered"
                id="datatable-basic">
                <thead className="table-light">
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Role Id</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </thead>
                <tbody>
                  {this.props.data &&
                    this.props.data.map((content) => (
                      <tr>
                        <td>{content.id}</td>
                        <td>{content.module_name}</td>
                        <td>{content.description}</td>
                        <td>{content.role_id}</td>
                        <td style={{ textAlign: "center" }}>
                          <i
                            className="fa fa-trash-o"
                            onClick={this.deleteModule(
                              `permission_modules/${content.id}`
                            )}></i>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthorizationTab>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.roles.setpermissionmod.success,
  data: state.roles.getpermissionmod.data,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  setPermissionMod,
  getPermissionMod,
  deletePermission,
})(PermissionModule);
