import React from "react";
import {
  getAdminRole,
  getPermissionMod,
  getPermission,
} from "../../../../../reduxStore/action/action.creator";
import { connect } from "react-redux";

class AdminPermissionForm extends React.Component {
  state = {
    permissions: "",
    role_id: "",
    module_id: "",
    permissions_json: {},
    permission_list: [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.module_id != this.state.module_id) {
      const module = this.state.module_id.split(",");
      this.setState({
        permission_list: prevProps.permission_list.filter(
          (content) => content.permission_module_id == module[0]
        ),
      });
    }

    if (prevState.role_id != this.state.role_id) {
      if (this.state.role_id == "12") {
        this.setState({
          permission_list: prevProps.permission_list,
        });
        const value = true;
        this.props.permission_list.map((content) => {
          let value = (this.state.permissions_json[content.name] = true);

          Object.keys(value).map((key) => {
            if (key != "value") {
              this.setState({
                permissions_json: { ...prevState.permissions_json, value },
              });
            }
          });
          // Object.keys(content).map((key) => {
          //   alert(key);
          // if (key != "value") {
          // this.setState({
          //   permissions_json: {...prevState.permissions_json, }
          // })
          // }
          // });
        });
      }
    }
  }

  componentDidMount() {
    this.props.getAdminRole(this.props.token);
    this.props.getPermissionMod(this.props.token);
    this.props.getPermission(this.props.token);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    const module = this.state.module_id.split(",");
    const newState = {
      permissions: module[1],
      role_id: this.state.role_id,
      module_id: module[0],
      permissions_json: this.state.permissions_json,
    };
    this.props.submitAdminPermission(newState);
    // alert(JSON.stringify(newState));
  };

  handleObject = (data) => {
    let mData = data.replace(/ /g, " ");
    let value = (this.state.permissions_json[mData] = true);

    Object.keys(value).map((key) => {
      if (key != "value") {
        this.setState((prevState) => ({
          permissions_json: { ...prevState.permissions_json, value },
        }));
      }
    });
  };

  render() {
    return (
      <div className="content" style={{ paddingTop: "1em" }}>
        <div className="" style={{ padding: ".8em 0" }}>
          <div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Enter Role Type</label>
              <select
                type="text"
                class="form-control"
                name="role_id"
                value={this.state.role_id}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter name">
                <option></option>
                {this.props.role &&
                  this.props.role.map((content) => (
                    <option value={content.id}>{content.name}</option>
                  ))}
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Permission Category
              </label>
              <select
                type="text"
                class="form-control"
                name="module_id"
                value={this.state.module_id}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter name">
                <option></option>
                {this.props.permission_category &&
                  this.props.permission_category.map((content) => (
                    <option value={`${content.id},${content.module_name}`}>
                      {content.module_name}
                    </option>
                  ))}
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Permission Category
              </label>
              <div style={{ maxHeight: "15em", overflowY: "scroll" }}>
                {this.state.permission_list &&
                  this.state.permission_list.map((content) => (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="col-1">
                          <input
                            type="checkbox"
                            onChange={() => this.handleObject(content.name)}
                            // checked={this.state.permissions_json[content.name]}
                          />
                        </div>
                        <div className="col-md-11">{content.name}</div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "1em" }}>
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.handleSubmit}>
              Assign
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.roles.getrole.data,
  permission_category: state.roles.getpermissionmod.data,
  permission_list: state.roles.getpermission.data,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getAdminRole,
  getPermissionMod,
  getPermission,
})(AdminPermissionForm);
