import { combineReducers } from "redux";
import {
  ADD_CATEGORY_CODE_CONFIG,
  GET_BILL_LIST,
  GET_BILL_TRANS,
  GET_CATEGORY_CODE_CONFIG,
  GET_GENERAL_CODE,
  GET_ITEM_CODE,
  SWITCH_BILL,
  GET_PRODUCT_CODE,
  GET_PROVIDER_CODE,
  GET_SERVICE_CODE,
  GET_SERVICE_SWITCH,
  GET_SWITCH_CODE,
  EDIT_CODE,
  EDIT_CODE_ERROR,
  ADD_CODE_ERROR,
} from "../action/action.types";

const initialState = {
  total: 0,
};

const billerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_BILL_LIST:
      return {
        ...state,
        loading: false,
        billerlist: action.payload.result,
      };
    case GET_BILL_TRANS:
      return {
        ...state,
        loading: false,
        billertrans: action.payload.transactions,
        total: action.payload.allTransactions,
      };
    default:
      return state;
  }
};

const switchBillReducer = (state = {}, action) => {
  switch (action.type) {
    case SWITCH_BILL:
      return {
        ...state,
        switch_message: action.payload.message,
      };
    default:
      return state;
  }
};

const billsConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORY_CODE_CONFIG:
      return {
        ...state,
        loading: false,
        category_code: action.payload,
      };
    case GET_GENERAL_CODE:
      return {
        ...state,
        loading: false,
        general_code: action.payload,
      };
    case GET_ITEM_CODE:
      return {
        ...state,
        loading: false,
        item_code: action.payload,
      };
    case GET_PRODUCT_CODE:
      return {
        ...state,
        loading: false,
        product_code: action.payload,
      };
    case GET_PROVIDER_CODE:
      return {
        ...state,
        loading: false,
        provider_code: action.payload,
      };
    case GET_SERVICE_CODE:
      return {
        ...state,
        loading: false,
        bills_service_code: action.payload,
      };
    case GET_SERVICE_SWITCH:
      return {
        ...state,
        loading: false,
        service_switch: action.payload,
      };
    case GET_SWITCH_CODE:
      return {
        ...state,
        loading: false,
        switch_code: action.payload,
      };
    default:
      return state;
  }
};

const addBillsConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CATEGORY_CODE_CONFIG:
      return {
        ...state,
        code_response: action.payload.response_message,
      };
    case EDIT_CODE:
      return {
        ...state,
        edit_response: action.payload.response_message,
      };
    case EDIT_CODE_ERROR:
      return {
        ...state,
        edit_response: action.payload.err_name,
      };
    case ADD_CODE_ERROR:
      return {
        ...state,
        code_response: action.payload,
      };
    default:
      return state;
  }
};

export const initialBillReducer = combineReducers({
  allbilllist: billerReducer,
  switchbill: switchBillReducer,
  billconfig: billsConfigReducer,
  addbillconfig: addBillsConfigReducer,
});
