import { headers } from "./api.config";

export const setCommission = async (data, token) => {
  const newData = {
    name: data.comName,
    transactionServiceCode: data.serviceCode,
    transactionCategoryCode: "",
    commissionValue: data.comValue,
    commissionType: data.comType,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/set_commission_profile`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(newData),
    }
  );
  if (response.ok) {
    const { response_description, success } = await response.json();
    return { response_description, success };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const fetchCommission = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_commission_profiles`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { success, data } = await response.json();
    const { commissionProfiles } = await data;
    return { commissionProfiles };
  } else {
    const { response_error } = await response.json();
    throw new Error(response_error);
  }
};

export const sendCommissionSplit = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/commission_split_config`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  const { success } = await response.json();
};

export const _toggleCommissionProfile = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/toggle_commission_profile/${id}`,
    {
      method: "PATCH",
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { commProfile } = await response.json();
    const { enabled } = await commProfile;
    return enabled;
  }
};

export const _deleteCommProf = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/delete_comm_profile/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success } = await response.json();
    return success;
  }
};
