import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import CompanyModal from "./CompanyModal";
import EditCompanyModal from "./EditCompanyModal";
import {
  addCompany,
  getCompany,
  getAdmins,
  updateInvestmentCompany,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  CompanyHead,
  CompanyBody,
} from "../../../components/Rows/InvestmentTable";

class CompanyConfig extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getCompany("/savinvest/investment_companies", this.props.token);
    this.props.getAdmins(this.props.token, 1);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      display: false,
      edit_company: false,
    });
  };

  submitCompany = (data) => {
    callBackMethod(
      this.props.addCompany(
        "/savinvest/investment_companies",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() =>
        this.props.getCompany(
          "/savinvest/investment_companies",
          this.props.token
        )
      );
  };

  editCompany = (props) => {
    this.setState({
      edit_company: true,
      companyData: props,
    });
  };

  editInvestmentCompany = (data) => {
    callBackMethod(
      this.props.updateInvestmentCompany(
        `/savinvest/investment_companies/${this.state.companyData.id}`,
        data,
        this.props.token,
        "PUT"
      )
    )
      .then(() => this.setState({ edit_company: false }))
      .then(() => alert(this.props.edit_company_resp))
      .then(() =>
        this.props.getCompany(
          "/savinvest/investment_companies",
          this.props.token
        )
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <CompanyHead />
          <tbody>
            {this.props.company_data &&
              this.props.company_data.map((content, index) => (
                <CompanyBody
                  {...content}
                  index={index + 1}
                  admin_data={this.props.admins}
                  editCompany={this.editCompany}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="company">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <CompanyModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitCompany={this.submitCompany}
        />
        <EditCompanyModal
          display={this.state.edit_company}
          closeModal={this.closeModal}
          companyData={this.state.companyData}
          edit_company={this.editInvestmentCompany}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  company_data: state.invest.getData.comp_data,
  admins: state.users.alladmins.adminData,
  edit_company_resp: state.invest.setData.edit_company_resp,
  comp_resp: state.invest.setData.comp_resp,
});
export default connect(mapStateToProps, {
  addCompany,
  getCompany,
  getAdmins,
  updateInvestmentCompany,
})(CompanyConfig);
