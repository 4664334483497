import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class SavingPenaltyModal extends React.Component {
  state = {
    amount: "",
    amount_type: "",
    cap_amount: "",
    cap_base: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.setSavingPenalty(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Savings Penalty">
        <div className="body-grid">
          <div className="box box9">
            <label>Amount</label>
            <input
              className="form-control"
              name="amount"
              value={this.state.amount}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Amount Type</label>
            <select
              className="form-control"
              name="amount_type"
              value={this.state.amount_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="fixed">Fixed</option>
              <option value="variable">Variable</option>
              <option value="interest">Interest</option>
              <option value="capital">Capital</option>
              <option value="total_sum">Total Sum</option>
            </select>
          </div>
          <div className="box box9">
            <label>Cap Amount</label>
            <input
              className="form-control"
              name="cap_amount"
              value={this.state.cap_amount}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Cap Base</label>
            <select
              className="form-control"
              name="cap_base"
              value={this.state.cap_base}
              onChange={this.handleChange}>
              <option></option>
              <option value="interest">Interest</option>
              <option value="capital">Capital</option>
            </select>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set saving product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
  admin_data: state.login.data,
});
export default connect(mapStateToProps, {})(SavingPenaltyModal);
