import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class FundModal extends React.Component {
  state = {
    wallet_id: "",
    amount: "",
    currency: "",
    transaction_type: "",
    admin_id: this.props.admins.id,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submitFund(this.state);
  };

  getWalletType = (wallet, wallet_id) => {
    const wallet_type =
      wallet &&
      wallet.map((content) => {
        if (content.id == wallet_id) {
          return content.currency;
        }
      });
    return wallet_type;
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Fund Wallet">
        <div className="body-grid">
          <div className="box box9">
            <div>
              <label>Wallet</label>
            </div>
            <select
              className="form-control"
              name="wallet_id"
              value={this.state.wallet_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.wallet_data &&
                this.props.wallet_data.map((content) => (
                  <option value={content.id}>{content.currency}</option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>
              Amount{" "}
              {this.getWalletType(this.props.wallet_data, this.state.wallet_id)}
            </label>
            <input
              className="form-control"
              name="amount"
              value={this.state.amount}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Currency</label>
            <select
              className="form-control"
              name="currency"
              value={this.state.currency}
              onChange={this.handleChange}>
              <option></option>
              {this.props.currency &&
                this.props.currency.map((content) => (
                  <option value={content.currency_code}>
                    {content.currency_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Transaction Type</label>
            <select
              className="form-control"
              name="transaction_type"
              value={this.state.transaction_type}
              onChange={this.handleChange}>
              <option></option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Fund Wallet
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  wallet_data: state.card.wallet.wallet_data,
  token: state.login.token,
  currency: state.virtualCards.get_rate.currency,
  admins: state.login.data,
});

export default connect(mapStateToProps, {})(FundModal);
