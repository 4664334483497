import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import { Link } from "react-router-dom";
import {
  getBillerList,
  switchBiller,
} from "../../../reduxStore/action/action.creator";
import {
  BillerListHead,
  BillerListBody,
} from "../../../components/Rows/BillerRow";
import { callBackMethod, Loader } from "../../../components/Accessories";
import BillerModal from "./BillerModal";

class BillerList extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentDidMount() {
    this.props.getBillerList(this.props.token);
  }

  showModal = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleRefresh = () => {
    this.props.getBillerList(this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  switchBills = (billName) => {
    this.setState({
      ...this.state,
      display: true,
      billName,
    });
    // this.props.switchBiller(billName, this.props.token);
  };

  handleSubmit = () => {
    callBackMethod(
      this.props.switchBiller(this.state.billName, this.props.token)
    )
      .then(() => alert(this.props.switchbill_msg))
      .then(() => this.setState({ display: false }));
    // alert(this.state.billName);
  };

  render() {
    let itemInTable;

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <BillerListHead />
          {this.props.billlist &&
            this.props.billlist.map((content, index) => (
              <BillerListBody
                {...content}
                index={this.getIndex(index + 1)}
                switchBills={this.switchBills}
              />
            ))}
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Biller Service"
              showModal={this.showModal}
              getInfo={this.handleRefresh}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Biller List</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BillerModal
          display={this.state.display}
          closeModal={this.closeModal}
          submit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.bills.allbilllist.loading,
  billlist: state.bills.allbilllist.billerlist,
  switchbill_msg: state.bills.switchbill.switch_message,
  token: state.login.token,
});

export default connect(mapStateToProps, { getBillerList, switchBiller })(
  BillerList
);
