import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import RateSettings from "./RateSetting/RateSettings";
import VirtualCardTransLimit from "./VirtualCardTransactionLimit/VirtualCardTransactionLimit";
import VirtualCardTrans from "./VirtualCardTransaction/VirtualCardTransaction";
import VirtualCardSetting from "./VirtualCardSettings/VirtualCardSettings";
import KycVirtualCardSetting from "./KycVirtualCardSettings/KycVirtualCardSettings";
import KycCardSetting from "./KycCardSettings/KycCardSettings";

const VirtualCardRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/rate-settings`} />
      </Route>
      <Route
        exact
        path={`${match.path}/rate-settings`}
        component={RateSettings}
      />
      <Route
        exact
        path={`${match.path}/virtual-card-transaction-limit`}
        component={VirtualCardTransLimit}
      />
      <Route
        exact
        path={`${match.path}/virtual-card-transaction`}
        component={VirtualCardTrans}
      />
      <Route
        exact
        path={`${match.path}/virtual-card-settings`}
        component={VirtualCardSetting}
      />
      <Route
        exact
        path={`${match.path}/kycvirtual-card-settings`}
        component={KycVirtualCardSetting}
      />
      <Route
        exact
        path={`${match.path}/kyc-card-settings`}
        component={KycCardSetting}
      />
    </Switch>
  </>
);
export default VirtualCardRoute;
