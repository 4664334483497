import React from "react";
import { connect } from "react-redux";
import InventoryBar from "../InventoryBar";
import { getCheckoutView } from "../../../reduxStore/action/action.creator";
import {
  CheckoutViewBody,
  CheckoutViewHead,
} from "../../../components/Rows/InventoryTable";
import { Loader } from "../../../components/Accessories";

class CheckoutView extends React.Component {
  componentDidMount() {
    this.props.getCheckoutView("checkout/views/product/all", this.props.token);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <CheckoutViewHead />
          <tbody>
            {this.props.checkout_view &&
              this.props.checkout_view.map((content, index) => (
                <CheckoutViewBody {...content} index={index + 1} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InventoryBar active="checkout">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
                <i class="fas fa-plus"></i> New
              </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InventoryBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  loading: state.inventory.get_product_inventory.loading,
  checkout_view: state.inventory.get_product_inventory?.checkout_view,
});

export default connect(mapStateToProps, { getCheckoutView })(CheckoutView);
