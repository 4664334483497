import { headers } from "./api.config";

export const getBulkPaymentData = async (url, token) => {
  const response = await fetch(`7b26fc4a41f2.ngrok.io/api/${url}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const result = await response.json();
    return { result };
  } else {
    const { response_message } = await response.json();
    throw Error(response_message);
  }
};
