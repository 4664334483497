import { headers } from "./api.config";

export const getFullDetails = async (url, token) => {
  // console.log('token 2', token);
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const result = await response.json();

    return { result };
  } else {
    const error = await response.json();
    throw new Error(error);
  }
};

export const getNewDetails = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const result = await response.json();
    return { result };
  } else {
    const error = await response.json();
    throw new Error(error);
  }
};

export const getBal = async (number, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/accounts/${number}/balance`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    return { result };
  } else {
  }
};

export const getStatement = async (begin, stop, acc, token) => {
  const data = {
    account_number: acc,
    start_date: begin,
    end_date: stop,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user_account/statement`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const res = await response.json();
    const result = res.data.TrxnList;
    return { result };
  } else {
  }
};
