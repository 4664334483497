import React from "react";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import DashboardNavBar from "../../../components/DashboardNavbar";
import SideBar from "../../../components/SideBar";
import {
  getSavingProduct,
  getUserSavingDetails,
  getSavingWallet,
  getUserSavingHistory,
} from "../../../reduxStore/action/action.creator";
import {
  UserSaveDetails,
  SavingHistoryHead,
  SavingHistoryBody,
} from "../../../components/Rows/SavingTable";

class UserSavingDetails extends React.Component {
  state = {};

  componentDidMount() {
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("g/") + 2, index.indexOf("/d"));
    this.props.getUserSavingDetails(
      `${process.env.REACT_APP_BASE_URL}/savinvest/user_savings/${index}`,
      this.props.token
    );
    this.props.getSavingProduct(this.props.token);
    this.props.getSavingWallet(this.props.token);
    this.props.getUserSavingHistory(index, this.props.token);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let itemInCard;
    if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
    } else {
      itemInCard = this.props.saving_detail && (
        <UserSaveDetails
          {...this.props.saving_detail[0]}
          product={this.props.savings_product}
          wallet={this.props.savings_wallet}
        />
      );
    }

    return (
      <div>
        <SideBar />
        <div className="main-content" id="panel">
          <DashboardNavBar settings="false" />
          <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
            <div class="container-fluid">
              <div class="header-body">
                <div class="row align-items-center py-4">
                  <div className="col-lg-6 col-7">
                    <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                      <span
                        style={{
                          padding: "0 .5em 0 0",
                          textAlign: "center",
                        }}>
                        <i
                          class="fas fa-chevron-left"
                          style={{
                            color: "#fff",
                            padding: "0.3em .5em",
                            border: "2px solid #fff",
                            borderRadius: "50%",
                            fontSize: "12px",
                          }}></i>
                      </span>
                      <span style={{ color: "#fff" }}>Back</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mt--5">
            <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
              <div class="col-5 userDetailsCol1">{itemInCard}</div>
              <div class="col-7 userDetailsCol2">
                <div class="tab" role="tabpanel">
                  <div
                    class="card-header"
                    style={{
                      margin: "0px",
                      backgroundColor: "lavenderblush",
                      borderBottom: "0px",
                    }}>
                    <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                      <li
                        role="presentation"
                        // onClick={() => this.changeTabActive(1)}
                        className="active">
                        <a
                          href="#Section1"
                          aria-controls="wallets"
                          role="tab"
                          //   onClick={this.loadBalance}
                          data-toggle="tab">
                          <i class="fa fa-dollar"> &nbsp; </i>Savings History
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content tabs">
                    <div role="tabpanel" class="tab-pane active" id="Section1">
                      <div className="table-responsive py-4 table-div">
                        <table
                          className="table table-flush"
                          id="datatable-basic">
                          <>
                            <SavingHistoryHead />
                            <tbody>
                              {this.props.saving_history &&
                                this.props.saving_history.map(
                                  (content, index) => (
                                    <SavingHistoryBody
                                      {...content}
                                      index={index + 1}
                                    />
                                  )
                                )}
                            </tbody>
                          </>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  saving_detail: state.saving.get_user_saving.saving_detail,
  loading: state.saving.get_user_saving.loading,
  saving_history: state.saving.get_user_saving.history_info,
  savings_wallet: state.saving.get_save_wallet.wallet_data,
  savings_product: state.saving.get_save_product.saving_data,
});
export default connect(mapStateToProps, {
  getSavingProduct,
  getUserSavingDetails,
  getSavingWallet,
  getUserSavingHistory,
})(UserSavingDetails);
