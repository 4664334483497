import { headers } from "./api.config";

export const setFeeProfile = async (data, token) => {
  const feeProfileData = {
    name: data.feeName,
    description: data.description,
    userId: data.userId,
    userCategory: data.userCategory,
    userType: data.userType,
    userLevel: data.level,
    transactionCategory: data.transCategory,
    transactionServiceCode: data.code,
    feeType: data.feeType,
    feeValue: data.feeValue,
    feeCap: data.feeCap,
    hasRange: data.range,
    minAmount: data.minAmount,
    maxAmount: data.maxAmount,
    includeVat: true,
    inclusive: true,
    enabled: true,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/set_fee_profile`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(feeProfileData),
    }
  );
  if (response.ok) {
    const { response_description, success } = await response.json();
    return { response_description, success };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};
const processFeeProfile = (result) => ({
  name: result.name,
  _id: result._id,
  description: result.description,
  userCategory: result.userCategory,
  transCategory: result.transactionCategoryName,
  transCode: result.transactionServiceCode,
  feeValue: result.feeValue,
  feeCap: result.feeCap,
  hasRange: result.hasRange,
  minAmount: result.minAmount,
  maxAmount: result.maxAmount,
  enabled: result.enabled,
});
export const fetchAllFees = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/fee_profiles?page=${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data, success } = await response.json();
    const { feeProfiles, allFeeProfiles } = await data;
    const mFeeProfiles = feeProfiles.map(processFeeProfile);
    return { mFeeProfiles, success, allFeeProfiles };
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const sendSplit = async (id, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/fee_split_config/${id}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const result = await response.json();
  } else {
    const { response_description } = await response.json();
    throw new Error(response_description);
  }
};

export const updateFee = async (data, token) => {
  const { id, datatoedit } = data;
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/update_fee_profile/${id}`,
    {
      method: "PATCH",
      headers: headers(token),
      body: JSON.stringify({
        name: datatoedit.e_feename,
        userCategory: datatoedit.e_category,
        feeValue: datatoedit.e_feeval,
        feeCap: datatoedit.e_feecap,
        minAmount: datatoedit.e_min,
        maxAmount: datatoedit.e_max,
        description: datatoedit.e_desc,
      }),
    }
  );

  if (response.ok) {
  }
};

export const toggleFee = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/toggle_fee_profile/${id}`,
    {
      method: "PATCH",
      headers: headers(token),
    }
  );

  if (response.ok) {
  }
};

export const deleteFeeProfile = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/delete_fee_profile/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );
  if (response.ok) {
  }
};

export const deleteFeeSplit = async (url, data, token) => {
  const { fee_profileid, beneficiary_id } = data;

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: "PATCH",
    headers: headers(token),
    body: JSON.stringify({ beneficiary_id }),
  });

  const result = await response.json();
  return { result };
};
