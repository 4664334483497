import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { RoleRoute } from "./RoleSettings";
import { PermissionRoute } from "./PermissionSetting";
import { PermissionModuleRoute } from "./PermissionModules";
import { AdminPermissionRoute } from "./AdminPermission";

import { Error } from "../../../Error";

const Show = () => <div>wassa</div>;

const AuthorizationRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/roles-settings`} />
      </Route>
      <Route path={`${match.path}/roles-settings`} component={RoleRoute} />
      <Route path={`${match.path}/permissions`} component={PermissionRoute} />
      <Route
        path={`${match.path}/permission-module`}
        component={PermissionModuleRoute}
      />

      <Route
        path={`${match.path}/set-admin-permissions`}
        component={AdminPermissionRoute}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);
export default AuthorizationRoute;
