import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllAgents from "./AllAgents";
// import AllAdminsDetails from "./AllAdminsDetails";

const AgentRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AllAgents} />
      {/* <Route
        exact
        path={`${match.path}/:name/details`}
        component={AllAdminsDetails}
      /> */}
    </Switch>
  </>
);
export default AgentRoute;
