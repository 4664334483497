import React from "react";
import { connect } from "react-redux";
import Settings from "../../../SettingNavbar";
import SideBar from "../../../../../components/SideBar";
import DashboardNavBar from "../../../../../components/DashboardNavbar";
import {
  getAllPromotionSub,
  getUsers,
} from "../../../../../reduxStore/action/action.creator";
import {
  SubscriberHead,
  SubscriberBody,
} from "../../../../../components/Rows/PromotionTable";

class PromotionSub extends React.Component {
  state = {};

  componentDidMount() {
    this.props.getAllPromotionSub(this.props.token);
    this.props.getUsers(this.props.token, `user/users-all`);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading...";
    } else {
      itemInTable = (
        <>
          <SubscriberHead />
          <tbody>
            {this.props.subscribers &&
              this.props.subscribers.map((content, index) => (
                <SubscriberBody {...content} index={index + 1} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Extra" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div className="table-responsive py-4 table-div">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.promotions.getSubscribers.loading,
  subscribers: state.promotions.getSubscribers.subcribers,
  users: state.users.allusers.usersData,
  token: state.login.token,
});
export default connect(mapStateToProps, { getAllPromotionSub, getUsers })(
  PromotionSub
);
