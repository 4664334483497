import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  getUserCategory,
  setUserCategory,
} from "../../reduxStore/action/action.creator";
import {
  UserCategoryHead,
  UserCategoryBody,
} from "../../components/Rows/UserTable";
import UserCategoryModal from "./UserCategoryModal";
import { callBackMethod } from "../../components/Accessories";

class UserCategory extends React.Component {
  state = {
    display: false,
  };

  //   componentWillReceiveProps(nextProps) {
  //     if (nextProps.cat_response) {
  //       alert(nextProps.cat_response);
  //     }
  //   }

  componentDidMount() {
    this.props.getUserCategory(this.props.token);
  }

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  submitCategory = (data) => {
    callBackMethod(this.props.setUserCategory(data, this.props.token))
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.cat_response))
      .then(() => this.props.getUserCategory(this.props.token));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading";
    } else {
      itemInTable = (
        <>
          <UserCategoryHead />
          {this.props.user_cat &&
            this.props.user_cat.map((content, index) => (
              <UserCategoryBody {...content} index={index + 1} />
            ))}
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Customer Category"
              getInfo={this.props.getUserCategory}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Customer Category</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserCategoryModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitCategory={this.submitCategory}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user_cat: state.users.getUserCat.user_cat,
  token: state.login.token,
  cat_response: state.users.getUserCat.response,
});

export default connect(mapStateToProps, { getUserCategory, setUserCategory })(
  UserCategory
);
