import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PermissionModule from "./PermissionModSettings";
import { Error } from "../../../../Error";

const Show = () => <div>wassa, Hello</div>;

const PermissionModuleRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={PermissionModule} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default PermissionModuleRoute;
