import { headers } from "./api.config";

export const getTransCat = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_txn_categories`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		const { transactionCategory } = await data;
		return transactionCategory;
	} else {
		const { response_description } = await response.json();
		throw new Error(response_description);
	}
};
export const getService = async (id, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/services_by_catId/${id}`,
		{
			headers: headers(token),
		}
	);
	if (response.ok) {
		const { data } = await response.json();
		const { service } = await data;
		return service;
	} else {
		const { response_description } = await response.json();
		throw new Error(response_description);
	}
};

export const getBeneficiary = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_beneficiaries`,
		{
			headers: headers(token),
		}
	);

	const { beneficiaries } = await response.json();

	return beneficiaries;
};

export const setBeneficiary = async (data, token) => {
	const { userType, userId } = data;
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/add_beneficiary`,
		{
			method: "POST",
			headers: headers(token),
			body: JSON.stringify({ userType, userId }),
		}
	);

	if (response.ok) {
		const { success, data } = await response.json();
		return { success, data };
	} else {
		const { response_description } = await response.json();
		throw new Error(response_description);
	}
};

export const updateBeneficiary = async (id, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/toggle_beneficiary/${id}`,
		{
			method: "PATCH",
			headers: headers(token),
		}
	);
};

export const deleteBeneficiary = async (id, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/delete_beneficiary/${id}`,
		{
			method: "DELETE",
			headers: headers(token),
		}
	);
	const result = await response.json();
	return result.success;
};

export const getPartners = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_service_partners`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { servicePartners } = await response.json();
		return { servicePartners };
	}
};

export const getInstitution = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_recipient_institutions`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		const { recipientInstitutionNames } = await data;
		return { recipientInstitutionNames };
	}
};

export const getTransactionService = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_txn_services`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		const { service } = await data;
		return { service };
	}
};
