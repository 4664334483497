import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	VERIFY_TOKEN_LOGIN,
	PASS_SUCCESS,
	LOGIN_SCAN,
} from "../action/action.types";

const initialState = {
	token: "",
	isLoggedIn: false,
	loading: false,
	loginError: "",
};

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case PASS_SUCCESS:
			return {
				...state,
				loginError: "",
				isLoggedIn: false,
				temp_token: action.payload.temp_token,
				setup: action.payload.setup,
				loading: false,
			};
		case LOGIN_SCAN:
			return {
				...state,
				loginError: "",
				isLoggedIn: false,
				qr_image: action.payload.qr_image,
				secret: action.payload.secret,
				loading: false,
				setup: false,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				loginError: "",
				token: action.payload.token,
				isLoggedIn: action.payload.success,
				permission: action.payload.permission,
				data: action.payload.data,
				loading: false,
			};
		case LOGIN_FAIL:
			return {
				...state,
				isLoggedIn: false,
				loading: false,
				loginError: action.payload,
			};
		case LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				loading: false,
				token: "",
				secret: "",
				qr_image: "",
				temp_token: "",
			};
		default:
			return state;
	}
};
