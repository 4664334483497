import { toDate } from "date-fns";
import React from "react";
import { dateFormatter } from "../Row";

export const NotificationHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Sender</th>
      <th>Title</th>
      <th>Date</th>
      <th>Recipient Email</th>
      <th>Description</th>
      <th>Notification Mode Code</th>
      <th>Notification Status</th>
      <th>Recipient User Id</th>
      <th>Request Reference</th>
      <th>Broadcast Reference</th>
      <th>Notification Channel Code</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const NotificationBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.sender}</td>
    <td>{props.title}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>{props.recipientsEmail}</td>
    <td>{props.description}</td>
    <td>{props.notificationModeCode}</td>
    <td>{props.notificationStatus}</td>
    <td>{props.recipientsUserId}</td>
    <td>{props.requestRef}</td>
    <td>{props.broadcastRef}</td>
    <td>{props.notificationChannelCode[0]}</td>
    <td></td>
  </tr>
);

export const ChannelHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Channel Code</th>
      <th>Date</th>
      <th>Enabled</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const ChannelBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.channelCode}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>{props.enabled ? "Enabled" : "Not Enabled"}</td>
    <td>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          onChange={() => props.toggleChannel(props._id, props.enabled)}
          checked={props.enabled}
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
  </tr>
);
