import { headers } from "./api.config";

export const getAllNotification = async (page, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/notification/get_all_notifications?page=${page}`,
		{
			headers: headers(token),
		}
	);
	const { data, response_description } = await response.json();
	const { notifications, allNotifications } = await data;

	return { notifications, response_description, allNotifications };
};

export const filterAllNotification = async (mdata, token, page) => {
	let url;
	if (mdata.start_date && mdata.end_date) {
		url = `${process.env.REACT_APP_BASE_URL}/notification/get_all_notifications?page=${page}&per_page=100&start_date=${mdata.start_date}&end_date=${mdata.end_date}`;
	} else {
		url = `${process.env.REACT_APP_BASE_URL}/notification/get_all_notifications?page=${page}&per_page=100`;
	}

	// `${process.env.REACT_APP_BASE_URL}/notification/get_all_notifications?per_page=100&notificationChannelCode=${mdata.channel_code}&notificationModeCode=${mdata.mode_code}&notificationTypeCode=${mdata.type}&notificationStatus=${mdata.status_code}`
	const response = await fetch(url, {
		headers: headers(token),
	});
	const { data, response_description, allNotifications } =
		await response.json();
	const { notifications } = await data;
	return { notifications, response_description, allNotifications };
};

export const getChannel = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/notification/get_notification_channels`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		const channels = await data.notificationChannels;

		return { channels };
	}
};

export const toggleChannel = async (id, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/notification/toggle_notification_channel/${id}`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { success, response_description } = await response.json();
		return { success, response_description };
	}
};

export const notificationValue = async (url, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/notification/${url}`,
		{
			headers: headers(token),
		}
	);
	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};
