import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import { dateFormatter, timeFormatter } from "../../../../../components/Row";
import { getKycLevelDetail } from "../../../../../reduxStore/action/action.creator";

class KycLevelDetail extends React.Component {
  state = {};

  componentDidUpdate(prevProps, prevState) {
    if (this.props.kyc_level_id != prevProps.kyc_level_id) {
      this.props.getKycLevelDetail(
        `kyc_levels/${this.props.kyc_level_id}`,
        this.props.token
      );
    }
  }

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Kyc Level Detail">
        <div className="body-grid" style={{ paddingTop: "2em" }}>
          <div className="box box1">
            <label>Level Name</label>
            <div>
              <span>
                {this.props.level_detail && this.props.level_detail.level_name}
              </span>
            </div>
          </div>
          <div className="box box2">
            <label>Level Type</label>
            <div className="">
              <span>
                {this.props.level_detail && this.props.level_detail.level}
              </span>
            </div>
          </div>
          <div className="box box9">
            <label>Description</label>
            <div className="">
              <span>
                {this.props.level_detail && this.props.level_detail.description}
              </span>
            </div>
          </div>
          <div className="box box1">
            <label>Time Created</label>
            <div className="">
              <span>
                {this.props.level_detail &&
                  timeFormatter(this.props.level_detail.created_at)}
              </span>
            </div>
          </div>
          <div className="box box2">
            <label>Date Created</label>
            <div className="">
              <span>
                {this.props.level_detail &&
                  dateFormatter(this.props.level_detail.created_at)}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_kyc_level.detail_loading,
  level_detail: state.kyc.get_kyc_level.level_detail,
  token: state.login.token,
});

export default connect(mapStateToProps, { getKycLevelDetail })(KycLevelDetail);
