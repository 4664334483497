import React, { Component } from "react";
import { connect } from "react-redux";
import IncentiveBar from "../IncentivesBar";
import { getEarnedPoint } from "../../../../../reduxStore/action/action.creator";
import {
  EarnedActionBody,
  EarnedActionHead,
} from "../../../../../components/Rows/IncentiveTable";

class EarnedAction extends Component {
  state = {};

  componentDidMount() {
    this.props.getEarnedPoint(`earned/actions`, this.props.token);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading";
    } else {
      itemInTable = (
        <>
          <EarnedActionHead />
          <tbody>
            {this.props.earned_action &&
              this.props.earned_action.map((content, index) => (
                <EarnedActionBody {...content} index={index} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <IncentiveBar {...this.props} active="earned-actions">
        <div
          class="col-lg-12 col-5 text-right"
          style={{ padding: "0 0.5em 1em" }}>
          <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
            <i class="fas fa-plus"></i> New
          </a>
        </div>
        <div className="table-responsive py-4">
          <table className="table table-flush" id="datatable-basic">
            {itemInTable}
          </table>
        </div>
      </IncentiveBar>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  earned_action: state.incentive.get_incentives.earned_action,
});

export default connect(mapStateToProps, { getEarnedPoint })(EarnedAction);
