import { headers } from "./api.config";

export const setPromotion = async (data, token) => {
  const categoryCode = data.categoryCode.split(",");
  const newData = {
    name: data.name,
    description: data.description,
    categoryCode: categoryCode[0],
    serviceCode: data.serviceCode,
    serviceApplicable: data.serviceCode_applicable,
    customerMaxTxnCount: data.customerMaxTxnCount,
    maxSubscriptionPermit: data.maxSubscriptionPermit,
    startDate: data.startDate,
    endDate: data.endDate,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/add_promotion`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(newData),
    }
  );

  if (response.ok) {
    const { success, data, response_description } = await response.json();
    const { promotion } = await data;

    return { success, promotion, response_description };
  }
};

export const fetchAllPromotion = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_all_promo_configs`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const {
      success,
      response_description,
      promoConfigs,
    } = await response.json();
    return { promoConfigs };
  }
};

export const deletePromo = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/delete_promo_config/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, response_description } = await response.json();
    return { success, response_description };
  }
};

export const togglePromotion = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/toggle_promo_config/${id}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, response_description } = await response.json();
    return { success, response_description };
  }
};

export const editGetriPromotion = async (id, data, token) => {
  const categoryCode = data.categoryCode.split(",");
  const newData = {
    name: data.name,
    description: data.description,
    categoryCode: categoryCode[0],
    serviceCode: data.serviceCode,
    customerMaxTxnCount: data.customerMaxTxnCount,
    maxSubscriptionPermit: data.maxSubscriptionPermit,
    startDate: data.startDate,
    endDate: data.endDate,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/update_promo_config/${id}`,
    {
      method: "PATCH",
      headers: headers(token),
      body: JSON.stringify(newData),
    }
  );

  if (response.ok) {
    const { success, data, response_description } = await response.json();
    const { promotion } = await data;

    return { success, promotion, response_description };
  }
};

export const fetchAllSubscriptions = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_promo_subscriptions`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { allPromoSubscriptions } = await response.json();
    return { allPromoSubscriptions };
  }
};

export const fetchPromotionSubscribers = async (serviceApplicable, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/get_promo_config_subs?serviceApplicable=${serviceApplicable}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { allPromoSubscriptions } = await response.json();
    return { allPromoSubscriptions };
  }
};
