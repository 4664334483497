import React from "react";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import "../../morestyles.css";
import {
  Loader,
  Icon,
  CardBodyHolder,
  Modal,
} from "../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class BulkPaymentBar extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar submenu="Bulk Payment" shouldRefresh="false" />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="all-batches"
                title={
                  <Link to={`/bulk-payment/all-batches`}>
                    <Icon className="" title="All Batches" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="all-batches-data"
                title={
                  <Link to={`/bulk-payment/all-batches-data`}>
                    <Icon className="" title="All Batches Data" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="bulk-transaction"
                title={
                  <Link to={`/bulk-payment/bulk-transaction`}>
                    <Icon className="" title="Bulk Transaction" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default BulkPaymentBar;
