import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";
import { dateFormatter, timeFormatter } from "../../../../components/Row";
import { getInterestDetail } from "../../../../reduxStore/action/action.creator";

class KycLevelDetail extends React.Component {
	state = {};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.interest_id != prevProps.interest_id) {
			this.props.getInterestDetail(
				`/${this.props.interest_id}`,
				this.props.token
			);
		}
	}

	render() {
		return (
			<Modal
				display={this.props.display ? "block" : "none"}
				closeModal={this.props.closeModal}
				modalTitle="Interest Detail"
			>
				<div className="body-grid" style={{ paddingTop: "2em" }}>
					<div className="box box1">
						<label>Name</label>
						<div>
							<span>
								{this.props.interest_detail &&
									this.props.interest_detail.name}
							</span>
						</div>
					</div>
					<div className="box box9">
						<label>Description</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									this.props.interest_detail.description}
							</span>
						</div>
					</div>
					<div className="box box9">
						<label>Minimum Amount</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									this.props.interest_detail.minimum_amount}
							</span>
						</div>
					</div>
					<div className="box box9">
						<label>Maximum Amount</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									this.props.interest_detail.minimum_amount}
							</span>
						</div>
					</div>
					<div className="box box9">
						<label>Annual Interest</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									this.props.interest_detail.annual_interest}
							</span>
						</div>
					</div>
					<div className="box box1">
						<label>Time Created</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									timeFormatter(
										this.props.interest_detail.createdAt
									)}
							</span>
						</div>
					</div>
					<div className="box box2">
						<label>Date Created</label>
						<div className="">
							<span>
								{this.props.interest_detail &&
									dateFormatter(
										this.props.interest_detail.createdAt
									)}
							</span>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.interest.get_interest.loading,
	interest_detail: state.interest.get_interest.interest_detail,
	token: state.login.token,
});

export default connect(mapStateToProps, { getInterestDetail })(KycLevelDetail);
