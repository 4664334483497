import React from "react";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import "../../../morestyles.css";
import { Loader, Icon, CardBodyHolder } from "../../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class TransactionReport extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Card Settings"
            report={false}
            getInfo={this.props.handleRefresh}
            handleReport={this.getTotalTransaction}
            shouldRefresh="false"
          />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="ratesetting"
                title={
                  <Link to={`/virtual-card/rate-settings`}>
                    <Icon className="" title="Rate Settings" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="virtualcard-limit"
                title={
                  <Link to={`/virtual-card/virtual-card-transaction-limit`}>
                    <Icon className="" title="Virtual Card Transaction Limt" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="virtualcard-trasaction"
                title={
                  <Link to={`/virtual-card/virtual-card-transaction`}>
                    <Icon className="" title="Virtual Card Transaction" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="virtualcard-setting"
                title={
                  <Link to={`/virtual-card/virtual-card-settings`}>
                    <Icon className="" title="Virtual Card" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="kycvirtualcard-setting"
                title={
                  <Link to={`/virtual-card/kycvirtual-card-settings`}>
                    <Icon className="" title="Kyc Virtual Card Setting" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              {/* <Tab
                eventKey="kyc-card-setting"
                title={
                  <Link to={`/virtual-card/kyc-card-settings`}>
                    <Icon className="" title="Kyc Card Setting" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab> */}
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default TransactionReport;
