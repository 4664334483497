import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import "../../../../morestyles.css";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Accessories";

class Kyc extends React.Component {
  state = {
    key: "Level",
  };

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  render() {
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="kyc" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.props.active}
                        onSelect={this.getActiveState}>
                        <Tab
                          eventKey="Level"
                          title={
                            <Link to={`/settings/kyc/level`}>
                              <Icon className="" title="Level" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="fields"
                          title="Fields"
                          tabClassName="mTab kycTab"></Tab>
                        <Tab
                          eventKey="Setting"
                          title={
                            <Link to={`/settings/kyc/setting`}>
                              <Icon className="" title="Kyc Level Setting" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="Document"
                          title={
                            <Link to={`/settings/kyc/document`}>
                              <Icon className="" title="Documents" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>

                        <Tab
                          eventKey="Requirements"
                          title={
                            <Link to={`/settings/kyc/requirement`}>
                              <Icon className="" title="Kyc Requirement" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>

                        <Tab
                          eventKey="Kyc Upgrade Request"
                          title={
                            <Link to={`/settings/kyc/kyc_upgrade`}>
                              <Icon className="" title="Kyc Upgrade Request" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>

                        <Tab
                          eventKey="Upload Document"
                          title={
                            <Link to={`/settings/kyc/upload_document`}>
                              <Icon className="" title="Upload Document" />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Kyc;
