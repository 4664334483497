import React from "react";
import { connect } from "react-redux";
import CardLayout from "../VirtualCardBar";
import {
  callBackMethod,
  Loader,
  Pagination,
} from "../../../../components/Accessories";
import {
  getVirtualCards,
  lockCard,
  unlockCard,
  cardTerminate,
  detailsInfo,
  getCurrencies,
  filterVirtualCard,
} from "../../../../reduxStore/action/action.creator";
import {
  VirtualCardBody,
  VirtualCardHead,
} from "../../../../components/Rows/CardTable";
import ConfirmationModal from "./ConfirmationModal";
import FilterModal from "./FilterModal";

class VirtualCardSetting extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    property: false,
  };

  componentDidMount() {
    this.props.getVirtualCards(`virtual_cards`, false, this.props.token);
    this.props.getCurrencies(this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getVirtualCards(
        `virtual_cards?page=${id}`,
        false,
        this.props.token
      );
    } else {
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getVirtualCards(
          `virtual_cards?page=${id}`,
          false,
          this.props.token
        );
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getVirtualCards(
          `virtual_cards?page=${id}`,
          false,
          this.props.token
        );
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.toggle_card_response &&
      nextProps.toggle_data == "Fluuterwave Errors " &&
      !nextProps.history
    ) {
      alert(JSON.stringify(nextProps));
    }
  }

  toggleHandler = () => {
    if (this.state.type == true) {
      this.terminateCard();
    } else {
      if (this.state.property.is_active == true) {
        //can lock/block card
        callBackMethod(
          this.props.lockCard(
            this.state.property,
            "block_user_virtual_card",
            this.props.token
          )
        )
          .then(() => this.setState({ display: false }))
          .then(() => alert(this.props.toggle_card_response));
      } else {
        //can unlock/unblock card
        callBackMethod(
          this.props.unlockCard(
            this.state.property,
            "un_block_user_virtual_card",
            this.props.token
          )
        )
          .then(() => this.setState({ display: false }))
          .then(() => alert(this.props.toggle_card_response));
      }
    }
  };

  terminateCard = () => {
    callBackMethod(
      this.props.detailsInfo(
        `user/users-profile/${this.state.property.user.id}`,
        this.props.token
      )
    )
      .then(() => {
        const data = {
          trans_ref: "some strings",
          account_no: this.props.details.data.bank_account[0].account_number,
          user_id: this.state.property.user.id,
          card_id: this.state.property.id,
        };
        this.props.cardTerminate(
          data,
          "terminate_user_virtual_card",
          this.props.token
        );
      })
      .then(() => alert(this.props.toggle_card_response))
      .then(() =>
        this.props.getVirtualCards(`virtual_cards`, false, this.props.token)
      );

    // alert(props.id);
    // callBackMethod(
    // ).then(() => alert(this.props.toggle_card_response));
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getVirtualCards(
      `virtual_cards?page=${this.state.currentPage}`,
      false,
      this.props.token
    );
  };

  ShowModal = (props, btnType) => {
    this.setState({
      ...this.state,
      display: true,
      property: props,
      type: btnType,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      filter: false,
    });
  };

  openFilterModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      filter: true,
    });
  };

  filter = (data) => {
    callBackMethod(
      this.props.filterVirtualCard(
        data,
        this.state.currentPage,
        this.props.token
      )
    ).then(() => this.setState({ filter: false }));
    this.setState({
      ...this.state,
      filterData: data,
    });
  };

  render() {
    let renderPaginate;
    let itemInTable;

    const total =
      this.props.total && parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <VirtualCardHead />
          <tbody className="table-div">
            {this.props.virtual_cards &&
              this.props.virtual_cards.map((content, index) => (
                <VirtualCardBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  toggleHandler={this.ShowModal}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <CardLayout active="virtualcard-setting">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.openFilterModal}>
              <i class="fa fa-filter"></i> Filter
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
            {renderPaginate}
          </div>
        </CardLayout>
        <ConfirmationModal
          display={this.state.display}
          is_active={this.state.property.is_active}
          status_name={this.state.type}
          closeModal={this.closeModal}
          changeStatus={this.toggleHandler}
        />
        <FilterModal
          display={this.state.filter}
          closeModal={this.closeModal}
          submitFilter={this.filter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  virtual_cards: state.virtualCards.get_virtual_config.data,
  loading: state.virtualCards.get_virtual_config.loading,
  toggle_card_response: state.virtualCards.toggle_card.response,
  toggle_data: state.virtualCards.toggle_card.data,
  details: state.details.user_detailinfo,
  total: state.virtualCards.get_virtual_config.total,
  filtering: state.virtualCards.get_virtual_config.filtering,
});

export default connect(mapStateToProps, {
  getVirtualCards,
  lockCard,
  unlockCard,
  cardTerminate,
  detailsInfo,
  getCurrencies,
  filterVirtualCard,
})(VirtualCardSetting);
