import React from "react";
import DashboardNavBar from "../../components/DashboardNavbar";
import { Tabs, Tab } from "react-bootstrap";
import SideBar from "../../components/SideBar";
import { CardBodyHolder, Icon } from "../../components/Accessories";
import { Link } from "react-router-dom";

class NotificationBar extends React.Component {
	state = {
		key: "notification",
	};

	getActiveState = (k) => {
		this.setState({ key: k });
	};

	render() {
		return (
			<div>
				<SideBar />
				<div class="main-content" id="panel">
					<DashboardNavBar
						submenu="notification"
						getInfo={this.props.handleRefresh}
						showModal={this.props.showModal}
						shouldFilter={true}
					/>
					<CardBodyHolder>
						<Tabs
							id="controlled-tab-example"
							activeKey={this.props.active}
							onSelect={this.getActiveState}
						>
							<Tab
								eventKey="notification"
								title={
									<Link to={`/notification`}>
										<Icon
											className="fa fa-bell"
											title="Notifications"
										/>
									</Link>
								}
								tabClassName="mTab kycTab"
							>
								{this.props.children}
							</Tab>
							<Tab
								eventKey="channel"
								title={
									<Link to={`/notification/channel`}>
										<Icon
											className=""
											title="Notifications Channel"
										/>
									</Link>
								}
								tabClassName="mTab kycTab"
							>
								{this.props.children}
							</Tab>
						</Tabs>
					</CardBodyHolder>
				</div>
			</div>
		);
	}
}
export default NotificationBar;
