import React from "react";
import InterestBar from "../InterestBar";
import {
	getInterestPayouts,
	getUserDetails,
	getPayoutsFilters,
} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader, Pagination } from "../../../components/Accessories";
import FilterPayouts from "./FilterPayouts";
import { dateFormatter, formatCurrency } from "../../../components/Row";
import UserDetails from "../InterestHistory/UserDetails";

class InterestPayout extends React.Component {
	state = {
		currentPage: 1,
		display: false,
		filter: false,
		data: {},
		showUser: false,
		csvReport: {
			data: [],
			filename: "some.csv",
		},
	};

	componentDidMount() {
		this.props.getInterestPayouts(this.props.token, this.state.currentPage);
	}

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};
	closeModal = () => {
		this.setState({
			display: false,
		});
	};
	closePopup = () => {
		this.setState({
			showUser: false,
		});
	};

	handleRefresh = () => {
		this.props.getInterestPayouts(this.props.token, 1);
	};

	displayUser = (e, id) => {
		e.preventDefault();
		this.props.getUserDetails(id, this.props.token);
		this.setState({
			showUser: true,
		});
	};

	changePage = (id) => () => {
		if (!this.state.filter) {
			this.props.getInterestPayouts(this.props.token, id);
		} else {
			this.props.getPayoutsFilters(this.props.token, id, this.state.data);
		}
		this.setState({
			currentPage: id,
		});
	};

	goPrevious = () => {
		if (this.state.currentPage - 1 != 0) {
			const id = this.state.currentPage - 1;
			if (!this.state.filter) {
				this.props.getInterestPayouts(this.props.token, id);
			} else {
				this.props.getPayoutsFilters(
					this.props.token,
					id,
					this.state.data
				);
			}

			this.setState({
				currentPage: id,
			});
		}
	};

	goNext = () => {
		const total = this.props.paginate.last_page;
		if (this.state.currentPage != total) {
			const id = this.state.currentPage + 1;
			if (!this.state.filter) {
				this.props.getInterestPayouts(this.props.token, id);
			} else {
				this.props.getPayoutsFilters(
					this.props.token,
					id,
					this.state.data
				);
			}
			this.setState({
				currentPage: id,
			});
		}
	};

	submitFilter = (data) => {
		let id;
		if (this.state.currentPage > 1) {
			id = 1;
		} else {
			id = this.state.currentPage;
		}
		this.props.getPayoutsFilters(this.props.token, id, data);

		this.setState({
			filter: true,
			data,
			display: false,
		});
	};

	exportCSV = (event, done) => {
		const report = {
			filename: "InterestPayout.csv",
			data: this.props.interests,
			// headers: [],
		};
		this.setState({ csvReport: report }, () => {
			done();
		});
	};

	render() {
		return (
			<>
				<InterestBar
					{...this.props}
					active="payout"
					showModal={this.showModal}
					handleRefresh={this.handleRefresh}
					csvreport={this.state.csvReport}
					downloadCSV={this.exportCSV}
					iscsv={true}
				>
					<div className="table-responsive py-4">
						<table
							className="table table-flush"
							id="datatable-basic"
						>
							{this.props.loading ? (
								<Loader loading={this.props.loading} />
							) : (
								this.props.interests && (
									<>
										<thead className="thead-light">
											<tr>
												<th>S/N</th>
												<th>User</th>
												<th>Account Number</th>
												<th>Payout Date</th>
												<th className="text-right">
													Interest Paid (NGN)
												</th>
											</tr>
										</thead>
										<tbody>
											{this.props.interests.map(
												(interest, i) => (
													<tr key={interest.id}>
														<td>
															{(this.props
																.paginate.page -
																1) *
																25 +
																i +
																1}
														</td>
														<td>
															<a
																href="#"
																onClick={(e) =>
																	this.displayUser(
																		e,
																		interest.user_id
																	)
																}
															>
																{
																	interest.user_id
																}
															</a>
														</td>
														<td>
															{
																interest.account_number
															}
														</td>
														<td>
															{dateFormatter(
																interest.payout_date
															)}
														</td>
														<td className="text-right">
															{formatCurrency(
																interest.interest_paid
															)}
														</td>
													</tr>
												)
											)}
										</tbody>
									</>
								)
							)}
						</table>
						{!this.props.loading &&
						this.props.paginate &&
						this.props.paginate.last_page &&
						this.props.paginate.last_page > 1 ? (
							<Pagination
								paginateNumber={Array(
									this.props.paginate.last_page
								).fill(0)}
								currentPage={this.state.currentPage}
								handlePagination={this.changePage}
								handlePrevious={this.goPrevious}
								handleNext={this.goNext}
								total={this.props.paginate.last_page}
								totalData={this.props.paginate.total}
							/>
						) : (
							<></>
						)}
					</div>
				</InterestBar>
				<FilterPayouts
					display={this.state.display}
					closeModal={this.closeModal}
					submit={this.submitFilter}
				/>
				<UserDetails
					display={this.state.showUser}
					close={this.closePopup}
					user_details={this.state.user}
				/>
				<FilterPayouts
					display={this.state.display}
					closeModal={this.closeModal}
					submit={this.submitFilter}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token,
	loading: state.interest.interest_payout.loading,
	interests: state.interest.interest_payout.interests,
	paginate: state.interest.interest_payout.paginate,
});

export default connect(mapStateToProps, {
	getInterestPayouts,
	getUserDetails,
	getPayoutsFilters,
})(InterestPayout);
