import {
  TRANSACTION_FAILED,
  TRANSACTION_SUCCESS,
  TRANS_CAT_FAILED,
  TRANS_CAT_SUCCESS,
  TRANS_SERVICE_CODE_FAILED,
  TRANS_SERVICE_CODE_SUCCESS,
  DETAIL_INFO,
  GET_BANK_TRANS,
  GET_FLUTTER,
  FILTER_BANK_TRANS,
  FILTER_FLUTTER,
  GET_BANK_TRANS_STATUS,
  GET_FLW_RANGE,
  FILTER_TRANSACTION_STATUS,
  SET_REQUERY,
  SET_REVERSAL,
  FILTER_TRANSACTION,
  GET_INSTITUTIONS,
  GET_TRANSACTION_SERVICE,
  SERVICE_APPLICABLE,
} from "../action/action.types";
import { combineReducers } from "redux";

const initialState = {
  transactions: [],
  transCat: [],
  serviceCode: [],
  serviceCodeApplicable: [],
  total: 0,
  loading: false,
};

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
      };
    case TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: action.payload.transaction,
        total: action.payload.allTransactions,
        loading: false,
        filtering: false,
        search_filter: false,
      };
    case FILTER_TRANSACTION_STATUS:
      return {
        ...state,
        filtering: true,
        transactions: action.payload.transaction,
        total: action.payload.allTransactions,
        loading: false,
      };
    case FILTER_TRANSACTION:
      return {
        ...state,
        transactions: action.payload.transaction,
        total: action.payload.allTransactions,
        loading: false,
        search_filter: true,
      };
    case TRANSACTION_FAILED:
      return {
        ...state,
        failed: action.payload,
        filtering: false,
      };
    default:
      return state;
  }
};

const transcategory = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case TRANS_CAT_SUCCESS:
      return {
        ...state,
        transCat: action.payload.transactionCategory,
        loading: false,
      };
    case TRANS_CAT_FAILED:
      return {
        ...state,
        transCat: [],
        failed: action.payload,
      };
    case TRANS_SERVICE_CODE_SUCCESS:
      return {
        ...state,
        serviceCode: action.payload.transactionServiceCode,
        loading: false,
      };
    case TRANS_SERVICE_CODE_FAILED:
      return {
        ...state,
        serviceCode: [],
        failed: action.payload,
      };
    case GET_TRANSACTION_SERVICE:
      return {
        ...state,
        service: action.payload.service,
      };
    case SERVICE_APPLICABLE:
      return {
        ...state,
        loading: false,
        serviceCodeApplicable: action.payload.serviceApplicable,
      };
    default:
      return state;
  }
};

const instituteReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INSTITUTIONS:
      return {
        ...state,
        institute: action.payload.recipientInstitutionNames,
      };
    default:
      return state;
  }
};

const bankTransactionReduer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_LOADING":
      return {
        ...state,
        loading: true,
        response_msg: "",
      };
    case GET_BANK_TRANS:
      return {
        ...state,
        loading: false,
        banktrans: action.payload.data,
        total: action.payload.total,
        filtering: false,
      };
    case FILTER_BANK_TRANS:
      return {
        ...state,
        loading: false,
        banktrans: action.payload.data,
        total: action.payload.total,
      };
    case SET_REQUERY:
      return {
        ...state,
        data: action.payload.data,
        response_msg: action.payload.response_message,
      };
    case SET_REVERSAL:
      return {
        ...state,
        data: action.payload.data,
        response_msg: action.payload.response_message,
      };
    case GET_BANK_TRANS_STATUS:
      return {
        ...state,
        loading: false,
        banktrans: action.payload.data,
        total: action.payload.total,
        filtering: true,
      };
    default:
      return state;
  }
};

const flutterwaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_FLUTTER:
      return {
        ...state,
        loading: false,
        flwtrans: action.payload.data,
        total: action.payload.total,
        isrange: false,
      };
    case FILTER_FLUTTER:
      return {
        ...state,
        loading: false,
        flwtrans: action.payload.data,
        total: action.payload.total,
      };
    case GET_FLW_RANGE:
      return {
        ...state,
        loading: false,
        flwtrans: action.payload.data,
        total: action.payload.total,
        isrange: true,
      };
    default:
      return state;
  }
};

export const transactionReducer = combineReducers({
  transaction: transaction,
  transcategory: transcategory,
  banktransactions: bankTransactionReduer,
  fluttertransactions: flutterwaveReducer,
  application_institute: instituteReducer,
});
