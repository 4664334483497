import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUsConfig from "./Aboutus.config";
import { Error } from "../../../../Error";

const AboutusRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AboutUsConfig} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default AboutusRoute;
