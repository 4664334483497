import { headers } from "./api.config";

export const flutterwaveRequest = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};

export const filterFlutterwave = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/flw_transactions?from=${data.startDate}&to=${data.endDate}&flw_trans_ref=${data.flw_trans_ref}&amount=${data.amount}&account_number=${data.acct_num}&transaction_status=${data.status}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};

export const filterFlutterRange = async (startDate, endDate, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/flw_transactions?from=${startDate}&to=${endDate}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};
