import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import ValidateDoc from "./ValidateDoc";
import { headers } from "../../../../../API/api.config";
// import KycSettingModal from "./KycSettingModal";
import {
  filterCustomerDocuments,
  seartchUserDocDetails,
  getCustomerDocuments,
  getCustomerDocumentByPage,
} from "../../../../../reduxStore/action/action.creator";
import {
  Loader,
  callBackMethod,
  Pagination,
} from "../../../../../components/Accessories";
import {
  CustomerDocumentHead,
  CustomerDocumentBody,
} from "../../../../../components/Rows/KycTable";

class UploadDocument extends React.Component {
  state = {
    display: false,
    approvedKYC: false,
    userObj: {},
    status: "",
    user_name: "",
    filteredValues: "",
    showDropUserName: false,
    showDropStatus: false,
  };

  filterByType = (e) => {
    // alert(e.target.value);
    callBackMethod(
      this.setState({
        filterby: e.target.value,
      })
    ).then(() => this.runByType(this.state.filterby));
  };

  runByType = (type) => {
    // console.log(type);
    if (type == "approve_kyc") {
      this.setState({
        approvedKYC: true,
        showDropUserName: false,
      });
    } else {
      this.setState({
        approvedKYC: false,
        showDropUserName: true,
      });
    }
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState(
      {
        display: false,
      },
      () => {
        if (this.state.status) {
          this.props.filterCustomerDocuments(
            `user_documents?paginate=yes&filter_by=${this.state.status}&no_of_pages=15`,
            this.props.token
          );
        } else if (this.state.user_name) {
          this.props.seartchUserDocDetails(
            `kyc_upgrade_request?user_name=${this.state.user_name}&paginate=yes&no_of_pages=15`,
            this.props.token
          );
        } else {
          this.props.getCustomerDocuments(this.props.token);
        }
      }
    );
  };

  componentDidMount() {
    this.props.getCustomerDocuments(this.props.token);
  }

  pickAgent = (userId, id, validationType) => {
    this.setState({
      ...this.state,
      display: true,
      obj: {
        docId: id,
        userId,
        validationType,
      },
    });
  };

  accetp_kyc_doc = async (token, obj) => {
    const requestOptions = {
      method: "POST",
      headers: headers(token),
      body: obj,
    };
    fetch("user_document/approve", requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ postId: data.id }));
  };

  handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
        filteredValues: `&filter_by=${e.target.value}`,
        filteredKey: "status",
      })
    ).then(() =>
      this.props.filterCustomerDocuments(
        `user_documents?paginate=yes&filter_by=${this.state.status}&no_of_pages=15`,
        this.props.token
      )
    );
  };

  handleUserNameFilter = async (e) => {
    if (e.target.value.length > 4) {
      callBackMethod(
        this.setState({
          user_name: e.target.value,
          showUsersDetails: true,
        })
      ).then(() => {
        // console.log(this.state.user_name);
        this.props.seartchUserDocDetails(
          `user_documents?user_name=${this.state.user_name}&paginate=yes&no_of_pages=15`,
          this.props.token
        );
      });
    }
  };

  //`${process.env.REACT_APP_BASE3_URL}/user_documents?paginate=yes&no_of_pages=15&page=${page}`,
  changePage = (id) => () => {
    console.log(this.state.filteredValues);
    if (this.state.filteredValues === "") {
      this.props.getCustomerDocumentByPage(id, this.props.token);
    } else {
      this.props.filterCustomerDocuments(
        `/user_documents?paginate=yes${this.state.filteredValues}&no_of_pages=15&page=${id}`,
        this.props.token
      );
    }
    this.setState({
      currentPage: id,
    });
  };

  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.state.filteredValues === "") {
        this.props.getCustomerDocumentByPage(id, this.props.token);
      } else {
        this.props.filterCustomerDocuments(
          `/user_documents?paginate=yes${this.state.filteredValues}&no_of_pages=15&page=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  goNext = () => {
    const total = parseInt(Math.ceil(this.props?.response?.total / 15));
    console.log(this.state.filteredValues);
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.state.filteredValues === "") {
        this.props.getCustomerDocumentByPage(id, this.props.token);
      } else {
        this.props.filterCustomerDocuments(
          `/kyc_upgrade_request?paginate=yes${this.state.filteredValues}&no_of_pages=15&page=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getCustomerDocuments(this.props.token);
  };

  render() {
    let renderPaginate;
    let itemToDisplay;
    const total =
      this.props?.response?.total &&
      parseInt(Math.ceil(this.props?.response?.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props?.response?.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props?.response?.total}
        />
      ));
    if (this.props.loading) {
      itemToDisplay = <Loader loading={this.props.loading} />;
    } else {
      itemToDisplay = (
        <>
          <CustomerDocumentHead />
          <tbody>
            {this.props.response &&
              this.props?.response?.data.map((content, index) => (
                <CustomerDocumentBody
                  index={index + 1}
                  showModal={this.showModal}
                  closeModal={this.closeModal}
                  getObj={this.handleRejectRequest}
                  pickAgent={this.pickAgent}
                  {...content}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <KycBar {...this.props} active="Upload Document">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <div className="col-md-6 text-left">
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  value={this.state.filterby}
                  className="form-control"
                  style={{ width: "20em" }}
                  onChange={this.filterByType}>
                  <option>filter by...</option>
                  <option value="approve_kyc">Approved Kyc</option>
                  <option value="user_name">User Name</option>
                </select>

                <div style={{ paddingLeft: "1em" }}>
                  {this.state.approvedKYC && (
                    <select
                      style={{ width: "15em" }}
                      onChange={this.handleStatusFilter}
                      className="form-control">
                      <option>Filter by...</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Pending">Pending</option>
                    </select>
                  )}
                  {this.state.showDropUserName && (
                    <input
                      style={{ width: "15em" }}
                      onChange={this.handleUserNameFilter}
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      value={this.state.value}
                    />
                  )}
                </div>
                {this.state.showUsersDetails && (
                  <div className="position-relative">
                    <div
                      className="card position-absolute"
                      style={{
                        paddingLeft: "1em",
                        width: "300px",
                        zIndex: "3",
                      }}>
                      <div className="card-body">
                        {this.props.user_search_details &&
                          this.props.user_search_details.data.map(
                            (content, index) => (
                              <ul style={{ listStyle: "none" }}>
                                <a
                                  href="#/"
                                  onClick={(e) =>
                                    this.handleSavingsUserIdFilter(
                                      e,
                                      content.id
                                    )
                                  }
                                  value={content.id}>
                                  {content.first_name} {content.last_name}
                                </a>
                              </ul>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> Upload New Document
            </a> */}
          </div>
          <div className="table-responsive py-4 ">
            <table
              className="table table-flush table-hover"
              id="datatable-basic">
              {itemToDisplay}
            </table>
            {renderPaginate}
          </div>
        </KycBar>
        <ValidateDoc
          display={this.state.display}
          closeModal={this.closeModal}
          setSetting={this.setSetting}
          obj={this.state.obj}
          token={this.props?.token}
          adminId={this.props?.login}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_customer_doc.loading,
  response: state.kyc.get_customer_doc.customer_docs,
  token: state.login.token,
  login: state.login.data,
});

export default connect(mapStateToProps, {
  filterCustomerDocuments,
  seartchUserDocDetails,
  getCustomerDocuments,
  getCustomerDocumentByPage,
})(UploadDocument);
