import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";
import { currencyFormatter } from "../../../../components/Row";

class RateModal extends React.Component {
  state = {
    currency: "",
    buying_rate: "",
    selling_rate: "",
    date_time: new Date().toISOString().substring(0, 10),
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkNumber = (e) => {
    if (+this.state.buying_rate >= 0 && +this.state.selling_rate >= 0) {
      return;
    } else {
      this.setState({
        [e.target.name]: "",
      });
    }
  };

  handleSubmit = () => {
    this.props.setRate(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Card Rate">
        <div className="body-grid">
          <div className="box box9">
            <label>Currency</label>
            <select
              className="form-control"
              name="currency"
              value={this.state.currency}
              onChange={this.handleChange}>
              <option></option>
              {this.props.currency &&
                this.props.currency.map((content) => (
                  <option value={content.currency_code}>
                    {content.currency_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Buying Rate</label>
            <input
              className="form-control"
              name="buying_rate"
              value={this.state.buying_rate}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box9">
            <label>Selling Rate</label>
            <input
              className="form-control"
              name="selling_rate"
              value={this.state.selling_rate}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set card rate
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  currency: state.virtualCards.get_rate.currency,
});
export default connect(mapStateToProps, {})(RateModal);
