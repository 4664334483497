import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/Accessories";

class CategoryTypeModal extends React.Component {
  state = {
    userCategory: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submitCategory(this.state.userCategory);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Category">
        <div className="body-grid">
          <div className="box box9">
            <label>Category Type</label>
            <select
              className="form-control"
              name="userCategory"
              value={this.state.userCategory}
              onChange={this.handleChange}>
              <option></option>
              {this.props.user_cat &&
                this.props.user_cat.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Upgrade Developer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user_cat: state.users.getUserCat.user_cat,
});

export default connect(mapStateToProps, {})(CategoryTypeModal);
