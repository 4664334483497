import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import { headers } from "../../../../../API/api.config";
import { getCustomerDocument } from "../../../../../reduxStore/action/action.creator";
import axios from "axios";

class KycUpgradeRequestModal extends React.Component {
  state = {
    document_type: "",
    name: "",
    description: "",
    system_can_approved: false,
    new_level: "",
    display: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    // console.log(headers(this.props.token));
    this.setState(
      {
        ...this.state,
        comment: this.state.comment,
        new_level: this.state.new_level,
        user_id: this.props.userId,
        current_level: this.props.kycLevel.id,
      },
      async () => {
        const requestOptions = {
          comment: this.state.comment,
          new_level: this.state.new_level,
          user_id: this.props.userId,
          current_level: this.props.kycLevel.id,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE3_URL}/admin/kyc/upgrade_request`,
            requestOptions,
            { headers: headers(this.props.token) }
          );
          const formData = await response.data;
          this.props.closeModal();
        } catch (error) {}
      }
    );
  };

  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Kyc Upgrade Request">
        <div className="body-grid">
          <div className="box box9">
            <label>New Level</label>
            <select
              className="form-control"
              name="new_level"
              onChange={this.handleChange}>
              <option></option>
              {this.props.kycLevels &&
                this.props.kycLevels.map((content) => (
                  <option value={content.id}>{content.level_name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Comment</label>
            <textarea
              className="form-control"
              name="comment"
              value={this.state.comment}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                Submit Kyc Request
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_customer_doc.loading,
  response: state.kyc.get_customer_doc.customer_docs,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getCustomerDocument,
})(KycUpgradeRequestModal);
