import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import InvestDocumentModal from "./InvestDocumentModal";
import {
  getInvestmentDoc,
  getCompany,
  getAdmins,
  uploadDocument,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  InvestmentDocHead,
  InvestmentDocBody,
} from "../../../components/Rows/InvestmentTable";

class InvestDocument extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getInvestmentDoc(
      "/savinvest/investment_company_documents",
      this.props.token
    );
    this.props.getAdmins(this.props.token, 1);

    this.props.getCompany("/savinvest/investment_companies", this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  addCategory = (data) => {};

  addDoc = (data) => {
    callBackMethod(this.props.uploadDocument(data))
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.upload_doc_resp))
      .then(() =>
        this.props.getInvestmentDoc(
          "/savinvest/investment_company_documents",
          this.props.token
        )
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestmentDocHead />
          <tbody>
            {this.props.invest_doc &&
              this.props.invest_doc.map((content, index) => (
                <InvestmentDocBody
                  {...content}
                  index={index + 1}
                  admin_data={this.props.admins}
                  company={this.props.company_data}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="document">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New Document
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <InvestDocumentModal
          display={this.state.display}
          closeModal={this.closeModal}
          addDoc={this.addDoc}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  loading: state.invest.getData.loading,
  invest_doc: state.invest.getData.invest_doc,
  upload_doc_resp: state.invest.setData.upload_doc_resp,
  company_data: state.invest.getData.comp_data,
  admins: state.users.alladmins.adminData,
});

export default connect(mapStateToProps, {
  getInvestmentDoc,
  getCompany,
  getAdmins,
  uploadDocument,
})(InvestDocument);
