import React from "react";
import { connect } from "react-redux";
import "../../../../../morestyles.css";
import AuthorizationTab from "../AuthorizationTab";
import RoleForm from "./RoleForm";
import {
  setAdminRole,
  getAdminRole,
  deletePermission,
  getPermissionMod,
} from "../../../../../reduxStore/action/action.creator";
import RoleEditModal from "./RoleEditModal";

class Role extends React.Component {
  state = { open: false, id: "" };

  componentDidMount() {
    this.props.getAdminRole(this.props.token);
  }

  setRole = async (data) => {
    this.props.setAdminRole(data, this.props.token);
  };

  deleteRole = (url) => () => {
    this.props.deletePermission(url, this.props.token);
    this.props.getAdminRole(this.props.token);
  };

  render() {
    return (
      <AuthorizationTab {...this.props} active="roles-settings">
        <div className="row" style={{ padding: "0 1em" }}>
          <div
            className="col-md-12"
            style={{
              backgroundColor: "#eee",
              borderBottom: "1px solid #aaa",
              paddingLeft: ".5em",
            }}
          ></div>
          <div className="col-md-5">
            <RoleForm submitRole={this.setRole} perms={this.props.perm} />
          </div>
          <div className="col-md-7">
            <div className="table-responsive py-4 table-div">
              <table
                className="table table-striped table-bordered"
                id="datatable-basic"
              >
                <thead className="table-light">
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </thead>
                <tbody>
                  {this.props.data &&
                    this.props.data.map((content, i) => (
                      <tr>
                        <td>{content.id}</td>
                        <td>{content.name}</td>
                        <td>{content.description}</td>
                        <td style={{ textAlign: "center" }}>
                          <i
                            className="fa fa-pencil"
                            onClick={() =>
                              this.setState({
                                open: true,
                                id: i,
                              })
                            }
                          ></i>

                          {/* <i
                            className="fa fa-trash-o"
                            onClick={this.deleteRole(`roles/${content.id}`)}
                          ></i> */}
                        </td>
                      </tr>
                    ))}

                  <RoleEditModal
                    display={this.state.open}
                    closeModal={() => this.setState({ open: false })}
                    data={this.props.data[this.state.id]}
                    perms={this.props.perm}
                    handleSubmit={this.handleSubmit}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthorizationTab>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.roles.setrole.success,
  response: state.roles.setrole.response_msg,
  data: state.roles.getrole.data,
  token: state.login.token,
  perm: state.roles.getpermissionmod.data,
});

export default connect(mapStateToProps, {
  setAdminRole,
  getAdminRole,
  deletePermission,
  getPermissionMod,
})(Role);
