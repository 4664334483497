import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class BillerModal extends React.Component {
  state = {};

  handleSubmit = () => {
    this.props.submit();
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Confirm Switch">
        <div className="body-grid">
          <div className="box box9">
            <div
              className="confirm-div"
              style={{ textAlign: "center", padding: "2em" }}>
              <span>Are you sure you want to switch biller</span>
            </div>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Switch Biller
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(BillerModal);
