import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import MerchantModal from "./MerchantModal";
import EditMerchantModal from "./EditMerchantModal";
import {
  getCompany,
  getInvestRole,
  createMerchant,
  getAllMerchant,
  getAdmins,
  editInvestmentMerchant,
  changeMerchantState,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  MerchantTableBody,
  MerchantTableHead,
} from "../../../components/Rows/InvestmentTable";

class MerchantConfig extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getCompany("/savinvest/investment_companies", this.props.token);
    this.props.getInvestRole("/savinvest/roles", this.props.token);
    this.props.getAllMerchant("/savinvest/merchant_users", this.props.token);
    this.props.getAdmins(this.props.token, 1);
    this.props.getInvestRole("/savinvest/roles", this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      merchant_edit: false,
    });
  };

  addMerchant = (data) => {
    callBackMethod(
      this.props.createMerchant(
        "/savinvest/merchant_users",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => {
        if (this.props.merchant_resp) {
          alert("successfully added merchant");
        }
      });
  };

  editMerchant = (props) => {
    this.setState({
      merchant_edit: true,
      merchant_info: props,
    });
  };

  updateMerchant = (data) => {
    this.props.editInvestmentMerchant(
      `/savinvest/merchant_users/${this.state.merchant_info.id}`,
      data,
      this.props.token,
      "PUT"
    );
  };

  viewMerchant = (data) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${data.id}/details`,
      state: data.id,
    });
  };

  handleChangeStatus = (props) => {
    callBackMethod(
      this.props.changeMerchantState(
        `/savinvest/lock_unlock/merchant_users/`,
        {
          action: props.enabled == true ? "lock" : "un-lock",
          merchant_id: props.id,
          admin_id: this.props.admin_data.id,
        },
        this.props.token
      )
    )
      .then(() => alert(this.props.merchant_status_resp))
      .then(() =>
        this.props.getAllMerchant("/savinvest/merchant_users", this.props.token)
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <MerchantTableHead />
          <tbody>
            {this.props.merchant_data &&
              this.props.merchant_data.map((content, index) => (
                <MerchantTableBody
                  {...content}
                  index={index + 1}
                  admin_data={this.props.admins}
                  company_data={this.props.company_data}
                  role_data={this.props.role_data}
                  editMerchant={this.editMerchant}
                  viewMerchant={this.viewMerchant}
                  toggleHandler={this.handleChangeStatus}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <InvestmentBar active="merchant">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <MerchantModal
          display={this.state.display}
          closeModal={this.closeModal}
          addMerchant={this.addMerchant}
        />
        <EditMerchantModal
          display={this.state.merchant_edit}
          closeModal={this.closeModal}
          merchantData={this.state.merchant_info}
          edit_merchant={this.updateMerchant}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  company_data: state.invest.getData.comp_data,
  merchant_resp: state.invest.setData.merchant_resp,
  merchant_data: state.invest.getData.merchant_data,
  loading: state.invest.getData.loading,
  admins: state.users.alladmins.adminData,
  admin_data: state.login.data,
  role_data: state.invest.getData.role_data,
  merchant_status_resp: state.invest.setData.merchant_status_resp,
});

export default connect(mapStateToProps, {
  getCompany,
  getInvestRole,
  createMerchant,
  getAllMerchant,
  getAdmins,
  editInvestmentMerchant,
  changeMerchantState,
})(MerchantConfig);
