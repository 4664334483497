import React from "react";
import { Modal } from "../../../../../components/Accessories";

class AddCode extends React.Component {
  state = {
    name: "",
    description: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        modalTitle="Add Code Config"
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Save Code Config
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddCode;
