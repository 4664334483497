import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { TableHead, Row } from "../../components/Rows";
import {
  getUsers,
  detailsInfo,
  tabDetail,
  filterUserTable,
  enableLock,
  disableLock,
  filterAllUsers,
  logOut,
  getAgents,
  AssignAgent,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import {
  callBackMethod,
  Loader,
  Modal,
  Pagination,
} from "../../components/Accessories";
import UserFilterModal from "./UserFilterModal";
import AgentModal, { ConfirmationModal } from "./AgentModal";

class AllUsers extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.response_msg && !this.props.loading) {
      // alert(nextProps.response_msg);
      this.props.getUsers(
        this.props.token,
        `user/users-all?page=${this.state.currentPage}`
      );
      // alert(JSON.stringify(nextProps.history));
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.response_msg && !prevProps.history) {
  //     this.props.getUsers(
  //       this.props.token,
  //       `user/users-all?page=${this.state.currentPage}`
  //     );
  //   }
  // }

  isAccessible = (key) => {
    const check =
      this.props.roles &&
      this.props.roles?.data.filter((res) => {
        return res?.permissions.find((pap) => pap.name === key);
      });
    return check.length === 0 ? false : true;
  };

  componentDidMount() {
    // console.log(this.props.login);
    this.props.getUsers(this.props.token, `user/users-all`);
    this.props.getAgents("user/sales_agents", this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
    } else {
      this.props.filterUserTable(this.state.filterbyItem, id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleNextPage = (props) => {
    const { id, index } = props;
    // this.props.detailsInfo(`user/users-profile/${index}`);
    // this.props.tabDetail(`transaction/get_history/${id}`);

    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/details`,
      state: id,
    });
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  _searchField = async (item) => {
    // alert(item);
    this.props.filterUserTable(item, 1, this.props.token);
    this.setState({
      ...this.state,
      filterbyItem: item,
    });
  };

  triggerLock = (id, lock_status) => {
    console.log(id);
    console.log(lock_status);
    this.setState({
      ...this.state,
      lock_modal: true,
      lock_status: lock_status,
      userId: id,
    });
  };

  toggleHandler = () => {
    if (this.state.lock_status == true && !this.props.loading) {
      callBackMethod(
        this.props.enableLock(
          `enable/users/${this.state.userId}`,
          this.props.token
        )
      )
        .then(() => alert(this.props.response_msg))
        .then(() =>
          this.setState({ show_confirm_modal: false, lock_modal: false })
        );
    } else {
      callBackMethod(
        this.props.disableLock(
          `disable/user/${this.state.userId}`,
          this.props.token
        )
      )
        .then(() => alert(this.props.response_msg))
        .then(() =>
          this.setState({ show_confirm_modal: false, lock_modal: false })
        );
    }
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      agent_modal: false,
      lock_modal: false,
      show_confirm_modal: false,
    });
  };

  onSubmit = (data) => {
    this.props.filterAllUsers(data, this.props.token);
  };

  exportCSV = (event, done) => {
    const report = {
      filename: "Users Data.csv",
      data: this.props.users,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  pickAgent = (id) => {
    console.log(id);
    this.setState({
      ...this.state,
      agent_modal: true,
      userId: id,
    });
  };

  showModalConfirm = ({ agent_id }) => {
    this.setState({
      ...this.state,
      show_confirm_modal: true,
      agent_id,
    });
  };

  assignAgent = () => {
    callBackMethod(
      this.props.AssignAgent(
        `user/claim_user/sales_agents`,
        {
          sales_agent_id: this.state.agent_id,
          user_id: this.state.userId,
        },
        this.props.token
      )
    )
      .then(() => alert(this.props.add_agent_response.Response_message))
      .then(() =>
        this.setState({ show_confirm_modal: false, agent_modal: false })
      );
  };

  render() {
    let renderPaginate;
    let itemInTable;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TableHead
            canLock={this.isAccessible("Lock Customer")}
            canView={this.isAccessible("View a customer Detail")}
          />
          <tbody>
            {this.props.users &&
              this.props.users.map((user, index) => (
                <Row
                  {...user}
                  goToDetails={this.handleNextPage}
                  index={this.getIndex(index + 1)}
                  toggleHandler={this.triggerLock}
                  pickAgent={this.pickAgent}
                  canLock={this.isAccessible("Lock Customer")}
                  canView={this.isAccessible("View a customer Detail")}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <div>
          <SideBar />

          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Users"
              currentPage={`user/users-all?page=${this.state.currentPage}`}
              getInfo={this.props.getUsers}
              search={this._searchField}
              shouldFilter={true}
              showModal={this.showModal}
              csvreport={this.state.csvReport}
              downloadCSV={this.exportCSV}
              iscsv={true}
            />
            {this.isAccessible("View all Customers") ? (
              <>
                {" "}
                <div class="container-fluid mt--6">
                  <div class="row">
                    <div class="col">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="mb-0">All Customers</h3>
                        </div>
                        <div className="table-responsive py-4">
                          <table
                            className="table table-flush"
                            id="datatable-basic">
                            {itemInTable}
                          </table>
                          {renderPaginate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <UserFilterModal
                  display={this.state.display}
                  closeModal={this.closeModal}
                  submit={this.onSubmit}
                />
                <AgentModal
                  display={this.state.agent_modal}
                  closeModal={this.closeModal}
                  submit={this.showModalConfirm}
                />
                <ConfirmationModal
                  display={this.state.lock_modal}
                  closeModal={this.closeModal}
                  action={this.toggleHandler}
                  title={
                    this.state.lock_status === "1" ? "Unlock user" : "Lock User"
                  }
                  actionText="Yes, proceed"
                />
                <ConfirmationModal
                  display={this.state.show_confirm_modal}
                  closeModal={this.closeModal}
                  action={this.assignAgent}
                  title="Assign User to Agent"
                  actionText="Assign"
                />
              </>
            ) : (
              <h2 className="text-center text-bolder">
                You do not have access to this page
              </h2>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.allusers.loading,
  users: state.users.allusers.usersData,
  total: state.users.allusers.total,
  err: state.users.allusers.failed,
  token: state.login.token,
  login: state.login.data,
  response_msg: state.users.allusers.response_msg,
  filtering: state.users.allusers.filtering,
  add_agent_response: state.users.allusers.add_agent_response,
  roles: state.roles.getrole,
});
export default connect(mapStateToProps, {
  getUsers,
  detailsInfo,
  tabDetail,
  filterUserTable,
  enableLock,
  disableLock,
  filterAllUsers,
  logOut,
  getAgents,
  AssignAgent,
})(AllUsers);

// <>
//   <div>
//     <SideBar />

//     {this.isAccessible("View all Customers") ? (
//       <div>
//         <div class="main-content" id="panel">
//           <DashboardNavBar
//             submenu="All Users"
//             currentPage={`user/users-all?page=${this.state.currentPage}`}
//             getInfo={this.props.getUsers}
//             search={this._searchField}
//             shouldFilter={true}
//             showModal={this.showModal}
//             csvreport={this.state.csvReport}
//             downloadCSV={this.exportCSV}
//             iscsv={true}
//           />
//           <div class="container-fluid mt--6">
//             <div class="row">
//               <div class="col">
//                 <div class="card">
//                   <div class="card-header">
//                     <h3 class="mb-0">All Customers</h3>
//                   </div>
//                   <div className="table-responsive py-4">
//                     {/* <table
//                       className="table table-flush"
//                       id="datatable-basic"
//                     >
//                       {itemInTable}
//                     </table>
//                     {renderPaginate} */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <UserFilterModal
//           display={this.state.display}
//           closeModal={this.closeModal}
//           submit={this.onSubmit}
//         />
//         <AgentModal
//           display={this.state.agent_modal}
//           closeModal={this.closeModal}
//           submit={this.showModalConfirm}
//         />
//         <ConfirmationModal
//           display={this.state.lock_modal}
//           closeModal={this.closeModal}
//           action={this.toggleHandler}
//           title={
//             this.state.lock_status === "1" ? "Unlock user" : "Lock User"
//           }
//           actionText="Yes, proceed"
//         />
//         <ConfirmationModal
//           display={this.state.show_confirm_modal}
//           closeModal={this.closeModal}
//           action={this.assignAgent}
//           title="Assign User to Agent"
//           actionText="Assign"
//         />
//       </div>
//     ) : (
//       <h2 className="text-center text-bolder">
//         You do not have access to this page
//       </h2>
//     )}
//   </div>
// </>
