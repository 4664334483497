import React, { createRef, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  List,
  UserTransactionList,
  UserTransactionServiceList,
  UserStatement,
} from "../../components/Rows";
import {
  VirtualCardBody,
  VirtualCardHead,
} from "../../components/Rows/CardTable";
import {
  UserEarnPointBody,
  UserEarnPointHead,
  UserInterestBody,
  UserInterestHead,
} from "../../components/Rows/IncentiveTable";
import {
  UserSavingDataHead,
  UserSavingDataBody,
} from "../../components/Rows/SavingTable";
import { callBackMethod, Modal, Loader } from "../../components/Accessories";
import {
  detailsInfo,
  tabDetail,
  newTabDetail,
  accountBalance,
  accountStatement,
  loadHistory,
  getKycDocuments,
  getKycLevel,
  uploadUserDocument,
  addUserBvn,
  getUserSavingsData,
  getSavingWallet,
  getUserEarnedPoint,
  getUserInterestHistory,
  userDetailsInfo,
  getCustomerDocument,
} from "../../reduxStore/action/action.creator";
import {
  LoginHistoryHead,
  LoginHistoryBody,
  UserDocumentHead,
  UserDocumentBody,
} from "../../components/Rows/UserTable";

import { KycUpgradeBody, KycUpgradeHead } from "../../components/Rows/KycTable";

import {
  CustomerDocumentBody,
  CustomerDocumentHead,
} from "../../components/Rows/KycTable";

import ValidateDoc from "../Settings/SettingsPage/KycSettings/UploadDocument/ValidateDoc";

import KycUpgradeRequestModal from "../Settings/SettingsPage/KycSettings/KycUpgrade/KycUpgradeRequestModal";
import UploadDocumentModal from "../Settings/SettingsPage/KycSettings/UploadDocument/UploadDocumentModal";
import KycUpgradeModal from "../Settings/SettingsPage/KycSettings/KycUpgrade/KycUpgradeModal";
import { headers } from "../../API/api.config";

class AllUsersDetails extends React.Component {
  state = {
    key: "kyc",
    active: "",
    display: false,
    currentPage: 1,
    total: 0,
    filterArray: [],
    begin: "2020-01-01",
    stop: "2020-11-05",
    documentFile: null,
    file: null,
    doc_name: "",
    doc_num: "",
    link_bvn: false,
    bvn: "",
    code_box: false,
    code: "",
    isTabActive: [],
    displayDoc: false,
    displayDocView: false,
    displayKycView: false,
  };
  fileRef = createRef();

  // componentDidUpdate(prevState, prevProp) {
  //   if (prevState.link_bvn !== this.state.link_bvn) {
  //     this.linkBVN();
  //   }
  // }

  showDocModal = (e, user_id) => {
    // console.log(user_id);
    e.preventDefault();
    this.setState({
      displayDocModal: true,
    });
  };

  closeDocModal = () => {
    this.setState({
      displayDocModal: false,
    });
  };

  showModalDoc = (e) => {
    e.preventDefault();
    this.setState({
      displayDocView: true,
    });
  };

  showKycModal = (e, kyc_user_id, kyc_id, kyc_user, kyc_status, others) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        displayKycView: true,
        kyclevelData: {new_level: others.new_level, current_level: others.current_level},
        kyc_user_id,
        kyc_id,
        kyc_user,
        kyc_status,
      },
      async () => {
        await this.props.getCustomerDocument(
          this.props.token,
          this.state.kyc_user_id
        );
        const response = await axios.get(
          `${process.env.REACT_APP_BASE3_URL}/user_documents/count?user_id=${this.state.kyc_user_id}&filter_by=Pending`
        );
        const { data } = await response.data;
        this.setState({ ...this.state, numOfPending: data.data });
        // console.log(data.data);
      }
    );
  };

  closeKycModal = () => {
    this.setState({
      displayKycView: false,
    });
  };

  closeModalDoc = () => {
    // console.log("close modal");
    this.setState(
      {
        displayDocView: false,
      },
      () => this.getUserDocuments(this.props.token)
    );
  };

  pickAgent = (userId, id, validationType) => {
    // console.log(userId);
    this.setState({
      ...this.state,
      displayDocView: true,
      obj: {
        docId: id,
        userId,
        validationType,
      },
    });
  };

  accetp_kyc_doc = async (token, obj) => {
    const requestOptions = {
      method: "POST",
      headers: headers(token),
      body: obj,
    };
    fetch("user_document/approve", requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ postId: data.id }));
  };

  /*
  
  {
    "user_id" : 1,
    "current_level" : 2,
    "new_level" : 3,
    "comment" : "I would like to upgrade my GetriPay plan"
}
  */

  showUpgradeModal = (e, user_id) => {
    // console.log(user_id);
    e.preventDefault();
    this.setState({
      displayUpgradeModal: true,
    });
  };

  closeUpgradeModal = () => {
    this.setState({
      displayUpgradeModal: false,
    });
  };
  // console.log("welcome to hello world1");
  //http://localhost:8001/api/v2/user_documents?user_id=1
  getUserDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE3_URL}/user_documents?user_id=${this.props.details.id}`
      );
      const { data } = await response.data;
      // console.log({ "welcome to hello world": data });
      this.setState({ ...this.state, user_documents: data });
    } catch (error) {
      console.log(error);
    }
  };

  getUserKycUpgrade = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE3_URL}/kyc_upgrade_request?user_id=${this.props.details.id}`
      );
      const { data } = await response.data;
      // console.log({ "welcome to hello world": data });
      this.setState({ ...this.state, user_kyc_upgrade: data });
    } catch (error) {
      console.log(error);
    }
  };

  getDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE3_URL}/kyc/documents?kyc_level=4&user_id=${this.props.details.id}`
      );
      const { data } = await response.data;
      // console.log(data);
      this.setState({ ...this.state, required_documents: data });
    } catch (error) {
      console.log(error);
    }
  };

  //http://localhost:8000/api/v2/kyc/documents?kyc_level=3&user_id=1

  componentDidMount() {
    // console.log(this.props.login);
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("s/") + 2, index.indexOf("/d"));
    this.setState({
      ...this.state,
      userId: index,
    });

    // this.props.detailsInfo(`user/users-profile/${index}`, this.props.token);

    // this.props.userDetailsInfo(`user/users-profile/${index}`, this.props.token);
    // console.log("token", this.props.token);
    callBackMethod(
      this.props.detailsInfo(
        `user/users-profile/${index}`,
        false,
        this.props.token
      )
    ).then(() => {
      this.getDocuments();
      this.getUserDocuments();
      this.getUserKycUpgrade();
      if (
        this.props?.details?.bank_account.length > 0 &&
        this.props?.details?.bank_account[0]?.account_number
      ) {
        this.props.accountBalance(
          this.props?.details?.bank_account[0]?.account_number,
          this.props.token
        );
        this.props.getUserInterestHistory(
          `interest/earnings/user/${this.props?.details?.bank_account[0]?.account_number}`,
          this.props.token
        );
      }
    });

    // this.props.detailsInfo();
    this.props.tabDetail(
      `transaction/get_history/${this.props?.history?.location.state}`,
      this.props.token
    );

    this.props.getKycDocuments(this.props.token);
    this.props.getKycLevel(this.props.token);
    this.props.getUserSavingsData(index, this.props.token);
    this.props.getSavingWallet(this.props.token);
    this.props.getUserEarnedPoint(
      `earned/user/actions/${index}`,
      this.props.token
    );

    // let activeTab = [];
    // activeTab[1] = true;
    // this.setState({
    //   isTabActive: activeTab,
    // });
    this.changeTabActive(1);
  }

  changeTabActive = (id) => {
    let activeTab = [];
    activeTab[id] = true;
    this.setState((prevState) => ({
      isTabActive: activeTab,
    }));
  };

  loadBalance = () => {
    if (
      this.props?.details?.bank_account.length > 0 &&
      this.props?.details?.bank_account[0]?.account_number
    ) {
      this.props.accountBalance(
        this.props?.details?.bank_account[0]?.account_number,
        this.props.token
      );
    }
  };

  loadStatement = () => {
    if (this.props?.details?.bank_account.length > 0) {
      let acc = this.props?.details?.bank_account[0]?.account_number;

      this.props.accountStatement(
        this.state.begin,
        this.state.stop,
        acc,
        this.props.token
      );
    }
  };

  // pagination controls ends...

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  getModal = () => {
    this.setState({
      display: true,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getDetails = (url) => () => {
    this.props.tabDetail(url, this.props.token);
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));

    this.props.newTabDetail(
      `transaction/get_history/${this.props.history.location.state}?startDate=${start}&endDate=${end}`,
      this.props.token
    );
  };

  handleRangeStatement = (event, picker) => {
    let begin = new Date(picker.startDate).toISOString();
    let stop = new Date(picker.endDate).toISOString();

    begin = begin.substring(0, begin.indexOf("T"));
    stop = stop.substring(0, stop.indexOf("T"));

    let acc = this.props.details.data.bank_account[0].account_number;
    this.props.accountStatement(begin, stop, acc, this.props.token);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFileChange = (e) => {
    this.setState({
      documentFile: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  uploadDocument = () => {
    const kyc_level = this.props.details.data.kyc_level;
    const user_id = this.props.details.data.id;
    let kyc_name;
    this.props.levels.map((content) => {
      if (content.id == kyc_level) {
        kyc_name = content.level_name;
      }
    });
    const formData = new FormData();
    formData.append("images", this.state.documentFile);
    formData.append("user_id", user_id);
    formData.append("kyc_level_name", kyc_name);
    formData.append("kyc_level_id", kyc_level);
    formData.append("name", this.state.doc_name);
    formData.append("identification_number", this.state.doc_num);
    formData.append("type", "document");

    const size = 1024 * 1024;
    if (this.state.documentFile.size > size) {
      alert("file is too large!!😣");
    } else {
      callBackMethod(
        this.props.uploadUserDocument(formData, this.props.token)
      ).then(() =>
        alert(JSON.stringify(this.props.set_doc_response.response_message))
      );
    }
  };

  linkBVN = () => {
    this.setState({
      link_bvn: !this.state.link_bvn,
    });
  };

  submitBVN = () => {
    const bvn_data = {
      user_id: this.props.details.data.id,
      bvn_number: this.state.bvn,
    };

    callBackMethod(this.props.addUserBvn(bvn_data, this.props.token))
      .then(() => alert(this.props.bvn_response.response_message))
      .then(() => this.setState({ code_box: true }));
  };

  submitCode = () => {
    const code_data = {
      user_id: this.props.details.data.id,
      bvn_number: this.state.bvn,
      otp_code: this.state.code,
      trans_ref: "",
    };
  };

  render() {
    let itemToRender;
    let itemInBody;

    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("s/") + 2, index.indexOf("/d"));

    if (this.props.doc_load) {
      itemInBody = "loading...";
    } else {
      itemInBody = (
        <>
          {this.props.kyc_docs &&
            this.props.kyc_docs.map((content) => {
              if (content.user_id == index) {
                return <UserDocumentBody {...content} />;
              }
            })}
        </>
      );
    }

    if (this.props.loading) {
      itemToRender = <Loader loading={this.props.loading} />;
    } else {
      const pic = this.props.details ? this.props.details.profile_photo : "";
      const regex = /^https/gm;

      const completeUrl = regex.test(pic) ? pic : `${process.env.REACT_APP_DOCUMENT_BASE_URL}${pic}`;
      // console.log(this.props.details);
      itemToRender = (
        <>
          {this.props.details && (
            <>
              <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
                <div class="container-fluid">
                  <div class="header-body">
                    <div class="row align-items-center py-4">
                      <div
                        className="col-lg-12 col-7"
                        style={{ paddingBottom: "1em" }}>
                        <span
                          onClick={this.goBack}
                          style={{ cursor: "pointer" }}>
                          <span
                            style={{
                              padding: "0 .5em 0 0",
                              textAlign: "center",
                            }}>
                            <i
                              class="fas fa-chevron-left"
                              style={{
                                color: "#fff",
                                padding: "0.3em .5em",
                                border: "2px solid #fff",
                                borderRadius: "50%",
                                fontSize: "12px",
                              }}></i>
                          </span>
                          <span style={{ color: "#fff" }}>Back</span>
                        </span>
                      </div>
                      <div class="col-lg-6 col-7">
                        <div className="col-lg-12 col-12">
                          <div className="wrap">
                            <div className="row">
                              <div className="col-md-3">
                                {this.props.details === null ? (
                                  <img
                                    alt="Image placeholder"
                                    src="../../assets/img/theme/team-4.jpg"
                                    class="avatar rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={completeUrl}
                                    alt="profile-photo"
                                    width="100"
                                    height="100"
                                    style={{
                                      borderRadius: "100%",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="update-partner-btn col-md-5">
                                <button onClick={this.getModal}>
                                  <i className="fa fa-edit"></i>
                                  Update Customer
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-12">
                          <div className="wrap" style={{ paddingTop: ".5em" }}>
                            <div className="row">
                              <div className="trans-email col-md-12">
                                <div>{this.props.details.email}</div>
                              </div>
                              <div className="trans-name col-md-12">
                                <div>{this.props.details.username}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="align-items-end d-flex">
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          onClick={this.showUpgradeModal}>
                          <i class="fas fa-plus"></i> KYC Upgrade Request
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-neutral"
                          onClick={this.showDocModal}>
                          <i class="fas fa-plus"></i> Upload New Document
                        </a>
                      </div>
                      <UploadDocumentModal
                        display={this.state.displayDocModal}
                        closeModal={this.closeDocModal}
                        setSetting={this.setSetting}
                        userId={this.props.details.id}
                        kycLevels={this.props.levels}
                        kycLevel={this.props.details?.kyc_level}
                        required_documents={this.state.required_documents}
                        token={this.props.token}
                      />
                      <KycUpgradeRequestModal
                        display={this.state.displayUpgradeModal}
                        closeModal={this.closeUpgradeModal}
                        setSetting={this.setSetting}
                        userId={this.props.details.id}
                        kycLevels={this.props.levels}
                        kycLevel={this.props.details?.kyc_level}
                        token={this.props.token}
                      />
                      <ValidateDoc
                        display={this.state.displayDoc}
                        closeModal={this.closeModalDoc}
                        setSetting={this.setSetting}
                        obj={this.state.obj}
                        token={this.props.token}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid mt--5">
                <div
                  class="row"
                  style={{ display: "flex", borderRadius: "2em" }}>
                  <div class="col-5 userDetailsCol1">
                    <table class="table table-hover mt-5">
                      <tbody>
                        <List
                          {...this.props.details}
                          balance={
                            this.props.bal != undefined
                              ? this.props.bal
                              : "0.00"
                          }
                        />
                      </tbody>
                    </table>
                  </div>
                  <div class="col-7 userDetailsCol2">
                    <div class="tab" role="tabpanel">
                      <div
                        class="card-header"
                        style={{
                          margin: "0px",
                          backgroundColor: "lavenderblush",
                          borderBottom: "0px",
                        }}>
                        <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[1] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(1)}>
                            <a
                              href="#Section1"
                              aria-controls="wallets"
                              role="tab"
                              onClick={this.loadBalance}
                              data-toggle="tab">
                              <i class="fa fa-dollar"> &nbsp; </i>Balances
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[2] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(2)}>
                            <a
                              href="#Section2"
                              aria-controls="transactions"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-credit-card"> &nbsp; </i>
                              Transactions
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[3] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(3)}>
                            <a
                              href="#Section3"
                              aria-controls="statement"
                              role="tab"
                              onClick={this.loadStatement}
                              data-toggle="tab">
                              <i class="fa fa-users"> &nbsp; </i>Account
                              Statement
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[4] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(4)}>
                            <a
                              href="#Section4"
                              aria-controls="services"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-briefcase"> &nbsp; </i>Services
                              Used
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[5] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(5)}>
                            <a
                              href="#Section5"
                              aria-controls="kyc"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-user"> &nbsp; </i>KYC
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[6] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(6)}>
                            <a
                              href="#Section6"
                              aria-controls="referrals"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-users"> &nbsp; </i>Referrals
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[7] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(7)}>
                            <a
                              href="#Section7"
                              aria-controls="history"
                              role="tab"
                              onClick={() =>
                                this.props.loadHistory(
                                  this.props.details.id,
                                  this.props.token
                                )
                              }
                              data-toggle="tab">
                              <i className="">&nbsp; </i>Login History
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[8] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(8)}>
                            <a
                              href="#Section8"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>User Document
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[9] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(9)}>
                            <a
                              href="#Section9"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>User Virtual Card
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[10] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(10)}>
                            <a
                              href="#Section10"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>User Saving
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[11] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(11)}>
                            <a
                              href="#Section11"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>Earned Reward
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[12] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(12)}>
                            <a
                              href="#Section12"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>Interest History
                            </a>
                          </li>
                          <li
                            role="presentation"
                            className={
                              this.state.isTabActive[13] ? "active" : ""
                            }
                            onClick={() => this.changeTabActive(13)}>
                            <a
                              href="#Section13"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>KYC Upgrade Request
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content tabs">
                        <div
                          role="tabpanel"
                          class="tab-pane active"
                          id="Section1">
                          <h3>Balances</h3>
                          <table class="table table-bordered table-hover tableClass">
                            <thead>
                              <tr class="table-primary">
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  S/N
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Account Number
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Availbale Balance
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Ledger Balance
                                </th>
                              </tr>
                            </thead>
                            {this.props.bal && (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>{this.props.bal.data.account_number}</td>
                                  <td>
                                    {this.props.bal.data.available_balance}
                                  </td>
                                  <td>{this.props.bal.data.book_balance}</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section2">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.tabdetail && !this.props.loading && (
                              <UserTransactionList
                                {...this.props.tabdetail}
                                range={this.handleRange}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section3">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.statementDetail &&
                              !this.props.loading && (
                                <UserStatement
                                  {...this.props.statementDetail}
                                  range={this.handleRangeStatement}
                                />
                              )}
                          </div>
                        </div>
                        {/* <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section4">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.tabdetail && !this.props.loading && (
                              <UserTransactionServiceList
                                {...this.props.tabdetail}
                              />
                            )}
                          </div>
                        </div> */}
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section5">
                          <div class="card">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Fields{" "}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  BVN :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.details.bvn
                                    ? this.props.details.bvn
                                    : "User has no bvn"}
                                  {!this.props.details.bvn && (
                                    <>
                                      <span>
                                        <button
                                          className="text-primary"
                                          onClick={this.linkBVN}>
                                          Link this user BVN
                                        </button>
                                      </span>
                                      <div
                                        className="py-2"
                                        style={{
                                          display:
                                            this.state.link_bvn &&
                                              !this.state.code_box
                                              ? "block"
                                              : "none",
                                        }}>
                                        <input
                                          className="form-control"
                                          style={{ width: "50%" }}
                                          placeholder="enter BVN here"
                                          name="bvn"
                                          value={this.state.bvn}
                                          onChange={this.handleChange}
                                        />
                                        <button
                                          className="btn btn-primary my-2"
                                          onClick={this.submitBVN}>
                                          Link BVN
                                        </button>
                                      </div>
                                      <div
                                        className="py-2"
                                        style={{
                                          display: this.state.code_box
                                            ? "block"
                                            : "none",
                                        }}>
                                        <input
                                          className="form-control"
                                          style={{ width: "50%" }}
                                          placeholder="enter code that was sent to you"
                                          name="code"
                                          value={this.state.code}
                                          onChange={this.handleChange}
                                        />
                                        <button
                                          className="btn btn-primary my-2"
                                          onClick={this.submitCode}>
                                          Validate BVN
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Level{" "}
                                </span>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.details.kyc_level?.level_name
                                    ? this.props.details.kyc_level.level_name
                                    : 0}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Document{" "}
                                </span>
                                <br />
                                <span style={{ fontSize: "11px" }}>N/A</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section6">
                          <h3>Referrals</h3>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section7">
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <LoginHistoryHead />
                            <tbody>
                              {this.props.login_history &&
                                this.props.login_history.map((content) => (
                                  <LoginHistoryBody {...content} />
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section8">
                          {/* <div className="upload py-2">
                            <input
                              type="file"
                              placeholder="upload document"
                              ref={this.fileRef}
                              onChange={this.handleFileChange}
                              hidden
                            />
                            <button
                              className="btn btn-primary"
                              onClick={() => this.fileRef.current.click()}>
                              select document
                            </button>
                            <span>
                              {this.state.documentFile != null &&
                                this.state.documentFile.name}
                            </span>
                            {this.state.documentFile && (
                              <div className="preview py-4">
                                <img
                                  src={this.state.file}
                                  width="100%"
                                  height="100%"
                                />
                                <select
                                  value={this.state.doc_name}
                                  name="doc_name"
                                  className="form-control my-2"
                                  onChange={this.handleChange}>
                                  <option>Select Your Card</option>
                                  <option value="voters card">
                                    Voters Card
                                  </option>
                                  <option value="national id card">
                                    National Id Card
                                  </option>
                                  <option value="international passport">
                                    International Passport
                                  </option>
                                  <option value="drivers licence">
                                    Drivers Licence
                                  </option>
                                </select>
                                <input
                                  className="form-control"
                                  name="doc_num"
                                  placeholder="Enter Identification Number"
                                  value={this.state.doc_num}
                                  onChange={this.handleChange}
                                />
                                <button
                                  className="btn btn-primary my-2"
                                  onClick={this.uploadDocument}>
                                  Upload Document
                                </button>
                              </div>
                            )}
                          </div> */}
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <CustomerDocumentHead />
                            <tbody>
                              {this.state.user_documents &&
                                this.state?.user_documents.map(
                                  (content, index) => (
                                    <CustomerDocumentBody
                                      index={index + 1}
                                      // showModal={this.showModalDoc}
                                      // closeModal={this.closeModalDoc}
                                      // getObj={this.handleRejectRequest}
                                      pickAgent={this.pickAgent}
                                      {...content}
                                    />
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                        <KycUpgradeModal
                          display={this.state.displayKycView}
                          closeModal={this.closeKycModal}
                          setSetting={this.setSetting}
                          userId={this.state.kyc_user_id}
                          token={this.props.token}
                          document={this.state.user_documents}
                          request_id={this.state.kyc_id}
                          user={this.state.kyc_user}
                          numOfPending={this.state.numOfPending}
                          status={this.state.kyc_status}
                          kyclevelData={this.state.kyclevelData}
                        />
                        <ValidateDoc
                          display={this.state.displayDocView}
                          closeModal={this.closeModalDoc}
                          setSetting={this.setSetting}
                          obj={this.state.obj}
                          token={this.props.token}
                        />
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section9">
                          {/* <table className="table table-bordered table-hover tableClass table-responsive">
                            <VirtualCardHead userdetails={true} />
                            <tbody>
                              {this.props.virtual_cards &&
                                this.props.virtual_cards.map(
                                  (content, itemIndex) => {
                                    if (content.user_id == index) {
                                      return (
                                        <VirtualCardBody
                                          {...content}
                                          userdetails={true}
                                          index={itemIndex + 1}
                                        />
                                      );
                                    }
                                  }
                                )}
                            </tbody>
                          </table> */}
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section10">
                          {/* <table className="table table-bordered table-hover tableClass table-responsive">
                            <UserSavingDataHead />
                            <tbody>
                              {this.props.a_user_savings &&
                                this.props.a_user_savings.map(
                                  (content, index) => (
                                    <UserSavingDataBody
                                      {...content}
                                      index={index + 1}
                                      userId={this.state.userId}
                                      wallet={this.props.savings_wallet}
                                    />
                                  )
                                )}
                            </tbody>
                          </table> */}
                        </div>
                        {/* <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section11">
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <UserEarnPointHead />
                            <tbody>
                              {this.props.user_earned_point &&
                                this.props.user_earned_point.map(
                                  (content, index) => (
                                    <UserEarnPointBody
                                      {...content}
                                      index={index + 1}
                                    />
                                  )
                                )}
                            </tbody>
                          </table>
                        </div> */}
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section12">
                          {/* <table className="table table-bordered table-hover tableClass table-responsive">
                            <UserInterestHead />
                            <tbody>
                              {this.props.user_interest_history && (
                                <UserInterestBody
                                  {...this.props.user_interest_history}
                                  index="1"
                                />
                              )}
                            </tbody>
                          </table> */}
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section13">
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <KycUpgradeHead />
                            <tbody>
                              {this.state.user_kyc_upgrade &&
                                this.state?.user_kyc_upgrade.map(
                                  (content, index) => (
                                    <KycUpgradeBody
                                      index={index + 1}
                                      showModal={(
                                        e,
                                        user_id,
                                        id,
                                        user,
                                        status,
                                        others
                                      ) =>
                                        this.showKycModal(
                                          e,
                                          user_id,
                                          id,
                                          user,
                                          status,
                                          others
                                        )
                                      }
                                      closeModal={this.closeKycModal}
                                      // getObj={this.handleRejectRequest}
                                      // pickAgent={this.pickAgent}
                                      {...content}
                                    />
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <div>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            {itemToRender}
            {/* {this.props.details && JSON.stringify(this.props.details.data)} */}
          </div>
        </div>
        <Modal
          closeModal={this.closeModal}
          modalTitle="something"
          display={this.state.display ? "block" : "none"}></Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: state.details.userdetailinfo,
  details: state.details.user_detailinfo,
  loading: state.details.loading,
  doc_load: state.kyc.kyc_doc.loading,
  tabdetail: state.details.tabdetail,
  bal: state.details.bal,
  statementDetail: state.details.statementDetail,
  login_history: state.details.login_history,
  failed: state.details.failed,
  token: state.login.token,

  levels: state.kyc.get_kyc_level.levels,
  kyc_docs: state.kyc.kyc_doc.documents,
  set_doc_response: state.kyc.set_doc.response_msg,
  bvn_response: state.users.setbvn.bvn_response,
  virtual_cards: state.virtualCards.get_virtual_config.data,
  a_user_savings: state.saving.get_user_saving.a_user_saving_data,
  savings_wallet: state.saving.get_save_wallet.wallet_data,
  user_earned_point: state.incentive.get_incentives.user_earned_points,
  user_interest_history: state.incentive.get_incentives.user_interest_history,
});
export default connect(mapStateToProps, {
  detailsInfo,
  tabDetail,
  accountBalance,
  accountStatement,
  newTabDetail,
  loadHistory,
  getKycDocuments,
  getKycLevel,
  uploadUserDocument,
  addUserBvn,
  getUserSavingsData,
  getSavingWallet,
  getUserEarnedPoint,
  getUserInterestHistory,
  userDetailsInfo,
  getCustomerDocument,
})(AllUsersDetails);
