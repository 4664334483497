import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BeneficiaryTableHead,
  BeneficiaryRow,
} from "../../../../components/Rows";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import {
  addBeneficiary,
  getBeneficiaries,
  _getPartners,
  getUsers,
} from "../../../../reduxStore/action/action.creator";
import {
  updateBeneficiary,
  deleteBeneficiary,
} from "../../../../API/ApiRequest";
import { Loader } from "../../../../components/Accessories";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

class Beneficiaries extends Component {
  state = {
    userType: "",
    userId: "",
    userCategory: "users",
    formReady: false,
    id: "",
    currentPage: 1,
    checked: false,
    currentIdToDelete: 0,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    const splitedData = this.state.userId.split(",");
    const data = {
      userId: splitedData[1],
      userType: this.state.userType,
    };
    this.props.addBeneficiary(data, this.props.token);
  };

  componentDidMount() {
    this.props.getBeneficiaries(this.props.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.success) {
      Swal.fire({
        title: "Success!",
        text: "Do you want to continue",
        icon: "success",
        confirmButtonText: "Close",
      });
      this.props.getBeneficiaries(this.props.token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    Object.keys(this.state).map((key) => {
      if (key !== "formReady") {
        if (prevState[key] != this.state[key]) {
          this.validateFields();
        }
      }
    });

    if (prevState.userCategory != this.state.userCategory) {
      this.setState({
        userId: "",
      });
      this.props._getPartners(this.props.token);
    }
  }

  validateFields = () => {
    if (this.state.userType !== "" && this.state.userId !== "") {
      this.setState({
        formReady: true,
      });
    } else {
      this.setState({
        formReady: false,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleModalContinue = async () => {
    ///
    try {
      const res = await deleteBeneficiary(
        this.state.currentIdToDelete,
        this.props.token
      );
      if (res == true) {
        this.props.getBeneficiaries(this.props.token);
      }
    } catch (error) {}
  };

  handleModalCancel = () => {
    // dataDismiss: "modal"
  };

  toggleHandler = async (id) => {
    try {
      const res = await updateBeneficiary(id, this.props.token);
      this.props.getBeneficiaries(this.props.token);
    } catch (error) {}
  };

  deleteBenId = (id) => {
    this.setState({
      currentIdToDelete: id,
    });
  };

  render() {
    const newData = this.state.userId.split(",");
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Beneficiaries" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <div className="row" style={{ padding: "0 1em" }}>
                        <div
                          className="col-md-12"
                          style={{
                            backgroundColor: "#eee",
                            borderBottom: "1px solid #aaa",
                            paddingLeft: ".5em",
                          }}>
                          <div className="col-md-6" style={{ padding: "0" }}>
                            <div
                              className="topic"
                              style={{ padding: "1em 0 .3em" }}>
                              Beneficiaries
                            </div>
                            <div
                              className=""
                              style={{ padding: ".5em 0 .1em" }}>
                              <h6>
                                All Beneficiaries
                                {/* : <span>Bronze</span> */}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                        <div
                          className="col-md-4"
                          style={{ backgroundColor: "#fff" }}>
                          <div
                            className="content"
                            style={{ paddingTop: "1em" }}>
                            <div
                              className="percentage"
                              style={{ paddingBottom: "1em" }}></div>

                            <div className="" style={{ padding: ".8em 0" }}>
                              <label>User Category</label>
                              <div className="input-div">
                                <select
                                  name="userCategory"
                                  onChange={this.handleChange}
                                  value={this.state.userCategory}>
                                  <option value="users">User</option>
                                  <option value="partner">Partner</option>
                                </select>
                              </div>

                              <label>User Type</label>
                              <div
                                style={{ marginBottom: "3%" }}
                                className="input-div">
                                <input
                                  name="userType"
                                  value={this.state.userType}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <label for="userId">User Name </label>

                              <div className="input-div">
                                {/* <select
                                  name="userId"
                                  value={newData[1]}
                                  onChange={this.handleChange}>
                                  {this.props.users.map((user) => (
                                    <option value={user.name + "," + user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                </select> */}
                                <input
                                  placeholder="Search Users"
                                  list="userId"
                                  name="userId"
                                  value={newData[0]}
                                  onChange={this.handleChange}
                                />
                                {this.state.userCategory == "users" ? (
                                  <datalist id="userId">
                                    {this.props.users.map((user) => (
                                      <option value={user.name + "," + user.id}>
                                        {user.name}
                                      </option>
                                    ))}
                                  </datalist>
                                ) : (
                                  <datalist id="userId">
                                    {this.props.partners &&
                                      this.props.partners.map((partner) => (
                                        <option
                                          value={
                                            partner.partnerName +
                                            "," +
                                            partner._id
                                          }>
                                          {partner.partnerName}
                                        </option>
                                      ))}
                                  </datalist>
                                )}
                              </div>

                              <div
                                className="divider"
                                style={{
                                  // border: "0.5px solid lightgray",
                                  marginTop: "5%",
                                }}></div>
                              <div style={{ paddingTop: "1em" }}>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={this.handleSubmit}
                                  disabled={!this.state.formReady}>
                                  Add Beneficiaries
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-8"
                          style={{ backgroundColor: "#fff" }}>
                          <div
                            className="content"
                            style={{ paddingTop: "2em" }}>
                            <div className="table-topic">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4>Existing Beneficiaries</h4>
                                </div>
                                <div className="col-md-6">
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}>
                                    <h4>
                                      Total Beneficiaries:{" "}
                                      {this.props.beneficiary.length}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="table-div table-responsive"
                              style={{
                                paddingBottom: "1em",
                                maxHeight: "20em",
                              }}>
                              <table
                                className="table table-flush"
                                id="datatable-basic">
                                <BeneficiaryTableHead />
                                <tbody>
                                  {this.props.beneficiary.map(
                                    (content, index) => (
                                      <BeneficiaryRow
                                        {...content}
                                        toggleHandler={this.toggleHandler}
                                        click={this.deleteBenId}
                                        index={this.getIndex(index + 1)}
                                      />
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div
                              className="divider"
                              style={{ border: "1px solid #ddd" }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal> */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ width: "auto" }}>
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLabel">Confirm Action</h5> */}
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style={{ textAlign: "center" }}>
                <i
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    color: "orange",
                  }}
                  class="fas fa-exclamation-triangle"></i>
                <br />
                <p style={{ fontSize: "30px", fontWeight: "300px" }}>
                  Wish to continue ?
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={this.handleModalContinue}
                  data-dismiss="modal">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.users.setben.beneficiaryResponse,
  loading: state.users.setben.loading,
  err: state.users.setben.failed,
  users: state.users.allusers.usersData,
  beneficiary: state.users.allben.bendata,
  partners: state.users.allben.partners,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  addBeneficiary,
  getBeneficiaries,
  _getPartners,
  getUsers,
})(Beneficiaries);
