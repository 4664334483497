import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import InventoryProduct from "./ProductManagement/products";
import InventoryProductDetails from "./ProductManagement/ProductDetails";
import EcommerceStore from "./E-commerceStores/Stores";
import InventoryOrders from "./OderingSystem/Orders";
import StoreDetails from "./E-commerceStores/StoreDetails";
import OrderDetails from "./OderingSystem/OrderDetails";
import UserSites from "./UserSites/Sites";
import ProductRatings from "./ProductRating/ProductRatings";
import CheckoutView from "./CheckoutView/CheckoutView";

const Starter = () => <h2>Hello</h2>;

const InvestmentRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/stores`} />
      </Route>
      <Route
        exact
        path={`${match.path}/products`}
        component={InventoryProduct}
      />
      <Route exact path={`${match.path}/stores`} component={EcommerceStore} />
      <Route
        exact
        path={`${match.path}/stores/:id/detail`}
        component={StoreDetails}
      />
      <Route exact path={`${match.path}/orders`} component={InventoryOrders} />
      <Route
        exact
        path={`${match.path}/products/:id/detail`}
        component={InventoryProductDetails}
      />
      <Route
        exact
        path={`${match.path}/orders/:id/detail`}
        component={OrderDetails}
      />
      <Route exact path={`${match.path}/sites`} component={UserSites} />
      <Route
        exact
        path={`${match.path}/product-rate`}
        component={ProductRatings}
      />
      <Route exact path={`${match.path}/checkout`} component={CheckoutView} />
    </Switch>
  </>
);
export default InvestmentRoute;
