import React from "react";
import "../../../morestyles.css";
import {} from "../../../components/Rows/ReportTable";
import {
  getReportByMonth,
  filterReportByMonth,
} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import { CSVLink } from "react-csv";
import ReportBar from "./SavingReportTab";
import {
  SavinvestReportHead,
  SavinvestReportBody,
} from "../../../components/Rows/ReportTable";

class TotalSavingReport extends React.Component {
  state = {
    years: [],
    default_year: new Date(Date.now()).getFullYear(),
  };

  componentDidMount() {
    const currentYear_inseconds = Date.now();
    const currentYear = new Date(currentYear_inseconds).getFullYear();
    const startYear = "2020";
    let year_range = [];

    for (var i = startYear; i <= currentYear; i++) {
      year_range.push(i);
    }

    this.setState({
      years: year_range,
    });

    this.props.getReportByMonth(
      `savinvest/monthly_savings_investment_analysis`,
      this.props.token
    );
  }

  handleYearChange = (e) => {
    this.setState({
      default_year: e.target.value,
    });
    this.props.filterReportByMonth(
      `savinvest/monthly_savings_investment_analysis?date=${e.target.value}`,
      this.props.token
    );
  };

  render() {
    let iteminTable;
    if (this.props.loading) {
      iteminTable = <Loader loading={this.props.loading} />;
    } else {
      iteminTable = (
        <>
          <SavinvestReportHead />
          <tbody>
            {this.props.report_month &&
              Object.keys(this.props.report_month).map((content) => (
                <SavinvestReportBody
                  content={content}
                  data={this.props.report_month[content]}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <ReportBar active="savingby_month" handleRefresh={this.handleRefresh}>
          <div class="card-header">
            <div className="row">
              <div className="col-md-8">
                <h3 class="mb-0">Savings and Investment Report</h3>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-8">
                    <select
                      name="year"
                      onChange={this.handleYearChange}
                      value={this.state.default_year}
                      className="form-control">
                      {this.state.years.map((content) => (
                        <option value={content}>{content}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    {/* <CSVLink
                      {...this.state.csvReport}
                      asyncOnClick={true}
                      className="btn btn-sm btn-neutral"
                      onClick={this.exportCSV}>
                      <i className="fa fa-export"></i> Export CSV
                    </CSVLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive py-4">
            <table class="table table-flush" id="datatable">
              {iteminTable}
            </table>
          </div>
        </ReportBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  report_month: state.report.savinvest_report.savinvest_month_report,
});

export default connect(mapStateToProps, {
  getReportByMonth,
  filterReportByMonth,
})(TotalSavingReport);
