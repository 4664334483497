import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Error } from "../../../Error";
import BillerCategoryCode from "./BillerCategoryCodeSetup/CodeSetup";
import GeneralCodeSetup from "./BillerGeneralCode/GeneralCode";
import ItemCode from "./ItemCodeSetup/ItemCode";
import ProductCode from "./ProductCodeSetup/ProductCode";
import ProviderCode from "./ProviderCodeSetup/ProviderCode";
import ServiceCode from "./ServiceCodeSetup/ServiceCode";
import ServiceSwitch from "./ServiceSwitchesConfig/ServiceSwitch";
import SwitchCode from "./SwitchCodesConfig/SwitchCode";

// const Show = () => <div>Wassa</div>;

const BillsRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/biller-category-codes`} />
      </Route>
      <Route
        exact
        path={`${match.path}/biller-category-codes`}
        component={BillerCategoryCode}
      />
      <Route
        exact
        path={`${match.path}/general-code`}
        component={GeneralCodeSetup}
      />
      <Route exact path={`${match.path}/item-code`} component={ItemCode} />
      <Route
        exact
        path={`${match.path}/product-code`}
        component={ProductCode}
      />
      <Route
        exact
        path={`${match.path}/provider-code`}
        component={ProviderCode}
      />
      <Route
        exact
        path={`${match.path}/service-code`}
        component={ServiceCode}
      />
      <Route
        exact
        path={`${match.path}/service-switch`}
        component={ServiceSwitch}
      />
      <Route exact path={`${match.path}/switch-code`} component={SwitchCode} />
      <Route exact component={Error} />
    </Switch>
  </>
);

export default BillsRoute;
