import React from "react";
import UserDetails from "../../pages/Interests/InterestHistory/UserDetails";
import {
  currencyFormatter,
  dateFormatter,
  statusButton,
  decimalFormatter,
  statusBadge,
  timeFormatter,
} from "../Row";

/** transaction table head */
export const TransactionTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Username/Debited Account</th>
      <th>Recipient Name</th>
      <th>Recipient Number</th>
      <th>TXN Service</th>
      <th>Status</th>
      <th>Reverse Transaction</th>
      <th>TXN Reference</th>
      <th>Amount</th>
      <th>Narration</th>
      <th>Transaction Date</th>
      <th>Transaction Time</th>
      <th>Transaction Type</th>
      <th>Actions</th>
    </tr>
  </thead>
);

const reverseStructure = (status) => {
  if (status == "TS_REVERSED") {
    return <span className="badge badge-warning">RVSL</span>;
  }
};

/** row body for transactions */
export const TransactionRow = (props) => {
  const { badgeColor, status } = statusBadge(props.status);
  const reversalStatus = statusBadge(props.internal_status);

  const amount = decimalFormatter(props.amount);
  const fullAmount = currencyFormatter(amount);
  return (
    <>
      <tr key={props.index}>
        <td
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#0038ba",
          }}
          onClick={() => {
            props.goToNextPage(props);
          }}
        >
          {props.index}
        </td>
        {props.username ? (
          <td>
            <a href="#" onClick={(e) => props.displayUser(e, props.id)}>
              {`${props.username} - ${props.account}`}{" "}
            </a>
          </td>
        ) : (
          <td>
            {/* <a href="#" onClick={(e) => props.displayUser(e, props.userId)}> */}
            {`${props.id} - ${props.account}`} {/* </a> */}
          </td>
        )}
        <td>{props.recipientName}</td>
        <td>{props.recipientNumber}</td>
        <td>{props.serviceName}</td>

        {/* {props.transactionCategory ? (
          <td>{props.transactionCategory.name}</td>
        ) : (
          <td>{props.serviceCode}</td>
        )} */}
        <td>
          <span class={badgeColor}>{status}</span>
          <span style={{ padding: "0 .5em" }}>
            {props.internal_status == "TS_FAILED"
              ? reverseStructure(props.internal_status)
              : ""}
          </span>
        </td>
        <td>
          {props.internal_status == "TS_FAILED" ? (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => props.reverseTransaction(props._id)}
              style={{
                padding: ".2em .5em",
                color: "#fff",
                borderRadius: "5px",
              }}
            >
              <span>reverse</span>
            </button>
          ) : (
            <span class={reversalStatus["badgeColor"]}>
              {reversalStatus.status}
            </span>
          )}
        </td>
        <td>{props.transactionRef}</td>
        {/* <td>{props.account}</td> */}
        <td>{fullAmount}</td>
        <td>{props.narration}</td>
        <td>{dateFormatter(props.date)}</td>
        <td>{timeFormatter(props.date)}</td>
        <td>{props.type}</td>
        {props?.canView && (
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-eye"
              style={{ padding: "0 .25em", cursor: "pointer" }}
              onClick={() => {
                props.goToNextPage(props);
              }}
            ></i>
          </td>
        )}
      </tr>
      <UserDetails
        display={props.showUser}
        close={props.closePopup}
        user_details={props.user_details}
      />
    </>
  );
};

export const TransactionList = (fullInfo) => {
  const props = fullInfo.transaction;
  const { buttonColor, status } = statusButton(props.transactionStatus);
  return (
    <>
      <div class="mt-4 ml-3 body-grid">
        <div className="box box3">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "lightgray",
              marginTop: "1em",
              marginBottom: "0em",
            }}
          >
            Amount
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0em",
            }}
          >
            NGN {currencyFormatter(props.transactionAmount)}
          </p>
        </div>
        <div
          className="box box10"
          style={{
            display: "grid",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <span type="button" class={buttonColor}>
            {status}
          </span>
        </div>
      </div>
      <table class="table table-hover mt-1" style={{ textAlign: "left" }}>
        <tbody>
          <tr>
            <td>Reference</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.transactionRef}
            </td>
          </tr>
          <tr>
            <td>Channel</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.channelId}
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.narration}
            </td>
          </tr>
          <tr>
            <td>Transaction Type</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.transactionType}
            </td>
          </tr>
          <tr>
            <td>Transaction Category</td>
            {props.transactionCategory ? (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.transactionCategory.name}
              </td>
            ) : (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.serviceCode}
              </td>
            )}
          </tr>
          <tr>
            <td>Customer Type</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>...</td>
          </tr>
          <tr>
            <td>Transaction Date</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {dateFormatter(props.transactionDate)}
            </td>
          </tr>
          <tr>
            <td>Debited Account</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.debitedAccount}
            </td>
          </tr>
          <tr>
            <td>User Name</td>
            {props.userObject ? (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.userObject.username}
              </td>
            ) : (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.userId}
              </td>
            )}
          </tr>
          <tr>
            <td>Full Name</td>
            {props.userObject ? (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.userObject.first_name + " " + props.userObject.last_name}
              </td>
            ) : (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.id}
              </td>
            )}
          </tr>
          <tr>
            <td>Email</td>
            {props.userObject ? (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.userObject.email}
              </td>
            ) : (
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                {props.id}
              </td>
            )}
          </tr>
          <tr>
            <td>Recipient Institution Name</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.recipientInstitutionName}
            </td>
          </tr>
          <tr>
            <td>Recipient Institution Logo</td>
            <td style={{ textAlign: "right" }}>
              <img
                src={props.recipientInstitutionLogoUrl}
                alt=""
                width="40px"
                height="40px"
              />
            </td>
          </tr>
          <tr>
            <td>Recipient Number</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.recipientNumber}
            </td>
          </tr>
          <tr>
            <td>Recipient Name</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.recipientName ? props.recipientName : "not given"}
            </td>
          </tr>
          <tr>
            <td>Token</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.token != null ? props.token : "not given"}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export const TransactionFee = (fullInfo) => {
  const props = fullInfo.feeSplits[0];
  const child_trans = fullInfo.childTransactions;
  return (
    <>
      {props ? (
        <div>
          <div>
            <span style={{ fontWeight: "bold", fontSize: "13px" }}>
              <i class="fas fa-comment-dollar"> &nbsp; </i> Transaction Fee
            </span>
            <br />
            <p
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "lightgray",
                marginTop: "1em",
                marginBottom: "0em",
              }}
            >
              Fee Configuration
            </p>

            <div>
              <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                Fee Value:
              </span>
              <span style={{ fontSize: "13px" }}> {props.feeValue} </span>
            </div>

            <div>
              <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                Fee Name:
              </span>
              <span style={{ fontSize: "13px" }}>
                {" "}
                {props.associatedTransaction.relatedFeeProfile.name}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                Fee Description:
              </span>
              <span style={{ fontSize: "13px" }}>
                {" "}
                {props.associatedTransaction.relatedFeeProfile.description}{" "}
              </span>
            </div>
          </div>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              color: "lightgray",
            }}
          >
            Fee Beneficiary
          </span>

          <table class="table table-bordered table-hover tableClass">
            <thead>
              <tr class="table-primary">
                <th scope="col" style={{ fontWeight: "bold" }}>
                  S/N
                </th>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  Beneficiary User ID
                </th>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  Beneficiary Share Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {props.beneficiariesAndShare.map((content, index) => (
                <tr class={index % 2 == 0 ? "table-light" : "table-primary"}>
                  <td scope="row">{index + 1}</td>
                  <td>{content[0]}</td>
                  <td>{content[1]}</td>
                </tr>
              ))}
              <tr class="table-light">
                <td
                  colspan="2"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td>{props.feeValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-div">
          <table className="table table-bordered table-hover tableClass">
            <thead>
              <th>Transaction Reference</th>
              <th>Transaction Type</th>
              <th>Transaction Status</th>
              <th>Internal Status</th>
            </thead>
            <tbody>
              {child_trans &&
                child_trans.map((content) => (
                  <tr>
                    <td>{content.transactionRef}</td>
                    <td>{content.transactionType}</td>
                    <td>{content.transactionStatus}</td>
                    <td>{content.internalStatus}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export const TransactionCommission = (fullInfo) => {
  const props = fullInfo.commissionSplits[0];
  return (
    <>
      {props ? (
        <div>
          <div>
            <span style={{ fontWeight: "bold", fontSize: "13px" }}>
              <i class="fas fa-comment-dollar"> &nbsp; </i> Transaction
              Commission
            </span>
            <br />
            <p
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "lightgray",
                marginTop: "1em",
                marginBottom: "0em",
              }}
            >
              Commission Configuration
            </p>
            <div>
              <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                Commission Value:
              </span>
              <span style={{ fontSize: "13px" }}>{props.commissionValue}</span>
            </div>
          </div>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              color: "lightgray",
            }}
          >
            Commission Beneficiary
          </span>

          <table class="table table-bordered table-hover tableClass">
            <thead>
              <tr class={"table-primary"}>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  S/N
                </th>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  Beneficiary User ID
                </th>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  Beneficiary Share Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {props.length > 0 &&
                props.beneficiariesAndShare.map((content, index) => (
                  <tr class={index % 2 == 0 ? "table-light" : "table-primary"}>
                    <td scope="row">{index + 1}</td>
                    <td>{content[0]}</td>
                    <td>{content[1]}</td>
                  </tr>
                ))}
              <tr class="table-light">
                <td
                  colspan="2"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td>{props.commissionValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        "no commission given"
      )}
    </>
  );
};
