import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { LoanReqDetails } from "../../components/Rows";
import { Modal } from "../../components/Accessories";
import { Link } from "react-router-dom";
import {
  detailsInfo,
  approveLoan,
  loanDisburse,
  loanTabDetails,
  tabDetail,
} from "../../reduxStore/action/action.creator";
import { currencyFormatter, decimalFormatter } from "../../components/Row";

class LoanRequestDetails extends React.Component {
  state = {
    isTabActive: [],
  };

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.tabDetails) {
  //     // alert(JSON.stringify(this.props.tabDetails.data));
  //   }
  // }
  componentDidMount() {
    const path = this.props.history.location.pathname;
    const loan_id = path.substring(path.indexOf("t/") + 2, path.indexOf("/d"));
    this.props.detailsInfo(
      `loan/loans/${loan_id}`,
      "loan_request",
      this.props.token
    );
    this.props.tabDetail(
      `user/users-profile/${this.props.history.location.state}`,
      this.props.token
    );

    let activeTab = [];
    activeTab[0] = true;
    this.setState({
      isTabActive: activeTab,
    });
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeStatus = async (id, status_id) => {
    if (status_id == "Pending") {
      alert("pending");
      // this.props.approveLoan(id);
    } else {
      this.props.loanDisburse(id, this.props.token);
      this.props.detailsInfo(
        `loan/loans/${id}`,
        "loan_request",
        this.props.token
      );
    }
  };

  changeTabActive = (url, id) => {
    let activeTab = [];
    activeTab[id] = true;
    this.setState((prevState) => ({
      isTabActive: activeTab,
    }));
    if (id === 0) {
      // this.props.tabDetail(
      //   `user/users-profile/${this.props.loanRequestDetails.data.user_id}`
      // );
    } else {
      this.props.loanTabDetails(url, this.props.token);
    }
  };

  render() {
    const params = this.props.location;
    let itemInCard;
    if (this.props.loading) {
      itemInCard = "";
    } else {
      itemInCard = this.props.loanRequestDetails && (
        <LoanReqDetails
          changeLoan={this.changeStatus}
          {...this.props.loanRequestDetails}
        />
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            padding: "0 .5em 0 0",
                            textAlign: "center",
                          }}>
                          <i
                            class="fas fa-chevron-left"
                            style={{
                              color: "#fff",
                              padding: "0.3em .5em",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}></i>
                        </span>
                        <span style={{ color: "#fff" }}>Back</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--5">
              <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
                <div class="col-5 userDetailsCol1">{itemInCard}</div>
                <div class="col-7 userDetailsCol2">
                  <div class="tab" role="tabpanel">
                    <div
                      class="card-header"
                      style={{
                        margin: "0px",
                        backgroundColor: "lavenderblush",
                        borderBottom: "0px",
                      }}>
                      <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                        <li
                          role="presentation"
                          className={this.state.isTabActive[0] ? "active" : ""}
                          onClick={() => this.changeTabActive(null, 0)}>
                          <a
                            href="#Section0"
                            aria-controls="user-details"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-user"></i> User Details
                          </a>
                        </li>
                        <li
                          role="presentation"
                          className={this.state.isTabActive[3] ? "active" : ""}
                          onClick={() => this.changeTabActive(null, 3)}>
                          <a
                            href="#Section3"
                            aria-controls="transactions"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-credit-card"></i> User KYC Details
                          </a>
                        </li>
                        <li
                          role="presentation"
                          className={this.state.isTabActive[1] ? "active" : ""}
                          onClick={() =>
                            this.changeTabActive(
                              `/repayment_schedules/${
                                this.props.loanRequestDetails &&
                                this.props.loanRequestDetails.data.id
                              }`,
                              1
                            )
                          }>
                          <a
                            href="#Section1"
                            aria-controls="kyc"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-user"></i> Repayment Schedule
                          </a>
                        </li>
                        <li
                          role="presentation"
                          className={this.state.isTabActive[2] ? "active" : ""}
                          onClick={() =>
                            this.changeTabActive(
                              `loans/get_user_loans/${
                                this.props.loanRequestDetails &&
                                this.props.loanRequestDetails.data.user_id
                              }`,
                              2
                            )
                          }>
                          <a
                            href="#Section2"
                            aria-controls="kyc"
                            style={{
                              padding: "1.5em 1em",
                            }}
                            role="tab"
                            data-toggle="tab">
                            <i class="fa fa-money"></i> Loan Application History
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="tab-content tabs">
                      <div
                        role="tabpanel"
                        class="tab-pane active"
                        id="Section0">
                        <div class="card">
                          {this.props.userInfo.data && (
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  Full Name :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.userInfo.data.first_name +
                                    " " +
                                    this.props.userInfo.data.last_name}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  Email :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.userInfo.data.email}{" "}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  Phone Number :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.userInfo.data.phone_number}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  Account Name :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.userInfo.data.account_name}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  Account Number :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.userInfo.data.account_number}
                                </span>
                              </li>
                            </ul>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "1em",
                            }}>
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  `/allusers/${this.props.loanRequestDetails.data.user_id}/details`
                                );
                              }}>
                              View more Info about this user
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div role="tabpanel" class="tab-pane fade" id="Section1">
                        <table className="table table-responsive table-div">
                          <thead>
                            <tr>
                              <th>Tenure Type</th>
                              <th>Tenure</th>
                              <th>Payment Date</th>
                              <th>Total Amount</th>
                              <th>Repayment Amount</th>
                              <th>Loan Interest</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.loantabDetails &&
                              this.props.loantabDetails.data.map((content) => (
                                <tr>
                                  <td>{content.tenure_type}</td>
                                  <td>{content.tenure}</td>
                                  <td>
                                    {new Date(
                                      content.payment_date
                                    ).toDateString()}
                                  </td>
                                  <td>
                                    {currencyFormatter(
                                      decimalFormatter(
                                        parseFloat(content.total_amount)
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {currencyFormatter(
                                      decimalFormatter(
                                        parseFloat(content.repayment_amount)
                                      )
                                    )}
                                  </td>
                                  <td>{content.loan_interest}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div role="tabpanel" class="tab-pane fade" id="Section2">
                        <table className="table table-responsive table-div">
                          <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Tenure</th>
                              <th>Tenure Type</th>
                              <th>Interest Rate</th>
                              <th>Loan Reason</th>
                              <th>Loan Remains</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.loantabDetails &&
                              this.props.loantabDetails.data.map((content) => (
                                <tr>
                                  <td>
                                    {currencyFormatter(
                                      decimalFormatter(
                                        parseFloat(content.amount)
                                      )
                                    )}
                                  </td>
                                  <td>{content.tenure}</td>
                                  <td>{content.tenure_type}</td>
                                  <td>{content.interest_rate}</td>
                                  <td>{content.reason}</td>
                                  <td>
                                    {currencyFormatter(
                                      decimalFormatter(
                                        parseFloat(content.amount_remain)
                                      )
                                    )}
                                  </td>

                                  <td>
                                    {new Date(
                                      content.start_date
                                    ).toDateString()}
                                  </td>
                                  <td>
                                    {new Date(content.end_date).toDateString()}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div role="tabpanel" class="tab-pane fade" id="Section3">
                        <div class="card">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}>
                                KYC Fields{" "}
                              </span>
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}>
                                BVN :
                              </span>{" "}
                              <span style={{ fontSize: "12px" }}></span>
                            </li>
                            <li class="list-group-item">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}>
                                KYC Level{" "}
                              </span>
                              <br />
                              <span style={{ fontSize: "12px" }}></span>
                            </li>
                            <li class="list-group-item">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}>
                                KYC Document{" "}
                              </span>
                              <br />
                              <span style={{ fontSize: "11px" }}>N/A</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.details.loading,
  loanRequestDetails: state.details.loan_detailinfo,
  loantabDetails: state.details.loantabdetail,
  userInfo: state.details.tabdetail,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  detailsInfo,
  approveLoan,
  loanDisburse,
  loanTabDetails,
  tabDetail,
})(LoanRequestDetails);
