import React from "react";
import { Modal } from "../../../../../components/Accessories";
import { headers } from "../../../../../API/api.config";
import axios from "axios";

class ValidateDoc extends React.Component {
  state = {
    document_type: "",
    name: "",
    comment: "",
    system_can_approved: false,
    imgURL: "",
    loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    this.setState(
      {
        ...this.state,
        comment: this.state.comment,
        user_id: this.props.obj.userId,
        document_id: this.props.obj.docId,
        approved_by: this.props?.adminId?.admin_id,
      },
      async () => {
        const requestOptions = {
          comment: this.state.comment,
          user_id: this.state.user_id,
          user_document_id: this.state.document_id,
          approved_by: this.state.approved_by,
        };
        if (this.props.obj.validationType === "Accept") {
          try {
            this.setState({ loading: true });
            const response = await axios.post(
              `${process.env.REACT_APP_BASE3_URL}/user_document/approve`,
              requestOptions
            );
            const formData = await response.data;
            if (formData) {
              this.setState({ loading: false });
              this.props.closeModal();
              this.setState({ comment: "" });
            }
          } catch (error) {
            this.props.closeModal();
            this.setState({ comment: "" });
          }

          // this.props.closeModal();
          // this.setState({ comment: "" });
        } else if (this.props.obj.validationType === "Reject") {
          // console.log(requestOptions);
          try {
            this.setState({ loading: true });
            const response = await axios.post(
              `${process.env.REACT_APP_BASE3_URL}/user_document/reject`,
              requestOptions
            );
            const formData = await response.data;
            if (formData) {
              this.setState({ loading: false });
              this.props.closeModal();
              this.setState({ comment: "" });
            }
          } catch (error) {
            this.props.closeModal();
            this.setState({ comment: "" });
          }
        }

        // console.log(this.state.obj)
      }
    );
  };

  render() {
    // console.log(this.props?.adminId?.admin_id);
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle={
          this.props.obj && this.props.obj.validationType + " Document"
        }>
        <div className="body-grid">
          <div className="box box9">
            <label>Comment</label>
            <textarea
              className="form-control"
              name="comment"
              value={this.state.comment}
              onChange={this.handleChange}></textarea>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                {this.state.loading ? "Loading" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ValidateDoc;
