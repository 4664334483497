import React from "react";
import { Modal } from "../../components/Accessories";

class DetailsModal extends React.Component {
  state = {};

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        width="50%"
        closeModal={this.props.closeModal}
        modalTitle="Bank Transaction Details">
        <div style={{ padding: "1em" }}>
          <div className="row">
            {Object.keys(this.props.data).map((key) => (
              <div className="col-6" style={{ padding: ".3em 5em" }}>
                <div className="" style={{ textAlign: "left" }}>
                  <div>
                    <span style={{ fontSize: "12px", fontWeight: "bolder" }}>
                      {key}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "12px" }}>
                      {this.props.data[key]
                        ? this.props.data[key]
                        : "Not provided"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default DetailsModal;
