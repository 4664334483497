import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class WalletModal extends React.Component {
  state = {
    currency: "",
    account_balance: "",
    ledger_balance: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.submitWallet(this.state);
  };

  checkNumber = (e) => {
    if (+this.state.account_balance >= 0 && +this.state.ledger_balance >= 0) {
      return;
    } else {
      this.setState({
        [e.target.name]: "",
      });
    }
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add Wallet">
        <div className="body-grid">
          <div className="box box9">
            <div>
              <label>Currency</label>
            </div>
            <select
              className="form-control"
              name="currency"
              value={this.state.currency}
              onChange={this.handleChange}>
              <option></option>
              {this.props.currency &&
                this.props.currency.map((content) => (
                  <option value={content.currency_code}>
                    {content.currency_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box1">
            <label>Account Balance</label>
            <input
              className="form-control"
              name="account_balance"
              value={this.state.account_balance}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Ledger Balance</label>
            <input
              className="form-control"
              name="ledger_balance"
              value={this.state.ledger_balance}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Add Wallet
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.virtualCards.get_rate.currency,
});

export default connect(mapStateToProps, {})(WalletModal);
