import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FlutterwaveTransact from "./FlutterwaveTransact";

const FlutterwaveRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={FlutterwaveTransact} />
    </Switch>
  </>
);
export default FlutterwaveRoute;
