import React, { Component } from "react";
import DashboardNavBar from "../../components/DashboardNavbar";
import SideBar from "../../components/SideBar";
import { connect } from "react-redux";
import {
  FlutterwaveTableHead,
  FlutterwaveBody,
} from "../../components/Rows/TransactServiceTable";
import { Loader, Pagination } from "../../components/Accessories";
import {
  getFlutterwaveService,
  filterFlutter,
  filterFlutterDateRange,
} from "../../reduxStore/action/action.creator";
import FlutterwaveTransactModal from "./FlutterwaveTransactModal";
import FlwDetailsModal from "./FlwDetailsModal";

class FlutterwaveTransact extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
    details: {},
  };

  componentDidMount() {
    this.props.getFlutterwaveService(
      "flw_transactions?per_page=15",
      this.props.token
    );
  }
  changePage = (id) => () => {
    this.props.getFlutterwaveService(
      `flw_transactions?page=${id}&per_page=15`,
      this.props.token
    );
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      this.props.getFlutterwaveService(
        `flw_transactions?page=${id}&per_page=15`,
        this.props.token
      );
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      this.props.getFlutterwaveService(
        `flw_transactions?page=${id}&per_page=15`,
        this.props.token
      );
      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };
  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
    });
  };

  filterFlutterwave = (data) => {
    this.props.filterFlutter(data, this.props.token);
  };

  handleRefresh = () => {
    this.props.getFlutterwaveService(
      `flw_transactions?page=${this.state.currentPage}&per_page=15`,
      this.props.token
    );
  };

  showDetails = (detail) => {
    this.setState({
      details: detail ? detail.data : "not provided",
      display2: true,
    });
  };

  render() {
    let itemInTable;
    let renderPaginate;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.isrange &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <FlutterwaveTableHead admin="true" />
          {this.props.flwtrans &&
            this.props.flwtrans.map((content, index) => (
              <tbody>
                <FlutterwaveBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  showDetails={this.showDetails}
                />
              </tbody>
            ))}
        </>
      );
    }

    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Flutterwave Service"
              showModal={this.showModal}
              getInfo={this.handleRefresh}
              shouldFilter={true}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Flutterwave Service</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                      {renderPaginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FlutterwaveTransactModal
          display={this.state.display}
          closeModal={this.closeModal}
          filter={this.filterFlutterwave}
        />
        <FlwDetailsModal
          data={this.state.details}
          display={this.state.display2}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  flwtrans: state.alltransactions.fluttertransactions.flwtrans,
  total: state.alltransactions.fluttertransactions.total,
  loading: state.alltransactions.fluttertransactions.loading,
  isrange: state.alltransactions.fluttertransactions.isrange,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getFlutterwaveService,
  filterFlutter,
  filterFlutterDateRange,
})(FlutterwaveTransact);
