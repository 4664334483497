import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllAgentsClaim from "./AgentClaims";
// import AllAdminsDetails from "./AllAdminsDetails";

const AgentClaimRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AllAgentsClaim} />
      {/* <Route
        exact
        path={`${match.path}/:name/details`}
        component={AllAdminsDetails}
      /> */}
    </Switch>
  </>
);
export default AgentClaimRoute;
