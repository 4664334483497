import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import RoleModal from "./RoleModal";
import EditRoleModal from "./EditRoleModal";
import {
  setInvestRole,
  getInvestRole,
  updateRole,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  InvestRoleBody,
  InvestRoleHead,
} from "../../../components/Rows/InvestmentTable";

class RoleConfig extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getInvestRole("/savinvest/roles", this.props.token);
  }

  handleRefresh = () => {
    this.props.getInvestRole("/savinvest/roles", this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      role_modal: false,
    });
  };

  addRole = (data) => {
    callBackMethod(
      this.props.setInvestRole("/savinvest/roles", data, this.props.token)
    )
      .then(() => this.setState({ display: false }))
      .then(() => {
        if (this.props.role_resp == true) {
          alert("successfully added to role");
        }
      });
  };

  editRole = (props) => {
    this.setState({
      role_modal: true,
      roleData: props,
    });
  };

  editInvestRole = (data) => {
    callBackMethod(
      this.props.updateRole(
        `/savinvest/roles/${this.state.roleData.id}`,
        data,
        this.props.token,
        "PUT"
      )
    )
      .then(() => this.setState({ role_modal: false }))
      .then(() => alert(this.props.edit_role_resp));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestRoleHead />
          <tbody>
            {this.props.role_data &&
              this.props.role_data.map((content, index) => (
                <InvestRoleBody
                  {...content}
                  index={index + 1}
                  editRole={this.editRole}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="roles">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <RoleModal
          display={this.state.display}
          closeModal={this.closeModal}
          addRole={this.addRole}
        />
        <EditRoleModal
          display={this.state.role_modal}
          closeModal={this.closeModal}
          roleData={this.state.roleData}
          editRole={this.editInvestRole}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role_resp: state.invest.setData.role_resp,
  role_data: state.invest.getData.role_data,
  loading: state.invest.getData.loading,
  edit_role_resp: state.invest.setData.edit_role_resp,
});

export default connect(mapStateToProps, {
  setInvestRole,
  getInvestRole,
  updateRole,
})(RoleConfig);
