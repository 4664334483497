import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class EditCompanyModal extends React.Component {
  state = {
    company_name: "",
    company_address: "",
    enabled: false,
    date_joined: null,
    date_approved: null,
    created_by: this.props.admin_data.id,
    updated_by: this.props.admin_data.id,
    phone_number: "",
    email_address: "",
    cac_number: "",
    staff_strength: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.companyData != prevProps.companyData) {
      this.setState({
        company_name: this.props.companyData.company_name,
        company_address: this.props.companyData.company_address,
        enabled: this.props.companyData.enabled,
        date_joined: this.props.companyData.date_joined,
        date_approved: this.props.companyData.date_approved,
        created_by: this.props.companyData.created_by,
        updated_by: this.props.companyData.updated_by,
        phone_number: this.props.companyData.phone_number,
        email_address: this.props.companyData.email_address,
        cac_number: this.props.companyData.cac_number,
        staff_strength: this.props.companyData.staff_strength,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    this.props.edit_company(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Edit Company">
        <div className="body-grid">
          <div className="box box1">
            <label>Company Name</label>
            <input
              className="form-control"
              name="company_name"
              value={this.state.company_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Company Address</label>
            <input
              className="form-control"
              name="company_address"
              value={this.state.company_address}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Date Joined</label>
            <input
              type="date"
              className="form-control"
              name="date_joined"
              value={this.state.date_joined}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Date Approved</label>
            <input
              type="date"
              className="form-control"
              name="date_approved"
              value={this.state.date_approved}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Phone Number</label>
            <input
              className="form-control"
              name="phone_number"
              value={this.state.phone_number}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Email Address</label>
            <input
              className="form-control"
              name="email_address"
              value={this.state.email_address}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>CAC Number</label>
            <input
              className="form-control"
              name="cac_number"
              value={this.state.cac_number}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Staff Strength</label>
            <input
              className="form-control"
              name="staff_strength"
              value={this.state.staff_strength}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Enable Company</label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="enabled"
                checked={this.state.enabled}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Save Company
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  admin_data: state.login.data,
});

export default connect(mapStateToProps, {})(EditCompanyModal);
