import React from "react";
import { Modal } from "../../components/Accessories";

class FlwDetailsModal extends React.Component {
  state = {};

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        width="90%"
        closeModal={this.props.closeModal}
        modalTitle="Flutterwave Transaction Details">
        <div style={{ padding: "1em" }}>
          <div className="row">
            {this.props.data != "not provided" &&
              Object.keys(this.props.data).map((key) => (
                <div className="col-4" style={{ padding: ".3em 5em" }}>
                  <div className="" style={{ textAlign: "left" }}>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        {key}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "12px" }}>
                        {this.props.data[key] &&
                        typeof this.props.data[key] != "object"
                          ? this.props.data[key]
                          : "not provided"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {this.props.data == "not provided" && (
              <div className="col-md-12" style={{ textAlign: "center" }}>
                NO DATA PROVIDED
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default FlwDetailsModal;
