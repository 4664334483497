import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Beneficiaries from "./Beneficiaries";
import {Error} from "../../../Error"

const BeneficiaryRoute = ({ match }) => (
  <>
  <Switch>
    <Route exact path={`${match.path}/`} component={Beneficiaries} />
    <Route exact component={Error}/>
  </Switch>
  </>
);

export default BeneficiaryRoute

