import React from "react";

class RoleForm extends React.Component {
  state = {
    name: "",
    description: "",
    permissions: [],
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    const data = {
      ...this.state,
      active: true,
    };
    console.log(data);
    // this.props.submitRole(data);
  };

  handleCheck = (id) => {
    if (!this.state.permissions.includes(id)) {
      this.setState({
        permissions: [...this.state.permissions, id],
      });
    } else {
      this.setState({
        permissions: this.state.permissions.filter((item) => item !== id),
      });
    }
  };

  selectAll = (data) => {
    const ids = data.map((item) => item.id);

    ids.forEach((datum) => {
      if (!this.state.permissions.find((item) => item === datum)) {
        this.state.permissions.push(datum);
      }
    });
  };

  render() {
    return (
      <div className="content" style={{ paddingTop: "1em" }}>
        <div className="" style={{ padding: ".8em 0" }}>
          <div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Enter Name of Role</label>
              <input
                type="text"
                class="form-control"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="Enter role name"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Description of Role
              </label>
              <textarea
                type="text"
                class="form-control"
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="Enter role description"
              />
            </div>

            <div class="form-group">
              <label for="exampleFormControlInput1">
                Select permissions for this role
              </label>

              <div id="accordion">
                {this.props.perms &&
                  this.props.perms.length !== 0 &&
                  this.props.perms.map((res, i) => {
                    return (
                      <div class="card" key={res?.id}>
                        <div class="card-header">
                          <a
                            class="card-link"
                            data-toggle="collapse"
                            href={`#collapse${res?.id}`}
                          >
                            {res?.module_name}
                          </a>
                          <small
                            className="ml-5 text-primary"
                            style={{ cursor: "pointer", fontSize: "12px" }}
                            href="#"
                            onClick={() => this.selectAll(res.permissions)}
                          >
                            Select all in {res?.module_name}
                          </small>
                        </div>
                        <div
                          id={`collapse${res?.id}`}
                          class={`collapse ${i === 0 && "show"}`}
                          data-parent="#accordion"
                        >
                          <div class="card-body d-flex flex-wrap">
                            {res.permissions.map((inner) => {
                              return (
                                <div id={inner?.id}>
                                  <div className="d-flex align-items-center mx-2">
                                    <input
                                      type="checkbox"
                                      value={inner?.id}
                                      name="permissions"
                                      checked={this.state.permissions.includes(
                                        inner?.id
                                      )}
                                      onChange={() =>
                                        this.handleCheck(inner?.id)
                                      }
                                      className="mr-2"
                                      style={{ width: "15px" }}
                                    />
                                    <label className="p-0 m-0">
                                      {inner?.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "1em" }}>
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.handleSubmit}
            >
              Insert Role
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleForm;
