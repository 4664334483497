import { combineReducers } from "redux";
import {
  ADD_CONVERSION,
  ADD_CONVERSION_ERROR,
  ADD_INCENTIVES,
  GET_A_CHARGE_BACK,
  GET_CHARGE_BACK,
  GET_CONVERSION,
  GET_EARNED_POINT,
  GET_INCENTIVES,
  GET_REDEEMED_TRANSFER,
  GET_USER_EARNED_POINT,
  GET_USER_INTEREST_HISTORY,
  INCENTIVE_ERROR,
  UPDATE_CHARGE_BACK,
  UPDATE_INCENTIVES,
  UPDATE_INCENTIVES_ERROR,
} from "../action/action.types";

const addIncentiveReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_INCENTIVES:
      return {
        ...state,
        incentive_response: action.payload.response_description,
      };
    case INCENTIVE_ERROR:
      return {
        ...state,
        incentive_response: action.payload,
      };
    case ADD_CONVERSION:
      return {
        ...state,
        conversion_resp: action.payload.response_description,
        conversion_status: action.payload.success,
      };
    case ADD_CONVERSION_ERROR:
      return {
        ...state,
        conversion_resp: action.payload,
      };
    case UPDATE_INCENTIVES:
      return {
        ...state,
        update_resp: action.payload,
      };
    case UPDATE_INCENTIVES_ERROR:
      return {
        ...state,
        update_resp: action.payload,
      };
    case UPDATE_CHARGE_BACK:
      return {
        ...state,
        charge_back_resp: action.payload,
      };
    default:
      return state;
  }
};

const getIncentiveReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_INCENTIVES:
      return {
        ...state,
        loading: false,
        incentives: action.payload.data,
      };
    case GET_REDEEMED_TRANSFER:
      return {
        ...state,
        loading: false,
        redeemedTrans: action.payload,
      };
    case GET_CONVERSION:
      return {
        ...state,
        loading: false,
        conversions: action.payload.data,
      };
    case GET_EARNED_POINT:
      return {
        ...state,
        loading: false,
        earned_action: action.payload,
      };
    case GET_CHARGE_BACK:
      return {
        ...state,
        loading: false,
        charge_back: action.payload,
      };
    case GET_USER_EARNED_POINT:
      return {
        ...state,
        loading: false,
        user_earned_points: action.payload,
      };
    case GET_A_CHARGE_BACK:
      return {
        ...state,
        loading: false,
        a_charge_back: action.payload,
      };

    case GET_USER_INTEREST_HISTORY:
      return {
        ...state,
        loading: false,
        user_interest_history: action.payload,
      };

    default:
      return state;
  }
};

export const incentiveReducer = combineReducers({
  add_incentives: addIncentiveReducer,
  get_incentives: getIncentiveReducer,
});
