import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { DeveloperApiCallStatsTableHead, DeveloperApiCallStatsRow } from "../../components/Rows";
import "../../morestyles.css";
import {
  getDeveloperCallStats
} from "../../reduxStore/action/action.creator";
import { Loader } from "../../components/Accessories";

class DeveloperApiCalls extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
    word: 'user'
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      this.setState({
        display2: false,
      });
      this.props.getDeveloperCallStats(this.props.token, 1);
    }
  }

  componentDidMount() {
    this.props.getDeveloperCallStats(this.props.token, 1);
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
    });
  };

  toggleHandler = async (id, lock) => {
    try {
      let action = "";
      if (lock == "0") {
        action = "disable";
      } else {
        action = "enable";
      }
      this.props.getAuditLogs(this.props.token, this.state.currentPage);
    } catch (error) {}
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <DeveloperApiCallStatsTableHead admin="true" />
          <tbody>
            {this.props.developerCallStats.developerCallStats.map((info, index) => (
              <DeveloperApiCallStatsRow
                {...info}
                admin="true"
                index={this.getIndex(index + 1)}
                goToDetails={this.handleNextPage}
                toggleHandler={this.toggleHandler}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Developer Call Stats"
              getInfo={this.props.getDeveloperApiCalls}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Developer Call Stats</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.devAccounts.developerCallStats.loading,
  token: state.login.token,
  developerCallStats: state.devAccounts.developerCallStats.developerCallStatsData
});
export default connect(mapStateToProps, {
  getDeveloperCallStats
})(DeveloperApiCalls);
