import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import SavingPenaltyModal from "./SavingPenaltyModal";
import {
  addSavingPenalty,
  getSavingPenalty,
  editSavingPenalty,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import { PenaltyHead, PenaltyBody } from "../../../components/Rows/SavingTable";
import EditSavingPenaltyModal from "./EditSavingPenaltyModal";

class SavingsPenalty extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getSavingPenalty(this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getSavingPenalty(this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      edit_modal: false,
    });
  };
  setSavingPenalty = (data) => {
    callBackMethod(this.props.addSavingPenalty(data, this.props.token))
      .then(() => {
        this.setState({ display: false });
      })
      .then(() => {
        if (this.props.penalty_resp == true) {
          alert("successfully saved penalty");
        }
      });
  };

  editPenalty = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props.id,
      edit_modal: true,
      penalty_info: props,
    });
  };
  updatePenalty = (data) => {
    callBackMethod(
      this.props.editSavingPenalty(this.state.idToEdit, data, this.props.token)
    )
      .then(() => this.setState({ ...this.state, edit_modal: false }))
      .then(() =>
        alert(JSON.stringify(this.props.penalty_update_resp.response_message))
      )
      .then(() => this.props.getSavingPenalty(this.props.token));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <PenaltyHead />
          <tbody>
            {this.props.penalty_data.map((content, index) => (
              <PenaltyBody
                {...content}
                index={index + 1}
                editPenalty={this.editPenalty}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <SavingBar active="savings-penalty">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </SavingBar>
        <SavingPenaltyModal
          display={this.state.display}
          closeModal={this.closeModal}
          setSavingPenalty={this.setSavingPenalty}
        />
        <EditSavingPenaltyModal
          display={this.state.edit_modal}
          closeModal={this.closeModal}
          penalty_info={this.state.penalty_info}
          updatePenalty={this.updatePenalty}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  penalty_resp: state.saving.set_save_penalty.response,
  penalty_update_resp: state.saving.set_save_penalty.result,
  penalty_data: state.saving.get_save_penalty.penalty_data,
  loading: state.saving.get_save_penalty.loading,
});

export default connect(mapStateToProps, {
  addSavingPenalty,
  getSavingPenalty,
  editSavingPenalty,
})(SavingsPenalty);
