import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AllBatches from "./AllBatches/AllBatches";
import AllBatchesData from "./AllBatchesData/AllBatchesData";
import BulkTransaction from "./BulkTransactions/BulkTransaction";

const BulkPaymentRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/all-batches`} />
      </Route>
      <Route exact path={`${match.path}/all-batches`} component={AllBatches} />
      <Route
        exact
        path={`${match.path}/all-batches-data`}
        component={AllBatchesData}
      />
      <Route
        exact
        path={`${match.path}/bulk-transaction`}
        component={BulkTransaction}
      />
    </Switch>
  </>
);
export default BulkPaymentRoute;
