import { headers } from "../api.config";

export const fetchWalletHistory = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/wallet_histories?page=${page}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const { total } = await data;
    const wallet_data = await data.data;

    return { wallet_data, total };
  }
};

export const fetchWallets = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/wallets?page=${page}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const addWallets = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/wallets`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const fundWallet = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/credit-or-debit-wallet`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
