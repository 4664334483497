import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import {
  getInvestmentSub,
  getInvestmentCycle,
  getSavingWallet,
} from "../../../reduxStore/action/action.creator";
import { Loader } from "../../../components/Accessories";
import {
  InvestmentSubHead,
  InvestmentSubBody,
} from "../../../components/Rows/InvestmentTable";

class UserInvestment extends React.Component {
  state = {};

  componentDidMount() {
    this.props.getInvestmentSub(
      "/savinvest/investment_subscriptions",
      this.props.token
    );
    this.props.getInvestmentCycle(
      "/savinvest/product_investment_cycles",
      this.props.token
    );
    this.props.getSavingWallet(this.props.token);
  }

  viewSubscription = (data) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${data.id}/details`,
      state: data.id,
    });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestmentSubHead />
          <tbody>
            {this.props.investment_data &&
              this.props.investment_data.map((content, index) => (
                <InvestmentSubBody
                  {...content}
                  index={index + 1}
                  cycle={this.props.cycle}
                  viewSubscription={this.viewSubscription}
                  savings_wallet={this.props.savings_wallet}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <InvestmentBar active="user-investment">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  investment_data: state.invest.getData?.invest_sub,
  loading: state.invest.getData.loading,
  cycle: state.invest.getData.cycle,
  savings_wallet: state.saving.get_save_wallet.wallet_data,
});
export default connect(mapStateToProps, {
  getInvestmentSub,
  getInvestmentCycle,
  getSavingWallet,
})(UserInvestment);
