import { headers } from "./api.config";

export const getBillListRequest = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/biller/biller_list`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  return { result };
};

export const getBillTransactionReq = async (per_page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/biller/biller_transaction?per_page=${per_page}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (response.ok) {
    const { transactions, allTransactions } = await response.json();
    return { transactions, allTransactions };
  }
};

export const switchBill = async (billname, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/biller/switch`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(billname),
    }
  );

  if (response.ok) {
    const { message } = await response.json();
    return { message };
  }
};

export const fetchBillsConfig = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE2_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
export const addBillsConfig = async (url, data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE2_URL}/${url}`, {
    headers: headers(token),
    method: "POST",
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const { data, response_message } = await response.json();
    return { data, response_message };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export const editBillsConfig = async (url, data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE2_URL}/${url}`, {
    headers: headers(token),
    method: "PUT",
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const { data, response_message } = await response.json();
    return { data, response_message };
  } else {
    const { message, errors } = await response.json();
    throw new Error(message);
  }
};
