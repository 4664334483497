import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import SavingWalletModal from "./SavingWalletModal";
import {
  getSavingWallet,
  lockSavingWallet,
} from "../../../reduxStore/action/action.creator";
import { Loader, callBackMethod } from "../../../components/Accessories";
import {
  SavingWalletHead,
  SavingWalletBody,
} from "../../../components/Rows/SavingTable";
import { ConfirmationModal } from "../SavingsConfig/SavingConfigModal";

class SavingsWallet extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getSavingWallet(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  lockWallet = () => {
    const data = {
      action_type:
        this.state.wallet_saving_data.lock == true ? "disable" : "enable",
      wallet_id: this.state.wallet_saving_data.id,
    };
    callBackMethod(this.props.lockSavingWallet(data, this.props.token))
      .then(() => {
        this.setState({ confirmDisplay: false });
      })
      .then(() => {
        alert(this.props.status_resp);
      })
      .then(() => {
        this.props.getSavingWallet(this.props.token);
      });
  };

  showConfirmation = (props) => {
    this.setState({
      ...this.state,
      confirmDisplay: true,
      wallet_saving_data: props,
    });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <SavingWalletHead />
          <tbody>
            {this.props.savings_wallet.map((content, index) => (
              <SavingWalletBody
                {...content}
                index={index + 1}
                toggleHandler={this.showConfirmation}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <SavingBar active="savings-wallet">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </SavingBar>
        <ConfirmationModal
          display={this.state.confirmDisplay}
          title="Lock Wallet"
          actionText="Lock"
          action={this.lockWallet}
        />
        {/* <SavingWalletModal
          display={this.state.display}
          closeModal={this.closeModal}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  savings_wallet: state.saving.get_save_wallet.wallet_data,
  loading: state.saving.get_save_wallet.loading,
  status_resp: state.saving.get_save_wallet.status_resp,
});

export default connect(mapStateToProps, { getSavingWallet, lockSavingWallet })(
  SavingsWallet
);
