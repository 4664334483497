import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { TransactionLimitRoute } from "./TransactionLimit";
import { AboutusRoute } from "./AboutusConfig";
import { PromotionRoute } from "./Promotion";
import { Error } from "../../../Error";
import IncentiveConfig from "./Incentives/IncentiveConfig";

const Show = () => <div>wassa</div>;

const ExtraRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/transaction-limit`} />
      </Route>
      <Route
        path={`${match.path}/transaction-limit`}
        component={TransactionLimitRoute}
      />
      <Route path={`${match.path}/set-about`} component={AboutusRoute} />
      <Route path={`${match.path}/promotion`} component={PromotionRoute} />
      <Route path={`${match.path}/incentives`} component={IncentiveConfig} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default ExtraRoute;
