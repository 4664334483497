import { combineReducers } from "redux";
import {
  DELETE_SAVINGS,
  GET_SAVING,
  GET_SAVINGS_PRODUCT_DETAILS,
  GET_SAVING_WALLET,
  GET_USER_SAVING,
  LOCK_SAVING_PROD,
  SET_PENALTY,
  SET_SAVING,
  GET_PENALTY,
  GET_WALLET_TRANSACTION,
  UPDATE_SAVING_PRODUCT,
  UPDATE_PENALTY,
  GET_A_USER_SAVINGS,
  SET_WITHDRAWAL,
  GET_WITHDRAWAL,
  CHANGE_WALLET_STATUS,
  GET_USER_SAVING_DETAILS,
  GET_HISTORY,
  UPLOAD_SAVING_IMG,
  FILTER_SAVINGS_BY_DATE,
  FILTER_SAVINGS_BY_STATUS,
  FILTER_USER_SAVINGS_DATE,
  FILTER_USER_SAVINGS_STATUS,
  GET_SAVINGS_GROUP,
  ADD_SAVE_GROUP,
  FILTER_GROUP_SAVE_DATE,
  FILTER_GROUP_SAVE_STATUS,
} from "../action/action.types";

const setSavingProductReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SAVING:
      return {
        ...state,
        save_product_response: action.payload.response_message,
      };
    case UPDATE_SAVING_PRODUCT:
      return {
        ...state,
        edit_product_response: action.payload.success,
      };
    case UPLOAD_SAVING_IMG:
      return {
        ...state,
        upload_saving_img_resp: action.payload.data,
      };
    case ADD_SAVE_GROUP:
      return {
        ...state,
        group_resp: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getSavingProductReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_SAVING:
      return {
        ...state,
        loading: false,
        filtering: false,
        saving_data: action.payload.saving_data,
      };
    case FILTER_SAVINGS_BY_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        saving_data: action.payload.saving_data,
      };
    case FILTER_SAVINGS_BY_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        saving_data: action.payload.saving_data,
      };
    case GET_SAVINGS_PRODUCT_DETAILS:
      return {
        ...state,
        loading: false,
        saving_prod: action.payload.data,
      };
    case LOCK_SAVING_PROD:
      return {
        ...state,
        loading: false,
        saving_prod_resp: action.payload.response_message,
      };
    case DELETE_SAVINGS:
      return {
        ...state,
        loading: false,
        delete_res: action.payload.Response_message,
      };
    default:
      return state;
  }
};

const getSavingWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_SAVING_WALLET:
      return {
        ...state,
        loading: false,
        wallet_data: action.payload.data,
      };
    case GET_WALLET_TRANSACTION:
      return {
        ...state,
        loading: false,
        wallet_trans: action.payload.data,
      };
    case CHANGE_WALLET_STATUS:
      return {
        ...state,
        loading: false,
        status_resp: action.payload.Response_message,
      };
    default:
      return state;
  }
};

const getUserSavingReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SAVING:
      return {
        ...state,
        loading: false,
        user_saving_data: action.payload.data,
      };
    case GET_A_USER_SAVINGS:
      return {
        ...state,
        loading: false,
        a_user_saving_data: action.payload.data,
      };
    case GET_USER_SAVING_DETAILS:
      return {
        ...state,
        loading: false,
        saving_detail: action.payload.data,
      };
    case GET_HISTORY:
      return {
        ...state,
        loading: false,
        history_info: action.payload.data,
      };
    case GET_SAVINGS_GROUP:
      return {
        ...state,
        loading: false,
        save_group: action.payload.data,
      };
    case FILTER_USER_SAVINGS_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        user_saving_data: action.payload.saving_data,
      };
    case FILTER_USER_SAVINGS_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        user_saving_data: action.payload.saving_data,
      };
    case FILTER_GROUP_SAVE_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        save_group: action.payload.saving_data,
      };
    case FILTER_GROUP_SAVE_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        save_group: action.payload.saving_data,
      };
    default:
      return state;
  }
};

const setSavingPenaltyReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PENALTY:
      return {
        ...state,
        response: action.payload.success,
      };
    case UPDATE_PENALTY:
      return {
        ...state,
        result: action.payload.result,
      };
    default:
      return state;
  }
};

const getSavingPenaltyReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_PENALTY:
      return {
        ...state,
        loading: false,
        penalty_data: action.payload.data,
      };
    default:
      return state;
  }
};
const setWithDrawalReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL:
      return {
        ...state,
        response: action.payload.message,
      };
    default:
      return state;
  }
};
const getWithDrawalReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        withdrawal_data: action.payload.data,
      };
    default:
      return state;
  }
};

export const savingReducer = combineReducers({
  set_save_product: setSavingProductReducer,
  get_save_product: getSavingProductReducer,
  get_save_wallet: getSavingWalletReducer,
  get_user_saving: getUserSavingReducer,
  set_save_penalty: setSavingPenaltyReducer,
  get_save_penalty: getSavingPenaltyReducer,
  set_withdrawal: setWithDrawalReducer,
  get_withdrawal: getWithDrawalReducer,
});
