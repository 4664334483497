import { headers } from "../api.config";

export const setPermissionModule = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/permission_modules`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const getPermissionModule = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_permissions?group_by_module=1`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};
