import React from "react";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import { getUserDetails } from "../../../reduxStore/action/action.creator";

class UserDetails extends React.Component {
  closePopup = (e) => {
    e.preventDefault();
    this.props.close();
  };

  render() {
    console.log('token user', this.props.user_details    );
    return (
      <div
        className={`interest-user ${
          this.props.display ? "d-block" : "d-none"
        }`}>
        <div className="text-right">
          <a
            href="#"
            onClick={this.closePopup}
            className="btn-sm btn-primary btn">
            Close
          </a>
        </div>
        <div className="user-div mt-5">
          {this.props.user_loading ? (
            <Loader loading={this.props.user_loading} />
          ) : (
            this.props.user_details && (
              <div className="details">
                {this.props.user_details.profile_photo == null ? (
                  <img
                    alt="Image_placeholder"
                    src="../../assets/img/theme/user_icon.png"
                    className="avatar rounded-circle"
                    width="200"
                    height="200"
                  />
                ) : (
                  <img
                    alt="profile_photo"
                    src={`${process.env.REACT_APP_DOCUMENT_BASE_URL}/${this.props.user_details.profile_photo}`}
                    className="avatar rounded-circle"
                    width="200"
                    height="200"
                  />
                )}
                <h1>{this.props.user_details.username}</h1>
                <p>
                  <strong>FullName:</strong>{" "}
                  {this.props.user_details.first_name}{" "}
                  {this.props.user_details.last_name}
                </p>
                <p>
                  <strong>Gender:</strong> {this.props.user_details.gender}
                </p>
                <p>
                  <strong>E-mail:</strong> {this.props.user_details.email}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {this.props.user_details.phone_number}
                </p>
                {this.props.user_details.bank_account.length > 0 && (
                  <p>
                    <strong>Account Number:</strong>{" "}
                    {
                      this.props.user_details.bank_account[0]
                        .account_number
                    }
                  </p>
                )}
                <div className="text-center">
                  <a
                    href={`/allusers/${this.props.user_details.id}/details`}
                    className="btn-sm btn-primary btn">
                    View More
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_details: state.details.user_detailinfo,
  user_loading: state.details.loading,
});

export default connect(mapStateToProps, {
  getUserDetails,
})(UserDetails);
