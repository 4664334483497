import React from "react";
import { connect } from "react-redux";
import {
  getNotificationChannels,
  toggleNotificationChannel,
} from "../../../reduxStore/action/action.creator";
import NotificationBar from "../NotificationBar";
import { Loader } from "../../../components/Accessories";
import {
  ChannelBody,
  ChannelHead,
} from "../../../components/Rows/NotificationRow";

class NotificationChannel extends React.Component {
  state = {
    display: false,
    currentPage: 1,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.toggle_msg) {
      //   this.props.getNotificationChannels();
    }
  }

  componentDidMount() {
    this.props.getNotificationChannels(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };
  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  toggleChannel = (id) => {
    this.props.toggleNotificationChannel(id, this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <ChannelHead />
          <tbody>
            {this.props.allchannels &&
              this.props.allchannels.map((content, index) => (
                <ChannelBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  toggleChannel={this.toggleChannel}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <NotificationBar
          {...this.props}
          active="channel"
          showModal={this.showModal}>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </NotificationBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.notifications.notificationchannels.loading,
  allchannels: state.notifications.notificationchannels.channels,
  toggle_msg: state.notifications.notificationchannels.toggle_msg,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getNotificationChannels,
  toggleNotificationChannel,
})(NotificationChannel);
