import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class WithdrawalModal extends React.Component {
  state = {
    user_type: "",
    user_id: this.props.admin_data.id,
    withdrawal_date: "",
    interval: "",
    interval_type: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.setWithdrawalDate(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set WithDrawal">
        <div className="body-grid">
          <div className="box box1">
            <label>User Type</label>
            <input
              className="form-control"
              name="user_type"
              value={this.state.user_type}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Withdrawal Date</label>
            <input
              type="date"
              className="form-control"
              name="withdrawal_date"
              value={this.state.withdrawal_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Interval</label>
            <input
              className="form-control"
              name="interval"
              value={this.state.interval}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Interval Type</label>
            <select
              className="form-control"
              name="interval_type"
              value={this.state.interval_type}
              onChange={this.handleChange}>
              <option value="days">Day</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Set Withdrawal Date
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  admin_data: state.login.data,
});

export default connect(mapStateToProps, {})(WithdrawalModal);
