import React from "react";
import { connect } from "react-redux";
import CardLayout from "../VirtualCardBar";
import KycVirtualCardModal from "./KycVirtualCardModal";
import {
  setKycVirtualCard,
  getKycVirtualCard,
  getKycLevel,
  editKycVirtualCard,
} from "../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../components/Accessories";
import {
  KycVirtualCardHead,
  KycVirtualCardBody,
} from "../../../../components/Rows/CardTable";
import KycVirtualCardEditModal from "./KycVirtualCardEditModal";

class KycVirtualCardSetting extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getKycVirtualCard(this.props.token);
    this.props.getKycLevel(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      shouldEdit: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getKycVirtualCard(this.props.token);
  };

  submitRate = (data) => {
    callBackMethod(this.props.setKycVirtualCard(data, this.props.token))
      .then(() =>
        this.setState({
          display: false,
        })
      )
      .then(() => alert(this.props.response));
  };

  editRate = (data) => {
    callBackMethod(
      this.props.editKycVirtualCard(data, this.state.idToEdit, this.props.token)
    )
      .then(() => this.setState({ shouldEdit: false }))
      .then(() => alert(this.props.edit_response))
      .then(() => this.props.getKycVirtualCard(this.props.token));
  };

  editKyc_VirtualCard = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props.id,
      shouldEdit: true,
      property: props,
    });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <KycVirtualCardHead />
          <tbody>
            {this.props.kyc_card &&
              this.props.kyc_card.map((content, index) => (
                <KycVirtualCardBody
                  {...content}
                  kyc_levels={this.props.levels}
                  index={index + 1}
                  edit={this.editKyc_VirtualCard}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardLayout active="kycvirtualcard-setting">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </CardLayout>
        <KycVirtualCardModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitRate={this.submitRate}
        />
        <KycVirtualCardEditModal
          display={this.state.shouldEdit}
          closeModal={this.closeModal}
          editRate={this.editRate}
          property={this.state.property}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  response: state.virtualCards.set_kyc_virtual.response,
  kyc_card: state.virtualCards.get_kyc_virtual.kyc_card,
  loading: state.virtualCards.get_kyc_virtual.loading,
  levels: state.kyc.get_kyc_level.levels,
  edit_response: state.virtualCards.set_kyc_virtual.response_message,
});

export default connect(mapStateToProps, {
  setKycVirtualCard,
  getKycVirtualCard,
  getKycLevel,
  editKycVirtualCard,
})(KycVirtualCardSetting);
