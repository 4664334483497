import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  LoansTableHead,
  LoanProductList,
  LoansRequests,
  LoanRequestsTableHead,
} from "../../../../components/Rows";
import "../../../../morestyles.css";
// import FeeSharing from "./FeeSharing";
import { getFullDetails } from "../../../../API/FullDetailRequest";
import {
  detailsInfo,
  getAllLoanProducts,
} from "../../../../reduxStore/action/action.creator";
import "../../../../morestyles.css";
import {
  Modal,
  Loader,
  Pagination,
  CardHeaderHolder,
  CardBodyHolder,
} from "../../../../components/Accessories";
import { setLoan, updateLoanProduct } from "../../../../API/loanRequest";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";

class LoanProducts extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    loanName: "",
    minAmount: "",
    maxAmount: "",
    penalty: "",
    tenureType: "days",
    minScore: "",
    minKyc: "",
    chargeFee: "",
    approve: false,
    maxTenure: "",
    intRate: "",
    rateType: "days",
    edit: false,
    idtoEdit: null,
  };

  componentDidMount() {
    this.props.getAllLoanProducts(this.props.token);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    try {
      const res = await setLoan(this.state, this.props.token);
      if (res.success) {
        this.setState({
          display: false,
        });
        this.props.getAllLoanProducts(this.props.token);
      }
      // alert(JSON.stringify(res.success));
    } catch (error) {}
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        display: true,
        edit: false,
      },
      this.getTransactionCategory
    );
  };
  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllLoanProducts(this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  goToProductDetails = async (props) => {
    const { index, _id } = props;
    this.props.detailsInfo(`loan/loan_products/${index}`, this.props.token);
    this.props.history.push({
      pathname: `${this.props.match.url}/${index}/details`,
      state: index,
    });
  };

  toggleApprove = () => {
    if (this.state.edit) {
      this.setState((prevState) => ({
        ...this.state,
        e_approve: !this.state.e_approve,
      }));
    } else {
      this.setState((prevState) => ({
        approve: !prevState.approve,
      }));
    }
  };

  editLoan = (id, props) => {
    this.setState((prevState) => ({
      ...this.state,
      display: true,
      edit: true,
      idtoEdit: id,
      e_loanName: props.name,
      e_minAmount: props.min_amount,
      e_maxAmount: props.max_amount,
      e_penalty: props.penalty,
      e_minscore: props.min_credit_score,
      e_minKyc: props.min_kyc_level,
      e_tenureType: props.tenure_type,
      e_chargefee: props.process_fees,
      e_approve: props.system_can_approved,
      e_max_tenure: props.max_tenure,
      e_rate: props.interest_rate,
    }));
  };

  handleEdit = async () => {
    try {
      const data = {
        id: this.state.idtoEdit,
        datatoedit: this.state,
      };
      const res = await updateLoanProduct(data, this.props.token);
      if (res == true) {
        this.setState({
          display: false,
        });
        this.props.getAllLoanProducts(this.props.token);
      }
    } catch (error) {}
  };

  render() {
    let itemInTable;
    // const total = parseInt(Math.ceil(this.props.total / 15));
    // const paginateNumber = Array(this.props.total).fill(0);
    // this.props.total > 15 &&
    //   !this.props.loading &&
    //   (renderPaginate = (
    //     <Pagination
    //       paginateNumber={paginateNumber}
    //       currentPage={this.state.currentPage}
    //       handlePagination={this.changePage}
    //       handlePrevious={this.goPrevious}
    //       handleNext={this.goNext}
    //       total={total}
    //       totalData={this.props.total}
    //     />
    //   ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <LoansTableHead />
          <tbody>
            {this.props.loans.map((content, index) => (
              <LoanProductList
                {...content}
                {...this.props}
                index={this.getIndex(index + 1)}
                goToDetails={this.goToProductDetails}
                toggleHandler={this.toggleHandler}
                editField={this.editLoan}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <CardHeaderHolder>
              <Settings {...this.props} active="LoanProducts" />
            </CardHeaderHolder>

            <CardBodyHolder>
              <Tabs id="controlled-tab-example">
                <Tab
                  eventKey="loanProducts"
                  title="Loan Products"
                  tabClassName="mTab kycTab">
                  <div
                    className="table-responsive py-4"
                    style={{ maxHeight: "22em" }}>
                    <div
                      class="col-lg-12 col-5 text-right"
                      style={{ padding: "0 0.5em 1em" }}>
                      <a
                        href="#"
                        class="btn btn-sm btn-neutral"
                        onClick={this.handleRefresh}>
                        <i class="fas fa-sync-alt"></i> Refresh
                      </a>
                      <a
                        href="#"
                        class="btn btn-sm btn-neutral"
                        onClick={this.showModal}>
                        <i class="fas fa-plus"></i> New
                      </a>
                      {/* <a href="#" class="btn btn-sm btn-neutral">
                                <i class="fas fa-cog"></i> Config
                              </a> */}
                    </div>
                    <table className="table table-flush" id="datatable-basic">
                      {itemInTable}
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </CardBodyHolder>
          </div>
        </div>

        <Modal
          display={this.state.display ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Create Loan Profile">
          <div style={{ padding: "1em" }}>
            <div className="row">
              <div className="col-md-12 col">
                <div className="input-div">
                  <input
                    placeholder="loan name"
                    name={this.state.edit ? "e_loanName" : "loanName"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_loanName
                        : this.state.loanName
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    placeholder="min amount"
                    name={this.state.edit ? "e_minAmount" : "minAmount"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_minAmount
                        : this.state.minAmount
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    placeholder="max amount"
                    name={this.state.edit ? "e_maxAmount" : "maxAmount"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_maxAmount
                        : this.state.maxAmount
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    placeholder="penalty"
                    name={this.state.edit ? "e_penalty" : "penalty"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_penalty
                        : this.state.penalty
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <select
                    name={this.state.edit ? "e_tenureType" : "tenureType"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_tenureType
                        : this.state.tenureType
                    }>
                    <option value="days">days</option>
                    <option value="month">month</option>
                    <option value="year">year</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 col">
                <div className="input-div">
                  <input
                    placeholder="min credit score"
                    type="number"
                    name={this.state.edit ? "e_minscore" : "minScore"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_minscore
                        : this.state.minScore
                    }
                  />
                </div>
              </div>
              <div className="col-md-4 col">
                <div className="input-div">
                  <input
                    placeholder="min kyc level"
                    type="number"
                    name={this.state.edit ? "e_minKyc" : "minKyc"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit ? this.state.e_minKyc : this.state.minKyc
                    }
                  />
                </div>
              </div>
              <div className="col-md-4 col">
                <div className="input-div">
                  <input
                    placeholder="charging fee"
                    type="number"
                    name={this.state.edit ? "e_chargefee" : "chargeFee"}
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_chargefee
                        : this.state.chargeFee
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                    checked={
                      this.state.edit
                        ? this.state.e_approve
                        : this.state.approve
                    }
                    onChange={this.toggleApprove}
                  />
                  <label
                    className="custom-control-label"
                    for=" customCheckLogin">
                    <span className="text-muted">Approval</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    placeholder="max tenure"
                    name={this.state.edit ? "e_max_tenure" : "maxTenure"}
                    type="number"
                    onChange={this.handleChange}
                    value={
                      this.state.edit
                        ? this.state.e_max_tenure
                        : this.state.maxTenure
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    placeholder="interest rate"
                    name={this.state.edit ? "e_rate" : "intRate"}
                    type="number"
                    onChange={this.handleChange}
                    value={
                      this.state.edit ? this.state.e_rate : this.state.intRate
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col">
                <div className="input-div">
                  <input
                    name="rateType"
                    onChange={this.handleChange}
                    disabled
                    value={
                      this.state.edit
                        ? this.state.e_tenureType
                        : this.state.tenureType
                    }
                    style={{ backgroundColor: "#ccc" }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  {this.state.edit ? (
                    <button
                      type="button"
                      class="btn btn-warning"
                      onClick={this.handleEdit}>
                      Edit
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={this.handleSubmit}>
                      Add Loan Profile
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loans: state.loans.allLoanProducts.loanProducts, // state.key_to_loanReducer.key_to_allloanReducer.key_from_allLoanReducer
  permission: state.login.permission,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  detailsInfo,
  getAllLoanProducts,
})(LoanProducts);
