import { headers } from "./api.config";

const processTransaction = (result) => ({
	_id: result.id,
	id: result.userId,
	username: result.user,
	account: result.debitedAccount,
	type: result.transactionType,
	status: result.transactionStatus,
	internal_status: result.internalStatus,
	date: result.transactionDate,
	amount: result.transactionAmount,
	narration: result.narration,
	userObject: result.userObject,
	transactionServiceId: result.transactionServiceId,
	serviceCode: result.serviceCode,
	serviceName: result.transactionService,
	transactionRef: result.transactionRef,
	transactionCategory: result.transactionCategory,
	recipientName: result.recipientName,
	recipientNumber: result.recipientNumber,
	allresult: result,
});
export const fetchTransaction = async (url, token) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
		method: "GET",
		headers: headers(token),
	});

	if (response.ok) {
		const { data, success } = await response.json();
		const { transactions, allTransactions } = await data;
		const transaction = transactions.map(processTransaction);
		return { transaction, success, allTransactions };
	} else {
		const { response_error } = await response.json();
		throw new Error(response_error);
	}
};

export const filterTransactionStatus = async (status, page, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_all_txns?page=${page}&transactionStatus=${status}`,
		{
			headers: headers(token),
		}
	);
	if (response.ok) {
		const { data, success } = await response.json();
		const { transactions, allTransactions } = await data;
		const transaction = transactions.map(processTransaction);
		return { transaction, success, allTransactions };
	}
};

export const filterTransaction = async (data, token, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/transaction/get_all_txns?per_page=15&startDate=${data.startDate}&endDate=${data.endDate}&per_page=1000&transactionRef=${data.reference}&debitedAccount=${data.debited_ac}&channelId=${data.channel}&transactionType=${data.transType}&narration=${data.narration}&transactionAmount=${data.transAmount}&serviceCode=${data.serviceCode}&page=${page}`,
		{
			headers: headers(token),
		}
	);
	if (response.ok) {
		const { data, success } = await response.json();
		const { transactions, allTransactions } = await data;
		const transaction = transactions.map(processTransaction);
		return { transaction, success, allTransactions };
	}
};
