import React from "react";
import { Loader, callBackMethod } from "../../../../../components/Accessories";
import BillsBar from "../BillsBar";
import {
  getBillServiceCode,
  addCodeConfig,
  editAllCode,
} from "../../../../../reduxStore/action/action.creator";
import {
  BillCategorySetupBody,
  BillCategorySetupHead,
} from "../../../../../components/Rows/BillerRow";
import { connect } from "react-redux";
import AddCode from "./ServiceCodeModal";
import EditCode from "../EditModal";

class ServiceCode extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getBillServiceCode(`services_codes`, this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      edit_display: false,
    });
  };

  handleSubmit = (data) => {
    // alert(JSON.stringify(data));
    callBackMethod(
      this.props.addCodeConfig(`services_codes`, data, this.props.token)
    )
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.code_response))
      .then(() =>
        this.props.getBillServiceCode(`services_codes`, this.props.token)
      );
  };

  editCode = (data) => {
    this.setState({
      ...this.state,
      edit_display: true,
      idToEdit: data.id,
      dataToEdit: data,
    });
  };

  handleEdit = (data) => {
    callBackMethod(
      this.props.editAllCode(
        `services_codes/${this.state.idToEdit}`,
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ edit_display: false }))
      .then(() => alert(this.props.edit_response))
      .then(() =>
        this.props.getBillServiceCode(`services_codes`, this.props.token)
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <BillCategorySetupHead />
          <tbody>
            {this.props.bills_service_code &&
              this.props.bills_service_code.map((content, index) => (
                <BillCategorySetupBody
                  {...content}
                  index={index + 1}
                  editCode={this.editCode}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <BillsBar {...this.props} active="Service-Code">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </BillsBar>
        <AddCode
          display={this.state.display}
          closeModal={this.closeModal}
          submit={this.handleSubmit}
        />
        <EditCode
          display={this.state.edit_display}
          closeModal={this.closeModal}
          codeData={this.state.dataToEdit}
          submit={this.handleEdit}
          isService={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  loading: state.bills.billconfig.loading,
  bills_service_code: state.bills.billconfig.bills_service_code,
  code_response: state.bills.addbillconfig.code_response,
  edit_response: state.bills.addbillconfig.edit_response,
});

export default connect(mapStateToProps, {
  getBillServiceCode,
  addCodeConfig,
  editAllCode,
})(ServiceCode);
