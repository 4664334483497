import React from "react";
import { BrouserRouter as Router, Route, Switch } from "react-router-dom";
import DeveloperAccounts from "./DeveloperAccounts";
import DeveloperApiCalls from "./DeveloperApiCalls";
import DeveloperCallStats from "./DeveloperCallStats";
import DeveloperDoc from "./DeveloperDocument";

const DeveloperAccountsRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={DeveloperAccounts} />
      <Route
        exact
        path={`${match.path}/api-calls`}
        component={DeveloperApiCalls}
      />
      <Route
        exact
        path={`${match.path}/call-stats`}
        component={DeveloperCallStats}
      />
      <Route exact path={`${match.path}/documents`} component={DeveloperDoc} />
    </Switch>
  </>
);

export default DeveloperAccountsRoute;
