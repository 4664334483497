import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import KycSettingModal from "./KycSettingModal";
import {
  setKycLevelSettings,
  getKycLevelSettings,
} from "../../../../../reduxStore/action/action.creator";
import { Loader } from "../../../../../components/Accessories";
import {
  LevelSettingHead,
  LevelSettingBody,
} from "../../../../../components/Rows/KycTable";

class KycSetting extends React.Component {
  state = {
    display: false,
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  setSetting = (data) => {
    this.props.setKycLevelSettings(data, this.props.token);
  };

  componentDidMount() {
    this.props.getKycLevelSettings(this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getKycLevelSettings(this.props.token);
  };

  render() {
    let itemToDisplay;
    if (this.props.loading) {
      itemToDisplay = <Loader loading={this.props.loading} />;
    } else {
      itemToDisplay = (
        <>
          <LevelSettingHead />
          <tbody>
            {this.props.level_settings &&
              this.props.level_settings.map((content) => (
                <LevelSettingBody {...content} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <KycBar {...this.props} active="Setting">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
          </div>
        </KycBar>
        <KycSettingModal
          display={this.state.display}
          closeModal={this.closeModal}
          setSetting={this.setSetting}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_level_settings.loading,
  response: state.kyc.set_level_settings.response,
  token: state.login.token,
  level_settings: state.kyc.get_level_settings.level_settings,
});

export default connect(mapStateToProps, {
  setKycLevelSettings,
  getKycLevelSettings,
})(KycSetting);
