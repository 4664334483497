import React from "react";
import { currencyFormatter, dateFormatter } from "../Row";
import { Link } from "react-router-dom";

export const AgentTableHead = () => (
  <thead class="thead-light">
    <tr>
      <th>S/N</th>
      <th>FirstName</th>
      <th>LastName</th>
      <th>Phone Number</th>
      <th>Email</th>
      <th>Address</th>
      <th>Sales Agent ID</th>
      <th>Number of Users</th>
      <th>Status</th>
      <th>Created At</th>
      {/* <th>Action</th> */}
      <th>Change Status</th>
    </tr>
  </thead>
);

export const AgentTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.first_name}</td>
    <td>{props.last_name}</td>
    <td>{props.phone_number}</td>
    <td>{props.email}</td>
    <td>{props.address}</td>
    <td>{props.sales_agent_id}</td>
    <td>{props.number_of_users}</td>
    <td>{props.enabled == 1 ? "Active" : "In-Active"}</td>
    <td>{dateFormatter(props.created_at)}</td>
    {/* <td>
      <i className="fa fa-pencil"></i>
    </td> */}
    <td>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          name="is_required"
          checked={props.enabled}
          onChange={() => props.toggleHandler(props)}
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
  </tr>
);

export const ClaimTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Sales Agent Id</th>
      <th>Claimed Email</th>
      <th>Claimed Phone Number</th>
      <th>Status</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const ClaimTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.sales_agent_id}</td>
    <td>{props.user_email}</td>
    <td>{props.user_phone_no}</td>
    <td>{props.status}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      {props.status != "approve" ? (
        <button onClick={() => props.approveClaim(props)}>approve claim</button>
      ) : (
        <span>already approved</span>
      )}
    </td>
  </tr>
);
