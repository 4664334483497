import React from "react";
import { connect } from "react-redux";
import ExtraBar from "../Extra";
import PromotionModal from "./PromotionModal";
import EditPromotionModal from "./EditPromotionModal";
import {
  sendPromotion,
  getAllPromotions,
  deletePromotion,
  togglePromo,
  editPromotion,
} from "../../../../../reduxStore/action/action.creator";
import {
  PromotionHead,
  PromotionRow,
} from "../../../../../components/Rows/PromotionTable";
import { data } from "jquery";
import { Loader } from "../../../../../components/Accessories";

class Promotion extends React.Component {
  state = {
    display: false,
    editing: false,
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.allpromo) {
    //   alert("loaded");
    // }
    // if (!nextProps.history && (nextProps.response || nextProps.toggle_res)) {
    //   alert(nextProps.response);
    //   // this.props.getAllPromotions();
    // }
  }

  componentDidMount() {
    this.props.getAllPromotions(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      editing: false,
    });
  };

  sendPromotion = (data) => {
    this.props.sendPromotion(data, this.props.token);
    this.setState({
      display: false,
    });
    this.props.getAllPromotions(this.props.token);
  };

  deletePromo = (id) => {
    this.props.deletePromotion(id, this.props.token);
  };

  togglePromotion = (id) => {
    this.props.togglePromo(id, this.props.token);
  };

  goToDetails = (id) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/promotion-details`,
      state: id,
    });
    // this.props.getPromoInfo(id);
  };

  goToSub = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `${this.props.match.url}/promotion-sub`,
    });
  };

  editPromotion = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props._id,
      display: true,
      promotionData: props,
      editing: true,
    });
  };

  editPromotionData = (data) => {
    this.props.editPromotion(this.state.idToEdit, data, this.props.token);
    this.setState({
      display: false,
    });
    this.props.getAllPromotions(this.props.token);
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllPromotions(this.props.token);
  };

  viewSubscribers = (serviceApplicable) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${serviceApplicable}/subscriber`,
      state: serviceApplicable,
    });
  };

  render() {
    let itemInTable = "";
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <PromotionHead />
          <tbody>
            {this.props.allpromo &&
              this.props.allpromo.map((content, index) => (
                <PromotionRow
                  {...content}
                  deletePromo={this.deletePromo}
                  togglePromotion={this.togglePromotion}
                  goToDetails={this.goToDetails}
                  index={index + 1}
                  editPromotion={this.editPromotion}
                  viewSubscribers={this.viewSubscribers}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <ExtraBar {...this.props} active="promotion">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.goToSub}>
              <i class="fa fa-bell-o"></i> Subscriptions
            </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </ExtraBar>
        <PromotionModal
          display={this.state.display}
          closeModal={this.closeModal}
          setPromotion={this.sendPromotion}
        />
        <EditPromotionModal
          display={this.state.editing}
          closeModal={this.closeModal}
          promotionData={this.state.promotionData}
          editPromotion={this.editPromotionData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  successmsg: state.promotions.setPromo.success,
  response_message: state.promotions.setPromo.res_msg,
  allpromo: state.promotions.getPromo.allpromo,
  loading: state.promotions.getPromo.loading,
  response: state.promotions.getPromo.response,
  toggle_res: state.promotions.getPromo.toggle_res,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  sendPromotion,
  getAllPromotions,
  deletePromotion,
  togglePromo,
  editPromotion,
})(Promotion);
