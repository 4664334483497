import React from "react";
import { getAdminRole } from "../../../../../reduxStore/action/action.creator";
import { connect } from "react-redux";

class PermissionModuleForm extends React.Component {
  state = {
    module_name: "",
    description: "",
    role_id: "",
  };

  componentDidMount() {
    this.props.getAdminRole(this.props.token);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submitPermissionModule(this.state);
  };

  render() {
    return (
      <div className="content" style={{ paddingTop: "1em" }}>
        <div className="" style={{ padding: ".8em 0" }}>
          <div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Name of Permission Module
              </label>
              <input
                type="text"
                class="form-control"
                name="module_name"
                value={this.state.module_name}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter name"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Enter Role Type</label>
              <select
                type="text"
                class="form-control"
                name="role_id"
                value={this.state.role_id}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter name">
                <option></option>
                {this.props.data &&
                  this.props.data.map((content) => (
                    <option value={content.id}>{content.name}</option>
                  ))}
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Description of Permission
              </label>
              <textarea
                type="text"
                class="form-control"
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter description"
              />
            </div>
          </div>
          <div style={{ paddingTop: "1em" }}>
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.handleSubmit}>
              Insert Permission Module
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
  token: state.login.token,
});

export default connect(mapStateToProps, { getAdminRole })(PermissionModuleForm);
