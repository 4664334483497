import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import "../../../../morestyles.css";
import { Icon } from "../../../../components/Accessories";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class AuthorizationTab extends React.Component {
  state = {
    key: "roles-settings",
  };

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  render() {
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings
                      {...this.props}
                      active="Authorization"
                      authorize={this.props.authorize}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={this.props.active}
                        onSelect={this.getActiveState}>
                        <Tab
                          eventKey="roles-settings"
                          title={
                            <Link to={`/settings/authorization/roles-settings`}>
                              <Icon
                                className="fa fa-cog"
                                title="Roles Config"
                              />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="permission-module"
                          title={
                            <Link
                              to={`/settings/authorization/permission-module`}>
                              <Icon
                                className="fa fa-door"
                                title="Permission Module Config"
                              />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        <Tab
                          eventKey="permission-settings"
                          title={
                            <Link to={`/settings/authorization/permissions`}>
                              <Icon
                                className="fa fa-door"
                                title="Permission Config"
                              />
                            </Link>
                          }
                          tabClassName="mTab kycTab">
                          {this.props.children}
                        </Tab>
                        {this.props.authorize && (
                          <Tab
                            eventKey="admin-permission"
                            title={
                              <Link
                                to={`/settings/authorization/set-admin-permissions`}>
                                <Icon
                                  className="fa fa-windows"
                                  title="Assign Permission"
                                />
                              </Link>
                            }
                            tabClassName="mTab kycTab">
                            {this.props.children}
                          </Tab>
                        )}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = () => ({
  authorize: true,
});

export default connect(mapStateToProps, {})(AuthorizationTab);
