import { headers } from "./api.config";

export const addCardBatch = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/batches`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { data, success, message } = await response.json();
    return { data, success, message };
  }
};

export const addCardRequestBatch = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/card_requests_batches`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetchCardRequestBatch = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/card_requests_batches`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetchAllBatches = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const removeBatch = async (id, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/${url}/${id}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { Response_message } = await response.json();
    return { Response_message };
  }
};
