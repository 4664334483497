import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";
import {
  addBatch,
  getAllCardBatch,
} from "../../../../reduxStore/action/action.creator";

class AddBatchModal extends React.Component {
  state = {
    batch_ref: "",
    status: "pending",
    admin_id: "",
    region: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    // alert(JSON.stringify(this.state));
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add Card Batch">
        <div className="body-grid">
          <div className="box box1">
            <label>Batch Reference</label>
            <input
              className="form-control"
              name="batch_ref"
              value={this.state.batch_ref}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box2">
            <label>Admin Id</label>
            <input
              className="form-control"
              name="admin_id"
              value={this.state.admin_id}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Region</label>
            <input
              className="form-control"
              name="region"
              value={this.state.region}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-4"
                onClick={this.submit}>
                Add Card Batch
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, { addBatch, getAllCardBatch })(AddBatchModal);
