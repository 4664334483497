import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Report from "./Report";
import UserReport from "./UserReport/UserReport";
import TransactionReportRoute from "./TransactionReport/TransactionReportRoute";
import SubscriptionReport from "./SubscriptionReport/Subscription";
import SavingsReportRoute from "./SavingsReport/SavingsReportRoute";
import InvestmentReportRoute from "./InvestmentReport/InvestmentReportRoute";

const ReportRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Report} />
      <Route exact path={`${match.path}/user-report`} component={UserReport} />
      <Route
        path={`${match.path}/transaction-report`}
        component={TransactionReportRoute}
      />
      <Route
        path={`${match.path}/subscription-report`}
        component={SubscriptionReport}
      />
      <Route
        path={`${match.path}/savings-report`}
        component={SavingsReportRoute}
      />
      <Route
        path={`${match.path}/investment-report`}
        component={InvestmentReportRoute}
      />
    </Switch>
  </>
);
export default ReportRoute;
