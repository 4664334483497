import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import SavingsConfig from "./SavingsConfig/SavingsConfig";
import SavingsWallet from "./SavingWalletConfig/SavingWallet";
import SavingsPenalty from "./SavingPenalty/SavingPenalty";
import UserSavings from "./AllSavings/UserSavings";
import SavingConfigDetail from "./SavingsConfig/SavingsConfigDetails";
import UserSavingWallet from "./AllSavings/UserSavingDetails";
import WalletTransaction from "./WalletTransaction/walletTransaction";
import Withdrawal from "./Withdrawal/withdrawaldate";
import SavingHistory from "./SavingHistory/SavingHistory";
import UserSavingDetails from "./AllSavings/UserSavingDetails";
import SavingsGroup from "./SavingsGroup/SavingsGroup";

const SavingRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/savings-config`} />
      </Route>
      <Route
        exact
        path={`${match.path}/savings-config`}
        component={SavingsConfig}
      />
      <Route
        exact
        path={`${match.path}/savings-config/:id/details`}
        component={SavingConfigDetail}
      />
      <Route
        exact
        path={`${match.path}/saving-wallet`}
        component={SavingsWallet}
      />

      <Route
        exact
        path={`${match.path}/saving-penalty`}
        component={SavingsPenalty}
      />
      <Route exact path={`${match.path}/user-saving`} component={UserSavings} />
      <Route
        exact
        path={`${match.path}/user-saving/:id/details`}
        component={UserSavingDetails}
      />
      <Route
        exact
        path={`${match.path}/wallet_transaction`}
        component={WalletTransaction}
      />
      <Route
        exact
        path={`${match.path}/withdrawal_date`}
        component={Withdrawal}
      />
      <Route
        exact
        path={`${match.path}/saving-history`}
        component={SavingHistory}
      />
      <Route
        exact
        path={`${match.path}/savings-group`}
        component={SavingsGroup}
      />
    </Switch>
  </>
);
export default SavingRoute;
