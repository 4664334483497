import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserCategory from "./UserCategory";

const CategoryRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={UserCategory} />
    </Switch>
  </>
);
export default CategoryRoute;
