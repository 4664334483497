import React from "react";

// table head for all fees
export const FeesTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>User Category</th>
      <th>Category type</th>
      <th>Service Code</th>
      <th>Fee Value</th>
      <th style={{ textAlign: "center" }}>Actions</th>
    </tr>
  </thead>
);

const checkCategory = (data, userCategory) => {
  const cat = data.map((content) => {
    if (content.id == userCategory) {
      return content.name;
    }
  });
  return cat;
};

/** table body row for fees */
export const FeeRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.name}</td>
      <td>{props.description}</td>
      <td>{checkCategory(props.categoryName, props.userCategory)}</td>

      <td>{props.transCategory}</td>
      <td>{props.transCode}</td>
      <td>{props.feeValue}</td>
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <i
          className="fa fa-trash-o"
          style={{ padding: "0 .25em" }}
          onClick={() => props.deleteProfile(props._id)}></i>
        <i
          className="fa fa-pencil"
          style={{ padding: "0 .25em" }}
          onClick={() => props.editProfile(props)}></i>
        <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            onChange={() => props.toggleHandler(props._id, props.enabled)}
            checked={props.enabled}
            // data-toggle="modal"
            // data-target="#exampleModal"
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
        <i
          class="fas fa-share-alt"
          onClick={() => props.goToDetails(props._id)}></i>
      </td>
    </tr>
  </>
);
