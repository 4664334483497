import React from "react";
import { connect } from "react-redux";
import CardBar from "../PhysicalCardBar";
import {
  getAllCardRequest,
  deleteBatch,
} from "../../../../reduxStore/action/action.creator";
import { Loader, callBackMethod } from "../../../../components/Accessories";
import {
  CardRequestHead,
  CardRequestBody,
} from "../../../../components/Rows/CardTable";

class CardRequest extends React.Component {
  state = {
    display: false,
    showBox: false,
    check_all: false,
    individual_check: false,
  };

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getAllCardRequest("card_requests", this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllCardRequest("card_requests", this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  deleteCardRequest = (id) => {
    callBackMethod(
      this.props.deleteBatch(id, "card_requests", this.props.token)
    ).then(() =>
      this.props.getAllCardRequest("card_requests", this.props.token)
    );
  };

  addCheckBox = () => {
    if (this.state.showBox) {
      //submit batches
    } else {
      this.setState({
        showBox: !this.state.showBox,
      });
    }
  };

  checkAll = () => {
    this.setState((prevState) => ({
      check_all: !prevState.check_all,
    }));
  };

  onToggleHandler = (id) => {
    this.props.all_request.map((content) => {
      if (content.id == id) {
        this.setState({
          individual_check: !this.state.individual_check,
        });
      }
    });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <CardRequestHead
            checkBoxShow={this.state.showBox}
            checkAll={this.checkAll}
          />
          <tbody>
            {this.props.all_request &&
              this.props.all_request.map((content, index) => (
                <CardRequestBody
                  {...content}
                  index={index + 1}
                  deleteRequest={this.deleteCardRequest}
                  checkBoxShow={this.state.showBox}
                  check_all={this.state.check_all}
                  onToggle={() => this.onToggleHandler(content.id)}
                  individual_check={this.state.individual_check}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardBar {...this.props} active="Card Request">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <button
              className="btn btn-sm btn-neutral"
              onClick={this.addCheckBox}>
              {!this.state.showBox ? "create batches" : "submit batches"}
            </button>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </CardBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.card.all_batches.loading,
  all_request: state.card.all_batches.request,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getAllCardRequest,
  deleteBatch,
})(CardRequest);
