import React from "react";
import { connect } from "react-redux";
import { SplitTableHead, SplitRow } from "../../../../components/Rows";
import {
  getBeneficiaries,
  detailsInfo,
  deleteSplit,
  getFeeSplitDetails,
} from "../../../../reduxStore/action/action.creator";
import { sendSplit, deleteFeeSplit } from "../../../../API/FeeRequest";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { Loader } from "../../../../components/Accessories";

class FeeSharing extends React.Component {
  state = {
    shareType: "Percentage",
    beneficiaryData: "",
    value: 0,
    splitDetail: [],
    shareTotal: 0,
    total: 100,
    formReady: false,
    currentPage: 1,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { feeSplit, feeValue } = nextProps.feedata;
    if (nextProps.feedata) {
      //
      let sum = 0;
      feeSplit &&
        feeSplit.map((content) => {
          sum += parseInt(content.share);
        });
      if (sum > 0) {
        this.setState((prevState) => ({
          splitDetail: feeSplit,
          total: 100 - sum,
          shareTotal: (sum / 100) * feeValue,
        }));
      }
    }
    if (nextProps.deleted) {
      let sum = 100;
      feeSplit &&
        feeSplit.map((content) => {
          sum -= parseInt(content.share);
        });
      if (this.state.total === 0) {
        this.setState((prevState) => ({
          splitDetail: feeSplit,
          total: sum,
          formReady: sum < 100 ? false : true,
        }));
      }
      // this.setState({
      //   splitDetail: feeSplit,
      // });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  sendRequest = async () => {
    try {
      const { _id } = this.props.feedata;

      const res = await sendSplit(
        _id,
        this.state.splitDetail,
        this.props.token
      );
      const id = this.props.history.location.state;
      this.props.detailsInfo(
        `transaction/get_fee_profile/${localStorage.getItem("key")}`,
        this.props.token
      );
    } catch (error) {}
  };

  handleSubmit = () => {
    const beneficiaryInfo = this.state.beneficiaryData.split(",");
    if (
      this.checkBeneficiary() == false &&
      this.state.beneficiaryData.length > 0
    ) {
      if (!this.props.loading) {
        const { feeValue, _id } = this.props.feedata;

        const data = {
          userType: beneficiaryInfo[1],
          share: this.state.value,
          beneficiary_id: beneficiaryInfo[0],
          // value: this.state.shareTotal,
        };
        const valueInPercentage = (parseFloat(data.share) / 100) * 10 * 10;
        let sum = 0;
        this.state.splitDetail.map((content) => {
          sum += parseFloat(content.share);
        });
        if (
          this.state.total <= 100 &&
          sum + parseFloat(this.state.value) <= 100
        ) {
          if (this.state.total !== 0) {
            this.setState((prevState) => ({
              shareTotal:
                prevState.shareTotal +
                (parseFloat(data.share) / 100) * feeValue,
              total: prevState.total - valueInPercentage,
              splitDetail: [...prevState.splitDetail, data],
            }));
          }
        } else {
          alert("more than 100");
        }
      } else {
        alert("loading");
      }
    } else {
      alert("beneficiary already exist or Beneficiary is empty");
    }
  };
  checkBeneficiary = () => {
    return this.state.splitDetail.some(
      (content) => content.userType === this.state.beneficiaryData.split(",")[1]
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.total !== this.state.total) {
      this.validateSplit();
    }
  }

  validateSplit = () => {
    if (this.state.total === 0) {
      this.setState({
        formReady: true,
      });
    }
  };

  componentDidMount() {
    this.props.getBeneficiaries(this.props.token);
    let index = this.props.history.location.pathname;
    const indexof_first = index.indexOf("s/");
    const indexof_second = index.indexOf("s/", indexof_first + 1);

    const id = index.substring(
      indexof_second + 2,
      index.indexOf("/feesharing")
    );
    this.props.getFeeSplitDetails(
      `transaction/get_fee_profile/${id}`,
      this.props.token
    );
    localStorage.setItem("key", id);
  }

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  deleteSplit = async (data) => {
    const { beneficiary_id, index } = JSON.parse(data);
    const { feeSplit, feeValue } = this.props.feedata;
    //
    const fee_profileid = localStorage.getItem("key");
    this.props.deleteSplit(
      `transaction/remove_fee_split_item/${fee_profileid}`,
      { fee_profileid, beneficiary_id },
      this.props.token
    );
    this.setState((prevState) => ({
      shareTotal:
        prevState.shareTotal -
        (prevState.splitDetail[index - 1].share / 100) * feeValue,
      total:
        parseInt(this.state.total) +
        parseInt(prevState.splitDetail[index - 1].share),
      splitDetail: prevState.splitDetail.filter(
        (fees) => fees.beneficiary_id != beneficiary_id
      ),
      formReady: false,
    }));
  };

  render() {
    const beneficiaryInfo = this.state.beneficiaryData.split(",");
    let itemInCard;
    if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
    } else {
      const { feeSplit, name } = this.props.feedata && this.props.feedata;
      // alert(JSON.stringify(feeSplit));
      // alert(JSON.stringify(feeSplit));
      itemInCard = (
        <>
          <div className="row" style={{ padding: "0 1em" }}>
            <div
              className="col-md-12"
              style={{
                backgroundColor: "#eee",
                borderBottom: "1px solid #aaa",
                paddingLeft: ".5em",
              }}>
              <div className="col-md-6" style={{ padding: "0" }}>
                <div className="topic" style={{ padding: "1em 0 .3em" }}>
                  FEE Sharing for [{name}]
                </div>
                <div className="" style={{ padding: ".5em 0 .1em" }}>
                  <h6>
                    Partner Type: <span>Bronze</span>
                  </h6>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="col-md-4" style={{ backgroundColor: "#fff" }}>
              <div className="content" style={{ paddingTop: "1em" }}>
                <div className="percentage" style={{ paddingBottom: "1em" }}>
                  <label for="">Select Share Type</label>
                  <div className="input-div">
                    <select
                      name="shareType"
                      value={this.state.shareType}
                      onChange={this.handleChange}>
                      <option>Percentage</option>
                      {/* <option>Fixed</option> */}
                    </select>
                  </div>
                </div>
                <div
                  className="divider"
                  style={{ border: "1px solid #ddd" }}></div>
                <div className="" style={{ padding: ".8em 0" }}>
                  <label>Beneficiary</label>
                  <div className="input-div">
                    <select
                      name="beneficiaryData"
                      onChange={this.handleChange}
                      value={this.state.beneficiaryData}>
                      <option></option>
                      {this.props.beneficiary.map((content) => (
                        <option value={content._id + "," + content.userType}>
                          {content.userType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label>Selected Type</label>
                  <div
                    className="input-div"
                    style={{ backgroundColor: "#eee" }}>
                    <input
                      placeholder="Percentage"
                      value={this.state.shareType}
                      disabled
                    />
                  </div>
                  <div className="value" style={{ padding: "1em 0" }}>
                    <div className="input-div" style={{ width: "50%" }}>
                      <input
                        name="value"
                        placeholder="Value in %"
                        value={this.state.value}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div
                    className="divider"
                    style={{ border: "1px solid #ddd" }}></div>
                  <div style={{ paddingTop: "1em" }}>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={this.handleSubmit}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8" style={{ backgroundColor: "#fff" }}>
              <div className="content" style={{ paddingTop: "2em" }}>
                <div className="table-topic">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Shared Total: {this.state.shareTotal}</h4>
                    </div>
                    <div className="col-md-6">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}>
                        <h4>Available Total: {this.state.total + ".00"}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-div" style={{ paddingBottom: "1em" }}>
                  <table className="table table-flush" id="datatable-basic">
                    <SplitTableHead />
                    <tbody style={{ textAlign: "center" }}>
                      {this.state.splitDetail.map((content, index) => (
                        <SplitRow
                          {...content}
                          index={this.getIndex(index + 1)}
                          delete={this.deleteSplit}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className="divider"
                  style={{ border: "1px solid #ddd" }}></div>
                <div style={{ paddingTop: "1em" }}>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.sendRequest}
                    disabled={!this.state.formReady}
                    style={{ padding: ".5em" }}>
                    Create Split
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Fees" key="Fee Sharing" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>{itemInCard}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  beneficiary: state.users.allben.bendata,
  feedata: state.details.fee_split_details,
  loading: state.details.loading,
  err: state.users.allben.failed,
  deleted: state.details.deleted,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  getBeneficiaries,
  detailsInfo,
  deleteSplit,
  getFeeSplitDetails,
})(FeeSharing);
