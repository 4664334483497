import React from "react";

// table head for all fees
export const TxnLimitTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Kyc Level</th>
      <th>User Category</th>
      <th>Limit Type</th>
      <th>Limit Value</th>
      <th>Service Name</th>
      <th>Category Name</th>
      <th style={{ textAlign: "center" }}>Actions</th>
    </tr>
  </thead>
);

/** table body row for fees */
export const TxnLimitRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.name}</td>
      <td>{props.description}</td>
      <td>{props.kycLevel ? props.kycLevel : "not given"}</td>
      <td>{props.userCategory ? props.userCategory : "not given"}</td>
      <td>{props.limitType}</td>
      <td>{props.limit}</td>

      {props.transactionServiceId ? (
        <td>{props.transactionServiceId.serviceName}</td>
      ) : (
        <td>{props.transactionServiceCode}</td>
      )}
      {props.transactionCategoryId ? (
        <td>{props.transactionCategoryId.name}</td>
      ) : (
        <td>{props.transactionCategoryCode}</td>
      )}
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <i
          className="fa fa-pencil"
          style={{ padding: "0 .25em", cursor: "pointer" }}
          onClick={() => props.editTransactionLimit(props)}></i>
        <i
          className="fa fa-trash-o"
          style={{ padding: "0 .25em" }}
          onClick={() => props.deleteLimit(props._id)}></i>
        {/* <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            onChange={() => props.toggleHandler(props._id, props.enabled)}
            checked={props.enabled}
            // data-toggle="modal"
            // data-target="#exampleModal"
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label> */}
      </td>
    </tr>
  </>
);
