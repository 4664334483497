import React from "react";
import { Modal } from "../../../../../components/Accessories";
import { headers } from "../../../../../API/api.config";

import axios from "axios";

class KycUpgradeModal extends React.Component {
  state = {
    document_type: "",
    name: "",
    description: "",
    system_can_approved: false,
    imgURL: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onImageChange = (event) => {
    this.setState({
      ...this.state,
      name: event.target.files[0].name,
      fileData: event.target.files[0],
    });
  };

  handleSubmission = async () => {
    const formData = new FormData();
    formData.append("file", this.state.fileData);

    console.log(this.state.fileData);

    if (this.state.fileData) {
      const response = await axios.post(
        `https://api.getripay.com/files/api/documents`,
        formData
      );
      const { data } = await response.data;

      const requestOptions = {
        user_id: this.props.userId,
        comment: this.state.comment,
        document_id: this.state.document_id,
        document_url: data.document_url,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE3_URL}/admin/user/upload_document`,
          requestOptions,
          { headers: headers(this.props.token) },
        );
        const formData = await response.data;
        this.props.closeModal();
      } catch (error) {}
    } else {
      const requestOptions = {
        user_id: this.props.userId,
        comment: this.state.comment,
        document_id: this.state.document_id,
        document_url: this.state.document_url,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE3_URL}/user/upload_document`,
          requestOptions,
          { headers: headers(this.props.token) },
        );
        const formData = await response.data;
        this.props.closeModal();
      } catch (error) {}
    }
  };

  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  displayFormType = (doc) => {
    if (doc && Array.isArray(doc)) {
      let find = doc.find(
        (d) => String(d.documents[0].id) === String(this.state.document_id)
      );
      let result = find ? find.documents[0].type : "file";
      return result;
    } else {
      return "text";
    }
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Upload Documents">
        <div className="px-5">
          <div className="file-side">
            <form>
              <div className="box box9">
                <label>Document Name</label>
                <select
                  className="form-control"
                  name="document_id"
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.required_documents &&
                    this.props.required_documents.map((content) => (
                      <option value={content.documents[0].id}>
                        {content.documents[0].name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="box box9 py-3">
                <label>Comment</label>
                <textarea
                  className="form-control"
                  name="comment"
                  value={this.state.comment}
                  onChange={this.handleChange}></textarea>
              </div>
              {this.displayFormType(this.props.required_documents) ===
              "file" ? (
                <div className="py-3 d-flex justify-content-start align-items-center">
                  <div className="pr-4">
                    <input
                      onChange={this.onImageChange}
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      name="file"
                    />
                    <label htmlFor="select-image">
                      <div
                        style={{
                          background: "#adb5bd",
                          padding: "8px",
                          color: "#0037BA",
                          fontStyle: "normal",
                          fontWeight: "600",
                        }}
                        className="file-button"
                        variant="contained">
                        Select Document
                      </div>
                    </label>
                  </div>
                  <h4>{this.state?.name}</h4>
                </div>
              ) : (
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  name="document_url"
                  value={this.state.document_url}
                  onChange={this.handleChange}
                  placeholder="Enter the value"
                />
              )}
              <div className="box box9">
                <div className="text-center">
                  <button
                    type="button"
                    class="btn btn-primary my-2"
                    onClick={this.handleSubmission}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

export default KycUpgradeModal;
