import { headers } from "./api.config";

export const getStoreInfo = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/ecommerce/${url}`,
    { headers: headers(token) }
  );

  if (response.ok) {
    const { data, response_description } = await response.json();
    return { data, response_description };
  }
};

export const getSiteInfo = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/getrishop/${url}`,
    { headers: headers(token) }
  );

  if (response.ok) {
    const data = await response.json();
    return { data };
  }
};
