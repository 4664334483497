import React from "react";
import { connect } from "react-redux";
import { DateRange, Modal } from "../../components/Accessories";

class LoanFilterModal extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    status: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        modalTitle="Filter Loan Request"
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Select Date Range</label>
            <DateRange handleRange={this.handleRange} />
          </div>
          <div className="box box9">
            <label>Enter Status Type</label>
            <select
              name="status"
              value={this.state.status}
              className="form-control"
              onChange={this.handleChange}>
              <option></option>
              {this.props.loanStatus &&
                this.props.loanStatus.map((content) => (
                  <option value={content.status}>{content.status}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Filter Loan
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(LoanFilterModal);
