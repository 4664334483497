import React from "react";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import "../../morestyles.css";
import {
  Loader,
  Icon,
  CardBodyHolder,
  Modal,
} from "../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class InventoryBar extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar submenu="Inventory" shouldRefresh="false" />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="stores"
                title={
                  <Link to={`/e-commerce/stores`}>
                    <Icon className="" title="Stores" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="products"
                title={
                  <Link to={`/e-commerce/products`}>
                    <Icon className="" title="Products" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="orders"
                title={
                  <Link to={`/e-commerce/orders`}>
                    <Icon className="" title="Orders" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="sites"
                title={
                  <Link to={`/e-commerce/sites`}>
                    <Icon className="" title="User Sites" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="rate"
                title={
                  <Link to={`/e-commerce/product-rate`}>
                    <Icon className="" title="Product Ratings" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="checkout"
                title={
                  <Link to={`/e-commerce/checkout`}>
                    <Icon className="" title="Checkout View" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}
export const ConfirmationModal = (props) => (
  <Modal
    display={props.display ? "block" : "none"}
    closeModal={props.closeModal}
    modalTitle={props.title}>
    <div className="body-grid">
      <div className="box box9 text-center my-4">
        <span className="">Are you sure about this operation</span>
      </div>
      <div className="box box1">
        <div className="text-center">
          <button type="button" class="btn btn-primary" onClick={props.action}>
            {props.actionText}
          </button>
        </div>
      </div>
      <div className="box box2">
        <div className="text-center">
          <button
            type="button"
            class="btn btn-danger"
            onClick={props.closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default InventoryBar;
