import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { AuditLogsTableHead, AuditLogsRow } from "../../components/Rows";
import "../../morestyles.css";
import { getAuditLogs } from "../../reduxStore/action/action.creator";
import { Loader } from "../../components/Accessories";

class AuditLogs extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
    word: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.success == true) {
    //   this.setState({
    //     display2: false,
    //   });
    //   this.props.getAuditLogs(this.state.word, this.props.token);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.word != this.props.word) {
      let word;
      if (this.props.word) {
        word = this.props.word;
      } else {
        word = this.state.word;
      }
      this.props.getAuditLogs(this.props.token, word, this.state.currentPage);
    }
  }

  componentDidMount() {
    this.props.getAuditLogs(this.props.token, "user", this.state.currentPage);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = () => {
    this.props.getAuditLogs(
      this.props.token,
      this.state.word,
      this.state.currentPage
    );
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
    });
  };

  toggleHandler = async (id, lock) => {
    try {
      let action = "";
      if (lock == "0") {
        action = "disable";
      } else {
        action = "enable";
      }
      this.props.getAuditLogs(
        this.props.token,
        this.state.word,
        this.state.currentPage
      );
    } catch (error) {}
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <AuditLogsTableHead admin="true" />
          <tbody>
            {this.props.auditLogs &&
              this.props.auditLogs.map((info, index) => (
                <AuditLogsRow
                  {...info}
                  admin="true"
                  index={this.getIndex(index + 1)}
                  goToDetails={this.handleNextPage}
                  toggleHandler={this.toggleHandler}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              currentPage={this.state.word}
              submenu="Audit Logs"
              getInfo={this.props.getAuditLogs}
              addNew={this.addNew}
            />

            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div
                      class="card-header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h3 class="mb-0">Audit Logs</h3>
                      <div
                        className="search_field"
                        style={{ width: "50%", display: "flex" }}>
                        <input
                          style={{ width: "80%" }}
                          className="form-control"
                          placeholder="search word"
                          name="word"
                          value={this.state.word}
                          onChange={this.handleChange}
                        />
                        <div style={{ paddingLeft: ".8em" }}>
                          <button
                            className="btn btn-info"
                            onClick={this.handleSearch}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.logs.auditLogs.loading,
  token: state.login.token,
  auditLogs: state.logs.auditLogs.auditLogsData,
  word: state.logs.auditLogs.word,
});
export default connect(mapStateToProps, {
  getAuditLogs,
})(AuditLogs);
