import React from "react";
import {
  currencyFormatter,
  dateFormatter,
  statusButton,
  decimalFormatter,
  statusBadge,
} from "../Row";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export const BankTransactTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Date</th>
      <th>Status</th>
      <th>Re-query/Reverse</th>
      <th>Transaction Reference</th>
      <th>Session Id</th>
      <th>Amount</th>
      <th>User Id</th>
      <th>Request Id</th>
      <th>Narration</th>
      <th>Source Account Number</th>
      <th>Destination Account Number</th>
      <th>Destination Account Name</th>
      <th>Destination Bank Code</th>
      <th>Channel Code</th>
      <th>value Given</th>
      <th>Source Account Name</th>
      <th>Institution Code</th>
      <th>Institution Name</th>
      <th>Bank Logo</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const BankTransactTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{props.status}</td>
    <td>
      {(props.status == "Pending" || props.status == "In Progress") && (
        <button
          type="button"
          className="bg bg-orange"
          onClick={() =>
            props.handleRequery(props.transaction_ref, props.status, props.id)
          }
          style={{
            padding: ".2em .5em",
            color: "#fff",
            borderRadius: "5px",
          }}>
          <span>re-query</span>
        </button>
      )}
    </td>
    <td>
      <span
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          color: "#0038ba",
        }}
        onClick={() => props.goToTransaction()}>
        {props.transaction_ref}
      </span>
    </td>
    <td>{props.session_id}</td>
    <td style={{ textAlign: "right" }}>{currencyFormatter(props.amount)}</td>
    <td>
      <Link onClick={() => props.goToUser(props.user_id)}>{props.user_id}</Link>
    </td>
    <td>{props.request_id}</td>
    <td>{props.narration}</td>
    <td>{props.source_account_no}</td>
    <td>{props.dest_account_no}</td>
    <td>{props.dest_account_name}</td>
    <td>{props.dest_bank_code}</td>
    <td>{props.channel_code}</td>
    <td>
      <span
        className={
          props.value_given ? "badge badge-success" : "badge badge-warning"
        }>
        {props.value_given == false ? "no value given" : "value given"}
      </span>
    </td>
    <td>{props.source_account_name}</td>
    <td>{props.bank.InstitutionCode}</td>
    <td>{props.bank.InstitutionName}</td>
    <td>
      <img
        src={props.bank.BankLogoUrl}
        alt=""
        width="30px"
        height="30px"
        // style={{ borderRadius: "50%" }}
      />
    </td>
    <td>
      <i
        className="fa fa-eye"
        style={{ padding: "0 .3em", cursor: "pointer", color: "#0038ba" }}
        onClick={() => props.showDetails(JSON.parse(props.request))}></i>
    </td>
  </tr>
);

export const FlutterwaveTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Transaction Reference</th>
      <th>Flutterwave Transaction Reference</th>
      <th>Request Id</th>
      <th>User Id</th>
      <th>Account Number</th>
      <th>Amount</th>
      <th>Transaction Status</th>
      <th>Payment Date</th>
      <th>Created At</th>
      <th>Updated At</th>
      <th>Transaction Updated</th>
      <th>Value Given</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const FlutterwaveBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.transaction_ref}</td>
    <td>{props.flw_trans_ref}</td>
    <td>{props.request_id}</td>
    <td>{props.user_id}</td>
    <td>{props.account_number}</td>
    <td style={{ textAlign: "right" }}>{currencyFormatter(props.amount)}</td>
    <td>{props.transaction_status ? props.transaction_status : "not given"}</td>
    <td>{props.payment_date}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{dateFormatter(props.updated_at)}</td>
    <td>{props.transaction_updated}</td>
    <td>{props.value_given}</td>
    <td>
      <i
        className="fa fa-eye"
        style={{ padding: "0 .3em", cursor: "pointer", color: "#0038ba" }}
        onClick={() => props.showDetails(JSON.parse(props.other_details))}></i>
    </td>
  </tr>
);
