import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class WalletHistoryModal extends React.Component {
  state = {};

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add Wallet History">
        <div className="body-grid">
          <div className="box box9">
            <label>Wallet Id</label>
            <select
              className="form-control"
              name="kyc_level"
              value={this.state.kyc_level}
              onChange={this.handleChange}>
              <option></option>
              {this.props.wallet_data &&
                this.props.wallet_data.map((content) => (
                  <option value={content.id}>{content.currency}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div>
              <label>Should be Able to Request</label>
            </div>
            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="is_required"
                checked={this.state.can_request}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box1">
            <label>Maximum Amount Kyc can Fund</label>
            <input
              className="form-control"
              name="max_amount_it_can_fund"
              value={this.state.max_amount_it_can_fund}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Card Request Fees</label>
            <input
              className="form-control"
              name="card_request_fees"
              value={this.state.card_request_fees}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set kyc virtual carc
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet_data: state.card.wallet.wallet_data,
  token: state.login.token,
});

export default connect(mapStateToProps, {})(WalletHistoryModal);
