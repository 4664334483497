import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";
import { getKycLevel } from "../../../../reduxStore/action/action.creator";

class KycVirtualCardModal extends React.Component {
  state = {
    kyc_level: "",
    can_request: false,
    max_amount_it_can_fund: "",
    card_request_fees: "",
  };

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleCheckBox = () => {
    this.setState((prevState) => ({
      can_request: !prevState.can_request,
    }));
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.submitRate(this.state);
  };

  checkNumber = (e) => {
    if (+this.state.max_amount_it_can_fund >= 0) {
      return;
    } else {
      this.setState({
        [e.target.name]: "",
      });
    }
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Kyc Virtual Card">
        <div className="body-grid">
          <div className="box box9">
            <label>Kyc Level</label>
            <select
              className="form-control"
              name="kyc_level"
              value={this.state.kyc_level}
              onChange={this.handleChange}>
              <option></option>
              {this.props.levels &&
                this.props.levels.map((content) => (
                  <option value={content.level}>{content.level_name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div>
              <label>Should be Able to Request</label>
            </div>
            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="is_required"
                checked={this.state.can_request}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box1">
            <label>Maximum Amount Kyc can Fund</label>
            <input
              className="form-control"
              name="max_amount_it_can_fund"
              value={this.state.max_amount_it_can_fund}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box2">
            <label>Card Request Fees</label>
            <input
              className="form-control"
              name="card_request_fees"
              value={this.state.card_request_fees}
              onChange={this.handleChange}
              onKeyUp={this.checkNumber}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set kyc virtual carc
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
});

export default connect(mapStateToProps, { getKycLevel })(KycVirtualCardModal);
