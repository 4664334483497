import { headers } from "./api.config";

export const addInterest = async (data, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/payouts/config`,
		{
			method: "POST",
			headers: headers(token),
			body: JSON.stringify(data),
		}
	);

	if (response.ok) {
		const result = await response.json();
		return { result };
	}
};

export const fetchInterestSetup = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/payouts/config`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const fetchInterestDetail = async (url, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/payouts/config${url}`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();

		return { data };
	}
};

export const deleteInterestSetup = async (url, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/payouts/config${url}`,
		{
			method: "DELETE",
			headers: headers(token),
		}
	);
	if (response.ok) {
		const { Response_message } = await response.json();
		return { Response_message };
	}
};

export const editInterestSetup = async (url, data, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/payouts/config${url}`,
		{
			method: "PATCH",
			headers: headers(token),
			body: JSON.stringify(data),
		}
	);

	if (response.ok) {
		const { response_message } = await response.json();
		return { response_message };
	}
};

export const fetchInterestHistories = async (token, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/interest/history?page=${page}&per_page=25`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const filterInterestHistories = async (token, page, data) => {
	let url;
	if (data.start_date && data.end_date && data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/history?page=${page}&per_page=25&account_number=${data.account_number}&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.start_date && data.end_date) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/history?page=${page}&per_page=25&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/history?page=${page}&per_page=25&account_number=${data.account_number}`;
	} else {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/history?page=${page}&per_page=25`;
	}

	const response = await fetch(url, {
		headers: headers(token),
	});

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const fetchInterestEarnings = async (token, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/interest/earnings?page=${page}&per_page=25`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const filterInterestEarnings = async (token, page, data) => {
	let url;
	if (data.start_date && data.end_date && data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/earnings?page=${page}&per_page=25&account_number=${data.account_number}&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.start_date && data.end_date) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/earnings?page=${page}&per_page=25&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/earnings?page=${page}&per_page=25&account_number=${data.account_number}`;
	} else {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/earnings?page=${page}&per_page=25`;
	}

	const response = await fetch(url, {
		headers: headers(token),
	});

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const fetchInterestPayouts = async (token, page) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/interests/interest/payouts?page=${page}&per_page=25`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const filterInterestPayouts = async (token, page, data) => {
	let url;
	if (data.start_date && data.end_date && data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/payouts?page=${page}&per_page=25&account_number=${data.account_number}&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.start_date && data.end_date) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/payouts?page=${page}&per_page=25&start_date=${data.start_date}&end_date=${data.end_date}`;
	} else if (data.account_number) {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/payouts?page=${page}&per_page=25&account_number=${data.account_number}`;
	} else {
		url = `${process.env.REACT_APP_BASE_URL}/interests/interest/payouts?page=${page}&per_page=25`;
	}

	const response = await fetch(url, {
		headers: headers(token),
	});

	if (response.ok) {
		const { data } = await response.json();
		return { data };
	}
};

export const fetchUserDetails = async (id, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/user/users-profile/${id}`,
		{
			headers: headers(token),
		}
	);

	if (response.ok) {
		const result = await response.json();

		return { result };
	} else {
		const error = await response.json();
		throw new Error(error);
	}
};
