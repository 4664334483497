import React from "react";
import { connect } from "react-redux";
import "../../../../../morestyles.css";
import AuthorizationTab from "../AuthorizationTab";
import AdminPermissionForm from "./AdminPermissionForm";
import {
  sendAdminPermission,
  getAdminPermission,
  deletePermission,
} from "../../../../../reduxStore/action/action.creator";

class AdminPermission extends React.Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.success) {
      //   alert(nextProps.response);
      //   this.props.getAdminPermission();
    }
  }

  componentDidMount() {
    this.props.getAdminPermission(this.props.token);
  }

  setPermission = (data) => {
    // alert(JSON.stringify(data));
    this.props.sendAdminPermission(data, this.props.token);
    this.props.getAdminPermission(this.props.token);
  };

  deleteAdminPermission = (url) => () => {
    this.props.deletePermission(url, this.props.token);
    this.props.getAdminPermission(this.props.token);
  };

  render() {
    return (
      <AuthorizationTab {...this.props} active="admin-permission">
        <div className="row" style={{ padding: "0 1em" }}>
          <div
            className="col-md-12"
            style={{
              backgroundColor: "#eee",
              borderBottom: "1px solid #aaa",
              paddingLeft: ".5em",
            }}></div>
          <div className="col-md-6">
            <AdminPermissionForm submitAdminPermission={this.setPermission} />
          </div>
          <div className="col-md-6">
            <div className="table-responsive py-4 table-div">
              <table
                className="table table-striped table-bordered"
                id="datatable-basic">
                <thead className="table-light">
                  <th>#</th>
                  <th>Role Id</th>
                  <th>Module Name</th>
                  <th>Permission</th>
                  <th>Sub Permission</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </thead>
                <tbody>
                  {this.props.data &&
                    this.props.data.map((content) => (
                      <tr>
                        <td>{content.id}</td>
                        <td>{content.role_id}</td>
                        <td>{content.module_id}</td>
                        <td>{content.permissions}</td>
                        <td>
                          {Object.keys(content.permissions_json).map((key) => (
                            <span>{`${key.replace("_", " ")} `}</span>
                          ))}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <i
                            className="fa fa-trash-o"
                            onClick={this.deleteAdminPermission(
                              `permissions/${content.id}`
                            )}></i>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthorizationTab>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.roles.setadminpermission.success,
  response: state.roles.setadminpermission.response,
  data: state.roles.getadminpermission.data,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  sendAdminPermission,
  getAdminPermission,
  deletePermission,
})(AdminPermission);
