import React from "react";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import "../../../morestyles.css";
import { Loader, Icon, CardBodyHolder } from "../../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class SavingReportTab extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Savings Report Summary"
            report={false}
            getInfo={this.props.handleRefresh}
            handleReport={this.getTotalTransaction}
          />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="savingby_month"
                title={
                  <Link to={`/report/savings-report/savings-month`}>
                    <Icon className="" title="Savings and Investment" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default SavingReportTab;
