import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import {
  getWalletTransaction,
  setWithDrawal,
  getWithDrawal,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import WithdrawalModal from "./withdrawalModal";
import {
  WithDrawalBody,
  WithDrawalHead,
} from "../../../components/Rows/SavingTable";

class Withdrawal extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getWithDrawal(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  setWithdrawalDate = (data) => {
    callBackMethod(this.props.setWithDrawal(data, this.props.token))
      .then(() => this.setState({ ...this.state, display: false }))
      .then(() => alert(this.props.withdrawal_resp));
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getWithDrawal(this.props.token);
  };

  deleteItem = () => {
    //
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <WithDrawalHead />
          <tbody>
            {this.props.withdrawal_data &&
              this.props.withdrawal_data.map((content, index) => (
                <WithDrawalBody
                  {...content}
                  index={index + 1}
                  deleteItem={this.deleteItem}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <SavingBar active="withdrawal_date">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </SavingBar>
        <WithdrawalModal
          display={this.state.display}
          setWithdrawalDate={this.setWithdrawalDate}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  wallet_tran: state.saving.get_save_wallet.wallet_trans,
  loading: state.saving.get_withdrawal.loading,
  withdrawal_resp: state.saving.set_withdrawal.response,
  withdrawal_data: state.saving.get_withdrawal.withdrawal_data,
});

export default connect(mapStateToProps, {
  getWalletTransaction,
  setWithDrawal,
  getWithDrawal,
})(Withdrawal);
