import { event } from "jquery";
import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";
import {
	getNotificationStatus,
	getNotificationTypes,
	getNotificationMode,
	getNotificationChannels,
} from "../../../reduxStore/action/action.creator";

class FilterModal extends React.Component {
	state = {
		channel_code: "",
		status_code: "",
		type: "",
		mode_code: "",
		start_date: "",
		end_date: "",
	};

	componentDidMount() {
		this.props.getNotificationStatus(
			"get_notification_statuses",
			this.props.token
		);
		this.props.getNotificationTypes(
			"get_notification_types",
			this.props.token
		);
		this.props.getNotificationMode(
			"get_notification_modes",
			this.props.token
		);
		this.props.getNotificationChannels(this.props.token);
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleSubmit = () => {
		this.props.submit(this.state);
	};

	render() {
		return (
			<Modal
				display={this.props.display ? "block" : "none"}
				closeModal={this.props.closeModal}
				modalTitle="Filter Notification"
			>
				<div className="body-grid">
					{/* <div className="box box1">
            <label for="fee-name">Request Reference</label>

            <div className="input-div">
              <input
                type="text"
                name="req_ref"
                value={this.state.req_ref}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div> */}
					<div className="box box1">
						<label for="fee-name">Notification Channel</label>

						<select
							name="channel_code"
							value={this.state.channel_code}
							onChange={this.handleChange}
							className="input-div"
						>
							<option></option>
							{this.props.channel_data &&
								this.props.channel_data.map((item) => (
									<option value={item.channelCode}>
										{item.name}
									</option>
								))}
							{/* <input
                type="text"
                name="email"
                value={this.state.email}
                id=""
                onChange={this.handleChange}
              /> */}
						</select>
					</div>
					{/* <div className="box box1">
            <label for="fee-name">recipients PhoneNumber</label>

            <div className="input-div">
              <input
                type="text"
                name="phone"
                value={this.state.phone}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div> */}
					{/* <div className="box box2">
            <label for="fee-name">Sender</label>

            <div className="input-div">
              <input
                type="text"
                name="sender"
                value={this.state.sender}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div> */}
					{/* <div className="box box1">
            <label for="fee-name">Title</label>

            <div className="input-div">
              <input
                type="text"
                name="title"
                value={this.state.title}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div> */}
					{/* <div className="box box2">
            <label for="fee-name">Description</label>

            <div className="input-div">
              <input
                type="text"
                name="description"
                value={this.state.description}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div> */}
					<div className="box box2">
						<label>Notification Status</label>

						<select
							name="status_code"
							value={this.state.status_code}
							onChange={this.handleChange}
							className="input-div"
						>
							<option></option>
							{this.props.status_data &&
								this.props.status_data.notificationStatus.map(
									(content) => (
										<option
											value={
												content.notificationStatusCode
											}
										>
											{content.name}
										</option>
									)
								)}
						</select>
					</div>
					<div className="box box1">
						<label for="fee-name">Notification Type</label>

						<select
							name="type"
							value={this.state.type}
							onChange={this.handleChange}
							className="input-div"
						>
							<option></option>
							{this.props.type_data &&
								this.props.type_data.notificationType.map(
									(content) => (
										<option
											value={content.notificationTypeCode}
										>
											{content.name}
										</option>
									)
								)}
							{/* <input
                type="text"
                name="mode_code"
                value={this.state.mode_code}
                id=""
                onChange={this.handleChange}
              /> */}
						</select>
					</div>
					<div className="box box2">
						<label for="fee-name">Notification Mode Code</label>

						<select
							name="mode_code"
							value={this.state.mode_code}
							onChange={this.handleChange}
							className="input-div"
						>
							<option></option>
							{this.props.mode_data &&
								this.props.mode_data.notificationMode.map(
									(content) => (
										<option value={content.modeCode}>
											{content.name}
										</option>
									)
								)}
							{/* <input
                type="text"
                name="channel"
                value={this.state.channel}
                id=""
                onChange={this.handleChange}
              /> */}
						</select>
					</div>
					<div className="box box1">
						<label for="fee-name">Start Date</label>
						<input
							type="date"
							name="start_date"
							className="input-div"
							value={this.state.start_date}
							onChange={this.handleChange}
						/>
					</div>
					<div className="box box2">
						<label for="fee-name">End Date</label>
						<input
							type="date"
							name="end_date"
							className="input-div"
							value={this.state.end_date}
							onChange={this.handleChange}
						/>
					</div>
					<div className="box box9">
						<div className="text-center">
							<button
								type="button"
								class="btn btn-primary"
								onClick={this.handleSubmit}
							>
								Filter Notification
							</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => ({
	status_data: state.notifications.allnotification.status_data,
	type_data: state.notifications.allnotification.type_data,
	mode_data: state.notifications.allnotification.mode_data,
	channel_data: state.notifications.notificationchannels.channels,
	token: state.login.token,
});
export default connect(mapStateToProps, {
	getNotificationStatus,
	getNotificationTypes,
	getNotificationMode,
	getNotificationChannels,
})(FilterModal);
