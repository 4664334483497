import { combineReducers } from "redux";
import {
  GET_RATE,
  SET_RATE,
  SET_KYC_VIRTUAL_CARD,
  GET_KYC_VIRTUAL_CARD,
  SET_VIRTUAL_LIMIT,
  GET_VIRTUAL_LIMIT,
  GET_VIRTUAL_TRANS,
  GET_VIRTUAL_CARD,
  GET_CURRENCIES,
  LOCK_CARD,
  UNLOCK_CARD,
  GET_A_RATE,
  TERMINATE_CARD,
  UPDATE_KYC_VIRTUAL_CARD,
  FILTER_VIRTUAL_CARD_TRANS,
  EDIT_VIRTUAL_CARD_LIMIT,
  FILTER_VIRTUAL_CARD,
} from "../action/action.types";

const setvirtualCardReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_RATE:
      return {
        ...state,
        rate_response: action.payload.response_message,
      };
    default:
      return state;
  }
};
const getvirtualCardReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_RATE:
      return {
        ...state,
        loading: false,
        virtual_rate: action.payload.rate_data,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        loading: false,
        currency: action.payload.data,
      };
    case GET_A_RATE:
      return {
        ...state,
        loading: false,
        a_rate: action.payload.data,
      };
    default:
      return state;
  }
};

const setKycVirtualCard = (state = {}, action) => {
  switch (action.type) {
    case SET_KYC_VIRTUAL_CARD:
      return {
        ...state,
        response: action.payload.response_message,
      };
    case UPDATE_KYC_VIRTUAL_CARD:
      return {
        ...state,
        response_message: action.payload.response_message,
      };

    default:
      return state;
  }
};

const getKycVirtualCard = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_KYC_VIRTUAL_CARD:
      return {
        ...state,
        loading: false,
        kyc_card: action.payload.data,
      };
    default:
      return state;
  }
};

const setVirtualCardConfig = (state = {}, action) => {
  switch (action.type) {
    case SET_VIRTUAL_LIMIT:
      return {
        ...state,
        response: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getVirtualCardConfig = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_VIRTUAL_LIMIT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case EDIT_VIRTUAL_CARD_LIMIT:
      return {
        ...state,
        loading: false,
        response_msg: action.payload.response_message,
      };
    case GET_VIRTUAL_TRANS:
      return {
        ...state,
        loading: false,
        data: action.payload.card_data,
        total: action.payload.total,
        filtering: false,
      };
    case FILTER_VIRTUAL_CARD_TRANS:
      return {
        ...state,
        loading: false,
        data: action.payload.card_data,
        total: action.payload.total,
        filtering: true,
      };
    case GET_VIRTUAL_CARD:
      return {
        ...state,
        loading: false,
        data: action.payload.card_data,
        total: action.payload.total,
      };
    case FILTER_VIRTUAL_CARD:
      return {
        ...state,
        loading: false,
        data: action.payload.card_data,
        total: action.payload.total,
        filtering: true,
      };
    default:
      return state;
  }
};

const toggleVirtualCard = (state = {}, action) => {
  switch (action.type) {
    case LOCK_CARD:
      return {
        ...state,
        response: action.payload.response_message,
        data: action.payload.data,
      };
    case UNLOCK_CARD:
      return {
        ...state,
        response: action.payload.response_message,
        data: action.payload.data,
      };
    case TERMINATE_CARD:
      return {
        ...state,
        response: action.payload.response_message,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export const virtualCardReducer = combineReducers({
  set_rate: setvirtualCardReducer,
  get_rate: getvirtualCardReducer,
  set_kyc_virtual: setKycVirtualCard,
  get_kyc_virtual: getKycVirtualCard,
  set_virtual_config: setVirtualCardConfig,
  get_virtual_config: getVirtualCardConfig,
  toggle_card: toggleVirtualCard,
});
