import React from "react";
import {
  currencyFormatter,
  dateFormatter,
  statusButton,
  timeFormatter,
} from "../Row";
import { DateRange } from "../../components/Accessories";
import { tabDetail } from "../../reduxStore/action/action.creator";

// table head for all users [Admin and Users]
export const TableHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Email</th>
      {!props.admin && (
        <>
          <th>Account Number</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>BVN</th>
          <th>DOB</th>
          <th>Map Agent</th>
        </>
      )}
      {props.admin && <th>Staff Id</th>}
      <th>Username</th>
      <th>Date Created</th>
      <th>Time Created</th>
      {(props?.canLock || props?.canLockAdmin) && <th>Lock</th>}

      <th>Reset Google Auth</th>
      <th>Actions</th>
    </tr>
  </thead>
);

/** body rows for all user */
export const Row = (props) => (
  <>
    <tr key={props.index}>
      <td>{props.index}</td>
      <td>{props.email}</td>
      {!props.admin && (
        <>
          <td>{props.account_num}</td>
          <td>{props.name}</td>
          <td>{props.phone}</td>
          <td>{props.bvn}</td>
          <td>{dateFormatter(props.dob)}</td>
          <td>
            <button onClick={() => props.pickAgent(props.id)}>
              pick agent
            </button>
          </td>
        </>
      )}
      {props.admin && <td>{props.staff_id}</td>}
      <td>{props.username}</td>
      <td>{dateFormatter(props.date_join)}</td>
      <td>{timeFormatter(props.date_join)}</td>

      {(props?.canLock || props?.canLockAdmin) && (
        <td>
          <label style={{ display: "inline-block" }} class="custom-toggle">
            <input
              type="checkbox"
              onChange={() =>
                props.toggleHandler(
                  props.id,
                  props.admin ? props.lock : props.locked_status
                )
              }
              checked={parseInt(props.admin ? props.lock : props.locked_status)}
              // data-toggle="modal"
              // data-target="#exampleModal"
            />
            <span class="custom-toggle-slider rounded-circle"></span>
          </label>
        </td>
      )}

      <td>
        <button onClick={() => props.resetAuth(props.id)}>Reset</button>
      </td>

      <td
        style={{
          display: "flex",
          justifyContent: props.admin ? "" : "center",
          alignItems: "center",
        }}
      >
        {(props?.canView || props?.canViewAdmin) && (
          <i
            className="fa fa-eye"
            style={{
              padding: "0 .3em",
              cursor: "pointer",
              color: "#0038ba",
            }}
            onClick={() => {
              props.goToDetails(props);
            }}
          ></i>
        )}

        {props.admin && (
          <>
            {props?.canDeleteAdmin && (
              <i
                className="fa fa-trash-o"
                style={{ padding: "0 .5em" }}
                onClick={() => props.deleteAdmin(props.id)}
              ></i>
            )}
            {props?.canEditAdmin && (
              <i
                className="fa fa-pencil"
                onClick={() => props.updateAdmin(props)}
              ></i>
            )}
          </>
        )}
      </td>
    </tr>
  </>
);

export const AuditLogsTableHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User ID</th>
      <th>Full Name</th>
      <th>Email</th>
      {/* <th>Staff ID</th>
      <th>Role ID</th> */}
      <th>Action Performed</th>
      <th>Platform used</th>
      <th>Device IP</th>
      <th>Date Performed</th>
      <th>User KYC</th>
    </tr>
  </thead>
);

export const AuditLogsRow = (props) => (
  <>
    <tr key={props.index}>
      <td>{props.index}</td>
      <td>{props.user_id}</td>
      <td>{props.full_name}</td>
      <td>{props.email}</td>
      {/* <td>{props.staff_id}</td>
      <td>{props.role_id}</td> */}
      <td>{props.action_perform}</td>
      <td>{props.platform}</td>
      <td>{props.ip}</td>
      <td>{props.date_perform}</td>
      <td>{props.kyc_level}</td>

      {/* <td>
        <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            onChange={() =>
              props.toggleHandler(
                props.id,
                props.admin ? props.lock : props.locked_status
              )
            }
            checked={parseInt(props.admin ? props.lock : props.locked_status)}
            // data-toggle="modal"
            // data-target="#exampleModal"
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
      </td> */}
      {/* <td
        style={{
          display: "flex",
          justifyContent: props.admin ? "" : "center",
          alignItems: "center",
        }}>
        <i
          className="fa fa-eye"
          style={{ padding: "0 .3em", cursor: "pointer", color: "#0038ba" }}
          onClick={() => {
            props.goToDetails(props);
          }}></i>
        {props.admin && (
          <>
            <i
              className="fa fa-trash-o"
              style={{ padding: "0 .5em" }}
              onClick={() => props.deleteAdmin(props.id)}></i>
            <i
              className="fa fa-pencil"
              onClick={() => props.updateAdmin(props)}></i>
          </>
        )}
      </td> */}
    </tr>
  </>
);

export const List = (props) => (
  <>
    <tr>
      <td>Account Number</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.bank_account.length > 0 && props.bank_account[0].account_number}
      </td>
    </tr>
    <tr>
      <td>Available Balance</td>
      <td
        style={{
          textAlign: "right",
          fontWeight: "bolder",
          color: "#0037ba",
          fontSize: "20px",
        }}
      >
        {props.balance.data != undefined && props.bank_account.length > 0
          ? `NGN ${props.balance.data.available_balance}`
          : "No Balance given"}
      </td>
    </tr>
    <tr>
      <td>Full Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.first_name + " " + props.last_name}
      </td>
    </tr>
    <tr>
      <td>User Name</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.username}
      </td>
    </tr>
    <tr>
      <td>Email</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>{props.email}</td>
    </tr>
    <tr>
      <td>Phone Number</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.phone_number}
      </td>
    </tr>
    <tr>
      <td>BVN</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>{props.bvn}</td>
    </tr>
    <tr>
      <td>Gender</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>{props.gender}</td>
    </tr>
    <tr>
      <td>Referral ID</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.referral_id}
      </td>
    </tr>
    <tr>
      <td>Last Login</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {dateFormatter(props.last_login)}
      </td>
    </tr>
    <tr>
      <td>Customer Type</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>...</td>
    </tr>
    <tr>
      <td>Category</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>...</td>
    </tr>
    <tr>
      <td>Status</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.locked_status}
      </td>
    </tr>
    <tr>
      <td>Activity Status</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>...</td>
    </tr>
  </>
);

export const UserTransactionList = (props) => {
  const transactionsForUser = props.transactions;
  return (
    <div>
      <div className="box box9">
        <label>Select Date Range</label>
        <DateRange handleRange={props.range} />
      </div>
      <table class="table table-bordered table-hover tableClass">
        <thead>
          <tr class="table-primary">
            <th scope="col" style={{ fontWeight: "bold" }}>
              S/N
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Transaction Amount
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Transaction Service
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Transaction Status
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Transaction Date
            </th>
          </tr>
        </thead>
        <tbody>
          <>
            {transactionsForUser &&
              transactionsForUser.map((content, index) => (
                <tr class="table-light">
                  <th scope="row">{index + 1}</th>
                  <td>{currencyFormatter(content.transactionAmount)}</td>
                  {content.transactionServiceId ? (
                    <td>{content.transactionServiceId.serviceName}</td>
                  ) : (
                    <td>{content.serviceCode}</td>
                  )}
                  <td>
                    <span class={statusButton(content.transactionStatus)}>
                      {content.transactionStatus
                        .toString()
                        .substring(3, content.transactionStatus.length)}
                    </span>
                  </td>
                  <td>{dateFormatter(content.transactionDate)}</td>
                </tr>
              ))}
          </>
        </tbody>
      </table>
    </div>
  );
};

export const UserTransactionServiceList = (props) => {
  const transactionsForUser = props.transactions;
  return (
    <table class="table table-bordered table-hover tableClass">
      <thead>
        <tr class="table-primary">
          <th scope="col" style={{ fontWeight: "bold" }}>
            S/N
          </th>
          <th scope="col" style={{ fontWeight: "bold" }}>
            Transaction Service Used
          </th>
          <th scope="col" style={{ fontWeight: "bold" }}>
            Transaction Category Used
          </th>
          <th scope="col" style={{ fontWeight: "bold" }}>
            Transaction Amount
          </th>
          {/* <th scope="col" style={{ fontWeight: "bold" }}>
          Transaction Date
        </th> */}
        </tr>
      </thead>
      <tbody>
        <>
          {transactionsForUser &&
            transactionsForUser.map((content, index) => (
              <tr class="table-light">
                <th scope="row">{index + 1}</th>
                {content.transactionServiceId ? (
                  <td>{content.transactionServiceId.serviceName}</td>
                ) : (
                  <td>{content.serviceCode}</td>
                )}

                {content.transactionCategory ? (
                  <td>{content.transactionCategory.name}</td>
                ) : (
                  <td>{content.transactionCategory}</td>
                )}
                {/* <td>{content.transactionCategory}</td> */}
                <td>{currencyFormatter(content.transactionAmount)}</td>
              </tr>
            ))}
        </>
      </tbody>
    </table>
  );
};

export const UserStatement = (props) => {
  const statementForUser = props.CASATrxns;
  return (
    <div>
      <div className="box box9" style={{ padding: "10px" }}>
        <label>Select Date Range for User's account statement</label>
        <DateRange handleRange={props.range} />
      </div>
      <table class="table table-bordered table-hover tableClass">
        <thead>
          <tr class="table-primary">
            <th scope="col" style={{ fontWeight: "bold" }}>
              S/N
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Date
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Balance
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Credit Amount
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Debit Amount
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Narration
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Txn No
            </th>
          </tr>
        </thead>
        <tbody>
          <>
            {statementForUser &&
              statementForUser.map((content, index) => (
                <tr class="table-light">
                  <th scope="row">{index + 1}</th>
                  <td>{content.ValueDate}</td>
                  <td>{currencyFormatter(content.Balance)}</td>
                  <td>{currencyFormatter(content.CRAmount)}</td>
                  <td>{currencyFormatter(content.DRAmount)}</td>
                  <td>{content.Narration}</td>
                  <td>{content.TrxnNo}</td>
                </tr>
              ))}
          </>
        </tbody>
      </table>
    </div>
  );
};

export const LoginHistoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Platform</th>
      <th>Device</th>
      <th>IP Address</th>
      <th>Date Logged In</th>
      <th>Time Logged In</th>
      <th>Channel</th>
    </tr>
  </thead>
);

export const LoginHistoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.platform}</td>
    <td>{props.device}</td>
    <td>{props.ip}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td>{props.channel}</td>
  </tr>
);

export const UserDocumentHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Document Name</th>
      <th>Document Type</th>
      <th>Kyc Level Name</th>
      <th>Created At</th>
      <th>Time</th>
    </tr>
  </thead>
);

export const UserDocumentBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{props.type}</td>
    <td>{props.kyc_level_name}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
  </tr>
);

export const UserCategoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Date Created</th>
      <th>Time Created</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const UserCategoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.name}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td>
      {/* <i className="fa fa-eye"></i> */}
      <i className="fa fa-pencil" style={{ padding: "0 .5em" }}></i>
    </td>
  </tr>
);
