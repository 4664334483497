import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  ClaimTableHead,
  ClaimTableBody,
} from "../../components/Rows/AgentTable";
import {
  getAgentClaims,
  approveUserToAgent,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import { callBackMethod, Loader } from "../../components/Accessories";
// import AgentModal from "./AgentModal";

class AgentClaims extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getAgentClaims(this.props.token, "user/sales_agent_claims");
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      //   this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
    } else {
      this.props.filterUserTable(this.state.filterbyItem, id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleNextPage = (props) => {
    const { id, index } = props;

    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/details`,
      state: id,
    });
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  _searchField = async (item) => {
    // alert(item);
    this.props.filterUserTable(item, 1, this.props.token);
    this.setState({
      ...this.state,
      filterbyItem: item,
    });
  };

  toggleHandler = ({ enabled, id }) => {
    callBackMethod(
      this.props.enableLock(
        `change_status/sales_agents`,
        { sales_agent_id: id },
        this.props.token
      )
    ).then(() => this.props.getAgents("user/sales_agents", this.props.token));
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  onSubmit = (data) => {
    this.props.filterAllUsers(data, this.props.token);
  };

  handleSubmit = (data) => {
    // callBackMethod(
    //   this.props.addAgent("user/sales_agents", data, this.props.token)
    // )
    //   .then(() =>
    //     this.setState({
    //       display: false,
    //     })
    //   )
    //   .then(() => alert(this.props.agnt_response));
  };

  approveClaim = (props) => {
    const data = {
      admin_id: this.props.admin_data.id,
      sales_agent_claims_id: props.id,
    };
    callBackMethod(
      this.props.approveUserToAgent(
        "user/admin_approve/sales_agent_claims",
        data,
        this.props.token
      )
    )
      .then(() =>
        alert(JSON.stringify(this.props.approve_response.response_message))
      )
      .then(() =>
        this.props.getAgentClaims(this.props.token, "user/sales_agent_claims")
      );
  };

  render() {
    let itemInTable;

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <ClaimTableHead />
          <tbody>
            {this.props.agent_claim_data &&
              this.props.agent_claim_data.map((content, index) => (
                <ClaimTableBody
                  {...content}
                  index={index + 1}
                  approveClaim={this.approveClaim}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Agents Claims"
              currentPage={`user/sales_agent_claims?page=${this.state.currentPage}`}
              getInfo={this.props.getAgentClaims}
              search={this._searchField}
              //   shouldFilter={true}
              //   showModal={this.showModal}
              addNew={this.addNew}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">All Agents Claims</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.alladmins.loading,
  token: state.login.token,
  agnt_response: state.users.alladmins.agent_resp,
  agent_claim_data: state.users.alladmins.agent_claim_data?.data,
  admin_data: state.login.data,
  approve_response: state.users.alladmins.approve_response,
});
export default connect(mapStateToProps, { getAgentClaims, approveUserToAgent })(
  AgentClaims
);
