import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import { headers } from "../../../../../API/api.config";
import { getCustomerDocument } from "../../../../../reduxStore/action/action.creator";
import axios from "axios";
import { toast } from "react-toastify";

class KycUpgradeModal extends React.Component {
  state = {
    document_type: "",
    name: "",
    description: "",
    system_can_approved: false,
    userDetails: [],
    userDocuments: [],
    count: 0,
    is_loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    this.setState(
      {
        ...this.state,
        request_id: this.props.request_id,
      },
      async () => {
        try {
          this.setState({ is_loading: true });
          const response = await axios.post(
            `${process.env.REACT_APP_BASE3_URL}/kyc_upgrade_request/approve?request_id=${this.props.request_id}&approved_by=${this.props?.login?.admin_id}`
          );
          const formData = await response.data;
          if (formData) {
            this.setState({ is_loading: false });
            this.props.closeModal();
          }
        } catch (error) {
          toast.error(error.response.data.response_message, {
            position: "top-right",
          });
          this.props.closeModal();
        }
      }
    );
  };

  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  render() {
    console.log(this.props);
    if (this.props?.document?.length < 1) {
      return (
        <Modal
          display={this.props.display ? "block" : "none"}
          size="50%"
          closeModal={this.props.closeModal}
          modalTitle="List of Documents Provided">
          <div className="px-5">
            <h3>
              Name:{" "}
              {this.props.user && this.props?.user?.first_name.toUpperCase()}{" "}
              {this.props.user && this.props?.user?.last_name.toUpperCase()}
            </h3>
            {/* <h5>Email: </h5> */}
          </div>
          <div className="px-5 py-4">
            <h4>No document found</h4>
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal
          display={this.props.display ? "block" : "none"}
          size="50%"
          closeModal={this.props.closeModal}
          modalTitle="List of Documents Provided">
          <div>
            <div className="px-5">
              <h3>
                Name:{" "}
                {this.props.user && this.props?.user?.first_name.toUpperCase()}{" "}
                {this.props.user && this.props?.user?.last_name.toUpperCase()}
              </h3>
            </div>
            <div className="row px-5">
              <div className="col-md-6">
                <h4>
                  Current Level:{" "}
                  <span style={{ color: "red" }}>
                    {this.props?.kyclevelData?.current_level?.level_name.toUpperCase()}
                  </span>
                </h4>
              </div>
              <div className="col-md-6">
                <h4>
                  Proposed Level:{" "}
                  <span style={{ color: "green" }}>
                    {this.props?.kyclevelData?.new_level?.level_name.toUpperCase()}
                  </span>
                </h4>
              </div>
            </div>
            <div className="px-3 py-4">
              <div>
                {this.props.document &&
                  this.props.document.map((content) => (
                    <div className="py-3 d-flex justify-content-around align-items-center">
                      <div style={{ width: "30% important" }}>
                        <h4>Document Name</h4>
                        <h5 className="">{content.document.name}</h5>
                      </div>
                      <div style={{ width: "30% important" }}>
                        <h4>Approved By</h4>
                        {content.approval_details ? (
                          <>
                            <h5>
                              {content?.approval_details?.username}
                              {/* {content.approval_details.first_name}{" "}
                              {content.approval_details.last_name} */}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div style={{ width: "30%" }} className=" ">
                        <h4
                          className="pr-3"
                          style={{
                            color:
                              content.approval_status === "Pending"
                                ? "blue"
                                : content.approval_status === "Approved"
                                ? "green"
                                : "red",
                          }}>
                          {content.approval_status}
                        </h4>
                        {content.document_url.substr(0, 4) === "http" ? (
                          <button
                            onClick={() =>
                              this.openInNewTab(content.document_url)
                            }
                            className="btn btn-primary">
                            View
                          </button>
                        ) : (
                          <p>{content.document_url}</p>
                        )}
                      </div>
                    </div>
                  ))}

                {this.props?.numOfPending &&
                (this.props?.numOfPending?.total > 0 ||
                  this.props?.status === "Approved") ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    onClick={this.submit}
                    className="btn btn-primary mt-3 mx-5">
                    {this.state.is_loading ? "Loading" : "Submit Approval"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  // loading: state.kyc.get_customer_doc.loading,
  response: state.kyc.get_customer_doc.customer_doc,
  token: state.login.token,
  login: state.login.data,
});

export default connect(mapStateToProps, {
  getCustomerDocument,
})(KycUpgradeModal);
