import React from "react";
import { connect } from "react-redux";
import ExtraBar from "../Extra";
import IncentiveModal from "./IncentiveModal";
import {
  addUserIncentives,
  getIncentiveSummary,
  getRedeemedTransfer,
  addConversionsPoint,
  getAllConversionPoint,
  updateAllIncentiveConversion,
} from "../../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../../components/Accessories";
import {
  IncentiveSummaryRow,
  ConversionHead,
  ConversionBody,
} from "../../../../../components/Rows/IncentiveTable";
import ConversionModal from "./ConversionModal";
import UpdateIncentives from "./UpdateIncentives";
import UpdateConversionModal from "./UpdateConversionModal";

class IncentiveConfig extends React.Component {
  state = {
    display: false,
    display2: false,
    updatedisplay: false,
  };

  componentDidMount() {
    this.props.getIncentiveSummary(this.props.token);
    this.props.getRedeemedTransfer(this.props.token);
    this.props.getAllConversionPoint(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      display2: false,
      updatedisplay: false,
      edit_conversion: false,
    });
  };

  submitIncentives = (data) => {
    callBackMethod(this.props.addUserIncentives(data, this.props.token))
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.incentive_resp));
    // alert(JSON.stringify(data));
  };

  submitConversions = (data) => {
    callBackMethod(this.props.addConversionsPoint(data, this.props.token))
      .then(() => this.setState({ display2: false }))
      .then(() => alert(this.props.conversion_resp));
  };

  updateUserIncentives = (data) => {
    callBackMethod(
      this.props.updateAllIncentiveConversion(
        "points/config",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ updatedisplay: false }))
      .then(() => alert(this.props.update_resp));
  };

  editUserPoints = (data) => {
    this.setState({
      ...this.state,
      edit_conversion: true,
      idToEdit: data._id,
      dataToEdit: data,
    });
  };

  updateConversion = (data) => {
    callBackMethod(
      this.props.updateAllIncentiveConversion(
        `conversions/config/${this.state.idToEdit}`,
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ edit_conversion: false }))
      .then(() => alert(this.props.update_resp));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <ConversionHead />
          <tbody>
            {this.props.conversions &&
              this.props.conversions.map((content, index) => (
                <ConversionBody
                  {...content}
                  index={index + 1}
                  editPoint={this.editUserPoints}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <ExtraBar {...this.props} active="incentives">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> Loyalty Point Config
            </a>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={() => this.setState({ display2: true })}>
              <i class="fas fa-plus"></i> Set conversions
            </a>
            <a
              href="#"
              className="btn btn-sm btn-neutral"
              onClick={() =>
                this.setState({
                  ...this.state,
                  updatedisplay: true,
                  id: "2",
                  // id: this.props.incentive_data._id,
                })
              }>
              <i className="fa fa-pencil"></i> Update Loyalty Point
            </a>
          </div>
          <div className="row" style={{ display: "flex", borderRadius: "2em" }}>
            <div className="col-4">
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1.5em 1.5em",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#F8F9FE",
                }}>
                {this.props.loading ? (
                  <Loader loading={this.props.loading} />
                ) : (
                  <>
                    <span style={{ fontSize: "14px", fontWeight: "bolder" }}>
                      Redeemed Transfers
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.redeemedTrans}
                    </span>
                  </>
                )}
              </div>
              <table class="table table-hover mt-5">
                {this.props.loading ? (
                  <Loader loading={this.props.loading} />
                ) : (
                  <tbody>
                    <IncentiveSummaryRow {...this.props.incentive_data} />
                  </tbody>
                )}
              </table>
            </div>
            <div className="col-8">
              <div className="table-responsive py-4 table-div">
                <table className="table table-flush" id="datatable-basic">
                  {itemInTable}
                </table>
              </div>
            </div>
          </div>
        </ExtraBar>
        <IncentiveModal
          display={this.state.display}
          closeModal={this.closeModal}
          onSubmit={this.submitIncentives}
        />
        <ConversionModal
          display={this.state.display2}
          closeModal={this.closeModal}
          onSubmit={this.submitConversions}
        />
        <UpdateIncentives
          display={this.state.updatedisplay}
          closeModal={this.closeModal}
          points={this.props.incentive_data}
          onSubmit={this.updateUserIncentives}
        />
        <UpdateConversionModal
          display={this.state.edit_conversion}
          closeModal={this.closeModal}
          data={this.state.dataToEdit}
          onSubmit={this.updateConversion}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.incentive.get_incentives.loading,
  token: state.login.token,
  incentive_resp: state.incentive.add_incentives.incentive_response,
  incentive_data: state.incentive.get_incentives.incentives,
  redeemedTrans: state.incentive.get_incentives.redeemedTrans,
  conversion_resp: state.incentive.add_incentives.conversion_resp,
  conversions: state.incentive.get_incentives.conversions,
  conversion_status: state.incentive.add_incentives.conversion_status,
  update_resp: state.incentive.add_incentives.update_resp,
});

export default connect(mapStateToProps, {
  addUserIncentives,
  getIncentiveSummary,
  getRedeemedTransfer,
  addConversionsPoint,
  getAllConversionPoint,
  updateAllIncentiveConversion,
})(IncentiveConfig);
