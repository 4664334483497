import { combineReducers } from "redux";
import {
  GET_DETAIL_FAILED,
  GET_DETAIL_SUCCESS,
  DETAIL_INFO,
  APPROVE_LOAN_SUCCESS,
  DISBURSE_SUCCESS,
  DELETED_INFO,
  GET_USER_LOAN,
  LOGOUT,
  ACCOUNT_BALANCE,
  ACCOUNT_STATEMENT,
  NEW_RANGE,
  TRANSACTION_DETAIL_INFO,
  GET_USER_DETAILS_INFO,
  LOAD_HISTORY,
  LOAN_DETAILS,
  REJECT_LOAN,
  GET_FEE_SPLIT,
} from "../action/action.types";

const initialState = {
  tabdetail: [],
  bal: "",
  fee_split_details: [],
  // user_detailinfo: []
};

export const detailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "DETAIL_LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        user_detailinfo: action.payload.result.data,
      };
    case TRANSACTION_DETAIL_INFO:
      return {
        ...state,
        loading: false,
        detailinfo: action.payload.result,
      };
    case GET_USER_DETAILS_INFO:
      return {
        ...state,
        loading: false,
        userdetailinfo: action.payload.result.data,
      };
    case LOAN_DETAILS:
      return {
        ...state,
        loading: false,
        loan_detailinfo: action.payload.result,
      };
    case DETAIL_INFO:
      return {
        ...state,
        loading: false,
        tabdetail: action.payload.result,
      };
    case NEW_RANGE:
      return {
        ...state,
        loading: false,
        tabdetail: action.payload.result,
      };
    case DELETED_INFO:
      return {
        ...state,
        loan_detailinfo: action.payload.result,
        loading: false,
        deleted: true,
      };
    case APPROVE_LOAN_SUCCESS:
      return {
        ...state,
        loan_detailinfo: action.payload.result,
        loading: false,
      };
    case DISBURSE_SUCCESS:
      return {
        ...state,
        loan_detailinfo: action.payload.result,
        loading: false,
      };
    case REJECT_LOAN:
      return {
        ...state,
        loan_detailinfo: action.payload.result,
        loading: false,
      };
    case GET_USER_LOAN:
      return {
        ...state,
        loantabdetail: action.payload.result,
        loading: false,
      };
    case GET_DETAIL_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    case ACCOUNT_BALANCE:
      // alert(JSON.stringify(action.payload.result));
      return {
        ...state,
        loading: false,
        bal: action.payload.result,
      };
    case ACCOUNT_STATEMENT:
      return {
        ...state,
        statementDetail: action.payload.result,
        loading: false,
      };
    case LOAD_HISTORY:
      return {
        ...state,
        loading: false,
        login_history: action.payload.result,
      };
    case GET_FEE_SPLIT:
      // alert(JSON.stringify(action.payload.result));
      return {
        ...state,
        loading: false,
        fee_split_details: action.payload.result.data.feeProfile,
      };
    default:
      return state;
  }
};
