import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";
import Select, { components } from "react-select";

class AdminEditModal extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    staff_id: "",
    role_id: "",
    lock: false,
    roles: [],
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.adminData != prevProps.adminData) {
      this.setState({
        email: this.props.adminData.email,
        username: this.props.adminData.username,
        staff_id: this.props.adminData.staff_id,
        role_id: this.props.adminData.role_id,
        roles: this.props.adminData.admin_roles.map((item) => ({
          label: item.name,
          value: item.id,
          key: item.id,
        })),
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const options =
      this.props?.data &&
      this.props?.data.map((item) => ({
        label: item.name,
        value: item.id,
        key: item.id,
      }));

    const Option = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                className="mr-2"
                style={{ width: "15px" }}
              />{" "}
              <label className="p-0 m-0">{props.label}</label>
            </div>
          </components.Option>
        </div>
      );
    };
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add New Admin"
      >
        <div className="px-4">
          <div className="box box1 mb-2">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box2 mb-2">
            <label for="fee-name">Username</label>

            <div className="input-div">
              <input
                type="text"
                name="username"
                value={this.state.username}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1 mb-2">
            <label>Staff Id</label>

            <div className="input-div">
              <input
                name="staff_id"
                value={this.state.staff_id}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2 mb-2">
            <label>Staff Role</label>

            <div>
              <Select
                isClearable
                isMulti
                onChange={(value) => {
                  this.setState({
                    ...this.state,
                    roles: value,
                  });
                }}
                value={this.state.roles}
                options={options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                components={{
                  Option,
                }}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    minHeight: "35px",
                    border: "0.5px solid #000000",
                    boxShadow: "none",
                  }),
                }}
              />
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() =>
                  this.props.handleEdit({
                    ...this.state,
                    roles: this.state.roles.map((role) => role.value),
                  })
                }
              >
                Update Admin
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
});

export default connect(mapStateToProps, { getAdminRole })(AdminEditModal);
