import React from "react";
import { connect } from "react-redux";
import InterestBar from "../InterestBar";
import {
	getInterestHistories,
	getHistoriesFilters,
	getUserDetails,
} from "../../../reduxStore/action/action.creator";
import { Loader, Pagination } from "../../../components/Accessories";
import { dateFormatter, formatCurrency } from "../../../components/Row";
import FilterHistory from "./FilterHistory";
import UserDetails from "./UserDetails";

class InterestHistory extends React.Component {
	state = {
		currentPage: 1,
		display: false,
		filter: false,
		data: {},
		showUser: false,
		csvReport: {
			data: [],
			filename: "some.csv",
		},
	};

	componentDidMount() {
		this.props.getInterestHistories(
			this.props.token,
			this.state.currentPage
		);
		this.setState({
			filter: false,
		});
	}

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};
	closeModal = () => {
		this.setState({
			display: false,
		});
	};

	handleRefresh = () => {
		this.props.getInterestHistories(this.props.token, 1);
		this.setState({
			filter: false,
		});
	};

	changePage = (id) => () => {
		if (!this.state.filter) {
			this.props.getInterestHistories(this.props.token, id);
		} else {
			this.props.getHistoriesFilters(
				this.props.token,
				id,
				this.state.data
			);
		}
		this.setState({
			currentPage: id,
		});
	};

	goPrevious = () => {
		if (this.state.currentPage - 1 != 0) {
			const id = this.state.currentPage - 1;
			if (!this.state.filter) {
				this.props.getInterestHistories(this.props.token, id);
			} else {
				this.props.getHistoriesFilters(
					this.props.token,
					id,
					this.state.data
				);
			}

			this.setState({
				currentPage: id,
			});
		}
	};

	goNext = () => {
		const total = this.props.paginate.last_page;
		if (this.state.currentPage != total) {
			const id = this.state.currentPage + 1;
			if (!this.state.filter) {
				this.props.getInterestHistories(this.props.token, id);
			} else {
				this.props.getHistoriesFilters(
					this.props.token,
					id,
					this.state.data
				);
			}
			this.setState({
				currentPage: id,
			});
		}
	};

	displayUser = (e, id) => {
		e.preventDefault();
		this.props.getUserDetails(id, this.props.token);
		this.setState({
			showUser: true,
		});
	};

	closePopup = () => {
		this.setState({
			showUser: false,
		});
	};

	submitFilter = (data) => {
		let id;
		if (this.state.currentPage > 1) {
			id = 1;
		} else {
			id = this.state.currentPage;
		}
		this.props.getHistoriesFilters(this.props.token, id, data);

		this.setState({
			filter: true,
			data,
			display: false,
		});
	};

	exportCSV = (event, done) => {
		const report = {
			filename: "Interests.csv",
			data: this.props.interests,
			// headers: [],
		};
		this.setState({ csvReport: report }, () => {
			done();
		});
	};

	render() {
		return (
			<>
				<InterestBar
					{...this.props}
					active="interest"
					showModal={this.showModal}
					handleRefresh={this.handleRefresh}
					csvreport={this.state.csvReport}
					downloadCSV={this.exportCSV}
					iscsv={true}
				>
					<div className="table-responsive py-4">
						<table
							className="table table-flush"
							id="datatable-basic"
						>
							{this.props.loading ? (
								<Loader loading={this.props.loading} />
							) : (
								this.props.interests && (
									<>
										<thead className="thead-light">
											<tr>
												<th>S/N</th>
												<th>User</th>
												<th>Account Number</th>
												<th>Date</th>
												<th className="text-right">
													Interest Received (NGN)
												</th>
												<th className="text-right">
													Interest Rate (%)
												</th>
												<th className="text-right">
													Principal (NGN)
												</th>
											</tr>
										</thead>
										<tbody>
											{this.props.interests.map(
												(interest, i) => (
													<tr key={interest.id}>
														<td>
															{(this.props
																.paginate.page -
																1) *
																25 +
																i +
																1}
														</td>
														<td>
															<a
																href="#"
																onClick={(e) =>
																	this.displayUser(
																		e,
																		interest.user_id
																	)
																}
															>
																{
																	interest.user_id
																}
															</a>
														</td>
														<td>
															{
																interest.account_number
															}
														</td>
														<td>
															{dateFormatter(
																interest.createdAt
															)}
														</td>
														<td className="text-right">
															{formatCurrency(
																interest.interest_recieved
															)}
														</td>
														<td className="text-right">
															{
																interest.interest_rate
															}
														</td>
														<td className="text-right">
															{formatCurrency(
																interest.principal
															)}
														</td>
													</tr>
												)
											)}
										</tbody>
									</>
								)
							)}
						</table>
						{!this.props.loading &&
							this.props.paginate &&
							this.props.paginate.last_page &&
							this.props.paginate.last_page > 1 && (
								<Pagination
									paginateNumber={Array(
										this.props.paginate.last_page
									).fill(0)}
									currentPage={this.state.currentPage}
									handlePagination={this.changePage}
									handlePrevious={this.goPrevious}
									handleNext={this.goNext}
									total={this.props.paginate.last_page}
									totalData={this.props.paginate.total}
								/>
							)}
					</div>
				</InterestBar>
				<UserDetails
					display={this.state.showUser}
					close={this.closePopup}
					user_details={this.state.user}
				/>
				<FilterHistory
					display={this.state.display}
					closeModal={this.closeModal}
					submit={this.submitFilter}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token,
	loading: state.interest.interest_history.loading,
	interests: state.interest.interest_history.interests,
	paginate: state.interest.interest_history.paginate,
});

export default connect(mapStateToProps, {
	getInterestHistories,
	getHistoriesFilters,
	getUserDetails,
})(InterestHistory);
