import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Promotion from "./Promotion";
import PromotionDetail from "./PromoDetails";
import PromotionSub from "./Subscription";
import PromotionSubscribers from "./PromotionSubscriber";
import { Error } from "../../../../Error";

const PromotionRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Promotion} />
      <Route
        exact
        path={`${match.path}/promotion-details`}
        component={PromotionDetail}
      />
      <Route
        exact
        path={`${match.path}/promotion-sub`}
        component={PromotionSub}
      />
      <Route
        exact
        path={`${match.path}/:name/subscriber`}
        component={PromotionSubscribers}
      />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default PromotionRoute;
