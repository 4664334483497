import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import {
  updateAdminRole,
  getAdminRole,
} from "../../../../../reduxStore/action/action.creator";

class RoleEditModal extends React.Component {
  state = {
    name: "",
    description: "",
    permissions: [],
  };

  componentDidUpdate(previousProps) {
    if (previousProps.data !== this.props.data) {
      this.setState({
        ...this.state,
        name: this.props.data?.name,
        description: this.props.data?.description,
        permissions: this.props.data?.permissions.map((res) => res?.id),
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateRole = () => {
    const newData = {
      ...this.state,
      active: true,
    };
    this.props
      .updateAdminRole(newData, this.props.data?.id, this.props.token)
      .then(() => {
        this.props.closeModal();
      })
      .then(() => alert("Success"))
      .then(() => {
        this.props.getAdminRole(this.props.token);
      });
  };

  handleCheck = (id) => {
    if (!this.state.permissions.includes(id)) {
      this.setState({
        permissions: [...this.state.permissions, id],
      });
    } else {
      this.setState({
        permissions: this.state.permissions.filter((item) => item !== id),
      });
    }
  };

  selectAll = (data) => {
    const ids = data.map((item) => item.id);

    ids.forEach((datum) => {
      if (!this.state.permissions.find((item) => item === datum)) {
        this.state.permissions.push(datum);
      }
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Update Role"
      >
        <div className="px-4">
          <div className="box box9 mb-2">
            <label>Name</label>

            <div className="input-div">
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1 mb-2">
            <label for="fee-name">Description</label>

            <div className="input-div">
              <input
                type="text"
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Update permissions for this role
              </label>

              <div id="accordion">
                {this.props.perms &&
                  this.props.perms.length !== 0 &&
                  this.props.perms.map((res, i) => {
                    return (
                      <div class="card" key={res?.id}>
                        <div class="card-header">
                          <a
                            class="card-link"
                            data-toggle="collapse"
                            href={`#collapse${res?.id}`}
                          >
                            {res?.module_name}
                          </a>
                          <small
                            className="ml-5 text-primary"
                            style={{ cursor: "pointer", fontSize: "12px" }}
                            href="#"
                            onClick={() => this.selectAll(res.permissions)}
                          >
                            Select all in {res?.module_name}
                          </small>
                        </div>
                        <div
                          id={`collapse${res?.id}`}
                          class={`collapse ${i === 0 && "show"}`}
                          data-parent="#accordion"
                        >
                          <div class="card-body d-flex flex-wrap">
                            {res.permissions.map((inner) => {
                              return (
                                <div id={inner?.id}>
                                  <div className="d-flex align-items-center mx-2">
                                    <input
                                      type="checkbox"
                                      value={inner?.id}
                                      name="permissions"
                                      checked={
                                        this.state.permissions &&
                                        this.state.permissions.includes(
                                          inner?.id
                                        )
                                      }
                                      onChange={() =>
                                        this.handleCheck(inner?.id)
                                      }
                                      className="mr-2"
                                      style={{ width: "15px" }}
                                    />
                                    <label className="p-0 m-0">
                                      {inner?.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.updateRole}
              >
                Update Role
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
});

export default connect(mapStateToProps, { updateAdminRole, getAdminRole })(
  RoleEditModal
);
