import React from "react";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import DashboardNavBar from "../../../components/DashboardNavbar";
import SideBar from "../../../components/SideBar";
import { UserInvestSub } from "../../../components/Rows/InvestmentTable";
import {
  get_A_UserInvestmentSub,
  getSavingWallet,
} from "../../../reduxStore/action/action.creator";

class UserInvestmentDetails extends React.Component {
  state = {};

  componentDidMount() {
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("t/") + 2, index.indexOf("/d"));

    this.props.get_A_UserInvestmentSub(
      `/savinvest/investment_subscriptions/${index}`,
      this.props.token
    );
    this.props.getSavingWallet(this.props.token);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let itemInCard;
    if (this.props.loading) {
      itemInCard = <Loader loading={this.props.loading} />;
    } else {
      itemInCard = this.props.user_sub && (
        <UserInvestSub
          {...this.props.user_sub}
          cycle={this.props.cycle}
          viewSubscription={this.viewSubscription}
          savings_wallet={this.props.savings_wallet}
        />
      );
    }

    return (
      <div>
        <SideBar />
        <div className="main-content" id="panel">
          <DashboardNavBar settings="false" />
          <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
            <div class="container-fluid">
              <div class="header-body">
                <div class="row align-items-center py-4">
                  <div className="col-lg-6 col-7">
                    <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                      <span
                        style={{
                          padding: "0 .5em 0 0",
                          textAlign: "center",
                        }}>
                        <i
                          class="fas fa-chevron-left"
                          style={{
                            color: "#fff",
                            padding: "0.3em .5em",
                            border: "2px solid #fff",
                            borderRadius: "50%",
                            fontSize: "12px",
                          }}></i>
                      </span>
                      <span style={{ color: "#fff" }}>Back</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mt--5">
            <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
              <div class="col-5 userDetailsCol1">{itemInCard}</div>
              <div class="col-7 userDetailsCol2">
                <div class="tab" role="tabpanel">
                  <div
                    class="card-header"
                    style={{
                      margin: "0px",
                      backgroundColor: "lavenderblush",
                      borderBottom: "0px",
                    }}></div>
                  <div class="tab-content tabs">
                    <div role="tabpanel" class="tab-pane active" id="Section1">
                      <div className="table-responsive py-4 table-div"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  user_sub: state.invest.getData.user_sub,
  loading: state.invest.getData.loading,
  savings_wallet: state.saving.get_save_wallet.wallet_data,
});
export default connect(mapStateToProps, {
  get_A_UserInvestmentSub,
  getSavingWallet,
})(UserInvestmentDetails);
