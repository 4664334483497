import { combineReducers } from "redux";
import {
  APPROVE_ACCOUNT_SUCCESS,
  GET_ALL_BATCHES,
  NEW_ACCOUNT_SUCCESS,
} from "../action/action.types";

const allNewRequest = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        all_requests: action.payload.transaction,
        totalPages: action.payload.total,
        filtering: false,
        search_filter: false,
      };
    default:
      return state;
  }
};
const approve = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case APPROVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        approve_resp: action.payload.response_message,
      };
    default:
      return state;
  }
};

export const newAccountReducer = combineReducers({
  newAccount: allNewRequest,
  approveNewAccount: approve,
});
