import React from "react";
import { connect } from "react-redux";
import InventoryBar from "../InventoryBar";
import { getUserSite } from "../../../reduxStore/action/action.creator";
import { Loader } from "../../../components/Accessories";
import {
  UserSiteBody,
  UserSiteHead,
} from "../../../components/Rows/InventoryTable";

class UserSites extends React.Component {
  state = {};

  componentDidMount() {
    this.props.getUserSite(`/api/stores`, this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getUserSite(`/api/stores`, this.props.token);
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <UserSiteHead />
          {this.props.all_site &&
            this.props.all_site.map((content, index) => (
              <UserSiteBody {...content} index={index + 1} />
            ))}
        </>
      );
    }
    return (
      <>
        <InventoryBar active="sites">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
          <i class="fas fa-plus"></i> New
        </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InventoryBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.inventory.get_site.loading,
  all_site: state.inventory.get_site.all_site,
  token: state.login.token,
});

export default connect(mapStateToProps, { getUserSite })(UserSites);
