import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";

class SavingHistory extends React.Component {
  state = {};

  componentDidMount() {
    // this.props.getSavingHistory();
  }

  render() {
    return (
      <>
        <SavingBar active="saving-history">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic"></table>
          </div>
        </SavingBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(null, {})(SavingHistory);
