import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPermission from "./AdminPermission";
import { Error } from "../../../../Error";

const Show = () => <div>wassa, Hello</div>;

const AdminPermissionRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AdminPermission} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default AdminPermissionRoute;
