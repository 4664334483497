import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { TransactionTableHead, TransactionRow } from "../../components/Rows";
import {
  getTransaction,
  detailsInfo,
  filterStatus,
  filterAllTransaction,
  reverseTransaction,
  getUserDetails,
  getTransactServices,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import { Loader, Pagination } from "../../components/Accessories";
import ModalFilter from "./ModalFilter";

class Transaction extends React.Component {
  state = {
    currentPage: 1,
    total: 0,
    display: false,
    filter: false,
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentDidMount() {
    // if (this.props.search_filter) {
    // 	this.props.filterAllTransaction(
    // 		this.state.dataToFilter,
    // 		this.props.token,
    // 		this.state.currentPage
    // 	);
    // } else {
    // 	this.props.getTransaction(
    // 		`transaction/get_all_txns`,
    // 		this.props.token
    // 	);
    // }
    this.props.getTransaction(`transaction/get_all_txns`, this.props.token);

    this.props.getTransactServices(this.props.token);
  }

  reverseUserTransaction = (id) => {
    this.props.reverseTransaction(
      this.props.admin_data.id,
      id,
      this.props.token
    );
    // setTimeout(() => {
    //   this.props.filterStatus(this.state.statusValue, id, this.props.token);
    // }, 1000);
  };

  changePage = (id) => () => {
    if (this.props.filtering == true) {
      this.props.filterStatus(this.state.statusValue, id, this.props.token);
    } else if (this.props.search_filter) {
      this.props.filterAllTransaction(
        this.state.dataToFilter,
        this.props.token,
        id
      );
    } else {
      this.props.getTransaction(
        `transaction/get_all_txns?page=${id}`,
        this.props.token
      );
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering == true) {
        this.props.filterStatus(this.state.statusValue, id, this.props.token);
      } else if (this.props.search_filter) {
        this.props.filterAllTransaction(
          this.state.dataToFilter,
          this.props.token,
          id
        );
      } else {
        this.props.getTransaction(
          `transaction/get_all_txns?page=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering == true) {
        this.props.filterStatus(this.state.statusValue, id, this.props.token);
      } else if (this.props.search_filter) {
        this.props.filterAllTransaction(
          this.state.dataToFilter,
          this.props.token,
          id
        );
      } else {
        this.props.getTransaction(
          `transaction/get_all_txns?page=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleRefresh = () => {
    this.setState({
      filtering: false,
      filter: false,
    });
    this.props.getTransaction(
      `transaction/get_all_txns?page=${this.state.currentPage}`,
      this.props.token
    );
  };

  getIndex = (index) => {
    let newIndex;
    newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  handleNextPage = (props) => {
    const { index, _id } = props;
    // this.props.detailsInfo(`transaction/get_tnx/${_id}`);

    this.props.history.push({
      pathname: `${this.props.match.url}/${_id}/details`,
      state: _id,
    });
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleSubmit = (data) => {
    this.props.filterAllTransaction(data, this.props.token, 1);
    this.setState({
      ...this.state,
      dataToFilter: data,
      display: false,
    });
  };

  filterByStatus = (status) => {
    this.props.filterStatus(status, 1, this.props.token);
    this.setState({
      ...this.state,
      statusValue: status,
    });
  };

  exportCSV = (event, done) => {
    const report = {
      filename: "TransactionReport.csv",
      data: this.props.transact,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  displayUser = (e, id) => {
    e.preventDefault();
    this.props.getUserDetails(id, this.props.token);
    this.setState({
      showUser: true,
    });
  };

  closePopup = () => {
    this.setState({
      showUser: false,
    });
  };

  isAccessible = (key) => {
    const check =
      this.props.roles &&
      this.props.roles?.data.filter((res) => {
        return res?.permissions.find((pap) => pap.name === key);
      });
    return check.length === 0 ? false : true;
  };

  render() {
    let renderPaginate;
    let itemInTable;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = total && Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TransactionTableHead />
          <tbody>
            {this.props.transact.map((trans, index) => (
              <TransactionRow
                {...trans}
                index={this.getIndex(index + 1)}
                goToNextPage={this.handleNextPage}
                reverseTransaction={this.reverseUserTransaction}
                displayUser={this.displayUser}
                user_details={this.state.user}
                closePopup={this.closePopup}
                showUser={this.state.showUser}
                canView={this.isAccessible("View a Transactions")}
              />
            ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Transactions"
              getInfo={this.handleRefresh}
              showModal={this.showModal}
              filterByStatus={this.filterByStatus}
              shouldFilter={true}
              csvreport={this.state.csvReport}
              downloadCSV={this.exportCSV}
              iscsv={true}
            />
            {this.isAccessible("View all Transactions") ? (
              <div>
                <div class="container-fluid mt--6">
                  <div class="row">
                    <div class="col">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="mb-0">Transactions</h3>
                        </div>
                        <div className="table-responsive py-4">
                          <table
                            className="table table-flush"
                            id="datatable-basic"
                          >
                            {itemInTable}
                          </table>
                          {renderPaginate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalFilter
                  display={this.state.display}
                  closeModal={this.closeModal}
                  handleSubmit={this.handleSubmit}
                  service={this.props.service}
                />
              </div>
            ) : (
              <h2 className="text-center text-bolder">
                You do not have access to this page
              </h2>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.alltransactions.transaction.loading,
  transact: state.alltransactions.transaction.transactions,
  total: state.alltransactions.transaction.total,
  err: state.alltransactions.transaction.failed,
  filtering: state.alltransactions.transaction.filtering,
  search_filter: state.alltransactions.transaction.search_filter,
  admin_data: state.login.data,
  token: state.login.token,
  service: state.alltransactions.transcategory.service,
  roles: state.roles.getrole,
});
export default connect(mapStateToProps, {
  getTransaction,
  detailsInfo,
  filterStatus,
  reverseTransaction,
  filterAllTransaction,
  getUserDetails,
  getTransactServices,
})(Transaction);
