import React from "react";
import "../../../morestyles.css";
import {} from "../../../components/Rows/ReportTable";
import {} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import { CSVLink } from "react-csv";
import ReportBar from "./InvestmentReportTab";

class InvestmentReport extends React.Component {
  state = {
    years: [],
  };

  componentDidMount() {
    const currentYear_inseconds = Date.now();
    const currentYear = new Date(currentYear_inseconds).getFullYear();
    const startYear = "2019";
    let year_range = [];

    for (var i = startYear; i <= currentYear; i++) {
      year_range.push(i);
    }

    this.setState({
      years: year_range,
    });
  }

  render() {
    let iteminTable;
    if (this.props.loading) {
      iteminTable = <Loader loading={this.props.loading} />;
    } else {
      iteminTable = "";
    }

    return (
      <>
        <ReportBar
          active="investment_by_month"
          handleRefresh={this.handleRefresh}>
          <div class="card-header">
            <div className="row">
              <div className="col-md-8">
                <h3 class="mb-0">Investment Report</h3>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-8">
                    <select
                      name="year"
                      onChange={this.handleYearChange}
                      value={this.state.year}
                      className="form-control">
                      {this.state.years.map((content) => (
                        <option value={content}>{content}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    {/* <CSVLink
                      {...this.state.csvReport}
                      asyncOnClick={true}
                      className="btn btn-sm btn-neutral"
                      onClick={this.exportCSV}>
                      <i className="fa fa-export"></i> Export CSV
                    </CSVLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive py-4">
            <table class="table table-flush" id="datatable">
              {iteminTable}
            </table>
          </div>
        </ReportBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(InvestmentReport);
