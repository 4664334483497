import React from "react";

export const SplitTableHead = () => (
  <thead className="thead-light" style={{ textAlign: "center" }}>
    <tr>
      <th>S/N</th>
      <th>Beneficiaries</th>
      <th>Value</th>
      <th>Shared Value</th>
      <th>Actions</th>
    </tr>
  </thead>
);

export const SplitRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.userType ? props.userType : props.beneficiary_id}</td>
      <td>{props.share}</td>
      <td></td>
      <td>
        <i
          className="fa fa-trash-o"
          onClick={() => props.delete(JSON.stringify(props))}></i>
      </td>
    </tr>
  </>
);
