import React from "react";
import { connect } from "react-redux";
import "../../../../../morestyles.css";
import { Modal, Loader } from "../../../../../components/Accessories";
import AuthorizationTab from "../AuthorizationTab";
import PermissionForm from "./PermissionForm";
import {
  setPermission,
  getPermission,
  deletePermission,
} from "../../../../../reduxStore/action/action.creator";

class Permission extends React.Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.success == true) {
      alert(nextProps.response);
    }
  }

  componentDidMount() {
    this.props.getPermission(this.props.token);
  }

  setPermission = (data) => {
    this.props.setPermission(data, this.props.token);
    this.props.getPermission(this.props.token);
  };

  deletePermissionList = (url) => () => {
    this.props.deletePermission(url, this.props.token);
    this.props.getPermission(this.props.token);
  };

  render() {
    return (
      <AuthorizationTab {...this.props} active="permission-settings">
        <div className="row" style={{ padding: "0 1em" }}>
          <div
            className="col-md-12"
            style={{
              backgroundColor: "#eee",
              borderBottom: "1px solid #aaa",
              paddingLeft: ".5em",
            }}></div>
          <div className="col-md-5">
            <PermissionForm submitPermission={this.setPermission} />
          </div>
          <div className="col-md-7">
            <div className="table-responsive py-4 table-div">
              <table
                className="table table-striped table-bordered"
                id="datatable-basic">
                <thead className="table-light">
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </thead>
                <tbody>
                  {this.props.data &&
                    this.props.data.map((content) => (
                      <tr>
                        <td>{content.id}</td>
                        <td>{content.name}</td>
                        <td>{content.description}</td>
                        <td style={{ textAlign: "center" }}>
                          <i
                            className="fa fa-trash-o"
                            onClick={this.deletePermissionList(
                              `permission_lists/${content.id}`
                            )}></i>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthorizationTab>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.roles.setpermission.success,
  response: state.roles.setpermission.response_message,
  data: state.roles.getpermission.data,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  setPermission,
  getPermission,
  deletePermission,
})(Permission);
