import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import {
  getInvestmentProduct,
  addInvestmentHistory,
  getInvestmentHistory,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  InvestmentHistoryBody,
  InvestmentHistoryHead,
} from "../../../components/Rows/InvestmentTable";
import InvestmentHistoryModal from "./InvestmentHistoryModal";

class InvestmentReturn extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getInvestmentProduct(
      "/savinvest/all/investment_products",
      this.props.token
    );

    this.props.getInvestmentHistory(
      `/savinvest/investment_progress_histories`,
      this.props.token
    );
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleRefresh = () => {
    //
  };

  addHistory = (data) => {
    callBackMethod(
      this.props.addInvestmentHistory(
        `/savinvest/investment_progress_histories`,
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.invest_prod_history));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestmentHistoryHead />
          <tbody>
            {this.props.invest_prod_history_data &&
              this.props.invest_prod_history_data.map((content, index) => (
                <InvestmentHistoryBody
                  {...content}
                  index={index + 1}
                  invest_prod={this.props.invest_prod}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="history">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <InvestmentHistoryModal
          display={this.state.display}
          closeModal={this.closeModal}
          addHistory={this.addHistory}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.invest.getData.loading,
  invest_return: state.invest.getData.invest_return,
  invest_prod_history: state.invest.setData.invest_prod_history,
  invest_prod_history_data: state.invest.getData.invest_prod_history_data,
  invest_prod: state.invest.getData.invest_prod?.data,
});

export default connect(mapStateToProps, {
  getInvestmentProduct,
  addInvestmentHistory,
  getInvestmentHistory,
})(InvestmentReturn);
