import { combineReducers } from "redux";
import {
  ADD_WALLETS,
  DELETE_BATCH,
  FUND_WALLET,
  GET_BATCH,
  GET_BATCH_REQUEST,
  GET_CARD_REQUEST,
  GET_WALLETS,
  GET_WALLET_HISTORY,
  SET_BATCH_REQUEST,
} from "../action/action.types";

const cardBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_BATCH:
      return {
        ...state,
        loading: false,
        batches: action.payload.data,
      };
    case GET_CARD_REQUEST:
      return {
        ...state,
        loading: false,
        request: action.payload.data,
      };
    case GET_BATCH_REQUEST:
      return {
        ...state,
        loading: false,
        batch_request: action.payload.data,
      };
    case DELETE_BATCH:
      return {
        ...state,
        loading: false,
        del_message: action.payload.Response_message,
      };
    default:
      return state;
  }
};

const addBatchRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BATCH_REQUEST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

const walletReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_WALLET_HISTORY:
      return {
        ...state,
        loading: false,
        wallet_history: action.payload.wallet_data,
        total: action.payload.total,
      };
    case GET_WALLETS:
      return {
        ...state,
        loading: false,
        wallet_data: action.payload.data,
      };
    case ADD_WALLETS:
      return {
        ...state,
        loading: false,
        wallet_resp: action.payload.response_message,
      };
    case FUND_WALLET:
      return {
        ...state,
        loading: false,
        fund_response: action.payload.response_message,
      };
    default:
      return state;
  }
};

export const cardReducer = combineReducers({
  all_batches: cardBatchReducer,
  set_req_batch: addBatchRequestReducer,
  wallet: walletReducer,
});
