import React from "react";
import { Modal, DateRange } from "../../components/Accessories";

class ModalFilter extends React.Component {
  state = {
    reference: "",
    debited_ac: "",
    channel: "",
    transType: "",
    narration: "",
    transAmount: "",
    startDate: "",
    endDate: "",
    serviceCode: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  submit = () => {
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <>
        <Modal
          display={this.props.display ? "block" : "none"}
          closeModal={this.props.closeModal}>
          <div className="body-grid">
            <div className="box box9">
              <label>Select Date Range</label>
              <DateRange handleRange={this.handleRange} />
            </div>
            <div className="box box1">
              <label>Enter Transaction Reference</label>
              <input
                name="reference"
                value={this.state.reference}
                onChange={this.handleChange}
                className="form-control"
                placeholder={`Filter by transaction Reference`}
              />
            </div>
            <div className="box box2">
              <label>Enter Debited Account</label>
              <input
                name="debited_ac"
                onChange={this.handleChange}
                value={this.state.debited_ac}
                placeholder={`Filter by debited Account`}
                className="form-control"
              />
            </div>
            <div className="box box1">
              <label>Select Transaction Type</label>
              <select
                className="form-control"
                name="transType"
                value={this.state.transType}
                onChange={this.handleChange}>
                <option value="" hidden>
                  Filter by Transaction Type
                </option>
                <option value="CREDIT">CREDIT</option>
                <option value="DEBIT">DEBIT</option>
              </select>
            </div>

            <div className="box box2">
              <label>Select Service Name</label>
              <select
                className="form-control"
                name="serviceCode"
                onChange={this.handleChange}
                value={this.state.serviceCode}>
                <option value="" hidden>
                  Filter by Service Code
                </option>
                {this.props.service &&
                  this.props.service.length > 0 &&
                  this.props.service.map((serv) => (
                    <option
                      value={serv.serviceCode}
                      style={{ textTransform: "capitalize" }}>
                      {serv.serviceName}
                    </option>
                  ))}
                {/* <option value={this.props.service[1].serviceCode}>
                  {this.props.service[1].serviceName}
                </option> */}
              </select>
            </div>
            <div className="box box1">
              <label>Enter Narration</label>
              <input
                className="form-control"
                name="narration"
                onChange={this.handleChange}
                value={this.state.narration}
                placeholder={`Filter by narration`}
              />
            </div>
            <div className="box box2">
              <label>Enter Transaction Amount</label>
              <input
                className="form-control"
                name="transAmount"
                onChange={this.handleChange}
                value={this.state.transAmount}
                placeholder={`Filter by transaction Amount`}
              />
            </div>

            <div className="box box9">
              <div className="text-center">
                <button
                  type="button"
                  class="btn btn-primary my-4"
                  onClick={this.submit}>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalFilter;
