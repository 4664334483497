import React from "react";
import { currencyFormatter, dateFormatter, timeFormatter } from "../Row";
import { Link } from "react-router-dom";

export const BatchesHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
    </tr>
  </thead>
);

export const BatchesBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.batch_code}</td>
    <td>{props.user_id}</td>
    <td>{props.batch_name}</td>
    <td>{props.batch_description}</td>
  </tr>
);
