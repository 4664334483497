import React from "react";
import { dateFormatter } from "../Row";

export const DeveloperAccountsTableHead = () => (
  <thead className="thead-light" style={{}}>
    <tr>
      <th>S/N</th>
      <th>User ID</th>
      <th>Business Name</th>
      <th>Business Support Email</th>
      <th>Address</th>
      <th>Actions</th>
    </tr>
  </thead>
);

export const DeveloperAccountsRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.userId}</td>
      <td>{props.businessName}</td>
      <td>{props.businessSupportEmail}</td>
      <td>
        {props.businessAddress.street +
          ", " +
          props.businessAddress.city +
          ", " +
          props.businessAddress.state +
          ", " +
          props.businessAddress.country}
      </td>
      {/* <td>{props.userType ? props.userType : props.beneficiary_id}</td> */}
      {/* <td>{props.share}</td> */}
      {/* <td></td> */}
      <td>
        <i className="fa fa-eye"></i>
      </td>
    </tr>
  </>
);

export const DeveloperApiCallsTableHead = () => (
  <thead className="thead-light" style={{}}>
    <tr>
      <th>S/N</th>
      <th>Business Support Email</th>
      <th>End-Point Called</th>
      <th>Request Body</th>
      <th>Api Response</th>
      <th>Date Accessed</th>
    </tr>
  </thead>
);

export const DeveloperApiCallsRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.businessSupportEmail}</td>
      <td>{props.endPointCalled}</td>
      <td>{props.requestBody}</td>
      <td>{props.apiResponse}</td>
      <td>{props.createdAt}</td>
    </tr>
  </>
);

export const DeveloperApiCallStatsTableHead = () => (
  <thead className="thead-light" style={{}}>
    <tr>
      <th>S/N</th>
      <th>Business Support Email</th>
      <th>End-Point Called</th>
      <th>Call Times</th>
      <th>Date Accessed</th>
    </tr>
  </thead>
);

export const DeveloperApiCallStatsRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.businessSupportEmail}</td>
      <td>{props.endPointCalled}</td>
      <td>{props.callTimes}</td>
      <td>{props.createdAt}</td>
    </tr>
  </>
);

export const DeveloperDocHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Username/Id</th>
      <th>Merchant Id</th>
      <th>Business Name</th>
      <th>Business Support Email</th>
      <th>Tax Document</th>
      <th>Business Registration Doc</th>
      <th>Utility Bill Document</th>
      <th>Created At</th>
      <th>Approved Status</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const DeveloperDocBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.userId}</td>
    <td>{props.merchantId}</td>
    <td>{props.businessName}</td>
    <td>{props.businessSupportEmail}</td>
    <td>{props.taxDocuments}</td>
    <td>{props.businessRegistrationDocuments}</td>
    <td>{props.utilityBillDocuments}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>{props.approved == true ? "approved" : "not approved"}</td>
    <td
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <i className="fas fa-eye" style={{ padding: "0 .5em" }}></i>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          checked={props.approved}
          onChange={() => props.toggleDevDoc(props)}
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
  </tr>
);
