import { createStore, compose, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
import {composeWithDevTools} from 'redux-devtools-extension'

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["fees", "commissions", "notifications", "details"],
  // whitelist: ["login", "details"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// let composeEnhancer = compose;

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
