export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SCAN = "LOGIN_SCAN";
export const VERIFY_TOKEN_LOGIN = "VERIFY_TOKEN_LOGIN";
export const PASS_SUCCESS = "PASS_SUCCESS";

export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FAILED = "ADMIN_FAILED";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILED = "TRANSACTION_FAILED";
export const LOGOUT = "LOGOUT";
export const DETAIL_INFO = "DETAIL_INFO";
export const FEE_PROFILE_SUCCESS = "FEE_PROFILE_SUCCESS";
export const GET_FEE_SPLIT = "GET_FEE_SPLIT";
export const FEE_PROFILE_FAILED = "FEE_PROFILE_FAILED";
export const TRANS_CAT_SUCCESS = "TRANS_CAT_SUCCESS";
export const TRANS_CAT_FAILED = "TRANS_CAT_FAILED";
export const TRANS_SERVICE_CODE_SUCCESS = "TRANS_SERVICE_CODE_SUCCESS";
export const TRANS_SERVICE_CODE_FAILED = "TRANS_SERVICE_CODE_FAILED";
export const SET_FEE_PROFILE_SUCCESS = "SET_FEE_PROFILE_SUCCESS";
export const SET_FEE_PROFILE_FAILED = "SET_FEE_PROFILE_FAILED";
export const GET_BEN_SUCCESS = "GET_BEN_SUCCESS";
export const GET_BEN_FAILED = "GET_BEN_FAILED";
export const SET_BENEFICIARY_SUCCESS = "SET_BENEFICIARY_SUCCESS";
export const SET_BENEFICIARY_FAILURE = "SET_BENEFICIARY_FAILURE";
export const GET_COMMISSION_SUCCESS = "GET_COMMISSION_SUCCESS";
export const GET_COMMISSION_FAILED = "GET_COMMISSION_FAILED";
export const SET_COMMISSION_SUCCESS = "SET_COMMISSION_SUCCESS";
export const SET_COMMISSION_FAILED = "SET_COMMISSION_FAILED";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS";
export const TRANSACTION_DETAIL_INFO = "TRANSACTION_DETAIL_INFO";
export const GET_USER_DETAILS_INFO = "GET_USER_DETAILS_INFO";
export const GET_DETAIL_FAILED = "GET_DETAIL_FAILED";
export const GET_LOAN_PRODUCTS_FAILED = "GET_LOAN_PRODUCTS_FAILED";
export const GET_LOAN_PRODUCTS_SUCCESS = "GET_LOAN_PRODUCTS_SUCCESS";
export const GET_LOAN_REQUEST_FAILED = "GET_LOAN_REQUEST_FAILED";
export const GET_LOAN_REQUEST_SUCCESS = "GET_LOAN_REQUEST_SUCCESS";
export const APPROVE_LOAN_SUCCESS = "APPROVE_LOAN_SUCCESS";
export const DISBURSE_SUCCESS = "DISBURSE_SUCCESS";
export const REJECT_LOAN = "REJECT_LOAN";
export const DELETED_INFO = "DELETED_INFO";
export const GET_TXN_LIMIT_CONFIGS_SUCCESS = "GET_TXN_LIMIT_CONFIGS_SUCCESS";
export const GET_TXN_LIMIT_CONFIGS_FAILED = "GET_TXN_LIMIT_CONFIGS_FAILED";
export const SET_TXN_LIMIT_SUCCESS = "SET_TXN_LIMIT_SUCCESS";
export const SET_TXN_LIMIT_FAILED = "SET_TXN_LIMIT_FAILED";
export const GET_USER_LOAN = "GET_USER_LOAN";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const TOGGLE_SUCCESS = "TOGGLE_SUCCESS";
export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const SET_PROMO = "SET_PROMO";
export const GET_PROMO = "GET_PROMO";
export const DELETE_PROMO = "DELETE PROMO";
export const TOGGLE_PROMO = "TOGGLE_PROMO";
export const SET_ROLE = "SET_ROLE";
export const UPDATE_AD_ROLE = "UPDATE_AD_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SET_PERMISSION = "SET_PERMISSION";
export const GET_PERMISSION = "GET_PERMISSION";
export const SET_PERMISSION_MODULE = "SET_PERMISSION_MODULE";
export const GET_PERMISSION_MODULE = "GET_PERMISSION_MODULE";
export const SET_ADMIN_PERMISSION = "SET_ADMIN_PERMISSION";
export const GET_ADMIN_PERMISSION = "GET_ADMIN_PERMISSION";
export const DELETE_PERMISSION_LIST = "DELETE_PERMISSION_LIST";
export const GET_BANK_TRANS = "GET_BANK_TRANS";
export const GET_FLUTTER = "GET_FLUTTER";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const FILTER_BANK_TRANS = "FILTER_BANK_TRANS";
export const FILTER_FLUTTER = "FILTER_FLUTTER";
export const GET_BILL_LIST = "GET_BILL_LIST";
export const GET_BILL_TRANS = "GET_BILL_TRANS";
export const GET_BANK_TRANS_STATUS = "GET_BANK_TRANS_STATUS";
export const GET_FLW_RANGE = "GET_FLW_RANGE";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const FILTER_NOTIFICATION = "FILTER_NOTIFICATION";
export const GET_CHANNEL = "GET_CHANNEL";
export const TOGGLE_CHANNEL = "TOGGLE_CHANNEL";
export const GET_NOTIFICATION_STATUS = "GET_NOTIFICATION_VALUE";
export const GET_NOTIFICATION_TYPES = "GET_NOTIFICATION_TYPES";
export const GET_NOTIFICATION_MODE = "GET_NOTIFICATION_MODE";
export const FILTER_TRANSACTION_STATUS = "FILTER_TRANSACTION_STATUS";
export const SET_REQUERY = "SET_REQUERY";
export const SET_REVERSAL = "SET_REVERSAL";
export const ENABLE_USER = "ENABLE_USER";
export const DISABLE_USER = "DISABLE_USER";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const FILTER_TRANSACTION = "FILTER_TRANSACTION";
export const FILTER_USERS = "FILTER_USERS";
export const USER_REPORT_INFO = "USER_REPORT_INFO";
export const TRANSACTION_REPORT = "TRANSACTION_REPORT";
export const ACCOUNT_BALANCE = "ACCOUNT_BALANCE";
export const ACCOUNT_STATEMENT = "ACCOUNT_STATEMENT";
export const NEW_RANGE = "NEW_RANGE";
export const FILTER_USER_REPORT = "FILTER_USER_REPORT";
export const REPORT_BY_INSTITUTE = "REPORT_BY_INSTITUTE";
export const GET_INSTITUTIONS = "GET_INSTITUTIONS";
export const FILTER_TRANS_REPORT_YEAR = "FILTER_TRANS_REPORT_YEAR";
export const FILTER_TRANS_REPORT_INSTITUTE = "FILTER_TRANS_REPORT_INSTITUTE";
export const GET_INSTITUTE_STATUS = "GET_INSTITUTE_STATUS";
export const FILTER_INSTITUTE_REPORT = "FILTER_INSTITUTE_REPORT";
export const GET_SERVICE_REPORT = "GET_SERVICE_REPORT";
export const GET_TRANSACTION_SERVICE = "GET_TRANSACTION_SERVICE";
export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const EDIT_LIMIT = "EDIT_LIMIT";
export const DELETE_TRANSACTION_LIMIT = "DELETE_TRANSACTION_LIMIT";
export const SERVICE_APPLICABLE = "SERVICE_APPLICABLE";
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const GET_PROMO_SUBSCRIBERS = "GET_PROMO_SUBSCRIBERS";
export const ADD_BATCH = "ADD_BATCH";
export const GET_BATCH = "GET_BATCH";
export const GET_CARD_REQUEST = "GET_CARD_REQUEST";
export const DELETE_BATCH = "DELETE_BATCH";
export const SET_BATCH_REQUEST = "SET_BATCH_REQUEST";
export const GET_BATCH_REQUEST = "GET_BATCH_REQUEST";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const SET_LEVEL = "SET_LEVEL";
export const GET_LEVEL = "GET_LEVEL";
export const SET_LEVEL_SETTINGS = "SET_LEVEL_SETTINGS";
export const GET_LEVEL_SETTINGS = "GET_LEVEL_SETTINGS";
export const GET_CUSTOMER_DOCUMENT = "GET_CUSTOMER_DOCUMENT";
export const GET_CUSTOMER_DOCUMENTS = "GET_CUSTOMER_DOCUMENTS";
export const GET_CUSTOMER_DOCUMENTS_PAGE = "GET_CUSTOMER_DOCUMENTS_PAGE";
export const GET_KYC_UPGRADES = "GET_KYC_UPGRADES";
export const GET_KYC_UPGRADES_PAGE = "GET_KYC_UPGRADES_PAGE";
export const SET_REQUIREMENT = "SET_REQUIREMENT";
export const GET_REQUIREMENT = "GET_REQUIREMENT";
export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
export const REJECT_DOCUMENT = "REJECT_DOCUMENT";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const LOAD_HISTORY = "LOAD_HISTORY";
export const LOAN_DETAILS = "LOAN_DETAILS";
export const FILTER_LOAN = "FILTER_LOAN";
export const KYC_LEVEL_DETAIL = "KYC_LEVEL_DETAIL";
export const DELETE_KYC_LEVEL = "DELETE_KYC_LEVEL";
export const AUDIT_LOGS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const AUDIT_LOGS_FAILED = "AUDIT_LOGS_FAILED";
export const EDIT_KYC_LEVEL = "EDIT_KYC_LEVEL";
export const FINAL_APPROVAL = "FINAL_APPROVAL";
export const DELETE_REQUIREMENT = "DELETE_REQUIREMENT";
export const SET_RATE = "SET_RATE";
export const GET_RATE = "GET_RATE";
export const GET_A_RATE = "GET_A_RATE";
export const SET_KYC_VIRTUAL_CARD = "SET_KYC_VIRTUAL_CARD";
export const GET_KYC_VIRTUAL_CARD = "GET_KYC_VIRTUAL_CARD";
export const UPDATE_KYC_VIRTUAL_CARD = "UPDATE_KYC_VIRTUAL_CARD";
export const SET_VIRTUAL_LIMIT = "SET_VIRTUAL_LIMIT";
export const GET_VIRTUAL_LIMIT = "GET_VIRTUAL_LIMIT";
export const GET_VIRTUAL_TRANS = "GET_VIRTUAL_TRANS";
export const GET_VIRTUAL_CARD = "GET_VIRTUAL_CARD";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const SWITCH_BILL = "SWITCH_BILL";
export const LOCK_CARD = "LOCK_CARD";
export const UNLOCK_CARD = "UNLOCK_CARD";
export const TERMINATE_CARD = "TERMINATE_CARD";
export const FILTER_DOCUMENT = "FILTER_DOCUMENT";
export const DEVELOPER_ACCOUNTS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const DEVELOPER_ACCOUNTS_FAILED = "AUDIT_LOGS_FAILED";
export const DEVELOPER_CALLS_SUCCESS = "DEVELOPER_CALLS_SUCCESS";
export const DEVELOPER_CALLS_FAILED = "DEVELOPER_CALLS_FAILED";
export const DEVELOPER_CALL_STATS_SUCCESS = "DEVELOPER_CALL_STATS_SUCCESS";
export const DEVELOPER_CALL_STATS_FAILED = "DEVELOPER_CALL_STATS_FAILED";
export const LINK_BVN = "LINK_BVN";
export const GET_WALLET_HISTORY = "GET_WALLET_HISTORY";
export const GET_WALLETS = "GET_WALLETS";
export const ADD_WALLETS = "ADD_WALLETS";
export const FUND_WALLET = "FUND_WALLET";
export const FILTER_VIRTUAL_CARD_TRANS = "FILTER_VIRTUAL_CARD_TRANS";
export const FILTER_VIRTUAL_CARD = "FILTER_VIRTUAL_CARD";
export const EDIT_VIRTUAL_CARD_LIMIT = "EDIT_VIRTUAL_CARD_LIMIT";
export const GET_DEV_DOC = "GET_DEV_DOC";
export const TOGGLE_DOC = "TOGGLE_DOC";
export const GET_USER_CATEGORY = "GET_USER_CATEGORY";
export const SET_USER_CATEGORY = "SET_USER_CATEGORY";
export const SET_SAVING = "SET_SAVING";
export const GET_SAVING = "GET_SAVING";
export const GET_SAVING_WALLET = "GET_SAVING_WALLET";
export const DELETE_SAVINGS = "DELETE_SAVINGS";
export const GET_USER_SAVING = "GET_USER_SAVING";
export const GET_SAVINGS_PRODUCT_DETAILS = "GET_SAVINGS_PRODUCT_DETAILS";
export const LOCK_SAVING_PROD = "LOCK_SAVING_PROD";
export const SET_PENALTY = "SET_PENALTY";
export const GET_PENALTY = "GET_PENALTY";
export const UPDATE_PENALTY = "UPDATE_PENALTY";
export const GET_WALLET_TRANSACTION = "GET_WALLET_TRANSACTION";
export const GET_A_USER_SAVINGS = "GET_A_USER_SAVINGS";
export const UPDATE_SAVING_PRODUCT = "UPDATE_SAVING_PRODUCT";
export const SET_WITHDRAWAL = "SET_WITHDRAWAL";
export const GET_WITHDRAWAL = "GET_WITHDRAWAL";
export const GET_HISTORY = "GET_HISTORY";
export const CHANGE_WALLET_STATUS = "CHANGE_WALLET_STATUS";
export const GET_USER_SAVING_DETAILS = "GET_USER_SAVING_DETAILS";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const SET_INVESTMENT_ROLE = "SET_INVESTMENT_ROLE";
export const GET_INVESTMENT_ROLE = "GET_INVESTMENT_ROLE";
export const ADD_MERCHANT = "ADD_MERCHANT";
export const GET_MERCHANT = "GET_MERCHANT";
export const GET_INVESTMENT_SUB = "GET_INVESTMENT_SUB";
export const ADD_INVEST_PRODUCT = "ADD_INVEST_PRODUCT";
export const GET_INVEST_PRODUCT = "GET_INVEST_PRODUCT";
export const ADD_INVEST_CATEGORY = "ADD_INVEST_CATEGORY";
export const GET_INVEST_CATEGORY = "GET_INVEST_CATEGORY";
export const GET_INVEST_RETURN = "GET_INVEST_RETURN";
export const GET_INVEST_DOC = "GET_INVEST_DOC";
export const UPLOAD_IMG = "UPLOAD_IMG";
export const APPROVE_INVEST_PROD = "APPROVE_INVEST_PROD";
export const GET_INVEST_CYCLE = "GET_INVEST_CYCLE";
export const GET_A_USER_INVEST_SUB = "GET_A_USER_INVEST_SUB";
export const EDIT_INVEST_PROD = "EDIT_INVEST_PROD";
export const EDIT_INVEST_PROD_ERROR = "EDIT_INVEST_PROD_ERROR";
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPLOAD_DOC = "UPLOAD_DOC";
export const DELETE_INVEST_PROD = "DELETE_INVEST_PROD";
export const VIEW_MERCHANT = "VIEW_MERCHANT";
export const VIEW_INVEST_PROD_DETAIL = "VIEW_INVEST_PROD_DETAIL";
export const CHANGE_MERCHANT_STATUS = "CHANGE_MERCHANT_STATUS";
export const ADD_INVEST_HISTORY = "ADD_INVEST_HISTORY";
export const GET_INVEST_HISTORY = "GET_INVEST_HISTORY";
export const UPLOAD_SAVING_IMG = "UPLOAD_SAVING_IMG";
export const FILTER_SAVINGS_BY_DATE = "FILTER_SAVINGS_BY_DATE";
export const FILTER_SAVINGS_BY_STATUS = "FILTER_SAVINGS_BY_STATUS";
export const FILTER_INVESTMENT_BY_DATE = "FILTER_INVESTMENT_BY_DATE";
export const FILTER_INVESTMENT_BY_STATUS = "FILTER_INVESTMENT_BY_STATUS";
export const FILTER_USER_SAVINGS_DATE = "FILTER_USER_SAVINGS_DATE";
export const FILTER_USER_SAVINGS_STATUS = "FILTER_USER_SAVINGS_STATUS";
export const GET_SAVINGS_GROUP = "GET_SAVINGS_GROUP";
export const ADD_SAVE_GROUP = "ADD_SAVE_GROUP";
export const FILTER_GROUP_SAVE_DATE = "FILTER_GROUP_SAVE_DATE";
export const FILTER_GROUP_SAVE_STATUS = "FILTER_GROUP_SAVE_STATUS";
export const GET_REPORT_MONTH = "GET_REPORT_MONTH";
export const FILTER_REPORT_MONTH = "FILTER_REPORT_MONTH";
export const ADD_AGENT = "ADD_AGENT";
export const GET_AGENT = "GET_AGENT";
export const ASSIGN_AGENT = "ASSIGN_AGENT";
export const GET_AGENT_CLAIMS = "GET_AGENT_CLAIMS";
export const APPROVE_CLAIMS = "APPROVE_CLAIMS";
export const ADD_INCENTIVES = "ADD_INCENTIVES";
export const INCENTIVE_ERROR = "INCENTIVE_ERROR";
export const GET_INCENTIVES = "GET_INCENTIVES";
export const GET_REDEEMED_TRANSFER = "GET_REDEEMED_TRANSFER";
export const ADD_CONVERSION = "ADD_CONVERSION";
export const ADD_CONVERSION_ERROR = "ADD_CONVERSION_ERROR";
export const GET_CONVERSION = "GET_CONVERSION";
export const GET_LOAN_STATUS = "GET_LOAN_STATUS";
export const UPDATE_INCENTIVES = "UPDATE_INCENTIVES";
export const UPDATE_INCENTIVES_ERROR = "UPDATE_INCENTIVES_ERROR";
export const GET_STORES = "GET_STORES";
export const GET_STORE_DETAILS = "GET_STORE_DETAILS";
export const GET_STORE_PRODUCT = "GET_STORE_PRODUCT";
export const GET_STORE_ORDER = "GET_STORE_ORDER";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_ORDERS = "GET_ORDERS";
export const CHANGE_STORE_STATUS = "CHANGE_STORE_STATUS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_SITES = "GET_SITES";

// for billers
export const GET_CATEGORY_CODE_CONFIG = "GET_CATEGORY_CODE_CONFIG";
export const ADD_CATEGORY_CODE_CONFIG = "ADD_CATEGORY_CODE_CONFIG";
export const GET_GENERAL_CODE = "GET_GENERAL_CODE";
export const GET_ITEM_CODE = "GET_ITEM_CODE";
export const GET_PRODUCT_CODE = "GET_PRODUCT_CODE";
export const GET_PROVIDER_CODE = "GET_PROVIDER_CODE";
export const GET_SERVICE_CODE = "GET_SERVICE_CODE";
export const GET_SERVICE_SWITCH = "GET_SERVICE_SWITCH";
export const GET_SWITCH_CODE = "GET_SWITCH_CODE";
export const EDIT_CODE = "EDIT_CODE";
export const EDIT_CODE_ERROR = "EDIT_CODE_ERROR";
export const ADD_CODE_ERROR = "ADD_CODE_ERROR";

// more incentives
export const GET_EARNED_POINT = "GET_EARNED_POINT";
export const GET_CHARGE_BACK = "GET_CHARGE_BACK";
export const GET_USER_EARNED_POINT = "GET_USER_EARNED_POINT";
export const GET_A_CHARGE_BACK = "GET_A_CHARGE_BACK";
export const UPDATE_CHARGE_BACK = "UPDATE_CHARGE_BACK";

//more ecommerce
export const GET_PRODUCT_VIEW = "GET_PRODUCT_VIEW";
export const GET_CHECKOUT_VIEW = "GET_CHECKOUT_VIEW";

// Bulk payment
export const GET_ALL_BATCHES = "GET_ALL_BATCHES";

export const NEW_ACCOUNT_SUCCESS = "NEW_ACCOUNT_SUCCESS";
export const NEW_ACCOUNT_FAILED = "NEW_ACCOUNT_FAILED";

export const APPROVE_ACCOUNT_SUCCESS = "APPROVE_ACCOUNT_SUCCESS";
export const APPROVE_ACCOUNT_FAILED = "APPROVE_ACCOUNT_FAILED";
// Interests
export const SETUP_INTEREST = "SETUP_INTEREST";
export const GET_INTERESTS = "GET_INTERESTS";
export const INTEREST_DETAIL = "INTEREST_DETAIL";
export const DELETE_INTEREST = "DELETE_INTEREST";
export const EDIT_INTEREST = "EDIT_INTEREST";
export const GET_INTEREST_HISTORIES = "GET_INTEREST_HISTORIES";
export const FILTER_INTEREST_HISTORIES = "FILTER_INTEREST_HISTORIES";
export const GET_INTEREST_EARNINGS = "GET_INTEREST_EARNINGS";
export const FILTER_INTEREST_EARNINGS = "FILTER_INTEREST_EARNINGS";
export const GET_INTEREST_PAYOUTS = "GET_INTEREST_PAYOUTS";
export const FILTER_INTEREST_PAYOUTS = "FILTER_INTEREST_PAYOUTS";

//USER INTEREST HISTORY\
export const GET_USER_INTEREST_HISTORY = "GET_USER_INTEREST_HISTORY";
