import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";

class EditCode extends React.Component {
  state = {
    name: "",
    description: "",
    services_code_id: "",
    provider_code_id: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.codeData != prevProps.codeData) {
      this.setState({
        name: this.props.codeData.name,
        description: this.props.codeData.description,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        modalTitle="Edit Code Config"
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          {this.props.isService != false && (
            <>
              <div className="box box9">
                <label>Service Code</label>
                <select
                  className="form-control"
                  name="services_code_id"
                  value={this.state.services_code_id}
                  onChange={this.handleChange}>
                  <option>{this.state.services_code_id}</option>
                  {this.props.bills_service_code &&
                    this.props.bills_service_code.map((content) => (
                      <option value={content.id}>{content.name}</option>
                    ))}
                </select>
              </div>
              <div className="box box9">
                <label>Provider Code</label>
                <select
                  className="form-control"
                  name="provider_code_id"
                  value={this.state.provider_code_id}
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.provider_code &&
                    this.props.provider_code.map((content) => (
                      <option value={content.id}>{content.name}</option>
                    ))}
                </select>
              </div>
            </>
          )}
          {this.props.extra && (
            <>
              <div className="box box9">
                <label>General Code</label>
                <select
                  className="form-control"
                  name="general_code_id"
                  value={this.state.general_code_id}
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.general_code &&
                    this.props.general_code.map((content) => (
                      <option value={content.id}>{content.name}</option>
                    ))}
                </select>
              </div>
              <div className="box box9">
                <label>Category Code</label>
                <select
                  className="form-control"
                  name="biller_category_id"
                  value={this.state.biller_category_id}
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.category_code &&
                    this.props.category_code.data.map((content) => (
                      <option value={content.id}>{content.name}</option>
                    ))}
                </select>
              </div>
            </>
          )}
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Save Code Config
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  bills_service_code: state.bills.billconfig.bills_service_code,
  provider_code: state.bills.billconfig.provider_code,
  general_code: state.bills.billconfig.general_code,
  category_code: state.bills.billconfig.category_code,
});

export default connect(mapStateToProps, {})(EditCode);
