import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class KycDocumentModal extends React.Component {
  state = {
    user_id: "",
    user_document_id: "",
    kyc_verification_id: "",
    reason: "",
    admin_id: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.documentProps != this.props.documentProps) {
      this.setState({
        user_document_id: this.props.documentProps.id,
        user_id: this.props.documentProps.user_id,
        admin_id: this.props.admins.id,
        kyc_verification_id:
          this.props.documentProps.kyc_verification.length > 0 &&
          this.props.documentProps.kyc_verification[0].id,
      });
    }

    if (this.props.display != prevProps.display) {
      this.setState({
        reason: "",
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    this.props.verifyKyc(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Approve Document">
        <div className="body-grid">
          {/* <div className="box box9">
            <label>User Id</label>
            <input
              className="form-control"
              name="user_id"
              value={this.state.user_id}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label htmlFor="">User Document Id</label>
            <input
              className="form-control"
              name="user_document_id"
              value={this.state.user_document_id}
              onChange={this.handleChange}
            />
          </div> */}
          {/* <div className="box box9">
            <label htmlFor="">Kyc Verification Id</label>
            <input
              className="form-control"
              name="kyc_verification_id"
              value={this.state.kyc_verification_id}
              onChange={this.handleChange}
            />
          </div> */}
          <div className="box box9">
            <label htmlFor="">Reason</label>
            <textarea
              className="form-control"
              name="reason"
              value={this.state.reason}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                {this.props.reject == true
                  ? "Reject Document"
                  : "Approve Document"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  admins: state.login.data,
});
export default connect(mapStateToProps, {})(KycDocumentModal);
