import React from "react";
import { connect } from "react-redux";
import InventoryBar from "../InventoryBar";
import {
  getAllStores,
  changeStoreStatus,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader, Modal } from "../../../components/Accessories";
import { StoreHead, StoreBody } from "../../../components/Rows/InventoryTable";

const ShowReasonModal = ({ display, ...props }) => (
  <Modal
    display={display ? "block" : "none"}
    modalTitle="Set Reason for Action"
    closeModal={props.closeModal}>
    <div style={{ padding: "1em" }}>
      <label>Reason</label>
      <textarea
        placeholder="Enter your reason for action"
        className="form-control"
        style={{ height: "10em" }}></textarea>

      <div className="text-center pt-4">
        <button
          type="button"
          class="btn btn-primary"
          onClick={props.handleSubmit}>
          Set Action
        </button>
      </div>
    </div>
  </Modal>
);

class Stores extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getAllStores("/stores", this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllStores("/stores", this.props.token);
  };

  viewDetails = (id) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/detail`,
      state: id,
    });
  };

  toggleHandler = (id) => {
    this.setState({
      ...this.state,
      display: true,
      idToApprove: id,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleSubmit = () => {
    callBackMethod(
      this.props.changeStoreStatus(
        `/store/toggle/${this.state.idToApprove}`,
        this.props.token
      )
    )
      .then(() => this.setState({ ...this.state, display: false }))
      .then(() => alert(this.props.store_status))
      .then(() => this.props.getAllStores("/stores", this.props.token));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <StoreHead />
          <tbody>
            {this.props.stores &&
              this.props.stores.map((content, index) => (
                <StoreBody
                  {...content}
                  index={index + 1}
                  storeDetails={this.viewDetails}
                  toggleHandler={this.toggleHandler}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <InventoryBar active="stores">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InventoryBar>
        <ShowReasonModal
          display={this.state.display}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.inventory.get_store_inventory.loading,
  stores: state.inventory.get_store_inventory.stores,
  token: state.login.token,
  store_status: state.inventory.get_store_inventory.store_status,
});

export default connect(mapStateToProps, { getAllStores, changeStoreStatus })(
  Stores
);
