import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoanRequest from "./LoanRequest";
import LoanRequestDetails from "./loanRequestDetail";

const LoanRequestRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={LoanRequest} />
      <Route
        exact
        path={`${match.path}/:name/detail`}
        component={LoanRequestDetails}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);
export default LoanRequestRoute;
