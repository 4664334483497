import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import {
  getSavingsGroup,
  getSavingProduct,
  addSavingsGroup,
  filterGroupSavings,
} from "../../../reduxStore/action/action.creator";
import {
  callBackMethod,
  Loader,
  DateRange,
} from "../../../components/Accessories";
import {
  SavingGroupHead,
  SavingGroupBody,
} from "../../../components/Rows/SavingTable";
import GroupSavingsModal from "./GroupSavingsModal";

class SavingGroup extends React.Component {
  state = {
    display: false,
    filterby: "",
    showBtnDate: false,
    showDropStatus: false,
    startDate: null,
    endDate: null,
    status: "",
  };

  componentDidMount() {
    this.props.getSavingsGroup();
    this.props.getSavingProduct(this.props.token);
  }

  showModal = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  addGroupSavings = (data) => {
    callBackMethod(this.props.addSavingsGroup(data, this.props.token))
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.group_resp));
  };

  filterByType = (e) => {
    // alert(e.target.value);
    callBackMethod(
      this.setState({
        filterby: e.target.value,
      })
    ).then(() => this.runByType(this.state.filterby));
  };

  runByType = (type) => {
    if (type == "status") {
      this.setState({
        showDropStatus: true,
        showBtnDate: false,
      });
    } else {
      this.setState({
        showBtnDate: true,
        showDropStatus: false,
      });
    }
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));

    callBackMethod(
      this.setState({
        startDate: start,
        endDate: end,
      })
    ).then(() =>
      this.props.filterGroupSavings(
        "date",
        `savinvest/all/savings_groups?from=${this.state.startDate}&to=${this.state.endDate}`,
        this.props.token
      )
    );
  };

  handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
      })
    ).then(() =>
      this.props.filterGroupSavings(
        "status",
        `savinvest/all/savings_groups?status=${this.state.status}`,
        this.props.token
      )
    );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <SavingGroupHead />
          <tbody>
            {this.props.saving_group &&
              this.props.saving_group.map((content) => (
                <SavingGroupBody {...content} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <SavingBar active="savings_group">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <div className="row">
              <div className="col-md-6 text-left">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    value={this.state.filterby}
                    className="form-control"
                    style={{ width: "20em" }}
                    onChange={this.filterByType}>
                    <option>filter by...</option>
                    <option value="status">filter by status</option>
                    <option value="date">filter by date</option>
                  </select>

                  <div style={{ paddingLeft: "1em" }}>
                    {this.state.showBtnDate && (
                      <DateRange handleRange={this.handleRange} />
                    )}

                    {this.state.showDropStatus && (
                      <select
                        style={{ width: "15em" }}
                        onChange={this.handleStatusFilter}
                        className="form-control">
                        <option>Filter by...</option>
                        <option value="open">Open Group</option>
                        <option value="close">Close Group</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <a
                  href="#"
                  class="btn btn-sm btn-neutral"
                  onClick={this.handleRefresh}>
                  <i class="fas fa-sync-alt"></i> Refresh
                </a>

                {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </SavingBar>
        <GroupSavingsModal
          display={this.state.display}
          closeModal={this.closeModal}
          setSavingGroup={this.addGroupSavings}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.saving.get_user_saving.loading,
  saving_group: state.saving.get_user_saving.save_group,
  group_resp: state.saving.set_save_product.group_resp,
});

export default connect(mapStateToProps, {
  getSavingsGroup,
  getSavingProduct,
  addSavingsGroup,
  filterGroupSavings,
})(SavingGroup);
