import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import KycLevel from "./KycLevel/Kyclevel";
import KycDocumentDetail from "./DocumentSettings/KycDocumentView";
import KycDocument from "./DocumentSettings/KycDocument";
import KycLevelSetting from "./KycSetting/KycSetting";
import KycRequirement from "./KycRequirement/KycRequirement";
import KycUpgrade from "./KycUpgrade/KycUpgrade";
import UploadDocument from "./UploadDocument/UploadDocument"

const KycRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/level`} />
      </Route>
      <Route exact path={`${match.path}/level`} component={KycLevel} />

      <Route exact path={`${match.path}/document`} component={KycDocument} />
      <Route
        exact
        path={`${match.path}/document/:name/details`}
        component={KycDocumentDetail}
      />
      <Route exact path={`${match.path}/setting`} component={KycLevelSetting} />
      <Route
        exact
        path={`${match.path}/requirement`}
        component={KycRequirement}
      />
      <Route
        exact
        path={`${match.path}/kyc_upgrade`}
        component={KycUpgrade}
      />
      <Route
        exact
        path={`${match.path}/upload_document`}
        component={UploadDocument}
      />
    </Switch>
  </>
);
export default KycRoute;
