import { headers } from "./api.config";

export const fetchDeveloperAccounts = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/third-party/developer_accounts`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    return { data, success };
  } else {
    const { message } = await response.json();
    alert(message);
    // throw new Error(message);
  }
};

export const fetchDeveloperApiCalls = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/third-party/developer_api_calls`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    console.log("get developer calls inside api", data);
    return { data, success };
  } else {
    const { message } = await response.json();
    alert(message);
    // throw new Error(message);
  }
};

export const fetchDeveloperCallStats = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/third-party/developer_call_stats`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    console.log("get developer call stats inside api", data);
    return { data, success };
  } else {
    const { message } = await response.json();
    alert(message);
    // throw new Error(message);
  }
};

export const fetchDeveloperDocument = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/third-party/get_all_developer_docs`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const toggleDeveloperDocument = async (id, supportEmail, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/third-party/toggle_documents_status/${id}?businessSupportEmail=${supportEmail}`,
    {
      method: "PATCH",
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};
