import React from "react";
import { Route, Switch } from "react-router-dom";
import InterestEarning from "./InterestEarning/InterestEarning";
import InterestHistory from "./InterestHistory/InterestHistory";
import InterestPayout from "./InterestPayout/InterestPayout";

export const InterestRoute = ({ match }) => (
	<>
		<Switch>
			<Route exact path={`${match.path}/`} component={InterestHistory} />
			<Route
				exact
				path={`${match.path}/earnings`}
				component={InterestEarning}
			/>
			<Route
				exact
				path={`${match.path}/payout`}
				component={InterestPayout}
			/>
		</Switch>
	</>
);
