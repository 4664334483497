import { headers } from "../api.config";

export const setpermissionRequest = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/permission_lists`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const getpermissionRequest = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/permission_lists`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data } = await response.json();
    return { success, data };
  }
};

export const deleteAuthPermission = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { success } = await response.json();
    return success;
  }
};
