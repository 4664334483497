import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class MerchantModal extends React.Component {
  state = {
    created_by: this.props.admin_data.id,
    name: "",
    phone_number: "",
    approval_date: null,
    created_date: null,
    enabled: false,
    company_id: "",
    email: "",
    password: "",
    role_id: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.addMerchant(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Setup Merchant">
        <div className="body-grid">
          <div className="box box1">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Phone Number</label>
            <input
              className="form-control"
              name="phone_number"
              value={this.state.phone_number}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box1">
            <label>Approval Date</label>
            <input
              type="date"
              className="form-control"
              name="approval_date"
              value={this.state.approval_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Created Date</label>
            <input
              type="date"
              className="form-control"
              name="created_date"
              value={this.state.created_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Company</label>
            <select
              className="form-control"
              name="company_id"
              value={this.state.company_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.company_data &&
                this.props.company_data.map((content) => (
                  <option value={content.id}>{content.company_name}</option>
                ))}
            </select>
          </div>
          <div className="box box2">
            <label>Email</label>
            <input
              className="form-control"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box1">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Role</label>
            <select
              className="form-control"
              name="role_id"
              value={this.state.role_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.role_data &&
                this.props.role_data.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="row">
              <div className="col-md-12">
                <label>Enable Merchant </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="enabled"
                checked={this.state.enabled}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set saving product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  admin_data: state.login.data,
  company_data: state.invest.getData.comp_data,
  role_data: state.invest.getData.role_data,
});

export default connect(mapStateToProps, {})(MerchantModal);
