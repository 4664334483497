import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuditLogs from "./AuditLogs"

const AuditLogsRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AuditLogs} />
    </Switch>
  </>
);

export default AuditLogsRoute