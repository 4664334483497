import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import { TransactionList, LoanDetails } from "../../../../components/Rows";
import { detailsInfo } from "../../../../reduxStore/action/action.creator";
import {
  currencyFormatter,
  decimalFormatter,
} from "../../../../components/Row";

class LoanProductDetails extends React.Component {
  state = {};

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    const params = this.props.location;
    return (
      <>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            padding: "0 .5em 0 0",
                            textAlign: "center",
                          }}>
                          <i
                            class="fas fa-chevron-left"
                            style={{
                              color: "#fff",
                              padding: "0.3em .5em",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}></i>
                        </span>
                        <span style={{ color: "#fff" }}>Back</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--5">
              <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
                <div class="col-6 userDetailsCol1">
                  {this.props.loanProductDetails && (
                    <LoanDetails {...this.props.loanProductDetails} />
                  )}
                </div>
                <div class="col-6 userDetailsCol2">
                  <div class="m-3">
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <i class="fas fa-money"> &nbsp; </i> Quick Loan Product
                      Information
                    </span>

                    <table class="table table-bordered table-hover tableClass mt-4 mb-5">
                      <thead>
                        <tr class="table-warning">
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Min / Max
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Amount
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Credit Score
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            KYC
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Tenure
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="table-light">
                          <th scope="row">Min</th>
                          <td>
                            {this.props.loanProductDetails &&
                              currencyFormatter(
                                decimalFormatter(
                                  parseFloat(
                                    this.props.loanProductDetails.data
                                      .min_amount
                                  )
                                )
                              )}
                          </td>
                          <td>
                            {
                              this.props.loanProductDetails.data
                                .min_credit_score
                            }
                          </td>
                          <td>
                            {this.props.loanProductDetails.data.min_kyc_level}
                          </td>
                          <td>
                            {this.props.loanProductDetails.data.min_tenure
                              ? `${this.props.loanProductDetails.data.min_tenure} ${this.props.loanProductDetails.data.tenure_type}`
                              : `0 ${this.props.loanProductDetails.data.tenure_type}`}
                          </td>
                        </tr>
                        <tr class="table-warning">
                          <th scope="row">Max</th>
                          <td>
                            {currencyFormatter(
                              decimalFormatter(
                                parseFloat(
                                  this.props.loanProductDetails.data.max_amount
                                )
                              )
                            )}
                          </td>
                          <td>
                            {
                              this.props.loanProductDetails.data
                                .min_credit_score
                            }
                          </td>
                          <td>
                            {this.props.loanProductDetails.data.min_kyc_level}
                          </td>
                          <td>
                            {this.props.loanProductDetails.data.max_tenure
                              ? `${this.props.loanProductDetails.data.max_tenure} ${this.props.loanProductDetails.data.tenure_type}`
                              : `0 ${this.props.loanProductDetails.data.tenure_type}`}
                          </td>
                        </tr>
                        <tr class="table-light">
                          <td
                            colspan="4"
                            style={{ textAlign: "right", fontWeight: "bold" }}>
                            Max Amount
                          </td>
                          <td>
                            {/* {this.props.loanProductDetails.data.max_amount} */}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                      <i class="fas fa-info"> &nbsp; </i> Aditional Information
                    </span>

                    <table class="table table-bordered table-hover tableClass mt-4">
                      <thead>
                        <tr class="table-warning">
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Interest Rate
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Interest Rate Type
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Processing Fee
                          </th>
                          <th scope="col" style={{ fontWeight: "bold" }}>
                            Penalty
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="table-light">
                          <td>
                            {this.props.loanProductDetails.data.interest_rate}
                          </td>
                          <td>
                            {
                              this.props.loanProductDetails.data
                                .interest_rate_type
                            }
                          </td>
                          <td>
                            {this.props.loanProductDetails.data.process_fees}
                          </td>
                          <td>{this.props.loanProductDetails.data.penalty}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loanProductDetails: state.details.detailinfo,
});
export default connect(mapStateToProps, { detailsInfo })(LoanProductDetails);
