import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import InvestCategoryModal from "./InvestCategoryModal";
import {
  addInvestmentCategory,
  getInvestmentCategory,
} from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  InvestCategoryHead,
  InvestCategoryBody,
} from "../../../components/Rows/InvestmentTable";

class InvestCategory extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getInvestmentCategory(
      "/savinvest/investment_categories",
      this.props.token
    );
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  addCategory = (data) => {
    callBackMethod(
      this.props.addInvestmentCategory(
        "/savinvest/investment_categories",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => {
        if (this.props.category_resp == true) {
          alert("successfully added category");
        }
      });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestCategoryHead />
          <tbody>
            {this.props.invest_category.map((content, index) => (
              <InvestCategoryBody {...content} index={index + 1} />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="category">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
        <InvestCategoryModal
          display={this.state.display}
          closeModal={this.closeModal}
          addCategory={this.addCategory}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  category_resp: state.invest.setData.category_resp,
  invest_category: state.invest.getData.invest_category,
  loading: state.invest.getData.loading,
});

export default connect(mapStateToProps, {
  addInvestmentCategory,
  getInvestmentCategory,
})(InvestCategory);
