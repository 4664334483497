import React from "react";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import "../../morestyles.css";
import { Loader, Icon, CardBodyHolder } from "../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class SavingBar extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar submenu="Savings" shouldRefresh="false" />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="savings-config"
                title={
                  <Link to={`/savings/savings-config`}>
                    <Icon className="" title="Savings Config" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="savings-wallet"
                title={
                  <Link to={`/savings/saving-wallet`}>
                    <Icon className="" title="Savings Wallet" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="savings-penalty"
                title={
                  <Link to={`/savings/saving-penalty`}>
                    <Icon className="" title="Savings Penalty" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="user-savings"
                title={
                  <Link to={`/savings/user-saving`}>
                    <Icon className="" title="User Savings" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="wallet_transaction"
                title={
                  <Link to={`/savings/wallet_transaction`}>
                    <Icon className="" title="Wallet Transaction" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="withdrawal_date"
                title={
                  <Link to={`/savings/withdrawal_date`}>
                    <Icon className="" title="Withdrawal Date" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="savings_group"
                title={
                  <Link to={`/savings/savings-group`}>
                    <Icon className="" title="Savings Group" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              {/* <Tab
                eventKey="saving-history"
                title={
                  <Link to={`/savings/saving-history`}>
                    <Icon className="" title="Saving History" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab> */}
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default SavingBar;
