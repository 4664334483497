import { headers } from "../api.config";

export const get_kyc_upgrade = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE3_URL}/kyc_upgrade_request?paginate`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetchCustomerUpgradeByPage = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE3_URL}/user_savings?paginate&no_of_pages=15&page=${page}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    const user_data = await data;
    return { user_data };
  }
};

export const filter_kyc_upgrade = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE3_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetch_user_kyc_upgrade = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE3_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
