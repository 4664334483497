import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../components/Accessories";

class SavingWalletModal extends React.Component {
  state = {};

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.setSavingWallet(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Savings Wallet">
        <div className="body-grid">
          <div className="box box1">
            <label>Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Description</label>
            <input
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                set saving product
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  levels: state.kyc.get_kyc_level.levels,
  admin_data: state.login.data,
});
export default connect(mapStateToProps, {})(SavingWalletModal);
