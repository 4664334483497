import React from "react";
import { connect } from "react-redux";
import WalletBar from "../WalletBar";
import {
  getWallets,
  getCurrencies,
  setWallets,
  addFunding,
} from "../../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../../components/Accessories";
import {
  WalletBody,
  WalletHead,
} from "../../../../../components/Rows/CardTable";
import WalletModal from "./WalletModal";
import FundModal from "./FundModal";

class Wallet extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentDidMount() {
    this.props.getWallets(this.state.currentPage, this.props.token);
    this.props.getCurrencies(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      funding: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getWallets(this.state.currentPage, this.props.token);
  };

  handleWallet = (data) => {
    this.props.setWallets(data, this.props.token);
  };

  openFundModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      funding: true,
    });
  };

  submitFund = (data) => {
    callBackMethod(this.props.addFunding(data, this.props.token))
      .then(() => this.setState({ funding: false }))
      .then(() => alert(this.props.fund_response))
      .then(() => this.props.getWallets(this.props.token));
  };

  render() {
    let itemToDisplay;
    if (this.props.loading) {
      itemToDisplay = <Loader loading={[this.props.loading]} />;
    } else {
      itemToDisplay = (
        <>
          <WalletHead />
          {this.props.wallet_data &&
            this.props.wallet_data.map((content, index) => (
              <WalletBody {...content} index={index + 1} />
            ))}
        </>
      );
    }
    return (
      <>
        <WalletBar {...this.props} active="User-Wallet">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>

            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.openFundModal}>
              <i class="fas fa-wallet"></i> Fund Wallet
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
          </div>
        </WalletBar>
        <WalletModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitWallet={this.handleWallet}
        />
        <FundModal
          display={this.state.funding}
          closeModal={this.closeModal}
          submitFund={this.submitFund}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
  loading: state.card.wallet.loading,
  wallet_data: state.card.wallet.wallet_data,
  fund_response: state.card.wallet.fund_response,
  wallet_resp: state.card.wallet.wallet_resp,
});
export default connect(mapStateToProps, {
  getWallets,
  getCurrencies,
  setWallets,
  addFunding,
})(Wallet);
