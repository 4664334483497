import React from "react";
import { connect } from "react-redux";
import {
  BatchesHead,
  BatchesBody,
} from "../../../components/Rows/BulkPaymentTable";
import BulkPaymentBar from "../BulkPaymentBar";
import { getAllBatches } from "../../../reduxStore/action/action.creator";

class AllBatches extends React.Component {
  componentDidMount() {
    this.props.getAllBatches(`bulk_transactions_batches`, this.props.token);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading...";
    } else {
      itemInTable = (
        <>
          <BatchesHead />
          <tbody>
            {this.props.all_batches.map((content) => (
              <BatchesBody {...content} />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <BulkPaymentBar active="all-batches">
        <div
          class="col-lg-12 col-5 text-right"
          style={{ padding: "0 0.5em 1em" }}>
          {/* <a
            href="#"
            class="btn btn-sm btn-neutral"
            onClick={this.handleRefresh}>
            <i class="fas fa-sync-alt"></i> Refresh
          </a> */}

          {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
                <i class="fas fa-plus"></i> New
              </a> */}
        </div>
        <div className="table-responsive py-4 table-div">
          <table className="table table-flush" id="datatable-basic">
            {itemInTable}
          </table>
        </div>
      </BulkPaymentBar>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  loading: state.bulk.batches.loading,
  all_batches: state.bulk.batches.all_batches,
});

export default connect(mapStateToProps, { getAllBatches })(AllBatches);
