import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import KycUpgradeModal from "./KycUpgradeModal";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import {
  getCustomerDocuments,
  getCustomerDocument,
  getKycUpgrades,
  filterKycUpgrades,
  seartchUserDetails,
  getCustomerUpgradeByPage,
  // seartchUser,
} from "../../../../../reduxStore/action/action.creator";
import {
  Loader,
  callBackMethod,
  Pagination,
} from "../../../../../components/Accessories";
import {
  KycUpgradeHead,
  KycUpgradeBody,
} from "../../../../../components/Rows/KycTable";

class KycUpgrade extends React.Component {
  state = {
    display: false,
    approvedKYC: false,
    user_id: "",
    displayUpgradeModal: false,
    count: 0,
    status: "",
    user_name: "",
    filteredValues: "",
    showDropUserName: false,
    showDropStatus: false,
  };

  filterByType = (e) => {
    callBackMethod(
      this.setState({
        filterby: e.target.value,
      })
    ).then(() => this.runByType(this.state.filterby));
  };

  // runByType = (type) => {
  //   if (type == "approve_kyc") {
  //     this.setState({
  //       approvedKYC: true,
  //     });
  //   }
  // };

  showModal = (e, user_id, id, user, status, others) => {
    e.preventDefault();
    console.log(others);
    this.setState(
      {
        ...this.state,
        display: true,
        user_id,
        id,
        user,
        status,
        kyclevelData: {new_level: others.new_level, current_level: others.current_level}
      },
      async () => {
        await this.props.getCustomerDocument(
          this.props.token,
          this.state.user_id
        );
        const response = await axios.get(
          `${process.env.REACT_APP_BASE3_URL}/user_documents/count?user_id=${this.state.user_id}&filter_by=Pending`
        );
        const { data } = await response.data;
        this.setState({ ...this.state, numOfPending: data.data });

        const responseLevel = await axios.get(
          `${process.env.REACT_APP_BASE3_URL}/kyc_upgrade_request?user_id=${this.state.user_id}`
        );
        const levelData = await responseLevel.data;
        console.log(levelData.data[0]);
        console.log(levelData.data);
        this.setState({ ...this.state, kyclevelData: levelData.data[0] });
      }
    );
  };

  runByType = (type) => {
    if (type == "approve_kyc") {
      this.setState({
        approvedKYC: true,
        showDropUserName: false,
      });
    } else {
      this.setState({
        approvedKYC: false,
        showDropUserName: true,
      });
    }
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  showUpgradeModal = (e, user_id) => {
    // console.log(user_id)
    e.preventDefault();
    this.setState({
      displayUpgradeModal: true,
    });
  };

  closeUpgradeModal = () => {
    this.setState(
      {
        displayUpgradeModal: false,
      },
      () => {
        if (this.state.status) {
          this.props.filterKycUpgrades(
            `kyc_upgrade_request?filter_by=${this.state.status}&paginate`,
            this.props.token
          );
        } else if (this.state.user_name) {
          this.props.seartchUserDetails(
            `kyc_upgrade_request?user_name=${this.state.user_name}&paginate=yes&no_of_pages=15`,
            this.props.token
          );
        } else {
          this.props.getKycUpgrades(this.props.token);
        }
      }
    );
  };

  componentDidMount() {
    this.props.getKycUpgrades(this.props.token);
    // console.log(this.props);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getKycUpgrades(this.props.token);
  };

  handleStatusFilter = (e) => {
    callBackMethod(
      this.setState({
        status: e.target.value,
        filteredValues: `&status=${e.target.value}`,
        filteredKey: "status",
      })
    ).then(() =>
      this.props.filterKycUpgrades(
        `kyc_upgrade_request?filter_by=${this.state.status}&paginate`,
        this.props.token
      )
    );
  };

  handleUserNameFilter = async (e) => {
    if (e.target.value.length > 4) {
      callBackMethod(
        this.setState({
          user_name: e.target.value,
          showUsersDetails: true,
        })
      ).then(() => {
        this.props.seartchUserDetails(
          `kyc_upgrade_request?user_name=${this.state.user_name}&paginate=yes&no_of_pages=15`,
          this.props.token
        );
      });
    }
  };

  changePage = (id) => () => {
    if (this.state.filteredValues === "") {
      this.props.getCustomerUpgradeByPage(id, this.props.token);
    } else {
      this.props.filterKycUpgrades(
        `/kyc_upgrade_request?paginate=yes${this.state.filteredValues}&no_of_pages=${id}`,
        this.props.token
      );
    }
    this.setState({
      currentPage: id,
    });
  };

  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.state.filteredValues === "") {
        this.props.getCustomerUpgradeByPage(id, this.props.token);
      } else {
        this.props.filterKycUpgrades(
          `/kyc_upgrade_request?paginate=yes${this.state.filteredValues}&no_of_pages=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  goNext = () => {
    const total = parseInt(Math.ceil(this.props?.response?.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.state.filteredValues === "") {
        this.props.getCustomerUpgradeByPage(id, this.props.token);
      } else {
        this.props.filterKycUpgrades(
          `/kyc_upgrade_request?paginate=yes${this.state.filteredValues}&no_of_pages=${id}`,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  render() {
    let renderPaginate;
    let itemToDisplay;
    // console.log(this.props.response);
    const total =
      this.props?.response?.total &&
      parseInt(Math.ceil(this.props?.response?.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props?.response?.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props?.response?.total}
        />
      ));
    if (this.props.loading) {
      itemToDisplay = <Loader loading={this.props.loading} />;
    } else {
      itemToDisplay = (
        <>
          <KycUpgradeHead />
          <tbody>
            {this.props.response &&
              this.props.response.data.map((content, index) => (
                <KycUpgradeBody
                  showModal={(e, user_id, id, user, status, others) =>
                    this.showModal(e, user_id, id, user, status, others)
                  }
                  index={index + 1}
                  closeModal={this.closeModal}
                  {...content}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <KycBar {...this.props} active="Kyc Upgrade Request">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <div className="col-md-6 text-left">
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  value={this.state.filterby}
                  className="form-control"
                  style={{ width: "20em" }}
                  onChange={this.filterByType}>
                  <option>filter by...</option>
                  <option value="approve_kyc">Filter Kyc Status</option>
                  <option value="user_name">User Name</option>
                </select>

                <div style={{ paddingLeft: "1em" }}>
                  {this.state.approvedKYC && (
                    <select
                      style={{ width: "15em" }}
                      onChange={this.handleStatusFilter}
                      className="form-control">
                      <option>Filter by...</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Pending">Pending</option>
                    </select>
                  )}

                  {this.state.showDropUserName && (
                    <input
                      style={{ width: "15em" }}
                      onChange={this.handleUserNameFilter}
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      value={this.state.value}
                    />
                  )}
                </div>
                {this.state.showUsersDetails && (
                  <div className="position-relative">
                    <div
                      className="card position-absolute"
                      style={{
                        paddingLeft: "1em",
                        width: "300px",
                        zIndex: "3",
                      }}>
                      <div className="card-body">
                        {this.props.user_search_details &&
                          this.props.user_search_details.data.map(
                            (content, index) => (
                              <ul style={{ listStyle: "none" }}>
                                <a
                                  href="#/"
                                  onClick={(e) =>
                                    this.handleSavingsUserIdFilter(
                                      e,
                                      content.id
                                    )
                                  }
                                  value={content.id}>
                                  {content.first_name} {content.last_name}
                                </a>
                              </ul>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ToastContainer />
            </div>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showUpgradeModal}>
              <i class="fas fa-plus"></i> KYC Upgrade Request
            </a> */}
          </div>
          <div className="table-responsive py-4 ">
            <table
              className="table table-flush table-hover"
              id="datatable-basic">
              {itemToDisplay}
            </table>
            {renderPaginate}
          </div>
          {/* <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
          </div> */}
        </KycBar>
        <KycUpgradeModal
          display={this.state.display}
          closeModal={this.closeModal}
          setSetting={this.setSetting}
          userId={this.state.user_id}
          token={this.props.token}
          document={this.props?.user_document}
          request_id={this.state.id}
          user={this.state.user}
          numOfPending={this.state.numOfPending}
          status={this.state.status}
          kyclevelData={this.state?.kyclevelData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.get_kyc_upgrade.loading,
  response: state.kyc.get_kyc_upgrade.kyc_request,
  user_document: state.kyc.get_customer_doc.customer_doc,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getCustomerDocuments,
  getCustomerDocument,
  getKycUpgrades,
  filterKycUpgrades,
  seartchUserDetails,
  getCustomerUpgradeByPage,
  // seartchUser,
})(KycUpgrade);
