import React from "react";
import { connect } from "react-redux";
import CardBar from "../PhysicalCardBar";
import AddBatchModal from "./AddCardBatchModal";
import {
  getAllCardBatch,
  deleteBatch,
  addBatch,
} from "../../../../reduxStore/action/action.creator";
import { Loader } from "../../../../components/Accessories";
import {
  CardBatchBody,
  CardBatchHead,
} from "../../../../components/Rows/CardTable";

class CardBatch extends React.Component {
  state = {
    display: false,
  };

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getAllCardBatch("batches", this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllCardBatch("batches", this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  deleteBatch = (id) => {
    this.callBackMethod(
      this.props.deleteBatch(id, "batches", this.props.token)
    ).then(() => this.props.getAllCardBatch("batches", this.props.token));
  };

  //callback method to delete batch
  callBackMethod = (cb) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(cb);
      }, 1000);
    });
  };

  handleSubmit = (data) => {
    this.callBackMethod(this.props.addBatch(data, this.props.token))
      .then(() =>
        this.setState({
          display: false,
        })
      )
      .then(() => this.props.getAllCardBatch("batches", this.props.token));
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <CardBatchHead />
          <tbody>
            {this.props.all_batches &&
              this.props.all_batches.map((content, index) => (
                <CardBatchBody
                  {...content}
                  index={index + 1}
                  deleteBatch={this.deleteBatch}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardBar {...this.props} active="Batches">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </CardBar>
        <AddBatchModal
          display={this.state.display}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.card.all_batches.loading,
  all_batches: state.card.all_batches.batches,
  del_message: state.card.all_batches.del_message,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getAllCardBatch,
  deleteBatch,
  addBatch,
})(CardBatch);
