import React from "react";
import { connect } from "react-redux";
import SavingBar from "../SavingBar";
import {
	getUserSavings,
	getSavingProduct,
	getSavingWallet,
	filterUserSavings,
} from "../../../reduxStore/action/action.creator";
import {
	Loader,
	DateRange,
	callBackMethod,
} from "../../../components/Accessories";
import {
	UserSavingHead,
	UserSavingBody,
} from "../../../components/Rows/SavingTable";

class UserSavings extends React.Component {
	state = {
		filterby: "",
		showBtnDate: false,
		showDropStatus: false,
		startDate: null,
		endDate: null,
		status: "",
	};

	componentDidMount() {
		this.props.getUserSavings(this.props.token);
		this.props.getSavingProduct(this.props.token);
		this.props.getSavingWallet(this.props.token);
	}

	viewDetails = (data) => {
		this.props.history.push({
			pathname: `${this.props.match.url}/${data.id}/details`,
			state: data.id,
		});
	};

	filterByType = (e) => {
		// alert(e.target.value);
		callBackMethod(
			this.setState({
				filterby: e.target.value,
			})
		).then(() => this.runByType(this.state.filterby));
	};

	runByType = (type) => {
		if (type == "status") {
			this.setState({
				showDropStatus: true,
				showBtnDate: false,
			});
		} else {
			this.setState({
				showBtnDate: true,
				showDropStatus: false,
			});
		}
	};

	handleRange = (event, picker) => {
		let start = new Date(picker.startDate).toISOString();
		let end = new Date(picker.endDate).toISOString();

		start = start.substring(0, start.indexOf("T"));
		end = end.substring(0, end.indexOf("T"));

		callBackMethod(
			this.setState({
				startDate: start,
				endDate: end,
			})
		).then(() =>
			this.props.filterUserSavings(
				"date",
				`savinvest/user_savings?from=${this.state.startDate}&to=${this.state.endDate}`,
				this.props.token
			)
		);
	};

	handleStatusFilter = (e) => {
		callBackMethod(
			this.setState({
				status: e.target.value,
			})
		).then(() =>
			this.props.filterUserSavings(
				"status",
				`savinvest/user_savings?status=${this.state.status}`,
				this.props.token
			)
		);
	};

	render() {
		let itemInTable;
		if (this.props.loading) {
			itemInTable = <Loader loading={this.props.loading} />;
		} else {
			itemInTable = (
				<>
					<UserSavingHead />
					<tbody>
						{this.props.user_savings &&
							this.props.user_savings.map((content, index) => (
								<UserSavingBody
									{...content}
									index={index + 1}
									product={this.props.savings_product}
									viewDetails={this.viewDetails}
								/>
							))}
					</tbody>
				</>
			);
		}
		return (
			<>
				<SavingBar active="user-savings">
					<div
						class="col-lg-12 col-5 text-right"
						style={{ padding: "0 0.5em 1em" }}
					>
						<div className="row">
							<div className="col-md-6 text-left">
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<select
										value={this.state.filterby}
										className="form-control"
										style={{ width: "20em" }}
										onChange={this.filterByType}
									>
										<option>filter by...</option>
										<option value="status">
											filter by status
										</option>
										<option value="date">
											filter by date
										</option>
									</select>

									<div style={{ paddingLeft: "1em" }}>
										{this.state.showBtnDate && (
											<DateRange
												handleRange={this.handleRange}
											/>
										)}

										{this.state.showDropStatus && (
											<select
												style={{ width: "15em" }}
												onChange={
													this.handleStatusFilter
												}
												className="form-control"
											>
												<option>Filter by...</option>
												<option value="active">
													Active
												</option>
												<option value="in-active">
													In-Active
												</option>
											</select>
										)}
									</div>
								</div>
							</div>
							<div className="col-md-6 text-right">
								<a
									href="#"
									class="btn btn-sm btn-neutral"
									onClick={this.handleRefresh}
								>
									<i class="fas fa-sync-alt"></i> Refresh
								</a>

								<a
									href="#"
									class="btn btn-sm btn-neutral"
									onClick={this.showModal}
								>
									<i class="fas fa-plus"></i> New
								</a>
							</div>
						</div>
					</div>
					<div className="table-responsive py-4">
						<table
							className="table table-flush"
							id="datatable-basic"
						>
							{itemInTable}
						</table>
					</div>
				</SavingBar>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token,
	user_savings: state.saving.get_user_saving.user_saving_data,
	loading: state.saving.get_user_saving.loading,
	savings_wallet: state.saving.get_save_wallet.wallet_data,
	savings_product: state.saving.get_save_product.saving_data,
});

export default connect(mapStateToProps, {
	getUserSavings,
	getSavingProduct,
	getSavingWallet,
	filterUserSavings,
})(UserSavings);
