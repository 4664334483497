import { headers } from "../api.config";

export const get_customer_documents = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE3_URL}/user_documents?paginate`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetchCustomerDocumentByPage = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE3_URL}/user_documents?paginate=yes&no_of_pages=15&page=${page}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    const doc_data = await data;
    return { doc_data };
  }
};

export const filter_customer_documents = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE3_URL}/${url}`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const get_customer_document = async (token, userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE3_URL}/user_documents?user_id=${userId}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const accetp_kyc_doc = async (token, obj) => {
  const requestOptions = {
    method: "POST",
    headers: headers(token),
    body: obj,
  };
  fetch("user_document/approve", requestOptions)
    .then((response) => response.json())
    .then((data) => this.setState({ postId: data.id }));
};

export const reject_kyc_doc = async (token, obj) => {};
