import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_SUCCESS,
  USER_FAILED,
  ADMIN_SUCCESS,
  ADMIN_FAILED,
  TRANSACTION_SUCCESS,
  TRANSACTION_FAILED,
  LOGOUT,
  DETAIL_INFO,
  FEE_PROFILE_FAILED,
  FEE_PROFILE_SUCCESS,
  TRANS_CAT_FAILED,
  TRANS_CAT_SUCCESS,
  TRANS_SERVICE_CODE_SUCCESS,
  TRANS_SERVICE_CODE_FAILED,
  SET_FEE_PROFILE_FAILED,
  SET_FEE_PROFILE_SUCCESS,
  GET_BEN_SUCCESS,
  GET_BEN_FAILED,
  SET_BENEFICIARY_SUCCESS,
  SET_BENEFICIARY_FAILURE,
  GET_COMMISSION_FAILED,
  GET_COMMISSION_SUCCESS,
  GET_DETAIL_FAILED,
  GET_DETAIL_SUCCESS,
  GET_LOAN_PRODUCTS_SUCCESS,
  GET_LOAN_PRODUCTS_FAILED,
  GET_LOAN_REQUEST_SUCCESS,
  GET_LOAN_REQUEST_FAILED,
  SET_COMMISSION_FAILED,
  SET_COMMISSION_SUCCESS,
  APPROVE_LOAN_SUCCESS,
  DISBURSE_SUCCESS,
  DELETED_INFO,
  GET_TXN_LIMIT_CONFIGS_SUCCESS,
  GET_TXN_LIMIT_CONFIGS_FAILED,
  SET_TXN_LIMIT_SUCCESS,
  SET_TXN_LIMIT_FAILED,
  GET_USER_LOAN,
  GET_PARTNER_SUCCESS,
  TOGGLE_SUCCESS,
  FILTER_SUCCESS,
  SET_PROMO,
  GET_PROMO,
  DELETE_PROMO,
  TOGGLE_PROMO,
  SET_ROLE,
  GET_ROLE,
  SET_PERMISSION,
  GET_PERMISSION,
  SET_PERMISSION_MODULE,
  GET_PERMISSION_MODULE,
  SET_ADMIN_PERMISSION,
  GET_ADMIN_PERMISSION,
  DELETE_PERMISSION_LIST,
  GET_BANK_TRANS,
  GET_FLUTTER,
  DELETE_ADMIN,
  FILTER_BANK_TRANS,
  FILTER_FLUTTER,
  GET_BILL_LIST,
  GET_BILL_TRANS,
  GET_BANK_TRANS_STATUS,
  GET_FLW_RANGE,
  GET_NOTIFICATION,
  FILTER_NOTIFICATION,
  GET_CHANNEL,
  TOGGLE_CHANNEL,
  GET_NOTIFICATION_STATUS,
  GET_NOTIFICATION_TYPES,
  GET_NOTIFICATION_MODE,
  FILTER_TRANSACTION_STATUS,
  SET_REQUERY,
  SET_REVERSAL,
  ENABLE_USER,
  DISABLE_USER,
  UPDATE_ADMIN,
  FILTER_TRANSACTION,
  FILTER_USERS,
  USER_REPORT_INFO,
  TRANSACTION_REPORT,
  ACCOUNT_BALANCE,
  ACCOUNT_STATEMENT,
  NEW_RANGE,
  FILTER_USER_REPORT,
  REPORT_BY_INSTITUTE,
  GET_INSTITUTIONS,
  FILTER_TRANS_REPORT_YEAR,
  FILTER_TRANS_REPORT_INSTITUTE,
  GET_INSTITUTE_STATUS,
  FILTER_INSTITUTE_REPORT,
  GET_SERVICE_REPORT,
  GET_TRANSACTION_SERVICE,
  EDIT_PROMOTION,
  EDIT_LIMIT,
  DELETE_TRANSACTION_LIMIT,
  SERVICE_APPLICABLE,
  GET_SUBSCRIBERS,
  GET_PROMO_SUBSCRIBERS,
  ADD_BATCH,
  GET_BATCH,
  DELETE_BATCH,
  GET_DOCUMENT,
  SET_LEVEL,
  GET_LEVEL,
  SET_LEVEL_SETTINGS,
  GET_LEVEL_SETTINGS,
  GET_CUSTOMER_DOCUMENT,
  GET_CUSTOMER_DOCUMENTS,
  GET_CUSTOMER_DOCUMENTS_PAGE,
  GET_KYC_UPGRADES,
  GET_KYC_UPGRADES_PAGE,
  SET_REQUIREMENT,
  GET_REQUIREMENT,
  APPROVE_DOCUMENT,
  REJECT_DOCUMENT,
  TRANSACTION_DETAIL_INFO,
  GET_USER_DETAILS_INFO,
  LOAD_HISTORY,
  LOAN_DETAILS,
  KYC_LEVEL_DETAIL,
  DELETE_KYC_LEVEL,
  AUDIT_LOGS_SUCCESS,
  AUDIT_LOGS_FAILED,
  EDIT_KYC_LEVEL,
  FINAL_APPROVAL,
  DELETE_REQUIREMENT,
  SET_RATE,
  GET_RATE,
  SET_KYC_VIRTUAL_CARD,
  GET_KYC_VIRTUAL_CARD,
  SET_VIRTUAL_LIMIT,
  GET_VIRTUAL_LIMIT,
  GET_VIRTUAL_TRANS,
  GET_VIRTUAL_CARD,
  GET_CURRENCIES,
  SWITCH_BILL,
  LOCK_CARD,
  UNLOCK_CARD,
  GET_CARD_REQUEST,
  SET_BATCH_REQUEST,
  GET_BATCH_REQUEST,
  GET_A_RATE,
  FILTER_DOCUMENT,
  DEVELOPER_ACCOUNTS_SUCCESS,
  DEVELOPER_ACCOUNTS_FAILED,
  DEVELOPER_CALLS_SUCCESS,
  DEVELOPER_CALLS_FAILED,
  DEVELOPER_CALL_STATS_SUCCESS,
  DEVELOPER_CALL_STATS_FAILED,
  TERMINATE_CARD,
  ADD_DOCUMENT,
  UPDATE_KYC_VIRTUAL_CARD,
  LINK_BVN,
  GET_WALLET_HISTORY,
  GET_WALLETS,
  ADD_WALLETS,
  FUND_WALLET,
  FILTER_VIRTUAL_CARD_TRANS,
  EDIT_VIRTUAL_CARD_LIMIT,
  GET_DEV_DOC,
  TOGGLE_DOC,
  GET_USER_CATEGORY,
  SET_USER_CATEGORY,
  FILTER_VIRTUAL_CARD,
  REJECT_LOAN,
  SET_SAVING,
  GET_SAVING,
  GET_SAVING_WALLET,
  DELETE_SAVINGS,
  GET_USER_SAVING,
  GET_SAVINGS_PRODUCT_DETAILS,
  LOCK_SAVING_PROD,
  SET_PENALTY,
  GET_PENALTY,
  GET_WALLET_TRANSACTION,
  GET_A_USER_SAVINGS,
  UPDATE_SAVING_PRODUCT,
  UPDATE_PENALTY,
  SET_WITHDRAWAL,
  GET_WITHDRAWAL,
  CHANGE_WALLET_STATUS,
  GET_USER_SAVING_DETAILS,
  GET_HISTORY,
  ADD_COMPANY,
  GET_COMPANY,
  SET_INVESTMENT_ROLE,
  GET_INVESTMENT_ROLE,
  ADD_MERCHANT,
  GET_MERCHANT,
  GET_INVESTMENT_SUB,
  ADD_INVEST_PRODUCT,
  GET_INVEST_PRODUCT,
  ADD_INVEST_CATEGORY,
  GET_INVEST_CATEGORY,
  GET_INVEST_RETURN,
  GET_INVEST_DOC,
  UPLOAD_IMG,
  APPROVE_INVEST_PROD,
  GET_INVEST_CYCLE,
  GET_A_USER_INVEST_SUB,
  EDIT_INVEST_PROD,
  EDIT_INVEST_PROD_ERROR,
  EDIT_MERCHANT,
  EDIT_COMPANY,
  UPDATE_ROLE,
  UPLOAD_DOC,
  DELETE_INVEST_PROD,
  VIEW_MERCHANT,
  VIEW_INVEST_PROD_DETAIL,
  CHANGE_MERCHANT_STATUS,
  GET_FEE_SPLIT,
  ADD_INVEST_HISTORY,
  GET_INVEST_HISTORY,
  UPLOAD_SAVING_IMG,
  FILTER_SAVINGS_BY_DATE,
  FILTER_SAVINGS_BY_STATUS,
  FILTER_INVESTMENT_BY_DATE,
  FILTER_INVESTMENT_BY_STATUS,
  FILTER_USER_SAVINGS_DATE,
  FILTER_USER_SAVINGS_STATUS,
  GET_SAVINGS_GROUP,
  ADD_SAVE_GROUP,
  FILTER_GROUP_SAVE_DATE,
  FILTER_GROUP_SAVE_STATUS,
  GET_REPORT_MONTH,
  FILTER_REPORT_MONTH,
  ADD_AGENT,
  GET_AGENT,
  ASSIGN_AGENT,
  GET_AGENT_CLAIMS,
  APPROVE_CLAIMS,
  ADD_INCENTIVES,
  INCENTIVE_ERROR,
  FILTER_LOAN,
  GET_INCENTIVES,
  GET_REDEEMED_TRANSFER,
  ADD_CONVERSION,
  GET_CONVERSION,
  GET_LOAN_STATUS,
  ADD_CONVERSION_ERROR,
  UPDATE_INCENTIVES,
  UPDATE_INCENTIVES_ERROR,
  GET_STORES,
  GET_STORE_DETAILS,
  GET_STORE_PRODUCT,
  GET_STORE_ORDER,
  GET_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_ORDERS,
  CHANGE_STORE_STATUS,
  GET_ORDER_DETAILS,
  GET_SITES,
  GET_CATEGORY_CODE_CONFIG,
  ADD_CATEGORY_CODE_CONFIG,
  GET_GENERAL_CODE,
  GET_ITEM_CODE,
  GET_PRODUCT_CODE,
  GET_PROVIDER_CODE,
  GET_SERVICE_CODE,
  GET_SERVICE_SWITCH,
  GET_SWITCH_CODE,
  EDIT_CODE,
  EDIT_CODE_ERROR,
  ADD_CODE_ERROR,
  GET_EARNED_POINT,
  GET_CHARGE_BACK,
  GET_USER_EARNED_POINT,
  GET_A_CHARGE_BACK,
  UPDATE_CHARGE_BACK,
  GET_PRODUCT_VIEW,
  GET_CHECKOUT_VIEW,
  GET_ALL_BATCHES,
  NEW_ACCOUNT_SUCCESS,
  NEW_ACCOUNT_FAILED,
  APPROVE_ACCOUNT_SUCCESS,
  APPROVE_ACCOUNT_FAILED,
  GET_INTERESTS,
  SETUP_INTEREST,
  INTEREST_DETAIL,
  DELETE_INTEREST,
  EDIT_INTEREST,
  GET_INTEREST_HISTORIES,
  GET_INTEREST_PAYOUTS,
  GET_INTEREST_EARNINGS,
  FILTER_INTEREST_HISTORIES,
  FILTER_INTEREST_EARNINGS,
  FILTER_INTEREST_PAYOUTS,
  GET_USER_INTEREST_HISTORY,
  PASS_SUCCESS,
  LOGIN_SCAN,
  UPDATE_AD_ROLE,
} from "./action.types";
import {
  loginAdmin,
  fetchAdmin,
  fetchUsers,
  fetchTransaction,
  fetchAllFees,
  fetchLimitConfigs,
  getTransCat,
  getService,
  setFeeProfile,
  getBeneficiary,
  setBeneficiary,
  fetchCommission,
  getFullDetails,
  getNewDetails,
  getLoanProducts,
  getLoanRequests,
  setCommission,
  changeStatus,
  disburseLoan,
  deleteFeeSplit,
  userLoanDetails,
  getPartners,
  _toggleCommissionProfile,
  filterBy,
  setPromotion,
  fetchAllPromotion,
  deletePromo,
  togglePromotion,
  setRole,
  getRole,
  setpermissionRequest,
  getpermissionRequest,
  setPermissionModule,
  getPermissionModule,
  setAdminPermission,
  getAllAdminPermission,
  deleteAuthPermission,
  bankTransactRequest,
  flutterwaveRequest,
  deleteAdminUser,
  filterBankTransact,
  filterFlutterwave,
  getBillListRequest,
  getBillTransactionReq,
  filterBankByStatus,
  filterFlutterRange,
  getAllNotification,
  filterAllNotification,
  getChannel,
  toggleChannel,
  notificationValue,
  filterTransactionStatus,
  sendRequery,
  setReversal,
  lockUser,
  editAdmin,
  filterTransaction,
  filterUsers,
  UserReportRequest,
  TransactionReportRequest,
  getBal,
  getStatement,
  filterUserReportRequest,
  getInstitution,
  InstitutionReport,
  getTransactionService,
  editGetriPromotion,
  editTransactionLimitConfig,
  deleteTransactionLimit,
  fetchAllSubscriptions,
  fetchPromotionSubscribers,
  addCardBatch,
  fetchAllBatches,
  removeBatch,
  getKycDocument,
  addKycLevel,
  fetchKycLevel,
  setLevelSetting,
  getLevelSetting,
  get_customer_documents,
  fetchCustomerDocumentByPage,
  get_customer_document,
  filter_customer_documents,
  get_kyc_upgrade,
  fetchCustomerUpgradeByPage,
  filter_kyc_upgrade,
  fetch_user_kyc_upgrade,
  setRequirement,
  getRequirement,
  ApproveDocument,
  getUserLoginHistory,
  kycLevel,
  deleteKyc,
  fetchAuditLogs,
  editKycLevel,
  finalApproval,
  deleteRequirement,
  CardRateRequest,
  fetchCardRateRequest,
  addKycVirtualCard,
  fetchKycVirtualCard,
  setVirtualCardConfig,
  getVirtualCardConfig,
  getRateCurrency,
  switchBill,
  changeCardStatus,
  addCardRequestBatch,
  _fetchRate,
  filterDocumentByStatus,
  fetchDeveloperAccounts,
  fetchDeveloperApiCalls,
  fetchDeveloperCallStats,
  addDocument,
  updateKycVirtualCard,
  setUserBvn,
  fetchWalletHistory,
  fetchCardRequestBatch,
  fetchWallets,
  addWallets,
  fundWallet,
  filterCardTransaction,
  updateVirtualCardLimit,
  fetchDeveloperDocument,
  toggleDeveloperDocument,
  fetchUserCategory,
  addUserCategory,
  filterVCard,
  rejectLoan,
  addSavingProduct,
  fetchSavingProduct,
  fetchSavingWallet,
  removeSavingsProduct,
  fetchUserSavings,
  fetchSavingProductDetail,
  disableSavingProduct,
  setSavingPenalty,
  fetchSavingPenalty,
  fetchWalletTransaction,
  fetch_a_UserSavings,
  updateSavingProduct,
  updateSavingPenalty,
  addWithDrawalDate,
  fetchWithDrawalDate,
  changeSavingWallet,
  fetchSavingHistory,
  filterSavingsProduct,
  fetchSavingsGroup,
  setSaveGroup,
  createAgent,
  readAgent,
  addAgentToUser,
  addIncentives,
  filterLoan,
  getIncentives,
  redeemedTransfer,
  conversionPoint,
  getConversionPoint,
  getLoanStatus,
  updateIncentiveConversion,
  getStoreInfo,
  getSiteInfo,
  fetchBillsConfig,
  addBillsConfig,
  editBillsConfig,
  getMoreIncentiveConfig,
  getBulkPaymentData,
  getUserHistoryConfig,
} from "../../API";
import {
  addInvestmentData,
  deleteInvestmentItem,
  fetchInvestmentData,
  uploadDocumentRequest,
  uploadImage,
} from "../../API/InvestmentRequest";
import { fetchReportMonth } from "../../API/ReportRequest";
import {
  fetchAccountRequest,
  approveNewAccount,
} from "../../API/NewAccountRequest";

import {
  addInterest,
  deleteInterestSetup,
  editInterestSetup,
  fetchInterestDetail,
  fetchInterestEarnings,
  fetchInterestHistories,
  fetchInterestPayouts,
  fetchInterestSetup,
  fetchUserDetails,
  filterInterestEarnings,
  filterInterestHistories,
  filterInterestPayouts,
} from "../../API/InterestRequest";
import { allowAdmin, sendQrCode } from "../../API/AuthRequest";
import { resetGoogleAuth } from "../../API/UserRequest";
import { filterDocumentByAccount } from "../../API/KycRequest/KycDocumentRequest";
import { updateAdRole } from "../../API/Authorization/RoleRequest";

export const adminLogin = (mData) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, temp_token, setup, data } = await loginAdmin(mData);
    dispatch({
      type: PASS_SUCCESS,
      payload: { success, temp_token, setup, data },
    });
  } catch (error) {
    // alert(error);
    dispatch({ type: LOGIN_FAIL, payload: error.message });
  }
};

export const QrCode = (mData) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, qr_image, secret } = await sendQrCode(mData);
    dispatch({
      type: LOGIN_SCAN,
      payload: { success, qr_image, secret },
    });
  } catch (error) {
    // alert(error);
    dispatch({ type: LOGIN_FAIL, payload: error.message });
  }
};

export const adminAllow = (mData) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, token, permission, data } = await allowAdmin(mData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { success, token, permission, data },
    });
  } catch (error) {
    console.log(error.message);
    const message = error.response
      ? error.response.data.message
      : "Invalid OTP";
    // alert(error);
    dispatch({ type: LOGIN_FAIL, payload: message });
  }
};

export const getUsers = (token, pageNum) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, users, total } = await fetchUsers(token, pageNum);
    dispatch({ type: USER_SUCCESS, payload: { success, users, total } });
  } catch (error) {
    dispatch({ type: USER_FAILED, payload: error });
  }
};

export const getAdmins = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { admin_info, success, total } = await fetchAdmin(token);
    // console.log(admin_info);
    dispatch({
      type: ADMIN_SUCCESS,
      payload: { success, admin_info, total },
    });
  } catch (error) {
    dispatch({ type: ADMIN_FAILED, payload: error });
  }
};

export const getTransaction = (page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, transaction, allTransactions } = await fetchTransaction(
      page,
      token
    );
    dispatch({
      type: TRANSACTION_SUCCESS,
      payload: { success, transaction, allTransactions },
    });
  } catch (error) {
    dispatch({ type: TRANSACTION_FAILED, payload: error });
  }
};

export const getFeeProfile = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, mFeeProfiles, allFeeProfiles } = await fetchAllFees(
      id,
      token
    );
    // console.log("all ", mFeeProfiles);
    dispatch({
      type: FEE_PROFILE_SUCCESS,
      payload: { success, mFeeProfiles, allFeeProfiles },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: FEE_PROFILE_FAILED, payload: error });
  }
};

export const setFee = (data, token) => async (dispatch) => {
  dispatch({ type: "ISLOADING" });
  try {
    const { response_description, success } = await setFeeProfile(data, token);
    dispatch({
      type: SET_FEE_PROFILE_SUCCESS,
      payload: { response_description, success },
    });
  } catch (error) {
    dispatch({ type: SET_FEE_PROFILE_FAILED, payload: error });
  }
};

export const getLimitConfigs = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { mLimitConfigs, allLimitConfigs } = await fetchLimitConfigs(
      id,
      token
    );
    // console.log("all ", mLimitConfigs);
    dispatch({
      type: GET_TXN_LIMIT_CONFIGS_SUCCESS,
      payload: { mLimitConfigs, allLimitConfigs },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_TXN_LIMIT_CONFIGS_FAILED, payload: error });
  }
};

export const getTransCategory = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const transactionCategory = await getTransCat(token);
    dispatch({ type: TRANS_CAT_SUCCESS, payload: { transactionCategory } });
  } catch (error) {
    dispatch({ type: TRANS_CAT_FAILED, payload: error });
  }
};

export const getServiceCode = (id, token) => async (dispatch) => {
  // dispatch({ type: "LOADING" });
  try {
    const transactionServiceCode = await getService(id, token);
    dispatch({
      type: TRANS_SERVICE_CODE_SUCCESS,
      payload: { transactionServiceCode },
    });
  } catch (error) {
    dispatch({ type: TRANS_SERVICE_CODE_FAILED, payload: error });
  }
};

export const getServiceApplicable = (id, token) => async (dispatch) => {
  try {
    const serviceApplicable = await getService(id, token);
    dispatch({ type: SERVICE_APPLICABLE, payload: { serviceApplicable } });
  } catch (error) {}
};

export const getBeneficiaries = (token) => async (dispatch) => {
  // dispatch({ type: "LOADING" });
  try {
    const beneficiaryDetails = await getBeneficiary(token);
    // console.log("all beneficiaries", beneficiaryDetails);
    dispatch({ type: GET_BEN_SUCCESS, payload: { beneficiaryDetails } });
  } catch (error) {
    dispatch({ type: GET_BEN_FAILED, payload: error });
  }
};

// Get loan products
export const getAllLoanProducts = (token) => async (dispatch) => {
  try {
    const { success, allLoanProducts } = await getLoanProducts(token);
    // console.log("all loan products", allLoanProducts);
    dispatch({
      type: GET_LOAN_PRODUCTS_SUCCESS,
      payload: { success, allLoanProducts },
    });
  } catch (error) {
    dispatch({ type: GET_LOAN_PRODUCTS_FAILED, payload: error });
  }
};

export const getAllLoanRequests = (page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, allLoanRequests, total } = await getLoanRequests(
      page,
      token
    );
    dispatch({
      type: GET_LOAN_REQUEST_SUCCESS,
      payload: { success, allLoanRequests, total },
    });
  } catch (error) {
    dispatch({ type: GET_LOAN_REQUEST_FAILED, payload: error });
  }
};

export const addBeneficiary = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success } = await setBeneficiary(data, token);
    dispatch({ type: SET_BENEFICIARY_SUCCESS, payload: { success } });
  } catch (error) {
    dispatch({ type: SET_BENEFICIARY_FAILURE, payload: error });
  }
};

export const getCommission = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { commissionProfiles } = await fetchCommission(token);
    dispatch({
      type: GET_COMMISSION_SUCCESS,
      payload: { commissionProfiles },
    });
  } catch (error) {
    dispatch({ type: GET_COMMISSION_FAILED, payload: error });
  }
};

export const setCommissionProfile = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { response_description, success } = await setCommission(data, token);
    dispatch({
      type: SET_COMMISSION_SUCCESS,
      payload: { response_description, success },
    });
  } catch (error) {
    dispatch({ type: SET_COMMISSION_FAILED, payload: error });
  }
};

export const logOut = () => ({
  type: LOGOUT,
  payload: true,
});

export const AppStart = () => ({
  type: "APP_START",
  payload: false,
});

export const getFeeSplitDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await getFullDetails(url, token);
    dispatch({ type: GET_FEE_SPLIT, payload: { result } });
  } catch (error) {}
};

export const userDetailsInfo = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await getFullDetails(id, token);
    // console.log(result);
    dispatch({ type: GET_USER_DETAILS_INFO, payload: { result } });
  } catch (error) {}
};

export const detailsInfo = (id, is_transaction, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    // console.log('yone tokne', token);
    const { result } = await getFullDetails(id, token);

    if (is_transaction === "transaction") {
      dispatch({ type: TRANSACTION_DETAIL_INFO, payload: { result } });
    } else if (is_transaction === "loan_request") {
      dispatch({ type: LOAN_DETAILS, payload: { result } });
    } else {
      // console.log(result, "result");
      dispatch({ type: GET_DETAIL_SUCCESS, payload: { result } });
    }
  } catch (error) {
    dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const approveLoan = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await changeStatus(id, token);
    dispatch({ type: APPROVE_LOAN_SUCCESS, payload: { result } });
  } catch (error) {}
};

export const loanDisburse = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await disburseLoan(id, token);
    // console.log("wasa");
    dispatch({ type: DISBURSE_SUCCESS, payload: { result } });
  } catch (error) {}
};

export const rejectUserLoan = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await rejectLoan(id, token);
    // console.log("wasa");
    dispatch({ type: REJECT_LOAN, payload: { result } });
  } catch (error) {}
};

export const loanTabDetails = (url, token) => async (dispatch) => {
  try {
    const { result } = await userLoanDetails(url, token);
    // const { success, data } = await result;
    dispatch({ type: GET_USER_LOAN, payload: { result } });
  } catch (error) {}
};

export const tabDetail = (url, token) => async (dispatch) => {
  try {
    const { result } = await getFullDetails(url, token);
    // console.log("all ", result);
    dispatch({ type: DETAIL_INFO, payload: { result } });
  } catch (error) {
    // dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const newTabDetail = (url, token) => async (dispatch) => {
  try {
    const { result } = await getNewDetails(url, token);
    // console.log("all deet n ", result);
    dispatch({ type: NEW_RANGE, payload: { result } });
  } catch (error) {
    // dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const deleteSplit = (url, data, token) => async (dispatch) => {
  try {
    const { result } = await deleteFeeSplit(url, data, token);
    dispatch({ type: DELETED_INFO, payload: { result } });
  } catch (error) {}
};
export const _getPartners = (token) => async (dispatch) => {
  try {
    const { servicePartners } = await getPartners(token);
    dispatch({ type: GET_PARTNER_SUCCESS, payload: { servicePartners } });
  } catch (error) {}
};

export const toggleCommProf = (id, token) => async (dispatch) => {
  try {
    const success = await _toggleCommissionProfile(id, token);
    dispatch({ type: TOGGLE_SUCCESS, payload: success });
  } catch (error) {}
};

export const filterUserTable = (data, page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { users, success, total } = await filterBy(data, page, token);
    dispatch({ type: FILTER_SUCCESS, payload: { users, success, total } });
  } catch (error) {}
};

export const sendPromotion = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, promotion, response_description } = await setPromotion(
      data,
      token
    );
    dispatch({
      type: SET_PROMO,
      payload: { success, promotion, response_description },
    });
  } catch (error) {}
};

export const getAllPromotions = (token) => async (dispatch) => {
  dispatch({ type: "PROMO_LOADING" });
  try {
    const { promoConfigs } = await fetchAllPromotion(token);
    dispatch({ type: GET_PROMO, payload: promoConfigs });
  } catch (error) {}
};

export const deletePromotion = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await deletePromo(id, token);
    dispatch({
      type: DELETE_PROMO,
      payload: { success, response_description },
    });
  } catch (error) {}
};
export const togglePromo = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await togglePromotion(id, token);
    dispatch({
      type: TOGGLE_PROMO,
      payload: { success, response_description },
    });
  } catch (error) {}
};
export const setAdminRole = (mData, token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await setRole(mData, token);
    dispatch({
      type: SET_ROLE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const updateAdminRole = (mData, id, token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await updateAdRole(
      mData,
      id,
      token
    );
    dispatch({
      type: UPDATE_AD_ROLE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const getAdminRole = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getRole(token);
    dispatch({
      type: GET_ROLE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const setPermission = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setpermissionRequest(
      mData,
      token
    );
    dispatch({
      type: SET_PERMISSION,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getPermission = (token) => async (dispatch) => {
  try {
    const { success, data } = await getpermissionRequest(token);
    dispatch({ type: GET_PERMISSION, payload: { success, data } });
  } catch (error) {}
};

export const setPermissionMod = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setPermissionModule(
      mData,
      token
    );
    dispatch({
      type: SET_PERMISSION_MODULE,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getPermissionMod = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getPermissionModule(
      token
    );
    dispatch({
      type: GET_PERMISSION_MODULE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const sendAdminPermission = (data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setAdminPermission(data, token);
    dispatch({
      type: SET_ADMIN_PERMISSION,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getAdminPermission = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getAllAdminPermission(
      token
    );
    dispatch({
      type: GET_ADMIN_PERMISSION,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const deletePermission = (url, token) => async (dispatch) => {
  try {
    const result = await deleteAuthPermission(url, token);
    dispatch({ type: DELETE_PERMISSION_LIST, payload: { result } });
  } catch (error) {}
};

export const getBankTransactionService = (url, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await bankTransactRequest(url, token);
    dispatch({ type: GET_BANK_TRANS, payload: { data, total } });
  } catch (error) {}
};

export const getFlutterwaveService = (url, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await flutterwaveRequest(url, token);
    dispatch({ type: GET_FLUTTER, payload: { data, total } });
  } catch (error) {}
};

export const deleteAdminAction = (token, id) => async (dispatch) => {
  try {
    const result = await deleteAdminUser(token, id);
    dispatch({ type: DELETE_ADMIN, payload: { result } });
  } catch (error) {}
};

export const resetGoogleAuthAction = (token, id) => async (dispatch) => {
  try {
    const result = await resetGoogleAuth(token, id);
    //dispatch({ type: DELETE_ADMIN, payload: { result } });
  } catch (error) {}
};

export const filterTransactions = (mdata, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterBankTransact(mdata, token);
    dispatch({ type: FILTER_BANK_TRANS, payload: { data, total } });
  } catch (error) {}
};

export const filterFlutter = (mData, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterFlutterwave(mData, token);
    dispatch({ type: FILTER_FLUTTER, payload: { data, total } });
  } catch (error) {}
};

export const getBillerList = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await getBillListRequest(token);
    dispatch({ type: GET_BILL_LIST, payload: { result } });
  } catch (error) {}
};

export const getBillTransactions = (per_page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactions, allTransactions } = await getBillTransactionReq(
      per_page,
      token
    );
    dispatch({
      type: GET_BILL_TRANS,
      payload: { transactions, allTransactions },
    });
  } catch (error) {}
};
export const filterBankTranStatus =
  (startDate, endDate, token) => async (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    try {
      const { data, total } = await filterBankByStatus(
        startDate,
        endDate,
        token
      );
      dispatch({ type: GET_BANK_TRANS_STATUS, payload: { data, total } });
    } catch (error) {}
  };

export const filterFlutterDateRange =
  (startDate, endDate, token) => async (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    try {
      const { data, total } = await filterFlutterRange(
        startDate,
        endDate,
        token
      );
      dispatch({ type: GET_FLW_RANGE, payload: { data, total } });
    } catch (error) {}
  };

export const getNotification = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { notifications, response_description, allNotifications } =
      await getAllNotification(token);
    dispatch({
      type: GET_NOTIFICATION,
      payload: { notifications, allNotifications, response_description },
    });
  } catch (error) {}
};

export const filterNotification = (mData, token, page) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { notifications, response_description, allNotifications } =
      await filterAllNotification(mData, token, page);
    dispatch({
      type: FILTER_NOTIFICATION,
      payload: { notifications, response_description, allNotifications },
    });
  } catch (error) {}
};

export const getNotificationChannels = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { channels } = await getChannel(token);
    dispatch({ type: GET_CHANNEL, payload: { channels } });
  } catch (error) {}
};

export const toggleNotificationChannel = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await toggleChannel(id, token);
    dispatch({
      type: TOGGLE_CHANNEL,
      payload: { success, response_description },
    });
  } catch (error) {}
};

export const getNotificationStatus = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_STATUS, payload: { data } });
  } catch (error) {}
};

export const getNotificationTypes = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_TYPES, payload: { data } });
  } catch (error) {}
};

export const getNotificationMode = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_MODE, payload: { data } });
  } catch (error) {}
};

export const filterStatus = (status, page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, transaction, allTransactions } =
      await filterTransactionStatus(status, page, token);
    dispatch({
      type: FILTER_TRANSACTION_STATUS,
      payload: { success, transaction, allTransactions },
    });
  } catch (error) {
    // dispatch({ type: TRANSACTION_FAILED, payload: error });
  }
};

export const reQuery = (admin_id, trans_ref, token) => async (dispatch) => {
  try {
    const { data, response_message } = await sendRequery(
      admin_id,
      trans_ref,
      token
    );
    dispatch({ type: SET_REQUERY, payload: { data, response_message } });
  } catch (error) {}
};

export const reverseTransaction =
  (admin_id, trans_id, token) => async (dispatch) => {
    try {
      const { data, response_message } = await setReversal(
        admin_id,
        trans_id,
        token
      );
      dispatch({
        type: SET_REVERSAL,
        payload: { data, response_message },
      });
    } catch (error) {}
  };

export const enableLock = (url, token) => async (dispatch) => {
  try {
    const { success, Response_message } = await lockUser(url, token);
    dispatch({
      type: ENABLE_USER,
      payload: { success, Response_message },
    });
  } catch (error) {}
};

export const disableLock = (url, token) => async (dispatch) => {
  try {
    const { success, Response_message } = await lockUser(url, token);
    dispatch({
      type: DISABLE_USER,
      payload: { success, Response_message },
    });
  } catch (error) {}
};

export const editAdminInfo = (id, data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await editAdmin(id, data, token);
    dispatch({
      type: UPDATE_ADMIN,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const filterAllTransaction = (data, token, page) => async (dispatch) => {
  try {
    const { transaction, allTransactions, success } = await filterTransaction(
      data,
      token,
      page
    );
    dispatch({
      type: FILTER_TRANSACTION,
      payload: { transaction, allTransactions, success },
    });
  } catch (error) {}
};

export const filterAllUsers = (data, token) => async (dispatch) => {
  try {
    const { success, users, total } = await filterUsers(data, token);
    dispatch({ type: FILTER_USERS, payload: { success, users, total } });
  } catch (error) {}
};

export const getUserReport = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await UserReportRequest(token);
    dispatch({ type: USER_REPORT_INFO, payload: { data } });
  } catch (error) {}
};

export const filterReport = (year, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filterUserReportRequest(year, token);
    dispatch({ type: FILTER_USER_REPORT, payload: { data } });
  } catch (error) {}
};

export const getTransactionReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await TransactionReportRequest(url, token);
    dispatch({ type: TRANSACTION_REPORT, payload: { transactionsArr } });
  } catch (error) {}
};

export const getTransactionByInstitute = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await TransactionReportRequest(url, token);
    dispatch({ type: REPORT_BY_INSTITUTE, payload: { transactionsArr } });
  } catch (error) {}
};

export const filterByYear = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await TransactionReportRequest(url, token);
    dispatch({ type: FILTER_TRANS_REPORT_YEAR, payload: { data } });
  } catch (error) {}
};

export const filterByInstitution = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await TransactionReportRequest(url, token);
    dispatch({ type: FILTER_TRANS_REPORT_INSTITUTE, payload: { data } });
  } catch (error) {}
};

// ACCOUNT BALANCE
export const accountBalance = (number, token) => async (dispatch) => {
  try {
    const { result } = await getBal(number, token);
    dispatch({ type: ACCOUNT_BALANCE, payload: { result } });
  } catch (error) {}
};

// ACCOUNT STATEMENT
export const accountStatement =
  (begin, stop, acc, token) => async (dispatch) => {
    try {
      const { result } = await getStatement(begin, stop, acc, token);
      dispatch({ type: ACCOUNT_STATEMENT, payload: { result } });
    } catch (error) {}
  };

export const getInstitutions = (token) => async (dispatch) => {
  try {
    const { recipientInstitutionNames } = await getInstitution(token);
    dispatch({
      type: GET_INSTITUTIONS,
      payload: { recipientInstitutionNames },
    });
  } catch (error) {}
};

export const instituteReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({ type: GET_INSTITUTE_STATUS, payload: { transactionsArr } });
  } catch (error) {}
};

export const serviceReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({ type: GET_SERVICE_REPORT, payload: { transactionsArr } });
  } catch (error) {}
};

export const filterInstitutionReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({
      type: FILTER_INSTITUTE_REPORT,
      payload: { transactionsArr },
    });
  } catch (error) {}
};

export const getTransactServices = (token) => async (dispatch) => {
  try {
    const { service } = await getTransactionService(token);
    dispatch({ type: GET_TRANSACTION_SERVICE, payload: { service } });
  } catch (error) {}
};

export const editPromotion = (id, data, token) => async (dispatch) => {
  try {
    const { response_description, success } = await editGetriPromotion(
      id,
      data,
      token
    );
    dispatch({
      type: EDIT_PROMOTION,
      payload: { response_description, success },
    });
  } catch (error) {}
};

export const updateTransactionLimit = (id, data, token) => async (dispatch) => {
  try {
    const { response_description } = await editTransactionLimitConfig(
      id,
      data,
      token
    );
    dispatch({ type: EDIT_LIMIT, payload: { response_description } });
  } catch (error) {}
};

export const deleteTransLimit = (id, token) => async (dispatch) => {
  try {
    const { response_description } = await deleteTransactionLimit(id, token);
    dispatch({
      type: DELETE_TRANSACTION_LIMIT,
      payload: { response_description },
    });
  } catch (error) {}
};

export const getAllPromotionSub = (token) => async (dispatch) => {
  dispatch({ type: "SUB_LOADING" });
  try {
    const { allPromoSubscriptions } = await fetchAllSubscriptions(token);
    dispatch({ type: GET_SUBSCRIBERS, payload: { allPromoSubscriptions } });
  } catch (error) {}
};

export const getPromotionSubscriber =
  (serviceApplicable, token) => async (dispatch) => {
    dispatch({ type: "SUB_LOADING" });
    try {
      const { allPromoSubscriptions } = await fetchPromotionSubscribers(
        serviceApplicable,
        token
      );
      dispatch({
        type: GET_PROMO_SUBSCRIBERS,
        payload: { allPromoSubscriptions },
      });
    } catch (error) {}
  };

export const addBatch = (mData, token) => async (dispatch) => {
  try {
    const { success, data, message } = await addCardBatch(mData, token);
    dispatch({ type: ADD_BATCH, payload: { success, data, message } });
  } catch (error) {}
};

export const getAllCardBatch = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchAllBatches(url, token);
    dispatch({ type: GET_BATCH, payload: { data } });
  } catch (error) {}
};
export const getAllCardRequest = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchAllBatches(url, token);
    dispatch({ type: GET_CARD_REQUEST, payload: { data } });
  } catch (error) {}
};

export const deleteBatch = (id, url, token) => async (dispatch) => {
  try {
    const { Response_message } = await removeBatch(id, url, token);
    dispatch({ type: DELETE_BATCH, payload: { Response_message } });
  } catch (error) {}
};

export const setBatchRequest = (mdata, token) => async (dispatch) => {
  try {
    const { data } = await addCardRequestBatch(mdata, token);
    dispatch({ type: SET_BATCH_REQUEST, payload: { data } });
  } catch (error) {}
};

export const getBatchRequest = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchCardRequestBatch(token);
    dispatch({ type: GET_BATCH_REQUEST, payload: { data } });
  } catch (error) {}
};

export const getKycDocuments = (page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data, total } = await getKycDocument(page, token);
    dispatch({ type: GET_DOCUMENT, payload: { data, total } });
  } catch (error) {}
};

export const uploadUserDocument = (formData, token) => async (dispatch) => {
  try {
    const { result } = await addDocument(formData, token);
    dispatch({ type: ADD_DOCUMENT, payload: { result } });
  } catch (error) {}
};

export const setKycLevel = (data, token) => async (dispatch) => {
  try {
    const { result } = await addKycLevel(data, token);
    dispatch({ type: SET_LEVEL, payload: { result } });
  } catch (error) {}
};

export const getKycLevel = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchKycLevel(token);
    dispatch({ type: GET_LEVEL, payload: { data } });
  } catch (error) {}
};

export const setKycLevelSettings = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await setLevelSetting(data, token);
    dispatch({ type: SET_LEVEL_SETTINGS, payload: { response_message } });
  } catch (error) {}
};

export const getKycLevelSettings = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getLevelSetting(token);
    dispatch({ type: GET_LEVEL_SETTINGS, payload: { data } });
  } catch (error) {}
};

export const getCustomerDocuments = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await get_customer_documents(token);
    dispatch({ type: GET_CUSTOMER_DOCUMENTS, payload: { data } });
  } catch (error) {}
};

export const getCustomerDocumentByPage = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { doc_data } = await fetchCustomerDocumentByPage(id, token);
    // console.log(doc_data);
    dispatch({
      type: GET_CUSTOMER_DOCUMENTS_PAGE,
      payload: { doc_data, total: doc_data.total },
    });
  } catch (error) {}
};

export const filterCustomerDocuments = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filter_customer_documents(url, token);
    console.log(data);
    dispatch({ type: GET_CUSTOMER_DOCUMENTS, payload: { data } });
  } catch (error) {}
};

export const seartchUserDocDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filter_customer_documents(url, token);
    console.log(data);
    dispatch({ type: GET_CUSTOMER_DOCUMENTS, payload: { data } });
  } catch (error) {}
};

export const getCustomerDocument = (token, id) => async (dispatch) => {
  // console.log("hello");
  // dispatch({ type: "LOADING" });
  try {
    const { data } = await get_customer_document(token, id);
    dispatch({ type: GET_CUSTOMER_DOCUMENT, payload: { data } });
  } catch (error) {}
};

export const getKycUpgrades = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await get_kyc_upgrade(token);
    dispatch({ type: GET_KYC_UPGRADES, payload: { data } });
  } catch (error) {}
};

export const getCustomerUpgradeByPage = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchCustomerUpgradeByPage(id, token);

    dispatch({
      type: GET_KYC_UPGRADES_PAGE,
      payload: { data, total: data.total },
    });
  } catch (error) {}
};

export const filterKycUpgrades = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filter_kyc_upgrade(url, token);
    dispatch({ type: GET_KYC_UPGRADES, payload: { data } });
  } catch (error) {}
};

export const seartchUserDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  // console.log(url, token);
  try {
    const { data } = await fetch_user_kyc_upgrade(url, token);
    // console.log(data);
    dispatch({ type: GET_KYC_UPGRADES, payload: { data } });
  } catch (error) {}
};

export const setKycRequirement = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await setRequirement(data, token);
    dispatch({ type: SET_REQUIREMENT, payload: { response_message } });
  } catch (error) {}
};

export const getKycRequirement = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getRequirement(token);
    dispatch({ type: GET_REQUIREMENT, payload: { data } });
  } catch (error) {}
};

export const approveKyc = (mData, url, token) => async (dispatch) => {
  try {
    const { response_message } = await ApproveDocument(mData, url, token);
    dispatch({ type: APPROVE_DOCUMENT, payload: { response_message } });
  } catch (error) {}
};

export const rejectKyc = (mData, url, token) => async (dispatch) => {
  try {
    const { response_message } = await ApproveDocument(mData, url, token);
    dispatch({ type: REJECT_DOCUMENT, payload: { response_message } });
  } catch (error) {}
};

export const loadHistory = (id, token) => async (dispatch) => {
  try {
    const { result } = await getUserLoginHistory(id, token);
    dispatch({ type: LOAD_HISTORY, payload: { result } });
  } catch (error) {}
};

export const getKycLevelDetail = (url, token) => async (dispatch) => {
  dispatch({ type: "DETAIL_LOADING" });
  try {
    const { data } = await kycLevel(url, token);
    dispatch({ type: KYC_LEVEL_DETAIL, payload: { data } });
  } catch (error) {}
};

export const deleteKycLevel = (url, token) => async (dispatch) => {
  try {
    const { Response_message } = await deleteKyc(url, token);
    dispatch({ type: DELETE_KYC_LEVEL, payload: { Response_message } });
  } catch (error) {}
};

export const updateKycLevel = (url, data, token) => async (dispatch) => {
  try {
    const { response_message } = await editKycLevel(url, data, token);
    dispatch({ type: EDIT_KYC_LEVEL, payload: { response_message } });
  } catch (error) {}
};

export const giveFinalApproval = (data, url, token) => async (dispatch) => {
  try {
    const { response_message } = await finalApproval(data, url, token);
    dispatch({ type: FINAL_APPROVAL, payload: { response_message } });
  } catch (error) {}
};

export const getAuditLogs = (token, word, page) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchAuditLogs(token, word, page);
    dispatch({ type: AUDIT_LOGS_SUCCESS, payload: { success, data } });
  } catch (error) {
    dispatch({ type: AUDIT_LOGS_FAILED, payload: error });
  }
};

export const deleteKycRequirement = (id, token) => async (dispatch) => {
  try {
    const { Response_message } = await deleteRequirement(id, token);
    dispatch({ type: DELETE_REQUIREMENT, payload: { Response_message } });
  } catch (error) {}
};

export const addCardRate = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await CardRateRequest(data, token);
    dispatch({ type: SET_RATE, payload: { response_message } });
  } catch (error) {}
};

export const getCardRate = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { rate_data } = await fetchCardRateRequest(token);
    dispatch({ type: GET_RATE, payload: { rate_data } });
  } catch (error) {}
};
export const setKycVirtualCard = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await addKycVirtualCard(data, token);
    dispatch({ type: SET_KYC_VIRTUAL_CARD, payload: { response_message } });
  } catch (error) {}
};
export const getKycVirtualCard = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchKycVirtualCard(token);
    dispatch({ type: GET_KYC_VIRTUAL_CARD, payload: { data } });
  } catch (error) {}
};

export const editKycVirtualCard = (data, id, token) => async (dispatch) => {
  try {
    const { response_message } = await updateKycVirtualCard(data, id, token);
    dispatch({
      type: UPDATE_KYC_VIRTUAL_CARD,
      payload: { response_message },
    });
  } catch (error) {}
};

export const setVirtualTransactionLimit =
  (data, url, token) => async (dispatch) => {
    try {
      const { response_message } = await setVirtualCardConfig(data, url, token);
      dispatch({
        type: SET_VIRTUAL_LIMIT,
        payload: { response_message },
      });
    } catch (error) {}
  };
export const getVirtualTransactionLimit =
  (url, limit, token) => async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const { data } = await getVirtualCardConfig(url, limit, token);
      dispatch({ type: GET_VIRTUAL_LIMIT, payload: { data } });
    } catch (error) {}
  };

export const getVirtualTransaction = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { card_data, total } = await getVirtualCardConfig(url, token);
    dispatch({ type: GET_VIRTUAL_TRANS, payload: { card_data, total } });
  } catch (error) {}
};

export const getVirtualCards = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { card_data, total } = await getVirtualCardConfig(url, token);
    dispatch({ type: GET_VIRTUAL_CARD, payload: { card_data, total } });
  } catch (error) {}
};

export const getCurrencies = (token) => async (dispatch) => {
  try {
    const { data } = await getRateCurrency(token);
    dispatch({ type: GET_CURRENCIES, payload: { data } });
  } catch (error) {}
};

export const switchBiller = (billname, token) => async (dispatch) => {
  try {
    const { message } = await switchBill(billname, token);
    dispatch({ type: SWITCH_BILL, payload: { message } });
  } catch (error) {}
};

export const lockCard = (mdata, url, token) => async (dispatch) => {
  try {
    const { response_message, data } = await changeCardStatus(
      mdata,
      url,
      token
    );
    dispatch({ type: LOCK_CARD, payload: { response_message, data } });
  } catch (error) {}
};

export const unlockCard = (mdata, url, token) => async (dispatch) => {
  try {
    const { response_message, data } = await changeCardStatus(
      mdata,
      url,
      token
    );
    dispatch({ type: UNLOCK_CARD, payload: { response_message, data } });
  } catch (error) {}
};

export const cardTerminate = (mdata, url, token) => async (dispatch) => {
  try {
    const { response_message, data } = await changeCardStatus(
      mdata,
      url,
      token
    );
    dispatch({ type: TERMINATE_CARD, payload: { response_message, data } });
  } catch (error) {}
};

export const getRateDetail = (id, token) => async (dispatch) => {
  try {
    const { data } = await _fetchRate(id, token);
    dispatch({ type: GET_A_RATE, payload: { data } });
  } catch (error) {}
};

export const filterDocument = (status, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filterDocumentByStatus(status, token);
    dispatch({ type: FILTER_DOCUMENT, payload: { data } });
  } catch (error) {}
};
export const filterDocumentAccount =
  (type, value, token) => async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const { data } = await filterDocumentByAccount(type, value, token);
      dispatch({ type: FILTER_DOCUMENT, payload: { data } });
    } catch (error) {}
  };
export const getDeveloperAccounts = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperAccounts(token);
    // console.log("developer accounts inside action creators", data);
    dispatch({
      type: DEVELOPER_ACCOUNTS_SUCCESS,
      payload: { success, data },
    });
  } catch (error) {
    dispatch({ type: DEVELOPER_ACCOUNTS_FAILED, payload: error });
  }
};

export const getDeveloperApiCalls = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperApiCalls(token);
    // console.log("developer calls inside action creators", data);
    dispatch({ type: DEVELOPER_CALLS_SUCCESS, payload: { success, data } });
  } catch (error) {
    dispatch({ type: DEVELOPER_CALLS_FAILED, payload: error });
  }
};

export const getDeveloperCallStats = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperCallStats(token);
    // console.log("developer call stats inside action creators", data);
    dispatch({
      type: DEVELOPER_CALL_STATS_SUCCESS,
      payload: { success, data },
    });
  } catch (error) {
    dispatch({ type: DEVELOPER_CALL_STATS_FAILED, payload: error });
  }
};

export const setWord = (word) => ({
  type: "SET_WORD",
  payload: word,
});

export const addUserBvn = (data, token) => async (dispatch) => {
  try {
    const { result } = await setUserBvn(data, token);
    dispatch({ type: LINK_BVN, payload: { result } });
  } catch (error) {}
};

export const getWalletHistory = (page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { wallet_data, total } = await fetchWalletHistory(page, token);
    dispatch({ type: GET_WALLET_HISTORY, payload: { wallet_data, total } });
  } catch (error) {}
};

export const getWallets = (page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchWallets(page, token);
    dispatch({ type: GET_WALLETS, payload: { data } });
  } catch (error) {}
};

export const setWallets = (mData, token) => async (dispatch) => {
  try {
    const { response_message } = await addWallets(mData, token);
    dispatch({ type: ADD_WALLETS, payload: { response_message } });
  } catch (error) {}
};

export const addFunding = (mData, token) => async (dispatch) => {
  try {
    const { response_message } = await fundWallet(mData, token);
    dispatch({ type: FUND_WALLET, payload: { response_message } });
  } catch (error) {}
};

export const filterVirtualCardTrans =
  (mData, page, token) => async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const { card_data, total } = await filterCardTransaction(
        mData,
        page,
        token
      );
      dispatch({
        type: FILTER_VIRTUAL_CARD_TRANS,
        payload: { card_data, total },
      });
    } catch (error) {}
  };

export const filterVirtualCard = (mData, page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { card_data, total } = await filterVCard(mData, page, token);
    dispatch({ type: FILTER_VIRTUAL_CARD, payload: { card_data, total } });
  } catch (error) {}
};

export const editVirtualCardLimit = (data, id, token) => async (dispatch) => {
  try {
    const { response_message } = await updateVirtualCardLimit(data, id, token);
    dispatch({
      type: EDIT_VIRTUAL_CARD_LIMIT,
      payload: { response_message },
    });
  } catch (error) {}
};

export const getDeveloperDoc = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchDeveloperDocument(token);
    dispatch({ type: GET_DEV_DOC, payload: { data } });
  } catch (error) {}
};

export const toggleDeveloperDoc =
  (id, supportEmail, token) => async (dispatch) => {
    try {
      const { result } = await toggleDeveloperDocument(id, supportEmail, token);
      dispatch({ type: TOGGLE_DOC, payload: { result } });
    } catch (error) {}
  };
export const getUserCategory = (token) => async (dispatch) => {
  try {
    const { data } = await fetchUserCategory(token);
    dispatch({ type: GET_USER_CATEGORY, payload: { data } });
  } catch (error) {}
};
export const setUserCategory = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await addUserCategory(data, token);
    dispatch({ type: SET_USER_CATEGORY, payload: { response_message } });
  } catch (error) {}
};

export const setSavingProduct = (mData, token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await addSavingProduct(
      mData,
      token
    );
    dispatch({ type: SET_SAVING, payload: { response_message } });
  } catch (error) {}
};

export const getSavingProduct = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { saving_data } = await fetchSavingProduct(token);
    dispatch({ type: GET_SAVING, payload: { saving_data } });
  } catch (error) {}
};

export const filterSavings = (type, url, token) => async (dispatch) => {
  if (type == "date") {
    type = FILTER_SAVINGS_BY_DATE;
  } else {
    type = FILTER_SAVINGS_BY_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { saving_data } = await filterSavingsProduct(url, token);
    dispatch({ type, payload: { saving_data } });
  } catch (error) {}
};

export const getSavingWallet = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingWallet(token);
    dispatch({ type: GET_SAVING_WALLET, payload: { data } });
  } catch (error) {}
};
export const deleteSavingsProduct = (id, token) => async (dispatch) => {
  try {
    const { Response_message } = await removeSavingsProduct(id, token);
    dispatch({ type: DELETE_SAVINGS, payload: { Response_message } });
  } catch (error) {}
};

export const getUserSavings = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchUserSavings(token);
    dispatch({ type: GET_USER_SAVING, payload: { data } });
  } catch (error) {}
};

export const getSavingProductDetail = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingProductDetail(id, token);
    dispatch({ type: GET_SAVINGS_PRODUCT_DETAILS, payload: { data } });
  } catch (error) {}
};

export const lockSavingProduct = (id, token) => async (dispatch) => {
  try {
    const { response_message } = await disableSavingProduct(id, token);
    dispatch({ type: LOCK_SAVING_PROD, payload: { response_message } });
  } catch (error) {}
};
export const addSavingPenalty = (data, token) => async (dispatch) => {
  try {
    const { success } = await setSavingPenalty(data, token);
    dispatch({ type: SET_PENALTY, payload: { success } });
  } catch (error) {}
};
export const getSavingPenalty = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingPenalty(token);
    dispatch({ type: GET_PENALTY, payload: { data } });
  } catch (error) {}
};
export const editSavingPenalty = (id, data, token) => async (dispatch) => {
  try {
    const result = await updateSavingPenalty(id, data, token);
    dispatch({ type: UPDATE_PENALTY, payload: { result } });
  } catch (error) {}
};
export const getWalletTransaction = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchWalletTransaction(token);
    dispatch({ type: GET_WALLET_TRANSACTION, payload: { data } });
  } catch (error) {}
};

export const getUserSavingsData = (id, token) => async (dispatch) => {
  try {
    const { data } = await fetch_a_UserSavings(id, token);
    dispatch({ type: GET_A_USER_SAVINGS, payload: { data } });
  } catch (error) {}
};

export const setWithDrawal = (mData, token) => async (dispatch) => {
  try {
    const { data, success, message } = await addWithDrawalDate(mData, token);
    dispatch({ type: SET_WITHDRAWAL, payload: { data, message } });
  } catch (error) {}
};

export const editSavingProductConfig =
  (id, data, token) => async (dispatch) => {
    try {
      const { response_message } = await updateSavingProduct(id, data, token);
      dispatch({
        type: UPDATE_SAVING_PRODUCT,
        payload: { response_message },
      });
    } catch (error) {}
  };

export const getWithDrawal = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchWithDrawalDate(token);
    dispatch({ type: GET_WITHDRAWAL, payload: { data } });
  } catch (error) {}
};

export const lockSavingWallet = (data, token) => async (dispatch) => {
  try {
    const { Response_message } = await changeSavingWallet(data, token);
    dispatch({ type: CHANGE_WALLET_STATUS, payload: { Response_message } });
  } catch (error) {}
};

export const getUserSavingDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingProductDetail(url, token);
    dispatch({ type: GET_USER_SAVING_DETAILS, payload: { data } });
  } catch (error) {}
};

export const getUserSavingHistory = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingHistory(id, token);
    dispatch({ type: GET_HISTORY, payload: { data } });
  } catch (error) {}
};

export const addCompany = (url, mData, token) => async (dispatch) => {
  try {
    const { data, response_message } = await addInvestmentData(
      url,
      mData,
      token
    );
    dispatch({ type: ADD_COMPANY, payload: { response_message } });
  } catch (error) {}
};
export const getCompany = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_COMPANY, payload: { data } });
  } catch (error) {}
};

export const setInvestRole = (url, mData, token) => async (dispatch) => {
  try {
    const { data, response_message } = await addInvestmentData(
      url,
      mData,
      token
    );
    dispatch({ type: SET_INVESTMENT_ROLE, payload: { response_message } });
  } catch (error) {}
};

export const getInvestRole = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVESTMENT_ROLE, payload: { data } });
  } catch (error) {}
};

export const createMerchant = (url, mData, token) => async (dispatch) => {
  try {
    const { data, success, response_message } = await addInvestmentData(
      url,
      mData,
      token
    );
    dispatch({ type: ADD_MERCHANT, payload: { response_message } });
  } catch (error) {}
};
export const getAllMerchant = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_MERCHANT, payload: { data } });
  } catch (error) {}
};
export const getInvestmentSub = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVESTMENT_SUB, payload: { data } });
  } catch (error) {}
};

export const addInvestProduct = (url, mData, token) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(url, mData, token);
    dispatch({ type: ADD_INVEST_PRODUCT, payload: { response_message } });
  } catch (error) {}
};

export const getInvestmentProduct = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_PRODUCT, payload: { data } });
  } catch (error) {}
};

export const addInvestmentCategory = (url, data, token) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(url, data, token);
    dispatch({ type: ADD_INVEST_CATEGORY, payload: { response_message } });
  } catch (error) {}
};
export const getInvestmentCategory = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_CATEGORY, payload: { data } });
  } catch (error) {}
};

export const getInvestmentReturn = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_RETURN, payload: { data } });
  } catch (error) {}
};

export const getInvestmentDoc = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_DOC, payload: { data } });
  } catch (error) {}
};

export const uploadProductImage =
  (url, id, form_data, token) => async (dispatch) => {
    try {
      const { data } = await uploadImage(url, id, form_data, token);
      dispatch({ type: UPLOAD_IMG, payload: { data } });
    } catch (error) {}
  };

export const uploadSavingImage =
  (url, id, form_data, token) => async (dispatch) => {
    try {
      const { data } = await uploadImage(url, id, form_data, token);
      dispatch({ type: UPLOAD_SAVING_IMG, payload: { data } });
    } catch (error) {}
  };

export const approveInvestDoc = (url, mData, token) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(url, mData, token);
    dispatch({ type: APPROVE_INVEST_PROD, payload: { response_message } });
  } catch (error) {}
};
export const getInvestmentCycle = (url, token) => async (dispatch) => {
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_CYCLE, payload: { data } });
  } catch (error) {}
};

export const get_A_UserInvestmentSub = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_A_USER_INVEST_SUB, payload: { data } });
  } catch (error) {}
};

export const editInvestmentProduct =
  (url, mData, token, type) => async (dispatch) => {
    try {
      const { response_message } = await addInvestmentData(
        url,
        mData,
        token,
        type
      );
      dispatch({ type: EDIT_INVEST_PROD, payload: { response_message } });
    } catch (error) {
      const errMessage = error.message;
      dispatch({ type: EDIT_INVEST_PROD_ERROR, payload: { errMessage } });
    }
  };
export const editInvestmentMerchant =
  (url, mData, token, type) => async (dispatch) => {
    try {
      const { response_message } = await addInvestmentData(
        url,
        mData,
        token,
        type
      );
      dispatch({ type: EDIT_MERCHANT, payload: { response_message } });
    } catch (error) {}
  };

export const updateInvestmentCompany =
  (url, mData, token, type) => async (dispatch) => {
    try {
      const { response_message } = await addInvestmentData(
        url,
        mData,
        token,
        type
      );
      dispatch({ type: EDIT_COMPANY, payload: { response_message } });
    } catch (error) {}
  };

export const updateRole = (url, mData, token, type) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(
      url,
      mData,
      token,
      type
    );
    dispatch({ type: UPDATE_ROLE, payload: { response_message } });
  } catch (error) {}
};

export const uploadDocument = (mData) => async (dispatch) => {
  try {
    const { data } = await uploadDocumentRequest(mData);
    dispatch({ type: UPLOAD_DOC, payload: { data } });
  } catch (error) {}
};
export const deleteInvestmentProduct = (url, token) => async (dispatch) => {
  try {
    const { response_message } = await deleteInvestmentItem(url, token);
    dispatch({ type: DELETE_INVEST_PROD, payload: { response_message } });
  } catch (error) {}
};
export const getMerchantDetail = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: VIEW_MERCHANT, payload: { data } });
  } catch (error) {}
};

export const getInvestProductDetail = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: VIEW_INVEST_PROD_DETAIL, payload: { data } });
  } catch (error) {}
};

export const changeMerchantState = (url, token) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(url, token);
    dispatch({
      type: CHANGE_MERCHANT_STATUS,
      payload: { response_message },
    });
  } catch (error) {}
};

export const addInvestmentHistory = (url, data, token) => async (dispatch) => {
  try {
    const { response_message } = await addInvestmentData(url, data, token);
    dispatch({ type: ADD_INVEST_HISTORY, payload: { response_message } });
  } catch (error) {}
};
export const getInvestmentHistory = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: GET_INVEST_HISTORY, payload: { data } });
  } catch (error) {}
};

export const filterInvestment = (type, url, token) => async (dispatch) => {
  let action_type;
  if (type == "date") {
    action_type = FILTER_INVESTMENT_BY_DATE;
  } else {
    action_type = FILTER_INVESTMENT_BY_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInvestmentData(url, token);
    dispatch({ type: action_type, payload: { data } });
  } catch (error) {}
};

export const filterUserSavings = (type, url, token) => async (dispatch) => {
  let action_type;
  if (type == "date") {
    action_type = FILTER_USER_SAVINGS_DATE;
  } else {
    action_type = FILTER_USER_SAVINGS_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { saving_data } = await filterSavingsProduct(url, token);
    dispatch({ type: action_type, payload: { saving_data } });
  } catch (error) {}
};
export const getSavingsGroup = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchSavingsGroup(token);
    dispatch({ type: GET_SAVINGS_GROUP, payload: { data } });
  } catch (error) {}
};
export const addSavingsGroup = (mData, token) => async (dispatch) => {
  try {
    const { data, response_message } = await setSaveGroup(mData, token);
    dispatch({ type: ADD_SAVE_GROUP, payload: { response_message } });
  } catch (error) {}
};

export const filterGroupSavings = (type, url, token) => async (dispatch) => {
  let action_type;
  if (type == "date") {
    action_type = FILTER_GROUP_SAVE_DATE;
  } else {
    action_type = FILTER_GROUP_SAVE_STATUS;
  }
  dispatch({ type: "LOADING" });
  try {
    const { saving_data } = await filterSavingsProduct(url, token);
    dispatch({ type: action_type, payload: { saving_data } });
  } catch (error) {}
};

export const getReportByMonth = (url, token) => async (dispatch) => {
  //
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchReportMonth(url, token);
    dispatch({ type: GET_REPORT_MONTH, payload: { data } });
  } catch (error) {}
};
export const filterReportByMonth = (url, token) => async (dispatch) => {
  //
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchReportMonth(url, token);
    dispatch({ type: FILTER_REPORT_MONTH, payload: { data } });
  } catch (error) {}
};

export const addAgent = (url, data, token) => async (dispatch) => {
  try {
    const { result } = await createAgent(url, data, token);
    dispatch({ type: ADD_AGENT, payload: { result } });
  } catch (error) {}
};

export const getAgents = (token, url) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data } = await readAgent(token, url);
    dispatch({ type: GET_AGENT, payload: { data } });
  } catch (error) {}
};

export const AssignAgent = (url, data, token) => async (dispatch) => {
  try {
    const { result } = await addAgentToUser(url, data, token);
    dispatch({ type: ASSIGN_AGENT, payload: { result } });
  } catch (error) {}
};

export const getAgentClaims = (url, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data } = await readAgent(url, token);
    dispatch({ type: GET_AGENT_CLAIMS, payload: { data } });
  } catch (error) {}
};

export const approveUserToAgent = (url, data, token) => async (dispatch) => {
  try {
    const { result } = await addAgentToUser(url, data, token);
    dispatch({ type: APPROVE_CLAIMS, payload: { result } });
  } catch (error) {}
};

export const addUserIncentives = (mData, token) => async (dispatch) => {
  try {
    const { response_description } = await addIncentives(mData, token);
    dispatch({ type: ADD_INCENTIVES, payload: { response_description } });
  } catch (error) {
    const err_msg = error.message;
    dispatch({ type: INCENTIVE_ERROR, payload: err_msg });
  }
};

export const getIncentiveSummary = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, response_description, data } = await getIncentives(token);
    dispatch({ type: GET_INCENTIVES, payload: { data } });
  } catch (error) {}
};

export const getRedeemedTransfer = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await redeemedTransfer(token);
    dispatch({ type: GET_REDEEMED_TRANSFER, payload: data });
  } catch (error) {}
};

export const addConversionsPoint = (mData, token) => async (dispatch) => {
  try {
    const { response_description, success } = await conversionPoint(
      mData,
      token
    );
    dispatch({
      type: ADD_CONVERSION,
      payload: { response_description, success },
    });
  } catch (error) {
    const err_msg = error.message;
    dispatch({ type: ADD_CONVERSION_ERROR, payload: err_msg });
  }
};

export const getAllConversionPoint = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getConversionPoint(token);
    dispatch({ type: GET_CONVERSION, payload: { data } });
  } catch (error) {}
};

export const filterUserLoan = (mData, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { allLoanRequests, total, success } = await filterLoan(mData, token);
    dispatch({
      type: FILTER_LOAN,
      payload: { allLoanRequests, total, success },
    });
  } catch (error) {}
};

export const getUserLoanStatus = (token) => async (dispatch) => {
  try {
    const { data } = await getLoanStatus(token);
    dispatch({ type: GET_LOAN_STATUS, payload: { data } });
  } catch (error) {}
};

export const updateAllIncentiveConversion =
  (url, mData, token) => async (dispatch) => {
    try {
      const { response_description } = await updateIncentiveConversion(
        url,
        mData,
        token
      );
      dispatch({
        type: UPDATE_INCENTIVES,
        payload: response_description,
      });
    } catch (error) {
      dispatch({ type: UPDATE_INCENTIVES_ERROR, payload: error.message });
    }
  };

export const getAllStores = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_STORES, payload: data });
  } catch (error) {}
};

export const getStoreDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_STORE_DETAILS, payload: data });
  } catch (error) {}
};

export const getStoreProduct = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_STORE_PRODUCT, payload: data });
  } catch (error) {}
};

export const getStoreOrder = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_STORE_ORDER, payload: data });
  } catch (error) {}
};

export const getAllInventoryProducts = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_PRODUCTS, payload: data });
  } catch (error) {}
};

export const getProductDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_PRODUCT_DETAILS, payload: data });
  } catch (error) {}
};

export const getAllInventoryOrders = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_ORDERS, payload: data });
  } catch (error) {}
};

export const changeStoreStatus = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { response_description } = await getStoreInfo(url, token);
    dispatch({ type: CHANGE_STORE_STATUS, payload: response_description });
  } catch (error) {}
};

export const getOrderDetails = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getStoreInfo(url, token);
    dispatch({ type: GET_ORDER_DETAILS, payload: data });
  } catch (error) {}
};

export const getUserSite = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await getSiteInfo(url, token);
    dispatch({ type: GET_SITES, payload: data });
  } catch (error) {}
};

export const getCodeSetup = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_CATEGORY_CODE_CONFIG, payload: data });
  } catch (error) {}
};

export const addCodeConfig = (url, mData, token) => async (dispatch) => {
  try {
    const { data, response_message } = await addBillsConfig(url, mData, token);
    dispatch({
      type: ADD_CATEGORY_CODE_CONFIG,
      payload: { data, response_message },
    });
  } catch (error) {
    dispatch({ type: ADD_CODE_ERROR, payload: error.message });
  }
};

export const getGeneralCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_GENERAL_CODE, payload: data });
  } catch (error) {}
};

export const getItemCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_ITEM_CODE, payload: data });
  } catch (error) {}
};

export const getProductCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_PRODUCT_CODE, payload: data });
  } catch (error) {}
};
export const getProviderCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_PROVIDER_CODE, payload: data });
  } catch (error) {}
};

export const getBillServiceCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_SERVICE_CODE, payload: data });
  } catch (error) {}
};
export const getServiceSwitch = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_SERVICE_SWITCH, payload: data });
  } catch (error) {}
};

export const getSwitchCode = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data } = await fetchBillsConfig(url, token);
    dispatch({ type: GET_SWITCH_CODE, payload: data });
  } catch (error) {}
};

export const editAllCode = (url, mData, token) => async (dispatch) => {
  try {
    const { data, response_message } = await editBillsConfig(url, mData, token);
    dispatch({ type: EDIT_CODE, payload: { data, response_message } });
  } catch (error) {
    dispatch({
      type: EDIT_CODE_ERROR,
      payload: { err_msg: error.message, err_name: error.message },
    });
  }
};

export const getEarnedPoint = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getMoreIncentiveConfig(url, token);
    dispatch({ type: GET_EARNED_POINT, payload: data });
  } catch (error) {}
};

export const getChargeBack = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getMoreIncentiveConfig(url, token);
    dispatch({ type: GET_CHARGE_BACK, payload: data });
  } catch (error) {}
};

export const getUserEarnedPoint = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getMoreIncentiveConfig(url, token);
    dispatch({ type: GET_USER_EARNED_POINT, payload: data });
  } catch (error) {}
};

export const getUserInterestHistory = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getUserHistoryConfig(url, token);
    dispatch({ type: GET_USER_INTEREST_HISTORY, payload: data });
  } catch (error) {}
};

export const getUnitChargeBack = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getMoreIncentiveConfig(url, token);
    dispatch({ type: GET_A_CHARGE_BACK, payload: data });
  } catch (error) {}
};

export const updateChargeBack = (url, mData, token) => async (dispatch) => {
  try {
    const { response_description } = await updateIncentiveConversion(
      url,
      mData,
      token
    );
    dispatch({ type: UPDATE_CHARGE_BACK, payload: response_description });
  } catch (error) {}
};

export const getProductView = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data, response_description } = await getStoreInfo(url, token);
    dispatch({ type: GET_PRODUCT_VIEW, payload: data });
  } catch (error) {}
};

export const getCheckoutView = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { data, response_description } = await getStoreInfo(url, token);
    dispatch({ type: GET_CHECKOUT_VIEW, payload: data });
  } catch (error) {}
};

export const getAllBatches = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { result } = await getBulkPaymentData(url, token);
    dispatch({ type: GET_ALL_BATCHES, payload: result });
  } catch (error) {}
};

// Interests
export const setupInterest = (data, token) => async (dispatch) => {
  try {
    const { result } = await addInterest(data, token);
    dispatch({ type: SETUP_INTEREST, payload: { result } });
  } catch (error) {}
};

export const getInterestSetup = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInterestSetup(token);
    dispatch({ type: GET_INTERESTS, payload: { data } });
  } catch (error) {}
};

export const getInterestDetail = (url, token) => async (dispatch) => {
  dispatch({ type: "DETAIL_LOADING" });
  try {
    const { data } = await fetchInterestDetail(url, token);
    dispatch({ type: INTEREST_DETAIL, payload: { data } });
  } catch (error) {}
};

export const deleteInterestDetail = (url, token) => async (dispatch) => {
  try {
    const { Response_message } = await deleteInterestSetup(url, token);
    dispatch({ type: DELETE_INTEREST, payload: { Response_message } });
  } catch (error) {}
};

export const updateInterestDetail = (url, data, token) => async (dispatch) => {
  try {
    const { response_message } = await editInterestSetup(url, data, token);
    dispatch({ type: EDIT_INTEREST, payload: { response_message } });
  } catch (error) {}
};

export const getInterestHistories = (token, page) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInterestHistories(token, page);
    dispatch({ type: GET_INTEREST_HISTORIES, payload: { data } });
  } catch (error) {
    // console.log(error);
    dispatch({ type: "STOPLOAD", payload: "Error Found" });
  }
};

export const getHistoriesFilters =
  (token, page, filters) => async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const { data } = await filterInterestHistories(token, page, filters);
      dispatch({ type: FILTER_INTEREST_HISTORIES, payload: { data } });
    } catch (error) {}
  };

export const getInterestPayouts = (token, page) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInterestPayouts(token, page);
    dispatch({ type: GET_INTEREST_PAYOUTS, payload: { data } });
  } catch (error) {
    console.log(error);
    dispatch({ type: "STOPLOAD", payload: "Error Found" });
  }
};

export const getPayoutsFilters = (token, page, filters) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filterInterestPayouts(token, page, filters);
    dispatch({ type: FILTER_INTEREST_PAYOUTS, payload: { data } });
  } catch (error) {}
};

export const getInterestEarnings = (token, page) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchInterestEarnings(token, page);
    dispatch({ type: GET_INTEREST_EARNINGS, payload: { data } });
  } catch (error) {
    console.log(error);
    dispatch({ type: "STOPLOAD", payload: "Error Found" });
  }
};

export const getEarningsFilters =
  (token, page, filters) => async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const { data } = await filterInterestEarnings(token, page, filters);
      dispatch({ type: FILTER_INTEREST_EARNINGS, payload: { data } });
    } catch (error) {}
  };

export const getUserDetails = (id, token) => async (dispatch) => {
  dispatch({ type: "DETAIL_LOADING" });
  try {
    const { result } = await fetchUserDetails(id, token);
    dispatch({ type: GET_DETAIL_SUCCESS, payload: { result } });
  } catch (error) {}
};

// NEW ACCOUNTS REQUEST

export const getNewAccountRequest = (page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transaction, success, total } = await fetchAccountRequest(
      page,
      token
    );
    dispatch({
      type: NEW_ACCOUNT_SUCCESS,
      payload: { transaction, success, total },
    });
  } catch (error) {
    dispatch({ type: NEW_ACCOUNT_FAILED, payload: error });
  }
};

export const approveAccountRequest = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });

  try {
    const { response_message } = await approveNewAccount(data, token);
    dispatch({
      type: APPROVE_ACCOUNT_SUCCESS,
      payload: { response_message },
    });
  } catch (error) {
    dispatch({ type: APPROVE_ACCOUNT_FAILED, payload: error });
  }
};
