import { combineReducers } from "redux";
import { GET_ALL_BATCHES } from "../action/action.types";

const allBatchesReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_BATCHES:
      return {
        ...state,
        loading: false,
        all_batches: action.payload,
      };
    default:
      return state;
  }
};

export const bulkTransactionReducer = combineReducers({
  batches: allBatchesReducer,
});
