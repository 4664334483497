import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Error } from "../../../Error";
import EarnedActions from "./EarnedActions/EarnedAction";
import ChargeBack from "./ChargeBack/ChargeBack";

// const Show = () => <div>Wassa</div>;

const IncentivesRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/earned-incentives`} />
      </Route>
      <Route
        exact
        path={`${match.path}/earned-incentives`}
        component={EarnedActions}
      />
      <Route exact path={`${match.path}/charge-backs`} component={ChargeBack} />

      <Route exact component={Error} />
    </Switch>
  </>
);

export default IncentivesRoute;
