import { headers } from "../api.config";

export const addKycLevel = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/kyc_levels`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};

export const fetchKycLevel = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/kyc_levels`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
export const kycLevel = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();

    return { data };
  }
};

export const deleteKyc = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "DELETE",
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { Response_message } = await response.json();
    return { Response_message };
  }
};

export const editKycLevel = async (url, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
