import React from "react";
import { Modal } from "../../../../../components/Accessories";

class KycLevelModal extends React.Component {
  state = {
    level_name: "",
    description: "",
    level: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = () => {
    this.props.setLevel(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Kyc Level setup">
        <div className="body-grid">
          <div className="box box9">
            <label>Kyc Level Name</label>
            <input
              className="form-control"
              name="level_name"
              value={this.state.level_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Kyc Description</label>
            <textarea
              className="form-control"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}></textarea>
          </div>
          <div className="box box9">
            <label>Kyc Level Type</label>
            <input
              className="form-control"
              placeholder="insert kyc level e.g 1"
              name="level"
              value={this.state.level}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary my-2"
                onClick={this.submit}>
                Set Kyc Level
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default KycLevelModal;
