import { headers } from "../api.config";

export const CardRateRequest = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/rates`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const fetchCardRateRequest = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/rates`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const rate_data = await data.data;
    return { rate_data };
  }
};

export const _fetchRate = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/rates/${id}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
