import React from "react";
import { connect } from "react-redux";
import CardLayout from "../VirtualCardBar";
import VirtualCardLimitModal from "./VirtualCardTransactionLimitModal";
import {
  getKycLevel,
  setVirtualTransactionLimit,
  getVirtualTransactionLimit,
  getCurrencies,
  editVirtualCardLimit,
} from "../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../components/Accessories";
import {
  VirtualLimitTableBody,
  VirtualLimitTableHead,
} from "../../../../components/Rows/CardTable";
import EditVirtualCardTransactionLimit from "./EditVirtualCardTransactionLimit";

class VirtualCardTransLimit extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getKycLevel(this.props.token);
    this.props.getVirtualTransactionLimit(
      `virtual_cards_transaction_limits`,
      true,
      this.props.token
    );
    this.props.getCurrencies(this.props.token);
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      editing: false,
    });
  };

  submitLimit = (data) => {
    callBackMethod(
      this.props.setVirtualTransactionLimit(
        data,
        `virtual_cards_transaction_limits`,
        this.props.token
      )
    )
      .then(() => this.setState({ display: false }))
      .then(() => alert(this.props.response));
  };

  editLimit = (props) => {
    this.setState({
      ...this.state,
      idToEdit: props.id,
      editing: true,
      item: props,
    });
  };

  editVCardLimit = (data) => {
    callBackMethod(
      this.props.editVirtualCardLimit(
        data,
        this.state.idToEdit,
        this.props.token
      )
    )
      .then(() => this.setState({ editing: false }))
      .then(() => alert(this.props.edit_resp))
      .then(() =>
        this.props.getVirtualTransactionLimit(
          `virtual_cards_transaction_limits`,
          true,
          this.props.token
        )
      );
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <VirtualLimitTableHead />
          <tbody>
            {this.props.all_virtual_limit &&
              this.props.all_virtual_limit.map((content, index) => (
                <VirtualLimitTableBody
                  {...content}
                  admin_data={this.props.admin_data}
                  index={index + 1}
                  editLimit={this.editLimit}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <CardLayout active="virtualcard-limit">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a>
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </CardLayout>
        <VirtualCardLimitModal
          display={this.state.display}
          closeModal={this.closeModal}
          submitLimit={this.submitLimit}
        />
        <EditVirtualCardTransactionLimit
          display={this.state.editing}
          closeModal={this.closeModal}
          property={this.state.item}
          editVCardLimit={this.editVCardLimit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  response: state.virtualCards.set_virtual_config.response,
  all_virtual_limit: state.virtualCards.get_virtual_config.data,
  edit_resp: state.virtualCards.get_virtual_config.response_msg,
  loading: state.virtualCards.get_virtual_config.loading,
  admin_data: state.login.data,
});

export default connect(mapStateToProps, {
  getKycLevel,
  setVirtualTransactionLimit,
  getVirtualTransactionLimit,
  getCurrencies,
  editVirtualCardLimit,
})(VirtualCardTransLimit);
