import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  SummaryData,
  dailyBillTrans,
  SavInvestSummary,
} from "../../API/ReportRequest";
import { Link } from "react-router-dom";
import { SingleCard } from "../../components/Accessories";
import "../../morestyles.css";

class Report extends React.Component {
  state = {
    totalTrans: [],
    totalCommissions: [],
    totalUserSum: [],
    totalNotification: [],
    savinvest_sum: {},
    group_analysis: {},
    user_saving_analysis: {},
  };

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10);
    this.getTotalTransaction(today);
  }

  getSavInvestSummary = async (date) => {
    const res = await SavInvestSummary(
      date,
      `savinvest/savings_and_investment_analysis`,
      this.props.token
    );
    this.setState({
      savinvest_sum: res,
    });
  };

  getSavingGroupAnalysis = async (date) => {
    const res = await SavInvestSummary(
      date,
      `savinvest/savings_groups_analysis`,
      this.props.token
    );

    const user_res = await SavInvestSummary(
      date,
      `savinvest/user_savings_analysis`,
      this.props.token
    );
    this.setState({
      group_analysis: res,
      user_saving_analysis: user_res,
    });
  };

  getTotalTransaction = async (date) => {
    try {
      await this.getSavInvestSummary(date);
      await this.getSavingGroupAnalysis(date);
      const {
        summaryArray,
        commissionArray,
        userArray,
        notification,
      } = await SummaryData(date, this.props.token);
      this.setState((prevState) => ({
        totalTrans: summaryArray,
        totalCommissions: commissionArray,
        totalUserSum: userArray,
        totalNotification: notification,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Report Summary"
            report={true}
            handleReport={this.getTotalTransaction}
          />
          <div class="container-fluid mt--6">
            <div class="row align-items-center py-4">
              <div class="col">
                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <span
                          style={{ fontSize: "18px", fontWeight: "600" }}
                          contentEditable>
                          User Management Summary Reports
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <Link
                            to={`${this.props.match.url}/user-report`}
                            class="btn btn-sm btn-neutral">
                            View Report Summary
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalUserSum.map((content) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          Transaction Summary Reports
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <Link
                            to={`${this.props.match.url}/transaction-report`}
                            class="btn btn-sm btn-neutral">
                            View Report Summary
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div className="row">
                      {this.state.totalTrans.map((content, index) => (
                        <SingleCard isTransaction={true} {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Commissions / Fees Summary Reports
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalCommissions.map((content, index) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                {/* <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Notification Report
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalNotification.map((content, index) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div> */}

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Saving and Investment Analysis
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      <SingleCard
                        summedAmount={this.state.savinvest_sum.total_investment}
                        topic="Total Investement"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.savinvest_sum.total_subscription
                        }
                        topic="Total Subscription"
                      />
                      <SingleCard
                        summedAmount={this.state.savinvest_sum.total_savings}
                        topic="Total Savings"
                      />
                      <SingleCard
                        summedAmount={this.state.savinvest_sum.total_merchant}
                        topic="Total Merchant"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.savinvest_sum.total_savings_group
                        }
                        topic="Total Savings Group"
                      />
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Group Savings Analysis
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      <SingleCard
                        summedAmount={this.state.group_analysis.total_group}
                        topic="Total Group"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.group_analysis.total_close_group
                        }
                        topic="Total Closed Group"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.group_analysis.total_open_group
                        }
                        topic="Total Open Group"
                      />
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      User Savings Analysis
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      <SingleCard
                        summedAmount={
                          this.state.user_saving_analysis.total_savings
                        }
                        topic="Total Savings"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.user_saving_analysis.total_active_savings
                        }
                        topic="Total Active Savings"
                      />
                      <SingleCard
                        summedAmount={
                          this.state.user_saving_analysis
                            .total_in_active_savings
                        }
                        topic="Total InActive Savings"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
});
export default connect(mapStateToProps, {})(Report);
