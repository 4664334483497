import React from "react";
import { connect } from "react-redux";
import { logOut, setWord } from "../reduxStore/action/action.creator";
import { callBackMethod, Modal } from "./Accessories";
import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
import { CSVLink } from "react-csv";

class DashboardNavBar extends React.Component {
	state = {
		display: false,
		search_word: "",
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.token) {
			window.location = "/";
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	_filterclass = () => {
		let classToShow = "";
		if (
			this.props.submenu == "Transactions" ||
			this.props.submenu == "All Users" ||
			this.props.submenu == "Bank Transactions"
		) {
			classToShow = "col-lg-4 col-5";
		} else {
			classToShow = "col-lg-12 col-5";
		}
		return classToShow;
	};

	// componentDidUpdate() {
	//   if (this.props.token) {
	//     //
	//   } else {
	//     window.location = "/";
	//   }
	// }

	handleLogout = (e) => {
		e.preventDefault();

		//dispatch an action here
		this.props.logOut();
	};

	handleRefresh = (e) => {
		e.preventDefault();
		this.props.getInfo(this.props.token, this.props.currentPage);
	};

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};

	closeModal = () => {
		this.setState({
			display: false,
		});
	};

	handleSearch = () => {
		// alert(this.state.search_word);
		this.props.setWord(this.state.search_word);
	};

	render() {
		return (
			<>
				<nav
					class="navbar navbar-top navbar-expand navbar-dark border-bottom"
					style={{ backgroundColor: "#0038ba" }}
				>
					<div class="container-fluid">
						<div
							class="collapse navbar-collapse"
							id="navbarSupportedContent"
						>
							<form
								class="navbar-search navbar-search-light form-inline mr-sm-3"
								id="navbar-search-main"
							>
								<div class="form-group mb-0">
									<div class="input-group input-group-alternative input-group-merge">
										<div class="input-group-prepend">
											<span class="input-group-text">
												<i
													class="fas fa-search"
													onClick={this.handleSearch}
												></i>
											</span>
										</div>
										<input
											class="form-control"
											placeholder="Search"
											type="text"
											name="search"
											value={this.state.search_word}
											onChange={(e) =>
												this.setState({
													search_word: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<button
									type="button"
									class="close"
									data-action="search-close"
									data-target="#navbar-search-main"
									aria-label="Close"
								>
									<span aria-hidden="true">×</span>
								</button>
							</form>
							<ul class="navbar-nav align-items-center  ml-md-auto ">
								<li class="nav-item d-xl-none">
									<div
										class="pr-3 sidenav-toggler sidenav-toggler-dark"
										data-action="sidenav-pin"
										data-target="#sidenav-main"
									>
										<div class="sidenav-toggler-inner">
											<i class="sidenav-toggler-line"></i>
											<i class="sidenav-toggler-line"></i>
											<i class="sidenav-toggler-line"></i>
										</div>
									</div>
								</li>
								<li class="nav-item d-sm-none">
									<a
										class="nav-link"
										href="#"
										data-action="search-show"
										data-target="#navbar-search-main"
									>
										<i class="ni ni-zoom-split-in"></i>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a
										class="nav-link"
										href="#"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i class="ni ni-bell-55"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden">
										<div class="px-3 py-3">
											<h6 class="text-sm text-muted m-0">
												You have{" "}
												<strong class="text-primary">
													13
												</strong>{" "}
												notifications.
											</h6>
										</div>
										<div class="list-group list-group-flush">
											<a
												href="#!"
												class="list-group-item list-group-item-action"
											>
												<div class="row align-items-center">
													<div class="col-auto">
														<img
															alt="Image placeholder"
															src="../../assets/img/theme/team-1.jpg"
															class="avatar rounded-circle"
														/>
													</div>
													<div class="col ml--2">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																<h4 class="mb-0 text-sm">
																	John Snow
																</h4>
															</div>
															<div class="text-right text-muted">
																<small>
																	2 hrs ago
																</small>
															</div>
														</div>
														<p class="text-sm mb-0">
															Let's meet at
															Starbucks at 11:30.
															Wdyt?
														</p>
													</div>
												</div>
											</a>
											<a
												href="#!"
												class="list-group-item list-group-item-action"
											>
												<div class="row align-items-center">
													<div class="col-auto">
														<img
															alt="Image placeholder"
															src="../../assets/img/theme/team-2.jpg"
															class="avatar rounded-circle"
														/>
													</div>
													<div class="col ml--2">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																<h4 class="mb-0 text-sm">
																	John Snow
																</h4>
															</div>
															<div class="text-right text-muted">
																<small>
																	3 hrs ago
																</small>
															</div>
														</div>
														<p class="text-sm mb-0">
															A new issue has been
															reported for
															Getripay.
														</p>
													</div>
												</div>
											</a>
											<a
												href="#!"
												class="list-group-item list-group-item-action"
											>
												<div class="row align-items-center">
													<div class="col-auto">
														<img
															alt="Image placeholder"
															src="../../assets/img/theme/team-3.jpg"
															class="avatar rounded-circle"
														/>
													</div>
													<div class="col ml--2">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																<h4 class="mb-0 text-sm">
																	Admin
																</h4>
															</div>
															<div class="text-right text-muted">
																<small>
																	5 hrs ago
																</small>
															</div>
														</div>
														<p class="text-sm mb-0">
															Your posts have been
															liked a lot.
														</p>
													</div>
												</div>
											</a>
											<a
												href="#!"
												class="list-group-item list-group-item-action"
											>
												<div class="row align-items-center">
													<div class="col-auto">
														<img
															alt="Image placeholder"
															src="../../assets/img/theme/team-4.jpg"
															class="avatar rounded-circle"
														/>
													</div>
													<div class="col ml--2">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																<h4 class="mb-0 text-sm">
																	John Snow
																</h4>
															</div>
															<div class="text-right text-muted">
																<small>
																	2 hrs ago
																</small>
															</div>
														</div>
														<p class="text-sm mb-0">
															Let's meet at
															Starbucks at 11:30.
															Wdyt?
														</p>
													</div>
												</div>
											</a>
											<a
												href="#!"
												class="list-group-item list-group-item-action"
											>
												<div class="row align-items-center">
													<div class="col-auto">
														<img
															alt="Image placeholder"
															src="../../assets/img/theme/team-5.jpg"
															class="avatar rounded-circle"
														/>
													</div>
													<div class="col ml--2">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																<h4 class="mb-0 text-sm">
																	John Snow
																</h4>
															</div>
															<div class="text-right text-muted">
																<small>
																	3 hrs ago
																</small>
															</div>
														</div>
														<p class="text-sm mb-0">
															A new issue has been
															reported for Argon.
														</p>
													</div>
												</div>
											</a>
										</div>
										<a
											href="#!"
											class="dropdown-item text-center text-primary font-weight-bold py-3"
										>
											View all
										</a>
									</div>
								</li>
							</ul>
							<ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
								<li class="nav-item dropdown">
									<a
										class="nav-link pr-0"
										href="#"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<div class="media align-items-center">
											<span class="avatar avatar-sm rounded-circle">
												<img
													alt="Image placeholder"
													src="../../assets/img/theme/team-4.jpg"
												/>
											</span>
											<div class="media-body  ml-2  d-none d-lg-block">
												<span class="mb-0 text-sm  font-weight-bold">
													John Snow
												</span>
											</div>
										</div>
									</a>
									<div class="dropdown-menu  dropdown-menu-right ">
										<div class="dropdown-header noti-title">
											<h6 class="text-overflow m-0">
												Welcome!
											</h6>
										</div>
										<Link
											to="/profile"
											class="dropdown-item"
										>
											<i class="ni ni-single-02"></i>
											<span>My profile</span>
										</Link>
										<Link
											to="/settings"
											class="dropdown-item"
										>
											<i class="ni ni-settings-gear-65"></i>
											<span>Settings</span>
										</Link>
										<a href="#!" class="dropdown-item">
											<i class="ni ni-calendar-grid-58"></i>
											<span>Activity</span>
										</a>
										<a href="#!" class="dropdown-item">
											<i class="ni ni-support-16"></i>
											<span>Support</span>
										</a>
										<div class="dropdown-divider"></div>
										<a
											href="#!"
											class="dropdown-item"
											onClick={this.handleLogout}
										>
											<i class="ni ni-user-run"></i>
											<span>Logout</span>
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</nav>
				{!this.props.settings && (
					<div
						class="header pb-6"
						style={{ backgroundColor: "#0038ba" }}
					>
						<div class="container-fluid">
							<div class="header-body">
								<div
									className={
										this.props.report
											? "row align-items-center py-0"
											: "row align-items-center py-4"
									}
								>
									<div class="col-lg-3 col-4">
										<nav
											aria-label="breadcrumb"
											class="d-none d-md-inline-block ml-md-4"
										>
											<ol class="breadcrumb breadcrumb-links breadcrumb-dark">
												<li class="breadcrumb-item">
													<a href="#">
														<i class="fas fa-home"></i>
													</a>
												</li>
												<li class="breadcrumb-item">
													<a href="/home">
														Dashboard
													</a>
												</li>
												<li
													class="breadcrumb-item active"
													aria-current="page"
												>
													{this.props.submenu}
												</li>
											</ol>
										</nav>
									</div>
									{!this.props.report && (
										<>
											<div class="col-lg-9 col-8 text-right">
												<div className="row">
													{(this.props.submenu ==
														"Transactions" ||
														this.props.submenu ==
															"Bank Transactions") && (
														<div className="col-lg-8 col-7">
															<select
																style={{
																	width: "50%",
																	color: "#fff",
																	border: "1px solid #eee",
																}}
																onChange={(e) =>
																	this.props.filterByStatus(
																		e.target
																			.value
																	)
																}
															>
																<option>
																	filter by...
																</option>
																<option
																	value={
																		this
																			.props
																			.submenu ==
																		"Bank Transactions"
																			? "TS_IN PROGRESS"
																			: "TS_PENDING"
																	}
																>
																	pending
																	transaction
																</option>
																<option value="TS_FAILED">
																	failed
																	transaction
																</option>
																<option value="TS_PAID">
																	successful
																	transaction
																</option>
															</select>
														</div>
													)}
													{this.props.submenu ==
														"All Users" && (
														<div className="col-md-6">
															<select
																style={{
																	border: "1px solid #eee",
																	color: "#fff",
																}}
																onChange={(e) =>
																	this.props.search(
																		e.target
																			.value
																	)
																}
															>
																<option>
																	filter users
																	by...
																</option>
																<option value="verify-users">
																	List of
																	Verified
																	Users
																</option>
																<option value="un-verify-users">
																	List of
																	Unverified
																	Users
																</option>
																<option value="users-that-have-bvn">
																	List of
																	Users with
																	BVN
																</option>
																<option value="users-that-do-not-have-bvn">
																	List of
																	Users
																	without BVN
																</option>
															</select>
														</div>
													)}

													{this.props.submenu ==
														"New Accounts" && (
														<div className="col-md-6">
															<select
																style={{
																	border: "1px solid #eee",
																	color: "#fff",
																}}
																onChange={(e) =>
																	this.props.search(
																		e.target
																			.value
																	)
																}
															>
																<option>
																	filter by...
																</option>
																<option value="approved">
																	Approved
																</option>
																<option value="not-approved">
																	Not approved
																</option>
															</select>
														</div>
													)}
													<div
														className={this._filterclass()}
														style={{
															margin: "auto",
														}}
													>
														{!this.props
															.shouldRefresh && (
															<a
																href=""
																class="btn btn-sm btn-neutral"
																onClick={
																	this
																		.handleRefresh
																}
															>
																<i class="fas fa-sync-alt"></i>{" "}
																Refresh
															</a>
														)}
														{this.props
															.shouldFilter ==
															true && (
															<a
																href="#"
																class="btn btn-sm btn-neutral"
																onClick={
																	this.props
																		.showModal
																}
															>
																<i className="fa fa-filter"></i>{" "}
																Filter
															</a>
														)}
														{(this.props.submenu ==
															"All Admins" ||
															this.props
																.submenu ==
																"Customer Category" ||
															this.props
																.submenu ==
																"All Agents") && (
															<a
																href="#"
																class="btn btn-sm btn-neutral"
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	this.props.addNew();
																}}
															>
																<i className="fa fa-plus"></i>{" "}
																Add new
															</a>
														)}
														{this.props.iscsv ==
															true && (
															<CSVLink
																{...this.props
																	.csvreport}
																asyncOnClick={
																	true
																}
																className="btn btn-sm btn-neutral"
																onClick={
																	this.props
																		.downloadCSV
																}
															>
																<i className="fa fa-export"></i>{" "}
																Export CSV
															</CSVLink>
														)}
													</div>
												</div>
											</div>
										</>
									)}
									{this.props.report && (
										<div class="col-lg-12 col-12 mt-4">
											<div
												className="filter-input"
												style={{
													width: "20%",
													backgroundColor: "#fff",
													display: "inline-block",
												}}
											>
												<input
													type="date"
													name="date"
													value={this.state.date}
													onChange={this.handleChange}
												/>
											</div>
											<div
												style={{
													display: "inline-block",
													padding: "0 .8em",
												}}
											>
												<button
													onClick={() =>
														this.props.handleReport(
															this.state.date
														)
													}
												>
													<i className="fa fa-arrow-right text-white"></i>
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<Modal
							closeModal={this.props.closeModal}
							modalTitle="Filter"
							display={
								this.props.display === true ? "block" : "none"
							}
						>
							<div
								className="filter-div"
								style={{ padding: "1em" }}
							>
								<div className="row">
									{this.props.modalinfo}
								</div>
							</div>
						</Modal>
					</div>
				)}
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.login.token,
});
export default connect(mapStateToProps, { logOut, setWord })(DashboardNavBar);
