import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/Accessories";

class UserCategoryModal extends React.Component {
  state = {
    name: "",
  };

  handleChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submitCategory(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Set Category">
        <div className="body-grid">
          <div className="box box9">
            <label>Category Name</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Set Category
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(UserCategoryModal);
