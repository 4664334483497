import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import KycLevelModal from "./KycLevelModal";
import {
	setKycLevel,
	getKycLevel,
	deleteKycLevel,
	updateKycLevel,
} from "../../../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../../../components/Accessories";
import {
	KycLevelHead,
	KycLevelBody,
} from "../../../../../components/Rows/KycTable";
import EditKycLevelModal from "./EditKycLevel";
import KycLevelDetail from "./KycLevelDetail";

class KycLevel extends React.Component {
	state = {
		display: false,
	};

	componentWillReceiveProps(nextProps) {}

	componentDidMount() {
		this.props.getKycLevel(this.props.token);
	}

	handleRefresh = (e) => {
		e.preventDefault();
		this.props.getKycLevel(this.props.token);
	};

	showModal = (e) => {
		e.preventDefault();
		this.setState({
			display: true,
		});
	};

	closeModal = () => {
		this.setState({
			display: false,
			editing: false,
			detailsview: false,
		});
	};

	addKycLevel = (data) => {
		this.props.setKycLevel(data, this.props.token);
	};

	goToNextPage = (props) => {
		const { id } = props;
		this.props.history.push({
			pathname: `${this.props.match.url}/${id}/details`,
			state: id,
		});
	};

	viewDetails = (props) => {
		const { id } = props;
		this.setState({
			...this.state,
			detailsview: true,
			idToView: id,
		});
	};

	deleteLevel = (props) => {
		callBackMethod(
			this.props.deleteKycLevel(
				`kyc_levels/${props.id}`,
				this.props.token
			)
		)
			.then(() => alert(this.props.response))
			.then(() => this.props.getKycLevel(this.props.token));
	};

	editLevel = (props) => {
		this.setState({
			...this.state,
			editing: true,
			idToEdit: props.id,
			level_props: props,
		});
	};

	editKycLevel = (data) => {
		// alert(JSON.stringify(data));
		callBackMethod(
			this.props.updateKycLevel(
				`kyc_levels/${this.state.idToEdit}`,
				data,
				this.props.token
			)
		)
			.then(() => alert(this.props.response))
			.then(() =>
				this.setState({
					editing: false,
				})
			)
			.then(() => this.props.getKycLevel(this.props.token));
	};

	render() {
		let itemToDisplay;
		if (this.props.loading) {
			itemToDisplay = <Loader loading={this.props.loading} />;
		} else {
			itemToDisplay = (
				<>
					<KycLevelHead />
					<tbody>
						{this.props.levels &&
							this.props.levels.map((content, index) => (
								<KycLevelBody
									{...content}
									goToNextPage={this.viewDetails}
									index={index + 1}
									deleteLevel={this.deleteLevel}
									editLevel={this.editLevel}
								/>
							))}
					</tbody>
				</>
			);
		}
		return (
			<>
				<KycBar {...this.props}>
					<div
						class="col-lg-12 col-5 text-right"
						style={{ padding: "0 0.5em 1em" }}
					>
						<a
							href="#"
							class="btn btn-sm btn-neutral"
							onClick={this.handleRefresh}
						>
							<i class="fas fa-sync-alt"></i> Refresh
						</a>

						<a
							href="#"
							class="btn btn-sm btn-neutral"
							onClick={this.showModal}
						>
							<i class="fas fa-plus"></i> New
						</a>
					</div>
					<div className="table-responsive py-4">
						<table
							className="table table-flush"
							id="datatable-basic"
						>
							{itemToDisplay}
						</table>
					</div>
				</KycBar>
				<KycLevelModal
					display={this.state.display}
					closeModal={this.closeModal}
					setLevel={this.addKycLevel}
				/>
				<EditKycLevelModal
					display={this.state.editing}
					closeModal={this.closeModal}
					editKycLevel={this.editKycLevel}
					levelProps={this.state.level_props}
				/>
				<KycLevelDetail
					display={this.state.detailsview}
					closeModal={this.closeModal}
					kyc_level_id={this.state.idToView}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.kyc.get_kyc_level.loading,
	response_msg: state.kyc.set_kyc_level.response_msg,
	token: state.login.token,
	levels: state.kyc.get_kyc_level.levels,
	response: state.kyc.get_kyc_level.response_msg,
});
export default connect(mapStateToProps, {
	setKycLevel,
	getKycLevel,
	deleteKycLevel,
	updateKycLevel,
})(KycLevel);
