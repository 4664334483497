import React from "react";
import { connect } from "react-redux";
import InventoryBar from "../InventoryBar";
import { getAllInventoryProducts } from "../../../reduxStore/action/action.creator";
import { Loader, Pagination } from "../../../components/Accessories";
import {
  InventoryProductHead,
  InventoryProductBody,
} from "../../../components/Rows/InventoryTable";

class InventoryProduct extends React.Component {
  state = {
    currentPage: 1,
  };

  componentDidMount() {
    this.props.getAllInventoryProducts("/products", this.props.token);
  }

  changePage = (id) => () => {
    this.props.getAllInventoryProducts(
      `/products?page=${id}`,
      this.props.token
    );
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      this.props.getAllInventoryProducts(
        `/products?page=${id}`,
        this.props.token
      );
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      this.props.getAllInventoryProducts(
        `/products?page=${id}`,
        this.props.token
      );

      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  viewStoreProductDetail = (id) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/detail`,
      state: id,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getAllInventoryProducts("/products", this.props.token);
  };

  render() {
    let renderPaginate;
    let itemInTable;

    const total = parseInt(Math.ceil(this.props.total / 15)) || 30;
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InventoryProductHead />
          <tbody>
            {this.props.inventory_products &&
              this.props.inventory_products.products.map((content, index) => (
                <InventoryProductBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  viewStoreProductDetail={this.viewStoreProductDetail}
                />
              ))}
          </tbody>
        </>
      );
    }

    return (
      <>
        <InventoryBar active="products">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
            {renderPaginate}
          </div>
        </InventoryBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.inventory.get_product_inventory.loading,
  inventory_products: state.inventory.get_product_inventory.inventory_products,
  token: state.login.token,
  total: state.inventory.get_product_inventory.inventory_products?.totalItems,
});

export default connect(mapStateToProps, { getAllInventoryProducts })(
  InventoryProduct
);
