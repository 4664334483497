import React from "react";
import {
  loanStatus,
  dateFormatter,
  currencyFormatter,
  decimalFormatter,
} from "../Row";

export const LoansTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Min Amount</th>
      <th>Max Amount</th>
      <th>Penalty</th>
      <th>Max Tenure</th>
      <th>Interest Rate</th>
      <th>Process Fees</th>
      <th style={{ textAlign: "center" }}>Actions</th>
    </tr>
  </thead>
);

export const LoanRequestsTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Account Number</th>
      <th>Loan Amount</th>
      <th>Interest Rate</th>
      <th>Tenure</th>
      <th>Loan Status</th>
      <th>Loan Action</th>
      <th>Application Date</th>
      <th>End Date</th>
      <th style={{ textAlign: "center" }}>Actions</th>
    </tr>
  </thead>
);

/** loan product body row */
export const LoanProductList = (props) => (
  <>
    <tr key={props.index}>
      <td>{props.index}</td>
      <td>{currencyFormatter(props.min_amount)}</td>
      <td>{currencyFormatter(props.max_amount)}</td>
      <td>{props.penalty}</td>
      <td>{`${props.max_tenure} ${props.tenure_type}`}</td>
      <td>{props.interest_rate}</td>
      <td>{props.process_fees}</td>
      <td
        style={{
          display: "flex",
          justifyContent: props.admin ? "" : "center",
          alignItems: "center",
        }}>
        {props.permission.map((permit) => {
          if (permit.permissions_json.loan_edit) {
            return (
              <i
                className="fa fa-pencil"
                style={{ padding: "0 .25em" }}
                onClick={() => props.editField(props.id, props)}></i>
            );
          }
        })}

        <i
          className="fa fa-eye"
          style={{ padding: "0 .3em", cursor: "pointer", color: "#0038ba" }}
          onClick={() => {
            props.goToDetails(props);
          }}></i>
      </td>
    </tr>
  </>
);

/** body rows for all loan request */

export const LoansRequests = (props) => {
  const { buttonColor, status_id, action, badgeColor } = loanStatus(
    props.status_id
  );
  const _decimal_amount = decimalFormatter(parseInt(props.amount));
  const _real_amount = currencyFormatter(_decimal_amount);
  return (
    <>
      <tr key={props.index}>
        <td>{props.index}</td>
        <td>{props.account_number}</td>
        <td>{_real_amount}</td>
        <td>{props.interest_rate}</td>
        <td>{`${props.tenure} ${props.tenure_type}`}</td>
        <td style={{ textAlign: "center" }}>
          <span style={{ marginTop: "0em" }} class={badgeColor}>
            {status_id}
          </span>
        </td>
        <td style={{ textAlign: "center" }}>
          <span
            style={{ margin: "auto" }}
            className={action == "Reject" ? "" : buttonColor}
            onClick={
              action != "Reject" &&
              (() => props.changeLoan(props.id, status_id))
            }>
            {action == "Reject" ? "Completed" : action}
          </span>
          {status_id != "Running" && status_id != "Rejected" && (
            <span
              className="btn btn-danger txn-status"
              onClick={() => props.rejectLoan(props.id)}>
              reject
            </span>
          )}
        </td>
        <td>{dateFormatter(props.created_at)}</td>
        <td>
          {props.end_date ? dateFormatter(props.end_date) : " not specified"}
        </td>
        <td
          style={{
            display: "flex",
            justifyContent: props.admin ? "" : "center",
            alignItems: "center",
          }}>
          <i
            className="fa fa-eye"
            style={{ padding: "0 .3em", cursor: "pointer", color: "#0038ba" }}
            onClick={() => {
              props.goToDetails(props);
            }}></i>
        </td>
      </tr>
    </>
  );
};

export const LoanDetails = (fullInfo) => {
  const props = fullInfo.data;
  const minAmount = currencyFormatter(
    decimalFormatter(parseFloat(props.min_amount))
  );
  const maxAmount = currencyFormatter(
    decimalFormatter(parseFloat(props.max_amount))
  );
  return (
    <>
      <div class="mt-4 ml-3">
        <div style={{ display: "inline-block" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "lightgray",
              marginTop: "1em",
              marginBottom: "0em",
            }}>
            Min Amount
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0em",
            }}>
            {minAmount}
          </p>
        </div>
        <div style={{ display: "inline-block", float: "right" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "lightgray",
              marginTop: "1em",
              marginBottom: "0em",
            }}>
            Max Amount
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0em",
            }}>
            {maxAmount}
          </p>
        </div>
      </div>
      <table class="table table-hover mt-1">
        <tbody>
          <tr>
            <td>Product Name</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {" "}
              {props.name}{" "}
            </td>
          </tr>
          <tr>
            <td>Max Tenure</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.max_tenure}
            </td>
          </tr>
          <tr>
            <td>Tenure Type</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.tenure_type}
            </td>
          </tr>
          <tr>
            <td>Min Credit Score</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.min_credit_score}
            </td>
          </tr>
          <tr>
            <td>Min KYC Level</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.min_kyc_level}
            </td>
          </tr>
          <tr>
            <td>Max Amount</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {maxAmount}
            </td>
          </tr>
          <tr>
            <td>Min Amount</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {minAmount}
            </td>
          </tr>
          <tr>
            <td>Interest Rate</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.interest_rate}
            </td>
          </tr>
          <tr>
            <td>Interest Rate Type</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.interest_rate_type}
            </td>
          </tr>
          <tr>
            <td>Processing Fee</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.process_fees}
            </td>
          </tr>
          <tr>
            <td>Penalty</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.penalty}
            </td>
          </tr>
          <tr>
            <td>System Can Approve ?</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.system_can_approved ? "true" : "false"}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export const LoanReqDetails = (fullInfo) => {
  const props = fullInfo.data;
  const { buttonColor, status_id, badgeColor, action } = loanStatus(
    props.status_id
  );
  return (
    <>
      <div className="body-grid">
        <div
          style={{
            margin: "auto 0",
            textAlign: "left",
          }}
          className="box3">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              color: "lightgray",
              marginTop: "1em",
              marginBottom: "0em",
            }}>
            Loan Amount
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0em",
            }}>
            {currencyFormatter(decimalFormatter(parseInt(props.amount)))}
          </p>
        </div>
        <div
          className="box10"
          style={{
            textAlign: "right",
            margin: "auto 0",
          }}>
          <div style={{ display: "inline-block", padding: "0 1em" }}>
            <span class={badgeColor}>{status_id}</span>
          </div>
          <span
            type="button"
            style={{ fontSize: action == "Reject" ? "13px" : "" }}
            onClick={() => fullInfo.changeLoan(props.id, status_id)}
            className={action == "Reject" ? "" : buttonColor}>
            {action == "Reject" ? "Completed" : action}
          </span>
        </div>
      </div>

      <table class="table table-hover mt-1">
        <tbody>
          <tr>
            <td>Cutomer Account Number</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {props.account_number}
            </td>
          </tr>
          <tr>
            <td>Loan Amount</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {" "}
              {currencyFormatter(decimalFormatter(parseInt(props.amount)))}
            </td>
          </tr>
          <tr>
            <td>Loan Interest Rate</td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
              }}>{`${props.interest_rate} ${props.interest_rate_type}`}</td>
          </tr>
          <tr>
            <td>Loan Tenure</td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
              }}>{`${props.tenure} ${props.tenure_type}`}</td>
          </tr>
          <tr>
            <td>Reason for Loan</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {" "}
              {props.reason ? props.reason : "not stated"}{" "}
            </td>
          </tr>
          <tr>
            <td>Loan Start Date</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {" "}
              {dateFormatter(props.start_date)}{" "}
            </td>
          </tr>
          <tr>
            <td>Loan Application Date</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {dateFormatter(props.created_at)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
