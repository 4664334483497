import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { FeesTableHead, FeeRow } from "../../../../components/Row";
import "../../../../morestyles.css";
import { Modal, Loader } from "../../../../components/Accessories";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import Settings from "../../SettingNavbar";
import {
  getCommission,
  getTransCategory,
  getServiceCode,
  setCommissionProfile,
  detailsInfo,
  toggleCommProf,
} from "../../../../reduxStore/action/action.creator";
import {
  _toggleCommissionProfile,
  _deleteCommProf,
} from "../../../../API/CommissionRequest";
import Swal from "sweetalert2";

class Commission extends React.Component {
  state = {
    comName: "",
    serviceCode: "",
    transCategory: "",
    comValue: "",
    comType: "",
    description: "",
    response: [],
    showShare: false,
    id: "",
    value: "",
    display: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.successmsg) {
      this.setCommissionIsSuccess(nextProps);
    }
  }

  setCommissionIsSuccess = (nextProps) => {
    if (nextProps.successmsg) {
      this.setState({
        display: false,
      });
      Swal.fire({
        title: nextProps.successmsg
          ? "Successfully Added Commission Profile"
          : "An Error occured",
        text: "Do you want to continue",
        icon: "success",
        confirmButtonText: "Close",
      });
    }
    this.props.getCommission();
  };

  componentDidMount() {
    this.props.getCommission(this.props.token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.transcat.length > 0 &&
      prevState.transCategory != this.state.transCategory
    ) {
      this.props.getServiceCode(this.state.transCategory, this.props.token);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  openSharePage = (e) => {
    let sum = 0;
    e.preventDefault();
    this.props.detailsInfo(
      `transaction/get_comm_split_config`,
      this.props.token
    );

    this.props.commissions.map((content) => {
      sum += content.commissionValue;
    });

    this.props.history.push({
      pathname: `${this.props.match.url}/commissionshare`,
      state: sum,
    });
  };

  getTransactionCategory = async () => {
    if (!this.props.transerr) {
      this.props.getTransCategory(this.props.token);
    }
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        display: true,
      },
      this.getTransactionCategory
    );
  };
  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getCommission(this.props.token);
  };

  handleSubmit = () => {
    this.props.setCommissionProfile(this.state, this.props.token);

    Object.keys(this.state).map((key) => {
      if (key !== "display" && key !== "showShare") {
        this.state[key] = "";
      }
    });
  };

  toggleCommission = async (id) => {
    try {
      const res = await _toggleCommissionProfile(id, this.props.token);
      this.props.getCommission(this.props.token);
    } catch (error) {}
  };

  deleteCommProf = async (id) => {
    try {
      const res = await _deleteCommProf(id, this.props.token);
      this.props.getCommission(this.props.token);
    } catch (error) {}
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <thead className="thead-light">
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Commission Type</th>
              <th>Service Code</th>
              <th>Commission Value</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.commissions.map((content, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{content.name}</td>
                <td>{content.commissionType}</td>
                <td>{content.transactionServiceCode}</td>
                <td>{content.commissionValue}</td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {/* <i className="fa fa-pencil"></i> */}
                  <i
                    className="fa fa-trash-o"
                    onClick={() => this.deleteCommProf(content._id)}
                    style={{ padding: "0 .6em" }}></i>
                  <label class="custom-toggle">
                    <input
                      type="checkbox"
                      checked={content.enabled}
                      onChange={() => this.toggleCommission(content._id)}
                    />
                    <span class="custom-toggle-slider rounded-circle"></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <Settings {...this.props} active="Commissions" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--6">
              <div class="row align-items-center py-4">
                <div class="col">
                  <div class="card">
                    <div style={{ padding: "1em" }}>
                      <Tabs id="controlled-tab-example">
                        <Tab
                          eventKey="commission"
                          title="Commission"
                          tabClassName="mTab kycTab">
                          <div
                            className="table-responsive py-4"
                            style={{ maxHeight: "22em" }}>
                            <div
                              class="col-lg-12 col-5 text-right"
                              style={{ padding: "0 0.5em 1em" }}>
                              <a
                                href="#"
                                class="btn btn-sm btn-neutral"
                                onClick={this.handleRefresh}>
                                <i class="fas fa-sync-alt"></i> Refresh
                              </a>
                              <a
                                href="#"
                                class="btn btn-sm btn-neutral"
                                onClick={this.showModal}>
                                <i class="fas fa-plus"></i> New
                              </a>
                              <a
                                href="#"
                                onClick={this.openSharePage}
                                className="btn btn-sm btn-neutral">
                                <i className="fas fa-cog"></i> Config
                              </a>
                            </div>
                            <table
                              className="table table-flush"
                              id="datatable-basic">
                              {itemInTable}
                            </table>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          display={this.state.display ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Commission Profile">
          <div className="formBody">
            <div className="row" style={{ padding: "1em" }}>
              <div className="col-md-6">
                <label for="fee-name">Title</label>
                <div className="input-div">
                  <input
                    type="text"
                    name="comName"
                    onChange={this.handleChange}
                    id=""
                    value={this.state.comName}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label for="fee-name">Transaction Category Code</label>
                <div className="input-div">
                  <select
                    name="transCategory"
                    value={this.state.transCategory}
                    onChange={this.handleChange}>
                    <option></option>
                    {this.props.transcat.map((option) => (
                      <option value={option._id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label for="fee-name">Transaction Service Code</label>
                <div className="input-div">
                  <select
                    name="serviceCode"
                    value={this.state.serviceCode}
                    onChange={this.handleChange}>
                    <option></option>
                    {this.props.servicecode.map((code) => (
                      <option value={code.serviceCode}>
                        {code.serviceCode}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label for="fee-name">Commission Value</label>
                <div className="input-div">
                  <input
                    type="text"
                    name="comValue"
                    onChange={this.handleChange}
                    id=""
                    value={this.state.comValue}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label for="fee-name">Commission Type</label>
                <div className="input-div">
                  <input
                    type="text"
                    name="comType"
                    onChange={this.handleChange}
                    id=""
                    value={this.state.comType}
                  />
                </div>
              </div>
              <div className="col-md-6" style={{ paddingTop: "1em" }}>
                <div className="input-div" style={{ height: "100%" }}>
                  <textarea
                    placeholder="Enter Description here"
                    name="description"
                    onChange={this.handleChange}
                    value={this.state.description}></textarea>
                </div>
              </div>
              <div className="col-md-12" style={{ paddingTop: "1em" }}>
                <div className="text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.handleSubmit}>
                    Add Commission Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  commissions: state.commissions.allcommissions.commissionProfile,
  loading: state.commissions.allcommissions.loading,
  err: state.commissions.allcommissions.failed,
  transcat: state.alltransactions.transcategory.transCat,
  servicecode: state.alltransactions.transcategory.serviceCode,
  transerr: state.alltransactions.transcategory.failed,
  successmsg: state.commissions.setcommissions.successMsg,
  failedmsg: state.commissions.setcommissions.failed,
  toggle: state.commissions.allcommissions.toggle,
  token: state.login.token,
});
export default connect(mapStateToProps, {
  getCommission,
  getTransCategory,
  getServiceCode,
  setCommissionProfile,
  detailsInfo,
  toggleCommProf,
})(Commission);
