import { headers } from "../api.config";

export const setRole = async (data, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/admin_roles`, {
    method: "POST",
    headers: headers(token),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const updateAdRole = async (data, id, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/admin_roles/${id}`, {
    method: "PUT",
    headers: headers(token),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const getRole = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/admin_roles`, {
    headers: headers(token),
  });

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const getPermissions = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/admin_permissions?group_by_module=1`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};
