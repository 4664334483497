import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class AgentModal extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    email: "",
    address: "",
    enabled: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add New Agent">
        <div className="body-grid">
          <div className="box box1">
            <label>FirstName</label>

            <div className="input-div">
              <input
                type="text"
                name="first_name"
                value={this.state.first_name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box2">
            <label>LastName</label>

            <div className="input-div">
              <input
                type="text"
                name="last_name"
                value={this.state.last_name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Phone Number</label>

            <div className="input-div">
              <input
                name="phone_number"
                value={this.state.phone_number}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Password</label>

            <div className="input-div">
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Address</label>

            <div className="input-div">
              <input
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Enabled </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"
                name="enabled"
                checked={this.state.enabled}
                onChange={this.handleCheckBox}
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => this.props.handleSubmit(this.state)}>
                Add new Agent
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
});

export default connect(null, {})(AgentModal);
