import React from "react";
import { connect } from "react-redux";
import WalletBar from "../WalletBar";
import {
  getWalletHistory,
  getWallets,
} from "../../../../../reduxStore/action/action.creator";
import { Loader, Pagination } from "../../../../../components/Accessories";
import {
  WalletHistoryBody,
  WalletHistoryHead,
} from "../../../../../components/Rows/CardTable";
import WalletHistoryModal from "./WalletHistoryModal";

class WalletHistory extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentDidMount() {
    this.props.getWalletHistory(this.state.currentPage, this.props.token);
    this.props.getWallets(this.state.currentPage, this.props.token);
  }

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getWalletHistory(this.state.currentPage, this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getWalletHistory(id, this.props.token);
    } else {
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getWalletHistory(id, this.props.token);
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getWalletHistory(id, this.props.token);
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  render() {
    let renderPaginate;
    let itemToDisplay;
    const total =
      this.props.total && parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));
    if (this.props.loading) {
      itemToDisplay = <Loader loading={this.props.loading} />;
    } else {
      itemToDisplay = (
        <>
          <WalletHistoryHead />
          {this.props.wallet_history &&
            this.props.wallet_history.map((content, index) => (
              <WalletHistoryBody
                {...content}
                index={this.getIndex(index + 1)}
              />
            ))}
        </>
      );
    }
    return (
      <>
        <WalletBar {...this.props} active="Wallet-History">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
            {renderPaginate}
          </div>
        </WalletBar>
        {/* <WalletHistoryModal
          display={this.state.display}
          closeModal={this.closeModal}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet_history: state.card.wallet.wallet_history,
  loading: state.card.wallet.loading,
  token: state.login.token,
  total: state.card.wallet.total,
});

export default connect(mapStateToProps, { getWalletHistory, getWallets })(
  WalletHistory
);
