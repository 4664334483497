import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";

class ConfirmationModal extends React.Component {
  state = {};

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Confirmation">
        <div className="body-grid">
          <div className="box box9">
            <div className="text-center py-4">
              <span>Are you sure want to do this</span>
            </div>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => this.props.changeStatus()}>
                {this.props.status_name == true
                  ? "Terminate Card"
                  : this.props.is_active == true
                  ? "block card"
                  : "unblock card"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(ConfirmationModal);
