import React from "react";
import { dateFormatter, timeFormatter } from "../Row";

export const IncentiveSummaryRow = (props) => (
  <>
    <tr>
      <td>Total Earned Points</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.totalEarnedPoints}
      </td>
    </tr>
    <tr>
      <td>Total Available Points</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.totalAvailablePoints}
      </td>
    </tr>
    <tr>
      <td>Total SignUp Points</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.totalSignUpPoints}
      </td>
    </tr>
    <tr>
      <td>Total Referral Points</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.totalReferralPoints}
      </td>
    </tr>
    <tr>
      <td>Total Transaction Points</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>
        {props.totalTransactionPoints}
      </td>
    </tr>
  </>
);

export const ConversionHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Transfer Loyalty Point</th>
      <th>Transfer Equivalence</th>
      <th>ChargeBack</th>
      <th>ChargeBack Loyalty Point</th>
      <th>Description</th>
      <th>Date Created</th>
      <th>Time Created</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const ConversionBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td style={{ textAlign: "right" }}>
      {props.pointsToTransferRatio.loyaltyPoint}
    </td>
    <td style={{ textAlign: "right" }}>
      {props.pointsToTransferRatio.transferEquivalence}
    </td>
    <td style={{ textAlign: "right" }}>
      {props.chargeBacksToPointsRatio.chargeBack}
    </td>
    <td style={{ textAlign: "right" }}>
      {props.chargeBacksToPointsRatio.loyaltyPoint}
    </td>
    <td>{props.description}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>{timeFormatter(props.createdAt)}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editPoint(props)}></i>
    </td>
  </tr>
);

export const EarnedActionHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Points Earned</th>
      <th>Charge Back Earned</th>
      <th>User Action</th>
      <th>Third Party Id</th>
      <th>Created Date</th>
      {/* <th>Action</th> */}
    </tr>
  </thead>
);

export const EarnedActionBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.pointsEarned}</td>
    <td>{props.chargeBackEarned}</td>
    <td>{props.userAction}</td>
    <td>{props.thirdPartyId}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    {/* <td>
      <i className="fa fa-eye" onClick={() => props.viewDetails(props)}></i>
    </td> */}
  </tr>
);

export const ChargeBackHead = () => (
  <thead className="thead-light">
    <tr>
      <th>SN</th>
      <th>Chargeback Percentage</th>
      <th>Chargeback Cycle In Days</th>
      <th>Chargeback Level</th>
      <th>Chargeback Level Code</th>
      <th>Airtime Purchase Volume</th>
      <th>Maximum Chargeback</th>
      <th>Date Created</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const ChargeBackBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.chargeBackPercentage}</td>
    <td>{props.chargeBackCycleInDays}</td>
    <td>{props.chargeBackLevel}</td>
    <td>{props.chargeBackLevelCode}</td>
    <td>{props.airtimePurchaseVolume}</td>
    <td>{props.maximumChargeBack}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.edit(props)}></i>
      {/* <i className="fa fa-eye px-3" onClick={() => props.view(props)}></i> */}
    </td>
  </tr>
);

export const UserEarnPointHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Points Earned</th>
      <th>ChargeBack Earned</th>
      <th>User Action</th>
      <th>Third Party Id</th>
      <th>Date Created</th>
    </tr>
  </thead>
);

export const UserEarnPointBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.pointsEarned}</td>
    <td>{props.chargeBackEarned}</td>
    <td>{props.userAction}</td>
    <td>{props.thirdPartyId}</td>
    <td>{dateFormatter(props.createdAt)}</td>
  </tr>
);

export const UserInterestHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Accrued Interest</th>
      <th>Last Payout Amount</th>
      <th>Payout Date</th>
    </tr>
  </thead>
);

export const UserInterestBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.accrued_interest}</td>
    <td>{props.last_payout_amount}</td>
    <td>{dateFormatter(props.last_payout_date)}</td>
  </tr>
);
