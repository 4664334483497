import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Settings from "./SettingNavbar";
import {
	FeesRoute,
	KycRoute,
	BeneficiariesRoute,
	CommissionRoute,
	LoanProductsRoute,
	ExtraRoute,
	AuthorizationRoute,
	WalletSettingRoute,
	BillsSetup,
	IncentivesRoute,
	Interest,
} from "./SettingsPage";
import { Error } from "../Error";
import { connect } from "react-redux";
import { RouterConsumer } from "../../context/RouterContext";

const SettingsRoute = ({ match, ...props }) => {
	const [state, setState] = useState({ settings_permissions: [] });

	useEffect(() => {
		props.permission.map((content) => {
			state.settings_permissions.push(content.permissions_json);
		});
	}, []);
	return (
		<>
			<Switch>
				<Route exact path={`${match.path}`}>
					<Redirect to={`${match.path}/kyc`} />
				</Route>
				{/* {state.settings_permissions.includes("Kyc") && ( */}
				<Route path={`${match.path}/kyc`} component={KycRoute} />
				{/* )} */}
				{/* {state.settings_permissions.map((content) => {
          if (content["View Fee Profile"] == true) { */}
				<Route path={`${match.path}/fees`} component={FeesRoute} />;
				{/* }
        })} */}
				<Route
					path={`${match.path}/loans`}
					component={LoanProductsRoute}
				/>
				<Route
					path={`${match.path}/beneficiaries`}
					component={BeneficiariesRoute}
				/>
				{state.settings_permissions.map((content) => {
					if (content["View commission Profile"] == true) {
						return (
							<Route
								path={`${match.path}/commissions`}
								component={CommissionRoute}
							/>
						);
					}
				})}
				<Route path={`${match.path}/extra`} component={ExtraRoute} />
				{/* <Route path={`${match.path}/cards`} component={CardRoute} /> */}
				<Route
					path={`${match.path}/authorization`}
					component={AuthorizationRoute}
				/>
				{/* <Route
        path={`${match.path}/authorization`}
        component={AuthorizationRoute}
      /> */}
				<Route
					path={`${match.path}/wallet`}
					component={WalletSettingRoute}
				/>
				<Route path={`${match.path}/bills`} component={BillsSetup} />
				<Route
					path={`${match.path}/incentives`}
					component={IncentivesRoute}
				/>
				<Route path={`${match.path}/interests`} component={Interest} />
				<Route exact component={Error} />
			</Switch>
		</>
	);
};

const mapStateToProps = (state) => ({
	admin_data: state.login.data,
	permission: state.login.permission,
});

export default connect(mapStateToProps, {})(SettingsRoute);
