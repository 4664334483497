import React from "react";
import "../morestyles.css";
import { currencyFormatter } from "./Row";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";

let paginationStart = 0;
let paginationEnd = 5;
let paginationBtnNum = 1;

export const Loader = (props) => (
  <div
    className="loader"
    style={{
      display: props.loading ? "flex" : "none",
    }}>
    <div className="spinner" style={props.styles}></div>
  </div>
);

export const LoaderTwo = (props) => (
  <>
    {/* <span>Loading</span> */}
    <div class="spinner2">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </>
);

export const Alert = (props) => (
  <>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
      <span class="alert-icon">
        <i class="ni ni-like-2"></i>
      </span>
      <span class="alert-text">
        <strong>Danger!</strong> This is a danger alert—check it out!
      </span>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </>
);

export const Modal = (props) => {
  return (
    <div
      className="modal"
      id="g-modal"
      style={{ display: props.display }}
      onClick={props.onClick}>
      <div
        className="modal-content"
        id="g-content"
        style={{ width: props.size }}>
        <div className="formBody">
          <div className="container_header">
            <div className="topic">
              <h3>{props.modalTitle}</h3>
            </div>
            <div className="close">
              <i className="fa fa-times-circle" onClick={props.closeModal}></i>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const Icon = (props) => (
  <>
    <i className={props.className} style={{ paddingRight: ".3em" }}></i>
    <span>{props.title}</span>
  </>
);

export const Pagination = (props) => {
  if (props.currentPage === paginationEnd) {
    paginationEnd += 1;
    // paginationStart;
  }
  return (
    <div class="pagination">
      <button
        onClick={props.handlePrevious}
        style={{ display: props.currentPage == 1 ? "none" : "block" }}>
        &laquo;
      </button>
      {props.paginateNumber
        .slice(paginationStart, paginationEnd)
        .map((num, index) => (
          <button
            className={index + 1 == props.currentPage ? "active" : ""}
            style={{ display: props.totalData > 15 ? "block" : "none" }}
            id={index + 1}
            onClick={props.handlePagination(index + 1)}>
            {index + paginationBtnNum}
          </button>
        ))}
      <button
        onClick={props.handleNext}
        style={{
          display: props.currentPage == props.total ? "none" : "block",
        }}>
        &raquo;
      </button>
    </div>
  );
};

export const CardHeaderHolder = (props) => (
  <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">{props.children}</div>
      </div>
    </div>
  </div>
);
export const CardBodyHolder = (props) => (
  <div class="container-fluid mt--6">
    <div class="row align-items-center py-4">
      <div class="col">
        <div class="card">
          <div style={{ padding: "1em" }}>{props.children}</div>
        </div>
      </div>
    </div>
  </div>
);

export const SingleCard = (props) => (
  <div class="col-xl-3 col-md-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h6 class="card-title text-uppercase text-muted mb-0">
              {props.topic}
            </h6>
            <span class="h2 font-weight-bold mb-0">
              {props.isTransaction
                ? currencyFormatter(parseInt(props.summedAmount))
                : props.summedAmount}
            </span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
              <i class={props.icon}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const DateRange = (props) => (
  <DateRangePicker onApply={props.handleRange}>
    <input type="text" className="form-control" />
  </DateRangePicker>
);

export const callBackMethod = (cb) => {
  return new Promise((resolve, reject) => {
    process.nextTick(() => resolve(cb));
  });
};

export const LogOutModal = (props) => {
  return (
    <Modal
      display={props.display ? "block" : "none"}
      closeModal={props.closeModal}
      modalTitle="">
      <div className="body-grid">
        <div className="box box9">
          <div className="message">
            <span>You'll be logged out from your account</span>
          </div>
        </div>
        <div className="box box1">
          <div className="btn-class">
            <button onClick={props.logout}>logout</button>
          </div>
        </div>
        <div className="box box2">
          <div className="btn-class">
            <button onClick={props.close}>continue</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
