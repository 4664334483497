import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../components/Accessories";
import { dateFormatter } from "../../../../components/Row";
import { getRateDetail } from "../../../../reduxStore/action/action.creator";

class RateViewModal extends React.Component {
  state = {};

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.display != false &&
      prevProps.display != this.props.display
    ) {
      //   alert(this.props.rateId);
      this.props.getRateDetail(this.props.rateId, this.props.token);
    }
  }

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Viewing Rate List">
        <div className="body-grid">
          <div className="box box1">
            <div>
              <label>Currency</label>
              <div>
                <span>{this.props.a_rate && this.props.a_rate.currency}</span>
              </div>
            </div>
          </div>
          <div className="box box2">
            <div>
              <label>Buying Rate</label>
              <div>
                <span>
                  {this.props.a_rate && this.props.a_rate.buying_rate}
                </span>
              </div>
            </div>
          </div>
          <div className="box box1">
            <div>
              <label>Selling Rate</label>
              <div>
                <span>
                  {this.props.a_rate && this.props.a_rate.selling_rate}
                </span>
              </div>
            </div>
          </div>
          <div className="box box2">
            <div>
              <label>Created At</label>
              <div>
                <span>
                  {this.props.a_rate &&
                    dateFormatter(this.props.a_rate.created_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  a_rate: state.virtualCards.get_rate.a_rate,
  loading: state.virtualCards.get_rate.loading,
  token: state.login.token,
});
export default connect(mapStateToProps, { getRateDetail })(RateViewModal);
