import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  AgentTableHead,
  AgentTableBody,
} from "../../components/Rows/AgentTable";
import {
  addAgent,
  getAgents,
  enableLock,
  disableLock,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import {
  callBackMethod,
  Loader,
  Modal,
  Pagination,
} from "../../components/Accessories";
import AgentModal from "./AgentModal";

class AllUsers extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.props.getAgents(this.props.token, "user/sales_agents");
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      //   this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
    } else {
      this.props.filterUserTable(this.state.filterbyItem, id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleNextPage = (props) => {
    const { id, index } = props;

    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/details`,
      state: id,
    });
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  _searchField = async (item) => {
    // alert(item);
    this.props.filterUserTable(item, 1, this.props.token);
    this.setState({
      ...this.state,
      filterbyItem: item,
    });
  };

  toggleHandler = ({ enabled, id }) => {
    callBackMethod(
      this.props.enableLock(
        `change_status/sales_agents`,
        { sales_agent_id: id },
        this.props.token
      )
    ).then(() => this.props.getAgents(this.props.token, "user/sales_agents"));
  };

  addNew = () => {
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  onSubmit = (data) => {
    this.props.filterAllUsers(data, this.props.token);
  };

  handleSubmit = (data) => {
    callBackMethod(
      this.props.addAgent("user/sales_agents", data, this.props.token)
    )
      .then(() =>
        this.setState({
          display: false,
        })
      )
      .then(() => alert(this.props.agnt_response.response_message))
      .then(() => this.props.getAgents(this.props.token, "user/sales_agents"));
  };

  render() {
    let itemInTable;

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <AgentTableHead />
          <tbody>
            {this.props.agnt_data &&
              this.props.agnt_data.map((content, index) => (
                <AgentTableBody
                  {...content}
                  index={index + 1}
                  toggleHandler={this.toggleHandler}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Agents"
              currentPage={`user/sales_agents?page=${this.state.currentPage}`}
              getInfo={this.props.getAgents}
              search={this._searchField}
              //   shouldFilter={true}
              //   showModal={this.showModal}
              addNew={this.addNew}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">All Agents</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AgentModal
          display={this.state.display}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.alladmins.loading,
  token: state.login.token,
  agnt_response: state.users.alladmins.agent_resp,
  agnt_data: state.users.alladmins.agent_data,
});
export default connect(mapStateToProps, {
  addAgent,
  getAgents,
  enableLock,
  disableLock,
})(AllUsers);
