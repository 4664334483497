import { da } from "date-fns/locale";
import { headers } from "../api.config";

export const getKycDocument = async (page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_documents?page=${page}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const { total } = await data;
    return { data, total };
  }
};

export const ApproveDocument = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}/kyc_level/document`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const finalApproval = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const filterDocumentByStatus = async (status, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_documents?filter=${status}`,
    {
      method: "GET",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const filterDocumentByAccount = async (type, value, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_documents?${type}=${value}`,
    {
      method: "GET",
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const addDocument = async (formData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_documents`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );

  if (response.ok) {
    const result = await response.json();
    return { result };
  }
};
