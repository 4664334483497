import { headers } from "./api.config";

export const fetchAuditLogs = async (token, word, page = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/audit/audit_log/search?words=${word}&page=${page}`,
    {
      method: "POST",
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { success, data } = await response.json();
    // const { total } = await data;
    return { data, success };
  } else {
    const { message } = await response.json();
    throw new Error(message);
  }
};

export default fetchAuditLogs;
