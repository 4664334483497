import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardNavBar from "../../components/DashboardNavbar";
import SideBar from "../../components/SideBar";
import {
  BankTransactTableBody,
  BankTransactTableHead,
} from "../../components/Rows/TransactServiceTable";
import {
  Loader,
  Pagination,
  Modal,
  callBackMethod,
} from "../../components/Accessories";
import {
  getBankTransactionService,
  filterTransactions,
  detailsInfo,
  tabDetail,
  filterBankTranStatus,
  reQuery,
  reverseTransaction,
} from "../../reduxStore/action/action.creator";
import BankTransactModal from "./BankTransactModal";
import DetailsModal from "./DetailsModal";

class BankTransactService extends React.Component {
  state = {
    currentPage: 1,
    display: false,
    display2: false,
    details: {},
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.response_msg != "") {
      this.setState({
        ...this.state,
        display3: true,
      });
      alert(nextProps.response_msg);
      this.props.getBankTransactionService(
        `bank_transfer_transactions?page=${this.state.currentPage}&per_page=15`,
        this.props.token
      );
    }
  }

  componentDidMount() {
    this.props.getBankTransactionService(
      "bank_transfer_transactions?per_page=15",
      this.props.token
    );
  }
  changePage = (id) => () => {
    if (this.props.filtering == true) {
      this.props.filterBankTranStatus(
        this.state.statusValue,
        id,
        this.props.token
      );
    } else {
      this.props.getBankTransactionService(
        `bank_transfer_transactions?page=${id}&per_page=15`,
        this.props.token
      );
    }

    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering == true) {
        this.props.filterBankTranStatus(
          this.state.statusValue,
          id,
          this.props.token
        );
      } else {
        this.props.getBankTransactionService(
          `bank_transfer_transactions?page=${id}&per_page=15`,
          this.props.token
        );
      }

      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering == true) {
        this.props.filterBankTranStatus(
          this.state.statusValue,
          id,
          this.props.token
        );
      } else {
        this.props.getBankTransactionService(
          `bank_transfer_transactions?page=${id}&per_page=15`,
          this.props.token
        );
      }

      this.setState({
        currentPage: id,
      });
    }
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };
  closeModal = () => {
    this.setState({
      ...this.state,
      display: false,
      display2: false,
      display3: false,
    });
  };
  handleFilter = (data) => {
    this.props.filterTransactions(data, this.props.token);
  };
  handleRefresh = () => {
    this.props.getBankTransactionService(
      `bank_transfer_transactions?page=${this.state.currentPage}&per_page=15`, this.props.token
    );
  };

  goToUser = (id) => {
    const location = window.open(`allusers/${id}/details`, "_blank");
    location.focus();
  };

  goToTransaction = () => {};

  showDetails = (detail) => {
    this.setState({
      details: detail,
      display2: true,
    });
  };

  handleRequery = (trans_ref, status, trans_id) => {
    if (status == "Pending" || status == "In Progress") {
      this.props.reQuery(this.props.admin_data.id, trans_ref, this.props.token);
    } else {
      this.setState({
        ...this.state,
        trans_id,
      });
    }
  };

  handleReversal = () => {
    this.props.reverseTransaction(
      this.props.admin_data.id,
      this.state.trans_id,
      this.props.token
    );
  };

  exportCSV = (event, done) => {
    const report = {
      filename: "Bank Transaction Report.csv",
      data: this.props.banktrans,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  filterByStatus = (data) => {
    const status = data.substring(data.indexOf("_") + 1, data.length);
    callBackMethod(this.props.filterBankTranStatus(status, 1, this.props.token)).then(() =>
      this.setState({
        ...this.state,
        statusValue: status,
      })
    );
  };

  render() {
    let itemInTable;
    let renderPaginate;
    const total = Math.ceil(parseInt(this.props.total) / 15);
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.daterange &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <BankTransactTableHead admin="true" />
          {this.props.banktrans &&
            this.props.banktrans.map((content, index) => (
              <tbody>
                <BankTransactTableBody
                  {...content}
                  index={this.getIndex(index + 1)}
                  goToUser={this.goToUser}
                  goToTransaction={this.goToTransaction}
                  showDetails={this.showDetails}
                  handleRequery={this.handleRequery}
                />
              </tbody>
            ))}
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Bank Transactions"
              showModal={this.showModal}
              getInfo={this.handleRefresh}
              filterByStatus={this.filterByStatus}
              range={true}
              shouldFilter={true}
              csvreport={this.state.csvReport}
              downloadCSV={this.exportCSV}
              iscsv={true}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">Bank Transactions</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table
                        className="table table-flush table-striped"
                        id="datatable-basic">
                        {itemInTable}
                      </table>
                      {renderPaginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BankTransactModal
          display={this.state.display}
          closeModal={this.closeModal}
          filter={this.handleFilter}
        />
        <DetailsModal
          data={this.state.details}
          display={this.state.display2}
          closeModal={this.closeModal}
        />
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ width: "100%" }}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Reverse Transaction
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="input-div">
                  <input type="text" placeholder="please enter your password" />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  onClick={this.handleReversal}
                  class="btn btn-primary">
                  Reverse Transaction
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          display={this.state.display3 ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Bank Transaction Status">
          <div className="container" style={{ textAlign: "center" }}>
            {this.props.response_msg &&
              `${
                this.props.response_msg && this.props.response_msg
              }\n your new status is ${this.props.re_data.status}`}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  banktrans: state.alltransactions.banktransactions.banktrans,
  total: state.alltransactions.banktransactions.total,
  loading: state.alltransactions.banktransactions.loading,
  filtering: state.alltransactions.banktransactions.filtering,
  admin_data: state.login.data,
  re_data: state.alltransactions.banktransactions.data,
  response_msg: state.alltransactions.banktransactions.response_msg,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getBankTransactionService,
  filterTransactions,
  detailsInfo,
  tabDetail,
  filterBankTranStatus,
  reQuery,
  reverseTransaction,
})(BankTransactService);
