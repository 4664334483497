import React, { Component } from "react";
import { Modal } from "../../../../../components/Accessories";

class EditChargeBack extends Component {
  state = {
    chargeback_percentage: "",
    chargeback_cycle: "",
    chargeback_level: "",
    chargeback_level_code: "",
    airtime_purchase_volume: "",
    max_chargeback: "",
  };

  componentDidUpdate(prevProps, prevState) {
    //
    if (prevProps.data != this.props.data) {
      this.setState({
        chargeback_percentage: this.props.data.chargeBackPercentage,
        chargeback_cycle: this.props.data.chargeBackCycleInDays,
        chargeback_level: this.props.data.chargeBackLevel,
        chargeback_level_code: this.props.data.chargeBackLevelCode,
        airtime_purchase_volume: this.props.data.airtimePurchaseVolume,
        max_chargeback: this.props.data.maximumChargeBack,
      });
    }
  }

  handleSubmit = () => {
    // alert(JSON.stringify(this.state));
    this.props.submit(this.state);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        modalTitle="Edit Charge Backs"
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>ChargeBack Percentage</label>
            <input
              className="form-control"
              name="chargeback_percentage"
              value={this.state.chargeback_percentage}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>ChargeBack Cycle</label>
            <input
              className="form-control"
              name="chargeback_cycle"
              value={this.state.chargeback_cycle}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>ChargeBack Level</label>
            <input
              className="form-control"
              name="chargeback_level"
              value={this.state.chargeback_level}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>ChargeBack Level Code</label>
            <input
              className="form-control"
              name="chargeback_level_code"
              value={this.state.chargeback_level_code}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Airtime Purchase Volume</label>
            <input
              className="form-control"
              name="airtime_purchase_volume"
              value={this.state.airtime_purchase_volume}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Maximum Chargeback</label>
            <input
              className="form-control"
              name="max_chargeback"
              value={this.state.max_chargeback}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Edit ChargeBack
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default EditChargeBack;
