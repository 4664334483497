import React from "react";
import ExtraBar from "../Extra";

class AboutusSettings extends React.Component {
  render() {
    return (
      <ExtraBar {...this.props} active="about-us">
        <div className="table-responsive py-4">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a href="#" class="btn btn-sm btn-neutral">
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            <a href="#" class="btn btn-sm btn-neutral">
              <i class="fas fa-plus"></i> New
            </a>
          </div>

          <table
            className="table table-flush"
            id="datatable-basic"
            contentEditable></table>
        </div>
      </ExtraBar>
    );
  }
}
export default AboutusSettings;
