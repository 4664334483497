import { headers } from "../api.config";

export const setVirtualCardConfig = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/${url}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const getVirtualCardConfig = async (url, limit, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    if (limit) {
      return { data };
    } else {
      const card_data = await data.data;
      const { total } = await data;
      return { card_data, total };
    }
  }
};

export const getRateCurrency = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/currencies`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const changeCardStatus = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/${url}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message, data } = await response.json();
    return { response_message, data };
  } else {
    const { response_message, data } = await response.json();
    return { response_message, data };
  }
};

export const filterCardTransaction = async (data, page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/virtual_cards_transactions?page=${page}&from=${data.startDate}&to=${data.endDate}&currency=${data.currency}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    const card_data = await data.data;
    const { total } = await data;
    return { card_data, total };
  }
};

export const filterVCard = async (data, page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/virtual_cards?page=${page}&from=${data.startDate}&to=${data.endDate}&currency=${data.currency}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const card_data = await data.data;
    const { total } = await data;
    return { card_data, total };
  }
};

export const updateVirtualCardLimit = async (data, id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/cards/virtual_cards_transaction_limits/${id}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};
