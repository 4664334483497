import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Wallet from "./Wallet/Wallet";
import WalletHistory from "./WalletHistory/WalletHistory";
import { Error } from "../../../Error";

const Show = () => <div>Wassa</div>;

const WalletRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/user-wallet`} />
      </Route>
      <Route exact path={`${match.path}/user-wallet`} component={Wallet} />
      <Route
        exact
        path={`${match.path}/wallet-history`}
        component={WalletHistory}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);

export default WalletRoute;
