import { combineReducers } from "redux";
import {
  ADD_COMPANY,
  ADD_MERCHANT,
  GET_COMPANY,
  GET_INVESTMENT_ROLE,
  SET_INVESTMENT_ROLE,
  GET_MERCHANT,
  GET_INVESTMENT_SUB,
  ADD_INVEST_PRODUCT,
  GET_INVEST_PRODUCT,
  ADD_INVEST_CATEGORY,
  GET_INVEST_CATEGORY,
  GET_INVEST_RETURN,
  GET_INVEST_DOC,
  UPLOAD_IMG,
  APPROVE_INVEST_PROD,
  GET_INVEST_CYCLE,
  GET_A_USER_INVEST_SUB,
  EDIT_INVEST_PROD,
  EDIT_INVEST_PROD_ERROR,
  EDIT_MERCHANT,
  EDIT_COMPANY,
  UPDATE_ROLE,
  UPLOAD_DOC,
  DELETE_INVEST_PROD,
  VIEW_MERCHANT,
  VIEW_INVEST_PROD_DETAIL,
  CHANGE_MERCHANT_STATUS,
  ADD_INVEST_HISTORY,
  GET_INVEST_HISTORY,
  FILTER_INVESTMENT_BY_DATE,
  FILTER_INVESTMENT_BY_STATUS,
} from "../action/action.types";

const setInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_COMPANY:
      return {
        ...state,
        comp_resp: action.payload.response_message,
      };
    case SET_INVESTMENT_ROLE:
      return {
        ...state,
        role_resp: action.payload.response_message,
      };
    case ADD_MERCHANT:
      return {
        ...state,
        merchant_resp: action.payload.response_message,
      };
    case ADD_INVEST_PRODUCT:
      return {
        ...state,
        invest_product_resp: action.payload.response_message,
      };
    case ADD_INVEST_CATEGORY:
      return {
        ...state,
        category_resp: action.payload.response_message,
      };
    case UPLOAD_IMG:
      return {
        ...state,
        upload_resp: action.payload.data,
      };
    case APPROVE_INVEST_PROD:
      return {
        ...state,
        approve_resp: action.payload.response_message,
      };
    case EDIT_INVEST_PROD:
      return {
        ...state,
        edit_prod_resp: action.payload.response_message,
      };
    case EDIT_INVEST_PROD_ERROR:
      return {
        ...state,
        edit_prod_resp: action.payload.errMessage,
      };
    case EDIT_MERCHANT:
      return {
        ...state,
        edit_merchant: action.payload.response_message,
      };
    case EDIT_COMPANY:
      return {
        ...state,
        edit_company_resp: action.payload.response_message,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        edit_role_resp: action.payload.response_message,
      };
    case UPLOAD_DOC:
      return {
        ...state,
        upload_doc_resp: action.payload.data,
      };
    case DELETE_INVEST_PROD:
      return {
        ...state,
        invest_del_resp: action.payload.response_message,
      };
    case CHANGE_MERCHANT_STATUS:
      return {
        ...state,
        merchant_status_resp: action.payload.response_message,
      };
    case ADD_INVEST_HISTORY:
      return {
        ...state,
        invest_prod_history: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getInvestmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY:
      return {
        ...state,
        comp_data: action.payload.data,
        loading: false,
      };
    case GET_INVESTMENT_ROLE:
      return {
        ...state,
        role_data: action.payload.data,
        loading: false,
      };
    case GET_MERCHANT:
      return {
        ...state,
        merchant_data: action.payload.data,
        loading: false,
      };
    case GET_INVESTMENT_SUB:
      return {
        ...state,
        invest_sub: action.payload.data.data,
        loading: false,
      };
    case GET_INVEST_PRODUCT:
      return {
        ...state,
        loading: false,
        filtering: false,
        invest_prod: action.payload.data,
      };
    case FILTER_INVESTMENT_BY_DATE:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_prod: action.payload.data,
      };
    case FILTER_INVESTMENT_BY_STATUS:
      return {
        ...state,
        loading: false,
        filtering: true,
        invest_prod: action.payload.data,
      };
    case GET_INVEST_CATEGORY:
      return {
        ...state,
        loading: false,
        invest_category: action.payload.data,
      };
    case GET_INVEST_RETURN:
      return {
        ...state,
        loading: false,
        invest_return: action.payload.data,
      };
    case GET_INVEST_DOC:
      return {
        ...state,
        loading: false,
        invest_doc: action.payload.data,
      };
    case GET_INVEST_CYCLE:
      return {
        ...state,
        loading: false,
        cycle: action.payload.data,
      };
    case GET_A_USER_INVEST_SUB:
      return {
        ...state,
        loading: false,
        user_sub: action.payload.data,
      };
    case VIEW_MERCHANT:
      return {
        ...state,
        loading: false,
        merchant_detail: action.payload.data,
      };
    case VIEW_INVEST_PROD_DETAIL:
      return {
        ...state,
        loading: false,
        invest_prod_detail: action.payload.data,
      };
    case GET_INVEST_HISTORY:
      return {
        ...state,
        loading: false,
        invest_prod_history_data: action.payload.data,
      };
    default:
      return state;
  }
};

export const investReducer = combineReducers({
  setData: setInvestmentReducer,
  getData: getInvestmentReducer,
});
