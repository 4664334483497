import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import TotalSavingReport from "./TotalSavingReport";

const View = () => <div>Wassa</div>;

const SavingsReportRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}/savings-month`} />
      </Route>
      <Route
        path={`${match.path}/savings-month`}
        component={TotalSavingReport}
      />
    </Switch>
  </>
);
export default SavingsReportRoute;
