import { headers } from "./api.config";

export const addSavingProduct = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_products`,
    {
      headers: headers(token),
      method: "POST",
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};
export const fetchSavingProduct = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_products`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    const saving_data = await data.data;
    return { saving_data };
  }
};

export const filterSavingsProduct = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const { data } = await response.json();
    const saving_data = await data.data;
    return { saving_data };
  }
};

export const removeSavingsProduct = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_products/${id}`,
    {
      headers: headers(token),
      method: "DELETE",
    }
  );
  if (response.ok) {
    const { Response_message } = await response.json();
    return { Response_message };
  }
};

//get wallet data
export const fetchSavingWallet = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/user_wallets`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

//get all user savings
export const fetchUserSavings = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/user_savings`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    let { data } = await response.json();
    data = data.data;
    return { data };
  }
};

export const fetchSavingProductDetail = async (url, token) => {
  const response = await fetch(url, {
    headers: headers(token),
  });
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const disableSavingProduct = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/lock/savings_products`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify({ savings_product_id: id }),
    }
  );
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const setSavingPenalty = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_penalties`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { success } = await response.json();
    return { success };
  }
};
export const fetchSavingPenalty = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_penalties`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const updateSavingPenalty = async (id, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_penalties/${id}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const result = await response.json();
    return result;
  }
};

export const fetchWalletTransaction = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/wallet_transactions`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetch_a_UserSavings = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/user/user_savings/${id}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const updateSavingProduct = async (id, data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_products/${id}`,
    {
      method: "PUT",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const addWithDrawalDate = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/withdrawal_dates`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { success, data, message } = await response.json();
    return { data, success, message };
  }
};

export const fetchWithDrawalDate = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/withdrawal_dates`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
export const fetchSavingHistory = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_histories/${id}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const fetchSavingsGroup = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/all/savings_groups`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    let { data } = await response.json();
    data = data.data;
    return { data };
  }
};

export const setSaveGroup = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/savings_groups`,
    {
      headers: headers(token),
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  if (response.ok) {
    const { success, data, response_message } = await response.json();
    return { success, data, response_message };
  }
};

export const changeSavingWallet = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/savinvest/disable_wallet`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { Response_message } = await response.json();
    return { Response_message };
  }
};
