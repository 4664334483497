import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";
import {
  getLimitConfigs,
  getTransCategory,
  getServiceCode,
  detailsInfo,
} from "../../../../../reduxStore/action/action.creator";
import { getLimitType } from "../../../../../API/limitConfigRequest";

class EditTransactionLimitModal extends React.Component {
  state = {
    limitName: "",
    limitDescription: "",
    transCategory: "",
    code: "",
    limitType: "",
    kycLevel: "",
    limitValue: "",
    limitTypeArray: [],
  };

  componentDidMount() {
    this.getLimitType();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.transcat.length > 0 &&
      prevState.transCategory != this.state.transCategory
    ) {
      const trans_data = this.state.transCategory.split(",");
      this.props.getServiceCode(trans_data[0], this.props.token);
    }
    if (prevProps.limitData != this.props.limitData) {
      this.setState({
        ...this.state,
        limitName: this.props.limitData.name,
        limitDescription: this.props.limitData.description,
        // transCat: this.props.limitData.transactionCategoryId.name,
        // tranService: this.props.limitData.transactionServiceCode,
        kycLevel: this.props.limitData.kycLevel,
        limitValue: this.props.limitData.limit,
      });
    }
  }

  getLimitType = async () => {
    try {
      const res = await getLimitType(this.props.token);
      const data = await res.data.transactionLimitType;
      console.log(data);
      this.setState((prevState) => ({
        limitTypeArray: [...prevState.limitTypeArray, ...data],
      }));
    } catch (error) {}
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEdit = () => {
    this.props.editTransactionLimit(this.state, this.props.token);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Transaction Limit Configuration">
        <div className="body-grid">
          <div className="box box1">
            <label for="fee-name">Limit Configuration Name</label>

            <div className="input-div">
              <input
                type="text"
                name="limitName"
                value={this.state.limitName}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label for="limitDescription">Limit Description</label>

            <div className="input-div">
              <input
                type="text"
                name="limitDescription"
                value={this.state.limitDescription}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Transaction Category</label>

            <div className="input-div">
              <select
                name="transCategory"
                value={this.state.transCategory}
                onChange={this.handleChange}>
                <option></option>

                {this.props.transcat.map((option) => (
                  <option value={`${option._id},${option.categoryCode}`}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box2">
            <label>Transaction Service Code</label>

            <div className="input-div">
              <select
                name="code"
                value={this.state.code}
                onChange={this.handleChange}>
                <option></option>

                {this.props.servicecode.map((code) => (
                  <option value={code.serviceCode}>{code.serviceCode}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box3">
            <label>Limit Type</label>

            <div className="input-div">
              <select
                name="limitType"
                value={this.state.limitType}
                onChange={this.handleChange}>
                <option></option>

                {this.state.limitTypeArray.map((option) => (
                  <option value={option.tnxLimitTypeCode}>{option.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="box box4">
            <label>KYC Level </label>

            <div className="input-div">
              <input
                type="text"
                name="kycLevel"
                value={this.state.kycLevel}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box5">
            <label>Limit Value</label>

            <div className="input-div">
              <input
                type="text"
                name="limitValue"
                value={this.state.limitValue}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <br />

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleEdit}>
                Edit Limit Config
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  limitConfigs: state.limits.allLimits.limitConfigs,
  loading: state.limits.allLimits.loading,
  total: state.limits.allLimits.total,
  err: state.limits.allLimits.failed,
  transcat: state.alltransactions.transcategory.transCat,
  servicecode: state.alltransactions.transcategory.serviceCode,
  transerr: state.alltransactions.transcategory.failed,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getLimitConfigs,
  getTransCategory,
  getServiceCode,
  detailsInfo,
})(EditTransactionLimitModal);
