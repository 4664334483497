import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class UpdateIncentive extends React.Component {
  state = {
    signUpActionsPoint: "",
    referralActionsPoint: "",
    transactionActionsPoint: "",
    otherActionsPoint: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.points != this.props.points) {
      this.setState({
        signUpActionsPoint: this.props.points.totalSignUpPoints,
        referralActionsPoint: this.props.points.totalReferralPoints,
        transactionActionsPoint: this.props.points.totalTransactionPoints,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Update Incentives">
        <div className="body-grid">
          <div className="box box9">
            <label>Enter SignUp Action Point</label>
            <input
              name="signUpActionsPoint"
              className="form-control"
              value={this.state.signUpActionsPoint}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter Refferal Action Point</label>
            <input
              name="referralActionsPoint"
              className="form-control"
              value={this.state.referralActionsPoint}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <label>Enter Transaction Action Point</label>
            <input
              name="transactionActionsPoint"
              className="form-control"
              value={this.state.transactionActionsPoint}
              onChange={this.handleChange}
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Update Points
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(UpdateIncentive);
