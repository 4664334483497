import { tr } from "date-fns/locale";
import React from "react";
import {
  currencyFormatter,
  dateFormatter,
  decimalFormatter,
  timeFormatter,
} from "../Row";

export const CardBatchHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Batch Reference</th>
      <th>Status</th>
      <th>Region</th>
      <th>Admin Id</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const CardBatchBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.batch_ref}</td>
    <td>{props.status}</td>
    <td>{props.region}</td>
    <td>{props.admin_id}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i
        className="fa fa-trash-o"
        style={{ padding: "0 .25em" }}
        onClick={() => props.deleteBatch(props.id)}></i>
      {/* <i
        className="fa fa-pencil"
        style={{ padding: "0 .25em" }}
        onClick={() => props.editPromotion(props)}></i>

      <i
        class="fas fa-eye"
        style={{ paddingLeft: ".5em" }}
        onClick={() => props.goToDetails(props._id)}></i> */}
    </td>
  </tr>
);

//card request table settings
export const CardRequestHead = (props) => (
  <thead className="thead-light">
    <tr>
      {props.checkBoxShow && (
        <th>
          <input type="checkbox" onChange={() => props.checkAll()} />
        </th>
      )}
      <th>S/N</th>
      <th>Customer Name</th>
      <th>Status</th>
      <th>Account Number</th>
      <th>Request Reference</th>
      <th>Account Name</th>
      <th>Account Type</th>
      <th>Batch Id</th>
      <th>Expiration Date</th>
      <th>Phone Number</th>
      <th>Address</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const CardRequestBody = (props) => (
  <tr>
    {props.checkBoxShow && (
      <td>
        <input
          type="checkbox"
          className="form-control"
          checked={props.check_all ? props.check_all : props.individual_check}
          onChange={props.onToggle}
          style={{ display: props.checkBoxShow ? "block" : "none" }}
        />
      </td>
    )}
    <td>{props.index}</td>
    <td>{props.user ? props.user.username : "not given"}</td>
    <td>{props.status}</td>
    <td>{props.account_number}</td>
    <td>{props["req-ref"]}</td>
    <td>{props.account_name ? props.account_name : "not given"}</td>
    <td>{props.account_type ? props.account_type : "not given"}</td>
    <td>{props.batch_id ? props.batch_id : "not given"}</td>
    <td>{props.expiry_date ? props.expiry_date : "not given"}</td>
    <td>{props.user ? props.user.phone_number : "not given"}</td>
    <td>{props.user ? props.user.address : "not given"}</td>
    <td>
      <i
        className="fa fa-trash-o"
        style={{ padding: "0 .25em" }}
        onClick={() => props.deleteRequest(props.id)}></i>
    </td>
  </tr>
);

export const BatchRequestHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
    </tr>
  </thead>
);

export const BatchRequestBody = (props) => (
  <tr>
    <td>{props.index}</td>
  </tr>
);

//virtual card
export const RateTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Currency</th>
      <th>Buying Rate</th>
      <th>Selling Rate</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const RateTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.currency}</td>
    <td>{props.buying_rate}</td>
    <td>{props.selling_rate}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-eye" onClick={() => props.viewRate(props.id)}></i>
      <i className="fa fa-pencil" style={{ padding: "0 1em" }}></i>
    </td>
  </tr>
);

export const KycVirtualCardHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Kyc Level</th>
      <th>Can Request Card</th>
      <th>Maximum Amount this Kyc can Fund</th>
      <th>Card Request Fees</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

const getLevelName = (props) => {
  const kyc_level_name =
    props.kyc_levels &&
    props.kyc_levels.map((content) => {
      if (content.level == props.kyc_level) {
        return content.level_name;
      }
    });
  return kyc_level_name;
};

export const KycVirtualCardBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{getLevelName(props)}</td>
    <td>{props.can_request ? "Yes" : "No"}</td>
    <td>{props.max_amount_it_can_fund}</td>
    <td>{props.card_request_fees}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i className="fa fa-trash-o"></i>
      <i
        className="fa fa-pencil"
        style={{ padding: "0 1em" }}
        onClick={() => props.edit(props)}></i>
    </td>
  </tr>
);

export const VirtualLimitTableHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Daily Limit</th>
      <th>Weekly Limit</th>
      <th>Monthly Limit</th>
      <th>Yearly Limit</th>
      <th>Total Limit</th>
      <th>Currency</th>
      <th>Created By</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const VirtualLimitTableBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.daily_limit}</td>
    <td>{props.weekly_limit}</td>
    <td>{props.monthly_limit}</td>
    <td>{currencyFormatter(props.yearly_limit)}</td>
    <td>{currencyFormatter(props.total_limit)}</td>
    <td>{props.currency}</td>
    <td>
      {props.admin_data.id == props.created_by
        ? props.admin_data.username
        : props.created_by}
    </td>
    <td>
      <i className="fa fa-pencil" onClick={() => props.editLimit(props)}></i>
    </td>
  </tr>
);

export const VirtualTransactionHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User</th>
      {/* <th>Card Id</th> */}
      <th>Transaction Type</th>
      <th>Rate</th>
      <th>Amount In Naira</th>
      <th>Amount In Other Currency</th>
      <th>Amount</th>
      <th>Currency</th>
    </tr>
  </thead>
);

export const VirtualTransactionBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.user && props.user.username}</td>
    {/* <td>{props.card_id == null ? "Not given" : props.card_id}</td> */}
    <td>{props.transaction_type}</td>
    <td>{props.rate}</td>
    <td>
      {currencyFormatter(decimalFormatter(parseFloat(props.amount_in_naira)))}
    </td>
    <td>{props.amount_in_other_currency}</td>
    <td>{props.amount}</td>
    <td>{props.currency}</td>
  </tr>
);

export const VirtualCardHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      {/* <th>Card Id</th> */}
      <th>Customers</th>
      <th>Account Id</th>
      <th>Amount</th>
      <th>Currency</th>
      <th>Expiration</th>
      <th>Date Created</th>
      <th>Card Type</th>
      <th>Masked Pan</th>
      <th>Status</th>
      {props.userdetails != true && (
        <>
          <th>Action</th>
          <th>More Action</th>
        </>
      )}
    </tr>
  </thead>
);

export const VirtualCardBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.user && props.user.username}</td>
    {/* <td>{props.card_id}</td> */}
    <td>{props.account_id}</td>
    <td>{currencyFormatter(props.amount)}</td>
    <td>{currencyFormatter(props.currency)}</td>
    <td>{dateFormatter(props.expiration)}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{props.card_type}</td>
    <td>{props.masked_pan}</td>
    <td>{props.status_name}</td>
    {props.userdetails != true && (
      <>
        <td
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <i className="fa fa-eye" style={{ padding: "0 .25em" }}></i>
          <label style={{ display: "inline-block" }} class="custom-toggle">
            <input
              type="checkbox"
              name="is_required"
              checked={props.is_active}
              onChange={() => props.toggleHandler(props, false)}
            />
            <span class="custom-toggle-slider rounded-circle"></span>
          </label>
        </td>
        <td>
          {props.status_name != "terminated" && (
            <button onClick={() => props.toggleHandler(props, true)}>
              Terminate Card
            </button>
          )}
        </td>
      </>
    )}
  </tr>
);

export const WalletHistoryHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Transaction Reference</th>
      <th>Amount</th>
      <th>Previous Balance</th>
      <th>User Type</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const WalletHistoryBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.transaction_ref}</td>
    <td>{currencyFormatter(props.amount)}</td>
    <td>{currencyFormatter(props.prev_balance)}</td>
    <td>{props.user_type}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>
      <i
        className="fa fa-trash-o"
        onClick={() => props.deleteWalletHistory(props)}></i>
    </td>
  </tr>
);

export const WalletHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Currency</th>
      <th>Account Balance</th>
      <th>Ledger Balance</th>
      <th>Date Created</th>
      <th>Time Created</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const WalletBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.currency}</td>
    <td>{currencyFormatter(props.account_balance)}</td>
    <td>{currencyFormatter(props.ledger_balance)}</td>
    <td>{dateFormatter(props.created_at)}</td>
    <td>{timeFormatter(props.created_at)}</td>
    <td></td>
  </tr>
);
