import React from "react";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import "../../morestyles.css";
import {
  Loader,
  Icon,
  CardBodyHolder,
  Modal,
} from "../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class InvestmentBar extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar submenu="Investment" shouldRefresh="false" />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="user-investment"
                title={
                  <Link to={`/investments/user-investment`}>
                    <Icon className="" title="User Investment" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="returns"
                title={
                  <Link to={`/investments/investment_return`}>
                    <Icon className="" title="Investment Return" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="investment-config"
                title={
                  <Link to={`/investments/investment-config`}>
                    <Icon className="" title="Investment Config" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="merchant"
                title={
                  <Link to={`/investments/merchant`}>
                    <Icon className="" title="Merchant Setup" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="company"
                title={
                  <Link to={`/investments/company`}>
                    <Icon className="" title="Company Setup" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="roles"
                title={
                  <Link to={`/investments/roles`}>
                    <Icon className="" title="Roles" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="category"
                title={
                  <Link to={`/investments/category`}>
                    <Icon className="" title="Category" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="document"
                title={
                  <Link to={`/investments/invest-document`}>
                    <Icon className="" title="Investment Document" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="history"
                title={
                  <Link to={`/investments/invest-history`}>
                    <Icon className="" title="Investment History" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}
export const ConfirmationModal = (props) => (
  <Modal
    display={props.display ? "block" : "none"}
    closeModal={props.closeModal}
    modalTitle={props.title}>
    <div className="body-grid">
      <div className="box box9 text-center my-4">
        <span className="">Are you sure about this operation</span>
      </div>
      <div className="box box1">
        <div className="text-center">
          <button type="button" class="btn btn-primary" onClick={props.action}>
            {props.actionText}
          </button>
        </div>
      </div>
      <div className="box box2">
        <div className="text-center">
          <button
            type="button"
            class="btn btn-danger"
            onClick={props.closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default InvestmentBar;
