import React from "react";
import { connect } from "react-redux";
import { Modal, DateRange } from "../../../../components/Accessories";

class FilterModal extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    name: "",
    currency: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  handleSubmit = () => {
    this.props.submitFilter(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Confirmation">
        <div className="body-grid">
          <div className="box box9">
            <label>Select Date Range</label>
            <DateRange handleRange={this.handleRange} />
          </div>
          <div className="box box9">
            <label>User Name</label>
            <input
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              className="form-control"
              placeholder="filter by user name"
            />
          </div>
          <div className="box box9">
            <label>Currency</label>
            <select
              className="form-control"
              name="currency"
              value={this.state.currency}
              onChange={this.handleChange}>
              <option></option>
              {this.props.currency &&
                this.props.currency.map((content) => (
                  <option value={content.currency_code}>
                    {content.currency_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Filter Virtual Card
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  currency: state.virtualCards.get_rate.currency,
});
export default connect(mapStateToProps, {})(FilterModal);
