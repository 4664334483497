import React from "react";

class PermissionForm extends React.Component {
  state = {
    name: "",
    description: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submitPermission(this.state);
  };

  render() {
    return (
      <div className="content" style={{ paddingTop: "1em" }}>
        <div className="" style={{ padding: ".8em 0" }}>
          <div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Name of Permission
              </label>
              <input
                type="text"
                class="form-control"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter name"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">
                Enter Description of Permission
              </label>
              <textarea
                type="text"
                class="form-control"
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                id="exampleFormControlInput1"
                placeholder="enter description"
              />
            </div>
          </div>
          <div style={{ paddingTop: "1em" }}>
            <button
              type="button"
              class="btn btn-primary"
              onClick={this.handleSubmit}>
              Insert Permission
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PermissionForm;
