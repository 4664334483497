import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import FeeSharing from "./FeeSharing";
import LoanProducts from "./LoanProducts";
import LoanProductDetails from "./loanProductDetail";
import LoanRequestDetails from "../../../LoanRequest/loanRequestDetail";
import { Error } from "../../../Error";

const LoanProductsRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={LoanProducts} />
      <Route
        exact
        path={`${match.path}/:name/details`}
        component={LoanProductDetails}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);

export default LoanProductsRoute;
