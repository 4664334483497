import React from "react";
import { connect } from "react-redux";
import { BatchesHead } from "../../../components/Rows/BulkPaymentTable";
import BulkPaymentBar from "../BulkPaymentBar";
import { getAllBatches } from "../../../reduxStore/action/action.creator";

class BulkTransaction extends React.Component {
  componentDidMount() {
    this.props.getAllBatches(``, this.props.token);
  }

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = "loading...";
    } else {
      itemInTable = (
        <>
          <BatchesHead />
          <tbody>{}</tbody>
        </>
      );
    }
    return (
      <BulkPaymentBar active="bulk-transaction">
        <div
          class="col-lg-12 col-5 text-right"
          style={{ padding: "0 0.5em 1em" }}>
          {/* <a
            href="#"
            class="btn btn-sm btn-neutral"
            onClick={this.handleRefresh}>
            <i class="fas fa-sync-alt"></i> Refresh
          </a> */}

          {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
                <i class="fas fa-plus"></i> New
              </a> */}
        </div>
        <div className="table-responsive py-4 table-div">
          <table className="table table-flush" id="datatable-basic">
            {itemInTable}
          </table>
        </div>
      </BulkPaymentBar>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
});

export default connect(mapStateToProps, { getAllBatches })(BulkTransaction);
