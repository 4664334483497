import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/Accessories";

class AddCode extends React.Component {
  state = {
    services_code_id: "",
    provider_code_id: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        modalTitle="Add Code Config"
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Service Code</label>
            <select
              className="form-control"
              name="services_code_id"
              value={this.state.services_code_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.bills_service_code &&
                this.props.bills_service_code.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <label>Provider Code</label>
            <select
              className="form-control"
              name="provider_code_id"
              value={this.state.provider_code_id}
              onChange={this.handleChange}>
              <option></option>
              {this.props.provider_code &&
                this.props.provider_code.map((content) => (
                  <option value={content.id}>{content.name}</option>
                ))}
            </select>
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Save Code Config
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  bills_service_code: state.bills.billconfig.bills_service_code,
  provider_code: state.bills.billconfig.provider_code,
});

export default connect(mapStateToProps, {})(AddCode);
