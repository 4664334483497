import { headers } from "./api.config";

let summaryArray = [];
let commissionArray = [];
let userArray = [];
let notification = [];

const totalUserSummary = async (url, jsonData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return {
      summedAmount: result.data,
      topic: jsonData.topic,
      icon: jsonData.icon,
    };
  }
};

export const UserReportRequest = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/summary-of-customer-details`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const filterUserReportRequest = async (year, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/summary-of-customer-details`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify({ year }),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const TransactionReportRequest = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { transactionsArr } = await response.json();
    return { transactionsArr };
  }
};

export const InstitutionReport = async (url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { transactionsArr } = await response.json();
    return { transactionsArr };
  }
};

const allTransSummary = async (url, jsonData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/${url}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return {
      summedAmount: result.data.summedAmount,
      topic: jsonData.topic,
      icon: jsonData.icon,
    };
  }
};

const allNotificationReport = async (jsonData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/notification/get_delivery_rate`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const result = await response.json();
    return {
      summedAmount: result.data[jsonData.sum],
      topic: jsonData.topic,
      icon: jsonData.icon,
    };
  }
};

export const SummaryData = async (date, token) => {
  const today = new Date().toISOString().substring(0, 10);
  const total_daily_txns = await allTransSummary(
    `total_daily_txns?date=${date ? date : today}`,
    {
      topic: "Daily Transaction",
    },
    token
  );
  const bill_daily = await allTransSummary(
    `daily_bill_payment_txns?date=${date ? date : today}`,
    {
      topic: "Daily Bills Payment Transactions",
    },
    token
  );
  const daily_transfer = await allTransSummary(
    `daily_transfer_txns?date=${date ? date : today}`,
    {
      topic: "Daily Transfer Transactions",
    },
    token
  );
  const daily_fees = await allTransSummary(
    `daily_fees_txns?date=${date ? date : today}`,
    {
      topic: "Daily Fee Transactions",
    },
    token
  );
  const daily_commission = await allTransSummary(
    `daily_commissions?date=${date ? date : today}`,
    {
      topic: "Daily Commission",
    },
    token
  );
  const daily_loan_trans = await allTransSummary(
    `daily_loan_txns?date=${date ? date : today}`,
    {
      topic: "Daily Loan Transactions",
    },
    token
  );
  const daily_acct_fund_trans = await allTransSummary(
    `daily_acc_funding_txns?date=${date ? date : today}`,
    {
      topic: "Daily Account Funding Transactions",
    },
    token
  );
  const all_time_commission = await allTransSummary(
    `all_time_commissions?date=${date ? date : today}`,
    {
      topic: "All time Commissions Paid",
    },
    token
  );
  const daily_ref_bonus = await allTransSummary(
    `daily_referral_bonuses?date=${date ? date : today}`,
    {
      topic: "Daily Referral Bonuses",
    },
    token
  );
  const all_time_ref_bonus = await allTransSummary(
    `all_time_referral_bonues?date=${date ? date : today}`,
    {
      topic: "All Time Referral Bonuses",
    },
    token
  );
  const daily_signup_bonus = await allTransSummary(
    `daily_signup_bonues?date=${date ? date : today}`,
    {
      topic: "Daily SignUp Bonuses",
    },
    token
  );
  const all_time_signup_bonus = await allTransSummary(
    `all_time_signup_bonues?date=${date ? date : today}`,
    { topic: "All Time Signup Bonuses" },
    token
  );
  const user_with_verified_acct = await totalUserSummary(
    `count-users-that-have-verify-account/${date}/${today}`,
    {
      topic: "Verified Users",
      icon: "ni ni-money-coins",
    },
    token
  );
  const user_with_unverfied = await totalUserSummary(
    `get-users-that-have-not-verify-account/${date}/${today}`,
    {
      topic: "Unverfied Users",
      icon: "ni ni-active-40",
    },
    token
  );
  const total_users = await totalUserSummary(
    `number-of-all-users/${date}/${today}`,
    {
      topic: "All Users",
      icon: "",
    },
    token
  );
  const user_with_referral = await totalUserSummary(
    `number-of-users-sign-up-with-referral/${date}/${today}`,
    {
      topic: "Referral SignUps",
      icon: "",
    },
    token
  );
  const users_with_bvn = await totalUserSummary(
    `number-of-users-that-have-bvn/${date}/${today}`,
    {
      topic: "BVN users",
      icon: "",
    },
    token
  );

  // const success_notification = await allNotificationReport(
  //   {
  //     sum: "totalSuccessfulNotifications",
  //     topic: "Successful Notification",
  //     icon: "",
  //   },
  //   token
  // );
  // const failed_notification = await allNotificationReport(
  //   {
  //     sum: "totalFailedNotifications",
  //     topic: "Failed Notification",
  //     icon: "",
  //   },
  //   token
  // );

  // const allNotification = [success_notification, failed_notification];
  const allTransSum = [
    total_daily_txns,
    bill_daily,
    daily_transfer,
    daily_fees,
    daily_loan_trans,
    daily_acct_fund_trans,
  ];
  const allCommissionSum = [
    daily_commission,
    all_time_commission,
    daily_ref_bonus,
    all_time_ref_bonus,
    daily_signup_bonus,
    all_time_signup_bonus,
  ];
  const allUserSum = [
    user_with_verified_acct,
    user_with_unverfied,
    total_users,
    user_with_referral,
    users_with_bvn,
  ];

  summaryArray = [...allTransSum];
  commissionArray = [...allCommissionSum];
  userArray = [...allUserSum];
  // notification = [...allNotification];

  return { summaryArray, commissionArray, userArray };
};

export const SavInvestSummary = async (date, url, token) => {
  const today = new Date().toISOString().substring(0, 10);
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/${url}?from=${date}&to=${today}`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    // alert(JSON.stringify(data));
    return data;
  }
};

export const fetchReportMonth = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
