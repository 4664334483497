import {
  SET_PROMO,
  GET_PROMO,
  DELETE_PROMO,
  TOGGLE_PROMO,
  EDIT_PROMOTION,
  GET_SUBSCRIBERS,
  GET_PROMO_SUBSCRIBERS,
} from "../action/action.types";
import { combineReducers } from "redux";

const initialState = {
  success: false,
  loading: false,
  res_msg: "",
  allpromo: [],
};

const setPromotionConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMO:
      return {
        success: true,
        res_msg: action.payload.response_description,
      };
    default:
      return state;
  }
};

const getPromotionConfig = (state = initialState, action) => {
  switch (action.type) {
    case "PROMO_LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_PROMO:
      return {
        ...state,
        allpromo: action.payload,
        loading: false,
      };
    case DELETE_PROMO:
      return {
        ...state,
        response: action.payload.response_description,
      };
    case EDIT_PROMOTION:
      return {
        ...state,
        response: action.payload.response_description,
      };
    case TOGGLE_PROMO:
      return {
        ...state,
        toggle_res: action.payload.response_description,
      };

    default:
      return state;
  }
};

const getSubscriberConfig = (state = {}, action) => {
  switch (action.type) {
    case "SUB_LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIBERS:
      return {
        ...state,
        loading: false,
        subcribers: action.payload.allPromoSubscriptions,
      };
    case GET_PROMO_SUBSCRIBERS:
      return {
        ...state,
        loading: false,
        promotion_subscribers: action.payload.allPromoSubscriptions,
      };
    default:
      return state;
  }
};

export const promotionReducer = combineReducers({
  setPromo: setPromotionConfig,
  getPromo: getPromotionConfig,
  getSubscribers: getSubscriberConfig,
});
