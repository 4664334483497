import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import TransactionLimit from "./Transaction.limit";
import { Error } from "../../../../Error";

const Show = () => <div>wassa</div>;

const TransactionLimitRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={TransactionLimit} />
      {/* <Route exact component={Error} /> */}
    </Switch>
  </>
);
export default TransactionLimitRoute;
