import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import {
  getAdminRole,
  getAdmins,
} from "../../reduxStore/action/action.creator";
import Select, { components } from "react-select";

class AdminModal extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    staff_id: "",
    role_id: "1",
    lock: false,
    roles: [],
  };

  componentDidMount() {
    this.props.getAdminRole(this.props.token);
  }

  componentDidUpdate(prevProps, prevState) {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  addNew = () => {
    this.props
      .handleSubmit({
        ...this.state,
        roles: this.state.roles.map((role) => role.value),
      })
      .then(() => {
        this.props.closeModal();
      })
      .then(() => alert("Success"))
      .then(() => this.props.getAdmins(this.props.token, 1));
  };

  render() {
    const options =
      this.props?.data &&
      this.props?.data.map((item) => ({
        label: item.name,
        value: item.id,
        key: item.id,
      }));

    const Option = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                className="mr-2"
                style={{ width: "15px" }}
              />{" "}
              <label className="p-0 m-0">{props.label}</label>
            </div>
          </components.Option>
        </div>
      );
    };
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add New Admin"
      >
        <div className="px-4">
          <div className="box box9 mb-2">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1 mb-2">
            <label for="fee-name">Username</label>

            <div className="input-div">
              <input
                type="text"
                name="username"
                value={this.state.username}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2 mb-2">
            <label for="limitDescription">Password</label>

            <div className="input-div">
              <input
                type="password"
                name="password"
                value={this.state.password}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1 mb-2">
            <label>Staff Id</label>

            <div className="input-div">
              <input
                name="staff_id"
                value={this.state.staff_id}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2 mb-2">
            <label>Staff Role</label>
            <div>
              <Select
                isClearable
                isMulti
                onChange={(value) => {
                  this.setState({
                    ...this.state,
                    roles: value,
                  });
                }}
                value={this.state.roles}
                options={options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                components={{
                  Option,
                }}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    minHeight: "35px",
                    border: "0.5px solid #000000",
                    boxShadow: "none",
                  }),
                }}
              />
            </div>
          </div>

          <div className="box box1">
            <div className="row">
              <div className="col-md-12">
                <label>Lock Admin </label>
              </div>
            </div>

            <label style={{ display: "inline-block" }} class="custom-toggle">
              <input
                type="checkbox"

                // checked={props.enabled}
                // data-toggle="modal"
                // data-target="#exampleModal"
              />
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.addNew}
              >
                Add new Admin
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
  token: state.login.token,
});

export default connect(mapStateToProps, { getAdminRole, getAdmins })(
  AdminModal
);
