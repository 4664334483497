import React from "react";
import { connect } from "react-redux";
import InvestmentBar from "../InvestmentBar";
import { getInvestmentReturn } from "../../../reduxStore/action/action.creator";
import { callBackMethod, Loader } from "../../../components/Accessories";
import {
  InvestReturnBody,
  InvestReturnHead,
} from "../../../components/Rows/InvestmentTable";

class InvestmentReturn extends React.Component {
  state = {
    display: false,
  };

  componentDidMount() {
    this.props.getInvestmentReturn(
      "/savinvest/investment_returns",
      this.props.token
    );
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <InvestReturnHead />
          <tbody>
            {this.props.invest_return &&
              this.props.invest_return.map((content) => (
                <InvestReturnBody {...content} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <InvestmentBar active="returns">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}>
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              onClick={this.handleRefresh}>
              <i class="fas fa-sync-alt"></i> Refresh
            </a>

            {/* <a href="#" class="btn btn-sm btn-neutral" onClick={this.showModal}>
              <i class="fas fa-plus"></i> New
            </a> */}
          </div>
          <div className="table-responsive py-4 table-div">
            <table className="table table-flush" id="datatable-basic">
              {itemInTable}
            </table>
          </div>
        </InvestmentBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.invest.getData.loading,
  invest_return: state.invest.getData.invest_return,
});

export default connect(mapStateToProps, { getInvestmentReturn })(
  InvestmentReturn
);
