import { headers } from "./api.config";

export const bankTransactRequest = async (url, token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: headers(token),
  });
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};

export const filterBankTransact = async (data, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/bank_transfer_transactions?from=${data.startDate}&to=${data.endDate}&transaction_ref=${data.trans_ref}&amount=${data.amount}&source_account_no=${data.source_num}&dest_account_no=${data.dest_acct_num}&status=${data.status}&source_account_name=${data.source_name}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};

export const filterBankByStatus = async (status, page, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/bank_transfer_transactions?status=${status}&page=${page}&per_page=15`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const result = await response.json();
    const { data, total } = await result.data;
    return { data, total };
  }
};

export const sendRequery = async (admin_id, trans_ref, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transfers/requery_transaction`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify({
        admin_user_id: admin_id,
        transaction_ref: trans_ref,
      }),
    }
  );

  if (response.ok) {
    const { data, response_message } = await response.json();
    return { data, response_message };
  }
};

export const setReversal = async (admin_id, trans_id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/acc_transaction/reverse`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify({
        admin_user_id: admin_id,
        transaction_id: trans_id,
      }),
    }
  );

  if (response.ok) {
    const { data, response_message } = await response.json();

    return { data, response_message };
  }
};
