import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { FeesTableHead, FeeRow } from "../../../../components/Rows";
import "../../../../morestyles.css";
import {
  Modal,
  Loader,
  Pagination,
  LoaderTwo,
  CardBodyHolder,
  CardHeaderHolder,
} from "../../../../components/Accessories";
import {
  getFeeProfile,
  getTransCategory,
  getServiceCode,
  setFee,
  detailsInfo,
  getUserCategory,
} from "../../../../reduxStore/action/action.creator";
import Settings from "../../SettingNavbar";
import SideBar from "../../../../components/SideBar";
import DashboardNavBar from "../../../../components/DashboardNavbar";
import {
  updateFee,
  deleteFeeProfile,
  toggleFee,
} from "../../../../API/FeeRequest";
import Swal from "sweetalert2";

class Fees extends React.Component {
  state = {
    feeName: "",
    userId: "",
    userCategory: "",
    userType: "",
    level: "",
    transCategory: "",
    code: "",
    feeType: "percentage",
    feeValue: "",
    feeCap: "",
    range: false,
    minAmount: "",
    maxAmount: "",
    description: "",
    id: "",
    display: false,
    currentPage: 1,
    edit: false,
    id_to_edit: "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.successmsg) {
      this.setFeeIsSuccess(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.transcat.length > 0 &&
      prevState.transCategory != this.state.transCategory
    ) {
      this.props.getServiceCode(this.state.transCategory, this.props.token);
    }
  }

  setFeeIsSuccess = (nextProps) => {
    if (nextProps.successmsg) {
      this.setState({
        display: false,
      });
      Swal.fire({
        title: nextProps.successmsg
          ? "Successfully Added Fee Profile"
          : "An Error occured",
        text: "Do you want to continue",
        icon: "success",
        confirmButtonText: "Close",
      });
    }
    this.props.getFeeProfile(this.props.token);
  };

  componentDidMount() {
    this.props.getFeeProfile(this.props.token);
    this.props.getUserCategory(this.props.token);
  }

  changePage = (id) => () => {
    this.props.getFeeProfile(id, this.props.token);
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      this.props.getFeeProfile(id, this.props.token);
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      this.props.getFeeProfile(id, this.props.token);
      this.setState({
        currentPage: id,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = async () => {
    this.props.setFee(this.state, this.props.token);

    Object.keys(this.state).map((key) => {
      if (key !== "currentPage" && key !== "range") {
        this.state[key] = "";
      }
    });
  };

  getTransactionCategory = async () => {
    if (!this.props.transerr) {
      this.props.getTransCategory(this.props.token);
    }
  };

  toggleRange = () => {
    if (this.state.edit) {
      this.setState((prevState) => ({
        ...this.state,
        e_hasrange: !this.state.e_hasrange,
      }));
    } else {
      this.setState((prevState) => ({
        range: !prevState.range,
      }));
    }
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        display: true,
        edit: false,
      },
      this.getTransactionCategory
    );
  };

  closeModal = () => {
    this.setState({
      display: false,
      confirm_delete_modal: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getFeeProfile(this.props.token);
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  goToDetails = async (id) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/feesharing`,
      state: id,
    });
  };

  toggleHandler = async (id) => {
    try {
      const res = await toggleFee(id, this.props.token);
      this.props.getFeeProfile(this.props.token);
    } catch (error) {}
  };

  setUpEdit = async (data) => {
    this.setState({
      ...this.state,
      display: true,
      edit: true,
      id_to_edit: data._id,
      e_feename: data.name,
      e_category: data.userCategory,
      e_code: data.transCode,
      e_feeval: data.feeValue,
      e_feecap: data.feeCap,
      e_hasrange: data.hasRange,
      e_max: data.maxAmount,
      e_min: data.minAmount,
      e_desc: data.description,
    });
  };

  handleEdit = async () => {
    try {
      const data = {
        disableValue: !this.state.edit,
        id: this.state.id_to_edit,
        datatoedit: this.state,
      };
      const res = await updateFee(data, this.props.token);
      this.setState({
        display: false,
      });
      this.props.getFeeProfile(this.props.token);
    } catch (error) {}
  };

  showConfirmModal = (id) => {
    this.setState({
      ...this.state,
      confirm_delete_modal: true,
      idToDelete: id,
    });
  };
  handleDelete = async () => {
    try {
      const res = await deleteFeeProfile(
        this.state.idToDelete,
        this.props.token
      );
      this.props.getFeeProfile(this.props.token);
      this.setState({
        confirm_delete_modal: false,
      });
    } catch (error) {}
  };

  dropDown = () => {
    let show = "";
    if (this.state.edit) {
      show = this.state.e_hasrange ? "flex" : "none";
    } else {
      show = this.state.range ? "flex" : "none";
    }

    return show;
  };

  render() {
    let renderPaginate;
    let itemInTable;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <FeesTableHead />
          <tbody>
            {this.props.fees.map((content, index) => (
              <FeeRow
                {...content}
                {...this.props}
                index={this.getIndex(index + 1)}
                goToDetails={this.goToDetails}
                toggleHandler={this.toggleHandler}
                editProfile={this.setUpEdit}
                deleteProfile={this.showConfirmModal}
                categoryName={this.props.user_cat}
              />
            ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <CardHeaderHolder>
              <Settings {...this.props} active="Fees" />
            </CardHeaderHolder>

            <CardBodyHolder>
              <Tabs id="controlled-tab-example">
                <Tab eventKey="fees" title="Fees" tabClassName="mTab kycTab">
                  <div
                    className="table-responsive py-4"
                    style={{ maxHeight: "22em" }}>
                    <div
                      class="col-lg-12 col-5 text-right"
                      style={{ padding: "0 0.5em 1em" }}>
                      <a
                        href="#"
                        class="btn btn-sm btn-neutral"
                        onClick={this.handleRefresh}>
                        <i class="fas fa-sync-alt"></i> Refresh
                      </a>
                      <a
                        href="#"
                        class="btn btn-sm btn-neutral"
                        onClick={this.showModal}>
                        <i class="fas fa-plus"></i> New
                      </a>
                    </div>
                    <table className="table table-flush" id="datatable-basic">
                      {itemInTable}
                    </table>
                    {renderPaginate}
                  </div>
                </Tab>
              </Tabs>
            </CardBodyHolder>
          </div>
        </div>
        <Modal
          display={this.state.display ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Fee Profile">
          <div className="body-grid">
            <div className="box box1">
              <label for="fee-name">Title</label>
              <div className="input-div">
                <input
                  type="text"
                  name={this.state.edit ? "e_feename" : "feeName"}
                  value={
                    this.state.edit ? this.state.e_feename : this.state.feeName
                  }
                  id=""
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="box box2">
              <label for="userCategory">User Category</label>
              <div className="input-div">
                <select
                  name={this.state.edit ? "e_category" : "userCategory"}
                  value={
                    this.state.edit
                      ? this.state.e_category
                      : this.state.userCategory
                  }
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.user_cat &&
                    this.props.user_cat.map((content) => (
                      <option value={content.id}>{content.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="box box1">
              <label>Transaction Category</label>
              <div className="input-div">
                <select
                  name="transCategory"
                  value={this.state.transCategory}
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.transcat.map((option) => (
                    <option value={option._id}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="box box2">
              <label>Transaction Service Code</label>
              <div className="input-div">
                <select
                  name={this.state.edit ? "e_code" : "code"}
                  value={this.state.edit ? this.state.e_code : this.state.code}
                  onChange={this.handleChange}>
                  <option></option>
                  {this.props.servicecode.map((code) => (
                    <option
                      value={
                        this.state.edit ? this.state.e_code : code.serviceCode
                      }>
                      {code.serviceCode}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="box box3">
              <label>Fee Type</label>
              <div className="input-div" style={{ paddingLeft: "0" }}>
                <select
                  name="feeType"
                  value={this.state.feeType}
                  onChange={this.handleChange}>
                  <option>percentage</option>
                  <option>fixed</option>
                </select>
              </div>
            </div>
            <div
              className="box box4"
              style={{
                display: this.state.feeType === "percentage" ? "" : "none",
              }}>
              <label>Fee Value</label>
              <div className="input-div">
                <input
                  type="number"
                  name={this.state.edit ? "e_feeval" : "feeValue"}
                  value={
                    this.state.edit ? this.state.e_feeval : this.state.feeValue
                  }
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div
              className="box box5"
              style={{
                display: this.state.feeType === "percentage" ? "" : "none",
              }}>
              <label>Fee Cap</label>
              <div className="input-div">
                <input
                  type="number"
                  name={this.state.edit ? "e_feecap" : "feeCap"}
                  value={
                    this.state.edit ? this.state.e_feecap : this.state.feeCap
                  }
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="box box9">
              <div className="range-class">
                <span>
                  <input
                    type="checkbox"
                    checked={
                      this.state.edit ? this.state.e_hasrange : this.state.range
                    }
                    onChange={this.toggleRange}
                  />
                </span>
                <span>
                  <label className="range-label">Has Range</label>
                </span>
              </div>
              <div
                className="min-max"
                style={{
                  display: this.dropDown(),
                }}>
                <label>Minimum Amount</label>
                <div className="input-div">
                  <input
                    name={this.state.edit ? "e_min" : "minAmount"}
                    value={
                      this.state.edit ? this.state.e_min : this.state.minAmount
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <label>Maximum Amount</label>
                <div className="input-div">
                  <input
                    name={this.state.edit ? "e_max" : "maxAmount"}
                    value={
                      this.state.edit ? this.state.e_max : this.state.maxAmount
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="box box9">
              <label>Description</label>
              <div className="input-div">
                <textarea
                  onChange={this.handleChange}
                  placeholder="Enter Description here"
                  name={this.state.edit ? "e_desc" : "description"}
                  value={
                    this.state.edit ? this.state.e_desc : this.state.description
                  }></textarea>
              </div>
            </div>
            <div className="box box9">
              <div className="text-center">
                {this.state.edit ? (
                  <button
                    type="button"
                    class="btn btn-warning"
                    onClick={this.handleEdit}>
                    {!this.props.set_loading ? "Edit" : <LoaderTwo />}
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.handleSubmit}>
                    {!this.props.set_loading ? (
                      "Add Fee Profile"
                    ) : (
                      <LoaderTwo />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          display={this.state.confirm_delete_modal ? "block" : "none"}
          closeModal={this.closeModal}
          modalTitle="Fee Profile">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div>Sure you want to delete this ? </div>
            <div style={{ paddingTop: "1em" }}>
              <button className="btn btn-danger" onClick={this.handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fees: state.fees.allfees.feeprofile,
  loading: state.fees.allfees.loading,
  total: state.fees.allfees.total,
  err: state.fees.allfees.failed,
  transcat: state.alltransactions.transcategory.transCat,
  servicecode: state.alltransactions.transcategory.serviceCode,
  transerr: state.alltransactions.transcategory.failed,
  successmsg: state.fees.setfees.successMsg,
  failedmsg: state.fees.setfees.failed,
  set_loading: state.fees.setfees.loading,
  token: state.login.token,
  user_cat: state.users.getUserCat.user_cat,
});
export default connect(mapStateToProps, {
  getFeeProfile,
  getTransCategory,
  getServiceCode,
  setFee,
  detailsInfo,
  getUserCategory,
})(Fees);
