import { combineReducers } from "redux";
import {
	DELETE_INTEREST,
	EDIT_INTEREST,
	FILTER_INTEREST_EARNINGS,
	FILTER_INTEREST_HISTORIES,
	FILTER_INTEREST_PAYOUTS,
	GET_INTERESTS,
	GET_INTEREST_EARNINGS,
	GET_INTEREST_HISTORIES,
	GET_INTEREST_PAYOUTS,
	INTEREST_DETAIL,
	SETUP_INTEREST,
} from "../action/action.types";

const setInterestReducer = (state = {}, action) => {
	switch (action.type) {
		case SETUP_INTEREST:
			return {
				...state,
				response_msg: action.payload.result,
			};
		default:
			return state;
	}
};

const getInterestReducer = (state = {}, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case "DETAIL_LOADING":
			return {
				...state,
				detail_loading: true,
			};
		case GET_INTERESTS:
			return {
				...state,
				loading: false,
				interests: action.payload.data,
			};
		case INTEREST_DETAIL:
			return {
				...state,
				loading: false,
				interest_detail: action.payload.data,
			};
		case EDIT_INTEREST:
			return {
				...state,
				loading: false,
				response_msg: action.payload.response_message,
			};
		case DELETE_INTEREST:
			return {
				...state,
				loading: false,
				response_msg: action.payload.Response_message,
			};
		default:
			return state;
	}
};

const interestHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case GET_INTEREST_HISTORIES:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case FILTER_INTEREST_HISTORIES:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case "STOPLOAD":
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const interestEarningsReducer = (state = {}, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case GET_INTEREST_EARNINGS:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case FILTER_INTEREST_EARNINGS:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case "STOPLOAD":
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const interestPayoutsReducer = (state = {}, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case GET_INTEREST_PAYOUTS:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case FILTER_INTEREST_PAYOUTS:
			return {
				...state,
				loading: false,
				interests: action.payload.data.data,
				paginate: action.payload.data.meta,
			};
		case "STOPLOAD":
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export const interestReducer = combineReducers({
	set_interest: setInterestReducer,
	get_interest: getInterestReducer,
	interest_history: interestHistoryReducer,
	interest_earning: interestEarningsReducer,
	interest_payout: interestPayoutsReducer,
});
