import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { UserInvestment, UserInvestmentDetail } from "./AllInvestment";
import { InvestmentConfig, InvestmentProductDetail } from "./InvestmentConfig";
import { MerchantSetup, MerchantDetails } from "./Merchant";
import CompanyConfig from "./Company/CompanySetup";
import RoleConfig from "./Role/RoleConfig";
import InvestCategory from "./InvestmentCategory/InvestCategory";
import InvestmentReturn from "./Returns/InvestmentReturns";
import InvestDocument from "./InvestmentDocument/InvestDocument";
import InvestmentHistory from "./InvestmentHistory/InvestmentHistory";

const InvestmentRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/user-investment`} />
      </Route>
      <Route
        exact
        path={`${match.path}/user-investment`}
        component={UserInvestment}
      />
      <Route
        exact
        path={`${match.path}/user-investment/:id/details`}
        component={UserInvestmentDetail}
      />
      <Route
        exact
        path={`${match.path}/investment-config`}
        component={InvestmentConfig}
      />
      <Route
        exact
        path={`${match.path}/investment-config/:id/details`}
        component={InvestmentProductDetail}
      />
      <Route exact path={`${match.path}/merchant`} component={MerchantSetup} />
      <Route
        exact
        path={`${match.path}/merchant/:id/details`}
        component={MerchantDetails}
      />
      <Route exact path={`${match.path}/company`} component={CompanyConfig} />
      <Route exact path={`${match.path}/roles`} component={RoleConfig} />
      <Route exact path={`${match.path}/category`} component={InvestCategory} />
      <Route
        exact
        path={`${match.path}/investment_return`}
        component={InvestmentReturn}
      />
      <Route
        exact
        path={`${match.path}/invest-document`}
        component={InvestDocument}
      />
      <Route
        exact
        path={`${match.path}/invest-history`}
        component={InvestmentHistory}
      />
    </Switch>
  </>
);
export default InvestmentRoute;
