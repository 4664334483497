import React from "react";

export const BeneficiaryTableHead = () => (
  <thead className="thead-light" style={{ textAlign: "center" }}>
    <tr>
      <th>S/N</th>
      <th>User Type</th>
      <th>User ID</th>
      <th>Actions</th>
    </tr>
  </thead>
);

// export const BeneficiaryTableHead = () => (
//   <thead className="thead-light">
//     <tr>
//       <th>S/N</th>
//       <th>User Type</th>
//       <th>User ID</th>
//       <th>Actions</th>
//     </tr>
//   </thead>
// );

export const BeneficiaryRow = (props) => (
  <tr
    style={{
      backgroundColor: props.disabled ? "#fff" : "#fbfbfd",
    }}>
    <td>{props.index}</td>
    <td>{props.userType}</td>
    <td>{props.userId}</td>
    <td class="flex">
      <button
        style={{ display: "inline-block" }}
        type="button"
        class="btn btn-danger b-actions mb-3"
        data-toggle="modal"
        onClick={() => props.click(props._id)}
        data-target="#exampleModal">
        <i class="fas fa-trash-alt"></i>
      </button>
      <label style={{ display: "inline-block" }} class="custom-toggle">
        <input
          type="checkbox"
          onChange={() => props.toggleHandler(props._id)}
          checked={props.disabled}
          // data-toggle="modal"
          // data-target="#exampleModal"
        />
        <span class="custom-toggle-slider rounded-circle"></span>
      </label>
    </td>
  </tr>
);
