import { headers } from "./api.config";

const loginAdmin = async (data) => {
	const { email, password } = data;
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/user/admin/login`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email, password }),
		}
	);

	if (response.ok) {
		const { success, data } = await response.json();
		const { login_temp_token, requires_setup } = await data;
		return {
			success,
			temp_token: login_temp_token,
			setup: requires_setup,
			data,
		};
	} else {
		const { response_message } = await response.json();
		throw Error(response_message);
	}
};

export const sendQrCode = async (data) => {
	const { email, login_temp_token } = data;
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/user/admin/initiate_2fa`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email, login_temp_token }),
		}
	);

	if (response.ok) {
		const { success, data } = await response.json();
		const { QR_Image, secret } = await data;
		return {
			success,
			qr_image: QR_Image,
			secret: secret,
		};
	} else {
		const { response_message } = await response.json();
		throw Error(response_message);
	}
};

export const allowAdmin = async (data) => {
	const { email, login_temp_token, one_time_password } = data;
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/user/admin/verify_2fa`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				login_temp_token,
				one_time_password,
			}),
		}
	);

	if (response.ok) {
		const { success, data } = await response.json();
		const { token, permission } = await data;
		return { success, token, permission, data };
	} else {
		const { response_message } = await response.json();
		throw Error(response_message);
	}
};

export const changePass = async (data, token) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/user/admin/password/reset/${1}`,
		{
			method: "POST",
			headers: headers(token),
			body: JSON.stringify(data),
		}
	);

	if (response.ok) {
		const { success, response_message } = await response.json();
		return { success, response_message };
	}
};

export default loginAdmin;
