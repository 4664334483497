import React from "react";
import { connect } from "react-redux";
import KycBar from "../KycBar";
import {
  getKycDocuments,
  approveKyc,
  rejectKyc,
  giveFinalApproval,
  filterDocument,
  filterDocumentAccount,
  getUserDetails,
} from "../../../../../reduxStore/action/action.creator";
import {
  callBackMethod,
  Loader,
  Pagination,
} from "../../../../../components/Accessories";
import {
  DocumentTableHead,
  DocumentTableBody,
} from "../../../../../components/Rows/KycTable";
import KycDocumentModal from "./KycDocumentModal";
import EditDocumentModal from "./editDocumentModal";

class KycDocument extends React.Component {
  state = {
    display: false,
    filterVal: "",
    accountNumber: "",
    userName: "",
    currentPage: 1,
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.response_msg) {
    //   alert(nextProps.response_msg);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterVal != this.state.filterVal) {
      this.props.filterDocument(this.state.filterVal, this.props.token);
    }
  }

  searchAccount = (type) => () => {
    if (this.state.accountNumber || this.state.userName) {
    if(type  === 'username'){
      this.props.filterDocumentAccount(
        type,
        this.state.userName,
        this.props.token
      );
    } else{
      this.props.filterDocumentAccount(
        type,
        this.state.accountNumber,
        this.props.token
      );
    }
    }
  }

  componentDidMount() {
    this.props.getKycDocuments(this.state.currentPage, this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getKycDocuments(id, this.props.token);
    } else {
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getKycDocuments(id, this.props.token);
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getKycDocuments(id, this.props.token);
      } else {
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  approveDoc = (props) => {
    this.setState({
      display: true,
      reject: false,
      documentProps: props,
    });
  };

  rejectDoc = (props) => {
    this.setState({
      display: true,
      reject: true,
      documentProps: props,
    });
  };

  handleApproveKyc = (data) => {
    if (this.state.reject == true) {
      callBackMethod(this.props.rejectKyc(data, "reject", this.props.token))
        .then(() => {
          this.setState({
            display: false,
          });
        })
        .then(() => alert(this.props.response_msg))
        .then(() =>
          this.props.getKycDocuments(this.state.currentPage, this.props.token)
        );
    } else {
      callBackMethod(this.props.approveKyc(data, "approved", this.props.token))
        .then(() => {
          this.setState({
            display: false,
          });
        })
        .then(() => alert(JSON.stringify(this.props.response_msg)))
        .then(() =>
          this.props.getKycDocuments(this.state.currentPage, this.props.token)
        );
    }
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      editing: false,
    });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.getKycDocuments(this.state.currentPage, this.props.token);
  };

  goToNextPage = (prop) => {
    const { id } = prop;

    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/details`,
      state: id,
    });
  };

  editDocument = (props) => {
    this.setState({
      ...this.state,
      editing: true,
      idToEdit: props.id,
    });
  };

  isValidUrl = (url_string) => {
    let url;
    try {
      url = new URL(url_string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  viewDoc = (path) => {
    const location = window.open(
      this.isValidUrl(path) ? `${path}` : `${process.env.REACT_APP_DOCUMENT_BASE_URL}${path}`,
      "_blank"
    );
    location.focus();
  };

  finalApprove = (data) => {
    // alert(props.user_id);
    callBackMethod(
      this.props.giveFinalApproval(
        data,
        `final-approved/kyc_level/request`,
        this.props.token
      )
    )
      .then(() => alert(this.props.response_msg))
      .then(() =>
        this.props.getKycDocuments(this.state.currentPage, this.props.token)
      );
  };

  handleChange = (e) => {
    this.setState({
      filterVal: e.target.value,
    });
  };

  handleAccountChange = (e) => {
    this.setState({
      accountNumber: e.target.value,
    });
  };

  handleUserNameChange = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };

  displayUser = (e, id) => {
    e.preventDefault();
    this.props.getUserDetails(id, this.props.token);
    this.setState({
      showUser: true,
    });
  };

  closePopup = () => {
    this.setState({
      showUser: false,
    });
  };

  render() {
    let renderPaginate;
    let itemToDisplay;

    const total =
      this.props.total && parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(total).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));

    // if (this.props.loading) {
    //   itemToDisplay = <Loader loading={this.props.loading} />;
    // } else {
      itemToDisplay = (
        <>
          <DocumentTableHead />
          <tbody>
            {this.props.kyc_docs.data.map((content, index) => (
              <DocumentTableBody
                {...content}
                index={this.getIndex(index + 1)}
                approveDoc={this.approveDoc}
                rejectDoc={this.rejectDoc}
                viewDetails={this.goToNextPage}
                editDocument={this.editDocument}
                viewDoc={this.viewDoc}
                finalApprove={this.finalApprove}
                displayUser={this.displayUser}
                user_details={this.state.user}
                closePopup={this.closePopup}
                showUser={this.state.showUser}
              />
            ))}
          </tbody>
        </>
      );
    // }
    return (
      <>
        <KycBar {...this.props} active="Document">
          <div
            class="col-lg-12 col-5 text-right"
            style={{ padding: "0 0.5em 1em" }}
          >
            <div
              style={{
                display: "inline",
                width: "30%",
                padding: "1em",
                // backgroundColor: "red",
              }}
            >
              <select
                value={this.state.filterVal}
                name="filterVal"
                onChange={this.handleChange}
                className="form-control"
                style={{ width: "inherit" }}
              >
                <option></option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
                <option value="approved">Approved</option>
              </select>
            </div>

            {/* <div className="d-flex w-50 justify-content-center">
              <input
                className="form-control mr-5"
                type="search"
                value={this.state.userName}
                name="userName"
                onChange={this.handleUserNameChange}
                onBlur={ this.searchAccount('username')}
                placeholder="Search by user name"
              />
              <input
                className="form-control"
                type="search"
                placeholder="Search by account number"
                value={this.state.accountNumber}
                name="accountNumber"
                onChange={this.handleAccountChange}
                onBlur={this.searchAccount('account_number')}
              />
            </div> */}
            <a
              href="#"
              class="btn btn-sm btn-neutral"
              style={{ display: "inline" }}
              onClick={this.handleRefresh}
            >
              <i class="fas fa-sync-alt"></i> Refresh
            </a>
          </div>
          <div className="table-responsive py-4">
            <table className="table table-flush" id="datatable-basic">
              {itemToDisplay}
            </table>
            {renderPaginate}
          </div>
        </KycBar>
        <KycDocumentModal
          display={this.state.display}
          reject={this.state.reject}
          closeModal={this.closeModal}
          documentProps={this.state.documentProps}
          verifyKyc={this.handleApproveKyc}
        />
        <EditDocumentModal
          display={this.state.editing}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.kyc.kyc_doc.loading,
  kyc_docs: state.kyc.kyc_doc.documents,
  total: state.kyc.kyc_doc.total,
  response_msg: state.kyc.kyc_doc.data,
  token: state.login.token,
  filtering: state.kyc.kyc_doc.filtering,
});

export default connect(mapStateToProps, {
  getKycDocuments,
  approveKyc,
  rejectKyc,
  giveFinalApproval,
  filterDocument,
  filterDocumentAccount,
  getUserDetails,
})(KycDocument);
