import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CommissionSharing from "./CommissionShare";
import Commission from "./Commission";
import { Error } from "../../../Error";

const CommissionRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Commission} />
      <Route
        exact
        path={`${match.path}/commissionshare`}
        component={CommissionSharing}
      />
      <Route exact component={Error} />
    </Switch>
  </>
);
export default CommissionRoute;
