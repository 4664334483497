import { combineReducers } from "redux";
import { getKycLevel, setKycLevelSettings } from "../action/action.creator";
import {
  APPROVE_DOCUMENT,
  DELETE_KYC_LEVEL,
  DELETE_REQUIREMENT,
  EDIT_KYC_LEVEL,
  FILTER_DOCUMENT,
  FINAL_APPROVAL,
  GET_DOCUMENT,
  GET_LEVEL,
  GET_LEVEL_SETTINGS,
  GET_CUSTOMER_DOCUMENT,
  GET_CUSTOMER_DOCUMENTS,
  GET_CUSTOMER_DOCUMENTS_PAGE,
  GET_KYC_UPGRADES,
  GET_KYC_UPGRADES_PAGE,
  GET_REQUIREMENT,
  KYC_LEVEL_DETAIL,
  REJECT_DOCUMENT,
  SET_LEVEL,
  SET_LEVEL_SETTINGS,
  SET_REQUIREMENT,
  ADD_DOCUMENT,
} from "../action/action.types";

const initialState = {
  loading: false,
  documents: [],
  data: "",
};

const KycDocumentReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENT:
      return {
        ...state,
        loading: false,
        filtering: false,
        documents: action.payload.data,
        total: action.payload.total,
      };
    case APPROVE_DOCUMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.response_message,
      };
    case REJECT_DOCUMENT:
      return {
        ...state,
        data: action.payload.response_message,
      };
    case FINAL_APPROVAL:
      return {
        ...state,
        data: action.payload.response_message,
      };
    case FILTER_DOCUMENT:
      return {
        ...state,
        loading: false,
        filtering: true,
        documents: action.payload.data,
      };
    default:
      return state;
  }
};

const setKycDocument = (state = {}, action) => {
  switch (action.type) {
    case ADD_DOCUMENT:
      return {
        ...state,
        response_msg: action.payload.result,
      };
    default:
      return state;
  }
};

const setKycLevelReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LEVEL:
      return {
        ...state,
        response_msg: action.payload.result,
      };
    default:
      return state;
  }
};

const getKycLevelReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "DETAIL_LOADING":
      return {
        ...state,
        detail_loading: true,
      };
    case GET_LEVEL:
      return {
        ...state,
        loading: false,
        levels: action.payload.data,
      };
    case KYC_LEVEL_DETAIL:
      return {
        ...state,
        loading: false,
        level_detail: action.payload.data,
      };
    case DELETE_KYC_LEVEL:
      return {
        ...state,
        loading: false,
        response_msg: action.payload.Response_message,
      };
    case EDIT_KYC_LEVEL:
      return {
        ...state,
        loading: false,
        response_msg: action.payload.response_message,
      };
    default:
      return state;
  }
};

const setKycLevelSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LEVEL_SETTINGS:
      return {
        ...state,
        response: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getKycLevelSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL_SETTINGS:
      return {
        ...state,
        loading: false,
        level_settings: action.payload.data,
      };
    default:
      return state;
  }
};

const setKycLevelRequirementReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_REQUIREMENT:
      return {
        ...state,
        response: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getKycLevelRequirementReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_REQUIREMENT:
      return {
        ...state,
        loading: false,
        requirement: action.payload.data,
      };
    case DELETE_REQUIREMENT:
      return {
        ...state,
        loading: false,
        response_message: action.payload.Response_message,
      };
    default:
      return state;
  }
};

const getCustomerDocReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_DOCUMENT:
      return {
        loading: false,
        customer_doc: action.payload.data,
      };
    case GET_CUSTOMER_DOCUMENTS:
      return {
        loading: false,
        customer_docs: action.payload.data,
      };
    case GET_CUSTOMER_DOCUMENTS_PAGE:
      return {
        ...state,
        loading: false,
        customer_docs: action.payload.doc_data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

const getKycRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_KYC_UPGRADES:
      // console.log(action.payload.data);
      return {
        loading: false,
        kyc_request: action.payload.data,
      };
    case GET_KYC_UPGRADES_PAGE:
      return {
        ...state,
        loading: false,
        kyc_request: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};

export const kycReducer = combineReducers({
  kyc_doc: KycDocumentReducer,
  set_doc: setKycDocument,
  set_kyc_level: setKycLevelReducer,
  get_kyc_level: getKycLevelReducer,
  set_level_settings: setKycLevelSettingReducer,
  get_level_settings: getKycLevelSettingReducer,
  set_requirement: setKycLevelRequirementReducer,
  get_requirement: getKycLevelRequirementReducer,
  get_kyc_upgrade: getKycRequestReducer,
  get_customer_doc: getCustomerDocReducer,
});
